import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

export default function ToggleButtons({ Options, onChange, value }) {
  const handleChange = (e, newAlignment) => {
    onChange(newAlignment);
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.root}`]: {
      borderRadius: "15px",
    },

    [`& .${toggleButtonGroupClasses.grouped}`]: {
      textTransform: "none",
    },
    [`& .${toggleButtonGroupClasses.selected}`]: {
      backgroundColor: "#4367B0",
      color: "#fff",
    },
    [`& .${toggleButtonGroupClasses.firstButton}`]: {
      borderStartStartRadius: "10px",
      borderEndStartRadius: "10px",
    },
    [`& .${toggleButtonGroupClasses.lastButton}`]: {
      borderEndEndRadius: "10px",
      borderStartEndRadius: "10px",
    },
  }));

  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {Options?.map((option) => (
        <ToggleButton value={option.value}>{option.label}</ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
