import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CrmBatchList.scss";
import { Link } from "react-router-dom";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import ImageList from "../../../utils/ImageList";
import { getCRMBatches } from "../../../interface/implementation/getCRMBatchesAPIImp";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function CrmBatchList() {
	const navigate = useNavigate();
	const token = Cookies.get("token");

	const [crmBatchList, setCRMBatchList] = useState([]);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getCRMBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCRMBatchList(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = crmBatchList.slice(indexOfFirstItem, indexOfLastItem);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);
	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;

	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems =
		crmBatchList && crmBatchList.length > 0
			? crmBatchList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="crmBatchList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Batch List</div>
					<div className="batchListContainer">
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch Name</th>
										<th>Code</th>
										<th>Course</th>
										<th>Start date</th>
										<th>End date</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((batch, index) => (
											<tr key={batch.batch_id}>
												<td>{startSerialNumber + index}</td>
												<td>{batch.batch_name}</td>
												<td>{batch.batch_code}</td>
												<td>{batch.course_name}</td>
												<td>
													{batch.start_date &&
														batch.start_date.split("-").reverse().join("/")}
												</td>
												<td>
													{batch.end_date &&
														batch.end_date.split("-").reverse().join("/")}
												</td>
												<td className="actionBtn">
													<Link
														to={{
															pathname: `/crm-batch/batch-list/${batch.batch_id}/my-students`,
														}}
														state={{ batchId: batch.batch_id }}
														className="editBtn"
													>
														<img src={ImageList.StudentIcon} alt="" />
														My Participants
													</Link>
													<Link
														to={{
															pathname: `/crm-batch/batch-list/${batch.batch_id}/all-students`,
														}}
														state={{ batchId: batch.batch_id }}
														className="editBtn"
														style={{ backgroundColor: "#FF742E" }}
													>
														<img src={ImageList.StudentIcon} alt="" />
														All Participants
													</Link>
													<Link
														to={{
															pathname: `/crm-batch/batch-list/${batch.batch_id}/homework`,
														}}
														state={{
															batchId: batch.batch_id,
															batchName: batch.batch_name,
														}}
														className="editBtn"
														style={{ backgroundColor: "#FF742E" }}
													>
														<img src={ImageList.StudentIcon} alt="" />
														Homework
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					{currentItems && currentItems.length > 0 && (
						<div className="pagination">
							<button
								onClick={() => paginate(currentPage - 1)}
								disabled={currentPage === 1}
							>
								{"<"}
							</button>
							{Array.from(
								{ length: Math.ceil(crmBatchList.length / itemsPerPage) },
								(_, index) => (
									<button
										key={index}
										onClick={() => paginate(index + 1)}
										className={currentPage === index + 1 ? "active" : ""}
									>
										{index + 1}
									</button>
								)
							)}
							<button
								onClick={() => paginate(currentPage + 1)}
								disabled={
									currentPage === Math.ceil(crmBatchList.length / itemsPerPage)
								}
							>
								{">"}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default CrmBatchList;
