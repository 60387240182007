import getTermsStatusAPI from '../apis/getTermsStatusAPI';

export function getTermsStatus(token){
    return new Promise(async(resolve, reject) => {
        getTermsStatusAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}