import React from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentBatch.scss";
import ImageList from "../../../utils/ImageList";
import StudentTable from "../../../components/StudentTable/StudentTable";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getStudentCourse } from "../../../interface/implementation/student/getStudentCourseAPIImp";
import { Link, useNavigate } from "react-router-dom";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { accessRequest } from "../../../interface/implementation/student/accessRequestAPIImp";
import RequestAccessModal from "../../../components/RequestAccessModal/RequestAccessModal";

const StudentBatch = () => {
	const [StudentBatchList, setStudentBatchList] = useState([]);
	const [showRequestModal, setShowRequestModal] = useState(false);
	const [selectedStudentBatch, setSelectedStudentBatch] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);

	const navigate = useNavigate();
	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getStudentCourse(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setStudentBatchList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	const handleRequestAccessModal = (selectedStudent) => {
		setShowRequestModal(true);
		setSelectedStudentBatch(selectedStudent);
	};

	const handleReload = () => {
		setReloadUsers(!reloadUsers);
	};
	const Headers = [
		"Sl. No.",
		"Batch",
		"Start Date",
		"CRM Name / Phone number",
		"Payment Due Date",
		"View / Class / Homework",
		"Invoice",
		"Pay",
	];

	return (
		<Layout
			headerChildren={
				<>
					<Button
						buttonName={"Create CRM Help Ticket"}
						background={"#4367B0"}
						buttonIcon={ImageList.ButtonTicketIcon}
						color={"#fff"}
						style={{
							height: "35px",
						}}
						onClick={() => {
							navigate("/student/course/ticket");
						}}
					/>
					{/* <Button
            buttonName={"T&C"}
            background={"#DE4B39"}
            buttonIcon={ImageList.AgreementIcon}
            color={"#fff"}
            style={{
              height: "35px",
            }}
            onClick={() => {}}
          /> */}
				</>
			}
			title={"Participant Batch"}
		>
			<ToastContainer limit={1} />
			<StudentTable
				headers={Headers}
				children={
					StudentBatchList &&
					StudentBatchList.length > 0 &&
					StudentBatchList.map((data, index) => {
						return (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>{data.batch_name}</td>
								<td>{data.batch_start_date.split("-").reverse().join("/")}</td>
								<td>
									<div className="CRMWrapper">
										<div className="crmNameWrapper">
											<span className="crmIconWrapper">
												<img className="crmIcon" src={ImageList.ProfileIcon} />
											</span>
											<span className="crmContent">
												{data.crm_data.batch_crm_name}
											</span>
										</div>{" "}
										<div className="crmNameWrapper">
											<span className="crmIconWrapper">
												<img className="crmIcon" src={ImageList.PhoneIcon} />
											</span>
											<span className="crmContent">
												{data.crm_data.batch_crm_phone_number}
											</span>
										</div>
									</div>
								</td>

								<td>
									{data.payment_due_date && (
										<span>
											{data.payment_due_date.split("-").reverse().join("/")}
										</span>
									)}
								</td>

								<td>
									{data.payment_status === "not_paid" ? (
										<>
											{!data.access_request_status && (
												<button
													className="buttonWrapper"
													onClick={() => handleRequestAccessModal(data)}
												>
													<span className="buttonIconWrapper">
														{/* <img src={ImageList.ClassifyIcon} alt="" srcset="" /> */}
													</span>
													<span className="buttonTitle">Access Request</span>
												</button>
											)}

											{data.access_request_status === "pending" &&
												"Request approval pending"}
											{data.access_request_status === "rejected" &&
												"Request approval rejected. Contact CRM"}

											<button className="buttonWrapperNonclickable">
												<span className="buttonIconWrapper">
													<img src={ImageList.ClassifyIcon} alt="" srcSet="" />
												</span>
												<span className="buttonTitle">Classes</span>
											</button>
										</>
									) : (
										<Link
											state={{
												batchId: data.batch_id,
												batchName: data.batch_name,
											}}
											to={"/student/course/class-list"}
										>
											<button className="buttonWrapper">
												<span className="buttonIconWrapper">
													<img src={ImageList.ClassifyIcon} alt="" srcSet="" />
												</span>
												<span className="buttonTitle">Classes</span>
											</button>
										</Link>
									)}
								</td>
								<td>{data.invoice}</td>
								<td>{data.pay}</td>
							</tr>
						);
					})
				}
			/>
			{showRequestModal && (
				<RequestAccessModal
					onClick={() => {
						setShowRequestModal(false);
						handleReload();
					}}
					requestDetails={selectedStudentBatch}
				/>
			)}
		</Layout>
	);
};

export default StudentBatch;
