import getAllStateListsAPI from "../apis/getAllStateListsAPI";

export function getAllStatesList(token) {
	return new Promise(async (resolve, reject) => {
		getAllStateListsAPI
			.getAll(token)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
