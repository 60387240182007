import { ApiCore } from "../../apiCore";

const apiTasks = new ApiCore({
  getAll: false,
  getSingle: true,
  postEncoded: false,
  post: false,
  put: false,
  delete: false,
  url: `api/admin/students/get-students-by-batch`,
  version: "",
});

export default apiTasks;
