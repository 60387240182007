import React, { useEffect } from "react";
import "./FooterPage.scss";
import mail from "../../../assets/mail.svg";
import phone from "../../../assets/phone.svg";
import fb from "../../../assets/fb.svg";
import yt from "../../../assets/yt.svg";
import linkdin from "../../../assets/linkdin.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const FooterPage = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);
	return (
		<div className="FooterContainer">
			<div className="FooterDetails">
				{/* <div data-aos="fade-up" data-aos-once={true}>
					<label htmlFor="">ABOUT RAHUL JAIN</label>
					<p>
						He conceptualized Business Coaching India in 2004, Over the last 19
						years, he has coached over 21,000 business owners, across all
						verticals. Read More...
					</p>
				</div> */}
				<div data-aos="fade-up" data-aos-once={true}>
					<label htmlFor="">REACH US</label>
					<div className="socialMedia">
						<img src={fb} alt="" />
						<img src={yt} alt="" />
						<img src={linkdin} alt="" />
					</div>
				</div>
				<div data-aos="fade-up" data-aos-once={true}>
					<label htmlFor="">CALL US</label>
					<label htmlFor="">BUSINESS COACHING INDIA LLP</label>
					<p>
						VIPPS Centre 2. 2nd Floor, Behind JMD Mall, Masjid Moth Greater
						Kailash Part 2, New Delhi - 110048
					</p>
				</div>
			</div>
			<div className="FooterContact">
				<label
					className="contactTitle"
					htmlFor="title"
					data-aos="fade-up"
					data-aos-once={true}
				>
					Get Connected
				</label>
				<div className="hrLine" data-aos="fade-up" data-aos-once={true}></div>
				<label
					className="subTitle"
					htmlFor="HLPLINE"
					data-aos="fade-up"
					data-aos-once={true}
				>
					BCI Helpline
				</label>
				<div className="contactDetails" data-aos="fade-up" data-aos-once={true}>
					<div>
						<img src={mail} alt="" /> rahuljain@bcoachindia.com
					</div>
					<div>
						<img src={phone} alt="" /> +91 95608 06807
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterPage;
