import React, { useState, useEffect } from "react";
import ImageList from "../../utils/ImageList";
import "./TopHeader.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { userDetails } from "../../interface/implementation/userDetailsAPIImp";
import { showToasterMessage } from "../../utils/helper";

function TopHeader({ dataHidden }) {
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState("");
	const [user, setUser] = useState({});

	const handleInputChange = (e) => {
		setSearchValue(e.target.value);
	};
	const token = Cookies.get("token");

	useEffect(() => {
		if (!dataHidden) {
			const userName = localStorage.getItem("userName");
			userDetails(token, userName)
				.then((res) => {
					if (res.status === "failure") {
						showToasterMessage(res.message);
						return;
					}

					setUser(res.user);
				})
				.catch();
		}
	}, []);

	return (
		<div className="topHeader">
			<div className="headerRight">
				<img src={ImageList.BCILogo} alt="bci-logo" className="logo" />
				<div className="loginForm">
					<div>
						{/* <div className="inputSearch">
              <img src={ImageList.SearchIcon} alt="search-icon" className="searchIcon" />
              <input
                type="text"
                id="search"
                placeholder="Search By Company name/ Phone No."
                onChange={handleInputChange}
                value={searchValue}
              />
              <div className="searchBtnContainer">

              <button className="searchBtn">Search</button>
              </div>
            </div> */}
					</div>
				</div>
			</div>
			{!dataHidden && (
				<div
					className="avatar"
					onClick={() => {
						navigate("/profile");
					}}
				>
					<img
						src={
							user.profile_picture
								? user.profile_picture[0].content
								: ImageList.AvatarIcon
						}
						alt=""
						className="avatarIcon"
					/>
					<p className="number">{user.full_name}</p>
				</div>
			)}
		</div>
	);
}

export default TopHeader;
