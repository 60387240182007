import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./WorkshopParticipantList.scss";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { getStudentBatchList } from "../../../interface/implementation/admin/getStudentBatchListAPIImp";
import { searchBatches } from "../../../interface/implementation/admin/searchBatchesAPIImp";
import { autoSearchBatches } from "../../../interface/implementation/admin/autoSearchBatchesAPIImp";
import { useNavigate, useLocation } from "react-router-dom";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { downloadFilteredStudents } from "../../../interface/implementation/admin/donwloadFilteredStudentsAPIImp";
import ReplaceModal from "../../../components/ReplaceModal/ReplaceModal";

import { getAllWorkshopStudent } from "../../../interface/implementation/admin/getAllWorkshopStudentAPIImp";
import { donwloadFilteredWorkshopStudents } from "../../../interface/implementation/admin/donwloadFilteredWorkshopStudentsAPIImp";
import { searchWorkshopStudent } from "../../../interface/implementation/admin/searchWorkshopStudentAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { logout } from "../../../interface/implementation/logoutAPIImp";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";
function WorkshopParticipantList() {
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const navigate = useNavigate();

	const location = useLocation();
	const workshopId = location.state.workshopId;
	// const batchId = location.state.batchId;
	const [search, setSearch] = useState("");

	const token = Cookies.get("token");
	const [batchStudentList, setBatchStudentList] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	// const [currentItems, setCurrentItems] = useState([]);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState([]);

	const [workshopName, setWorkshopName] = useState("");

	const [showModal, setShowModal] = useState(false);
	const [replaceData, setReplaceData] = useState({});

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);

	useEffect(() => {
		let body = `?workshop_id=${workshopId}`;
		getAllWorkshopStudent(token, body)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchStudentList(res);
				const uniqueWorkshopsObj = res?.reduce((acc, workshop) => {
					acc[workshop.workshop_id] = workshop;
					return acc;
				}, {});

				const uniqueWorkshops = Object.values(uniqueWorkshopsObj);
				setDropdownOptions(uniqueWorkshops);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	// useEffect(() => {
	//   const fetchDropdowns = async () => {
	//     await getCRM(token).then((res) => {
	//       if (res.detail === "Signature has expired.") {
	//         navigate("/");
	//         showToast("Token has expired. Please log in again.");
	//         return;
	//       }
	//       if (res.status === "failure") {
	//         showToast(res.message);
	//         return;
	//       }
	//       setDropdownOptions((prevData) => ({
	//         ...prevData,
	//         crm: res.users,
	//       }));
	//     });
	//   }
	//   fetchDropdowns()
	// }, [])

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleReload = () => {
		setReloadUsers((prevReloadUsers) => !prevReloadUsers);
	};

	const handleSearch = async () => {
		let newBody = `?workshop_id=${workshopId}`;

		if (search) {
			newBody += `&search=${search}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const searchFilter = (newBody) => {
		searchWorkshopStudent(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchStudentList(res.student_details);
				setCurrentPage(1);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems = batchStudentList
		? batchStudentList?.slice(indexOfFirstItem, indexOfLastItem)
		: [];

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleDownload = () => {
		let newBody = `?workshop_id=${workshopId}`;

		if (search) {
			newBody += `&search=${search}`;
		}

		donwloadFilteredWorkshopStudents(
			token,
			`?workshop_id=${workshopId}&search=${search}`
		)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
				const filename = `Participants-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch();
	};
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;

	return (
		<div className="batchStudentList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Batch Participants List</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs batchStudentSearch">
								{/* <div className="inputContainer">
                  <label htmlFor="crm">Workshop Name</label>
                  <div className="inputCode">
                    <select
                      id="crm"
                      value={workshopName}

                      onChange={(e) => {
                        setWorkshopId(e.target.value);
                        handleInputChange(e, setWorkshopName)
                      }}
                    >
                      <option value="">Select Workshop Name</option>
                      {dropdownOptions?.map((option, i) => (
                        <option key={i} value={option.workshop_id}>
                          {option.workshop_title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

								<div className="inputContainer">
									<label htmlFor="search">Search</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder="Company name..."
											onChange={(e) => {
												handleInputChange(e, setSearch);
											}}
											value={search}
										/>
									</div>
								</div>

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>

								{selectedRole !== 3 && (
									<div className="searchBtnContainer">
										<button className="searchBtn" onClick={handleDownload}>
											Download
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="batchStudentTable">
								<thead>
									<tr>
										<th>Sl. No.</th>
										{/* <th>Company Name</th> */}
										<th>Workshop Name</th>
										<th>Participant Name</th>
										<th>Phone</th>
										<th>Participant Email</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((batchData, index) => (
											<tr key={index}>
												<td>{startSerialNumber + index}</td>
												{/* <td style={{ whiteSpace: "wrap" }}>{batchData.company_name}</td> */}
												<td>{batchData.workshop_title}</td>
												<td style={{ textTransform: "capitalize" }}>
													{batchData.student_name}
												</td>
												<td>{batchData.student_phone_number}</td>
												<td>{batchData.student_email_id}</td>
											</tr>
										))}
								</tbody>
							</table>
							{showModal && (
								<ReplaceModal
									onClick={() => setShowModal(false)}
									replaceData={replaceData}
									handleReload={handleReload}
								/>
							)}
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(batchStudentList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WorkshopParticipantList;
