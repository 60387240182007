import React from "react";
import "./ThankYou.scss";
import SuccessImg from "../../assets/successTick.png";
import TopHeader from "../../components/TopHeader/TopHeader";
import { useNavigate } from "react-router-dom";

function ThankYou() {
  const navigate = useNavigate();
  const takeMeToLogin = () => {
    navigate("/");
  };
  return (
    <>
      <TopHeader dataHidden={true} />
      <div className="thankYouContainer">
        <img src={SuccessImg} alt="" />
        <h1>Thank You!</h1>
        <p className="info">You have successfully registered & marked your attendance.</p>
        {/* <button onClick={takeMeToLogin} className="navBtn">Go to Login</button> */}
      </div>
    </>
  );
}

export default ThankYou;
