import getStudentPaymentInfoAPI from '../../apis/student/getStudentPaymentInfoAPI';


export function getStudentPaymentInfo(token, body){
    return new Promise(async(resolve, reject) => {
      getStudentPaymentInfoAPI.sort(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}