import React, { useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const Pagination = ({ totalPages, currentPage, onPageChange, isLoading }) => {
	const [page, setPage] = useState(currentPage);
	const [inputValue, setInputValue] = useState(currentPage);

	const handlePreviousClick = () => {
		if (page > 1) {
			setPage(page - 1);
			onPageChange(page - 1);
			setInputValue(page - 1);
		}
	};

	const handleNextClick = () => {
		if (page < totalPages) {
			setPage(page + 1);
			onPageChange(page + 1);
			setInputValue(page + 1);
		}
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleInputBlurOrEnter = () => {
		const newPage = Math.max(1, Math.min(totalPages, Number(inputValue)));
		setPage(newPage);
		onPageChange(newPage);
		setInputValue(newPage);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleInputBlurOrEnter();
		}
	};

	return (
		<div style={styles.paginationContainer}>
			<button onClick={handlePreviousClick} style={styles.button}>
				&lt;
			</button>
			<div style={styles.pageInfo}>
				Page
				{isLoading && <LoadingSpinner isLoading={true} />}
				<input
					type="number"
					value={inputValue}
					onChange={handleInputChange}
					onBlur={handleInputBlurOrEnter}
					onKeyDown={handleKeyDown}
					style={styles.input}
					min="1"
					max={totalPages}
				/>
				of {totalPages}
			</div>
			<button onClick={handleNextClick} style={styles.button}>
				&gt;
			</button>
		</div>
	);
};

const styles = {
	paginationContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "7px 10px",
		borderRadius: "8px",
		backgroundColor: "#fff",
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
	},
	button: {
		border: "none",
		background: "none",
		fontSize: "24px",
		cursor: "pointer",
		outline: "none",
	},
	pageInfo: {
		display: "flex",
		alignItems: "center",
		margin: "0 10px",
	},
	input: {
		width: "4rem",
		textAlign: "center",
		margin: "0 5px",
		fontSize: "16px",
		border: "1px solid #ccc",
		borderRadius: "4px",
	},
};

export default Pagination;
