import getBatchCRMPaymentInfoAPI from '../../apis/batchCRM/getBatchCRMPaymentInfoAPI';


export function getBatchCRMPaymentInfo(token){
    return new Promise(async(resolve, reject) => {
      getBatchCRMPaymentInfoAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}