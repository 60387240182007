import getMemberAmountAPI from '../../apis/admin/getMemberAmountAPI';


export function getMemberAmount(token,id){
    return new Promise(async(resolve, reject) => {
        getMemberAmountAPI.getSingle(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}