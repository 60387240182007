import React, { useState } from "react";
import "./ConfirmationModal.scss";
import Cookies from "js-cookie";
import { showSuccessToast, showToasterMessage } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { studentVideoRequest } from "../../interface/implementation/student/studentVideoRequestAPIImp";
import { deletePayment } from "../../interface/implementation/admin/deletePaymentAPIImp";

function ConfirmationModal({ onClick, paymentId, handleReload }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleCloseClick = () => {
		onClick();
	};
	const handleSaveClick = () => {
		let body = {
			payment_id: paymentId,
		};
		deletePayment(token, body)
			.then((res) => {
				showSuccessToast("Deleted successfully");
				handleReload();
				onClick();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="video-modal-overlay ConfirmationModal">
			<div className="video-modal-content">
				<div className="video-modal-header">
					<p className="video-title">Delete installment</p>
					<p className="closeBtn" onClick={handleCloseClick}>
						x
					</p>
				</div>
				<div className="body">
					<div className="inputContainer">
						<label htmlFor="selectedParticipantType">
							Do you want to delete this Installment? This action cannot be
							undone.
						</label>
						<div className="inputCode">
							{/* <input
                type="text"
                id="comments"
                placeholder="Enter your reason for access"
                onChange={(e)=> setComments(e.target.value)}
                value={comments}
              /> */}
						</div>
					</div>
				</div>
				<div className="saveButtonWrapper">
					{/* <button className='approveBtn' onClick={handleCloseClick}>No</button> */}
					<button className="approveBtn" onClick={handleSaveClick}>
						Confirm
					</button>
				</div>
			</div>
		</div>
	);
}

export default ConfirmationModal;
