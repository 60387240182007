import getBusinessAPI from '../../apis/admin/getBusinessAPI';

export function getBusiness(token){
    return new Promise(async(resolve, reject) => {
        getBusinessAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}