import React, { useEffect, useState, useRef } from "react";
import "./CRMMessagesModal.scss";
import ImageList from "../../utils/ImageList";
import Cookies from "js-cookie";
import { getStudentMessages } from "../../interface/implementation/getStudentMessagesAPIImp";
import { crmReply } from "../../interface/implementation/crmReplyAPIImp";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { studentReply } from "../../interface/implementation/studentReplyAPIImp";
import { useNavigate } from "react-router-dom";
import { updateMsgStatus } from "../../interface/implementation/student/updateMsgStatusAPIImp";
function CRMMessagesModal({
	onClick,
	studentId,
	batchId,
	crmId,
	role,
	readStatus,
}) {
	const navigate = useNavigate();
	const chatBottomRef = useRef(null);
	const token = Cookies.get("token");
	const [ticket, setTicket] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);

	// const [isChecked, setIsChecked] = useState(false);
	const [message, setMessage] = useState("");
	const loggedInUser = localStorage.getItem("fullname");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const scrollToBottom = () => {
		if (chatBottomRef.current) {
			chatBottomRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		let newBody = `?student_id=${studentId}`;
		getStudentMessages(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setTicket(res);
				scrollToBottom();
			})
			.catch((err) => showToast("No messages found"));
	}, [studentId, reloadUsers]);

	useEffect(() => {
		if (role === "student") {
			let newBody = {
				student_id: studentId,
			};
			updateMsgStatus(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					// setTicket(res)
				})
				.catch();
		}
	}, []);
	const handleCloseClick = () => {
		onClick();
	};

	const handleReload = () => {
		setReloadUsers(!reloadUsers);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	// const handleInputChange = (e) => {
	//   setIsChecked(e.target.checked);
	// };

	const handleSendClick = () => {
		if (role === "crm") {
			let formData = {
				student_id: studentId,
				batch_id: batchId,
				messages: message,
				readStatus: readStatus,
			};
			crmReply(token, formData)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/login");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					// navigate("/course-list")
					successToast("Message sent successfully.");
					setMessage("");
					handleReload();
					// onClick();
				})
				.catch();
		} else {
			let formData = {
				student_id: studentId,
				batch_id: batchId,
				crm_id: crmId,
				messages: message,
			};
			studentReply(token, formData)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					// navigate("/course-list")
					successToast("Message sent successfully.");
					setMessage("");
					handleReload();
					// onClick();
				})
				.catch();
		}
	};

	return (
		<div className="modal-overlay CRMMessagesModal">
			<ToastContainer limit={1} />
			<div className="modal-content">
				<div className="modalHeader">
					<div className="left">
						<img src={ImageList.TicketIcon} alt="" />
						<p className="title">Messages</p>
					</div>
					<p className="crossIcon" onClick={handleCloseClick}>
						x
					</p>
				</div>

				<div className="modalBody">
					{ticket?.map((message, index) => (
						<div key={index} className="chatBubble">
							<div ref={chatBottomRef}></div>
							<div
								className={`bubble ${
									message.sent_by_full_name === loggedInUser
										? "userBubble"
										: "supportBubble"
								}`}
							>
								<>
									<div className="top">
										<p
											className={`name ${
												message.sent_by_full_name === loggedInUser
													? "boldBlack"
													: ""
											}`}
										>
											{message.sent_by_full_name}
										</p>

										<p className="timeStamp">
											{new Date(message.delivered_datetime)
												.toLocaleString("en-GB", {
													day: "numeric",
													month: "numeric",
													year: "numeric",
													hour: "numeric",
													minute: "numeric",
													hour12: false,
												})
												.replace(",", " -")}
										</p>
									</div>
									<p className="desc">{message.messages}</p>
								</>
							</div>
						</div>
					))}
				</div>

				<div className="modalFooter">
					<input
						type="text"
						className="msgBox"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<div className="actions">
						<button className="sendBtn" onClick={handleSendClick}>
							Send <img src={ImageList.SendIcon} alt="" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CRMMessagesModal;
