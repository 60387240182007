import getCRMAttendanceAPI from '../../apis/crmAdmin/getCRMAttendanceAPI';


export function getCRMAttendance(token, body){
    return new Promise(async(resolve, reject) => {
        getCRMAttendanceAPI.getAll(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}