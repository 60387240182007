import React from "react";
import "./BusinessOwnerSolutions.scss";
import { BusinessOwnerSolutionsData } from "../../../assets/Data/Constants";
import { Link } from "react-router-dom";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";

const BusinessOwnerSolutions = () => {
	return (
		<div className="BusinessOwnerSolutionsContainer">
			<h1 data-aos="fade-up" data-aos-once={true}>
				{BusinessOwnerSolutionsData &&
					BusinessOwnerSolutionsData.title.substring(0, 8)}
				<br />
				{BusinessOwnerSolutionsData &&
					BusinessOwnerSolutionsData.title.substring(8)}
			</h1>
			<div className="listContainer">
				{BusinessOwnerSolutionsData &&
					BusinessOwnerSolutionsData.title &&
					BusinessOwnerSolutionsData.list.map((l, index) => (
						<div
							data-aos="fade-up"
							data-aos-once={true}
							className="Challangelist"
							key={index}
						>
							{l}
						</div>
					))}
			</div>
			<div className="solution">
				<span>
					{BusinessOwnerSolutionsData &&
						BusinessOwnerSolutionsData.soluiton.substring(0, 14)}
				</span>
				{BusinessOwnerSolutionsData &&
					BusinessOwnerSolutionsData.soluiton.substring(14)}
			</div>
			<div className="desc">
				{BusinessOwnerSolutionsData && BusinessOwnerSolutionsData.desc}
			</div>
			<Link
				to="/registrationForm"
				state={{ work_card_id: 1 }}
				data-aos="fade-up"
				data-aos-once={true}
			>
				<button>
					Register Now to Double Your Profits <img src={ForwardArrow} alt="" />
				</button>
			</Link>
		</div>
	);
};

export default BusinessOwnerSolutions;
