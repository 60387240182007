import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./BatchCRMMessages.scss";
import Cookies from "js-cookie";
import { getMessages } from "../../../interface/implementation/getMessagesAPIImp";
import ImageList from "../../../utils/ImageList";
import CRMMessagesModal from "../../../components/CRMMesagesModal/CRMMessagesModal";
// import ImageList from '../../utils/ImageList';

function BatchCRMMessages() {
	const [userRole, setUserRole] = useState("");
	const token = Cookies.get("token");
	const [batchId, setBatchId] = useState();
	const [studentId, setStudentId] = useState();
	const [readStatus, setReadStatus] = useState();
	const [showModal, setShowModal] = useState(false);
	const [reloadUsers, setReloadUsers] = useState(false);

	const [messageList, setMessagesList] = useState([]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getMessages(token)
			.then((res) => {
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setMessagesList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	const handleReload = () => {
		setReloadUsers(!reloadUsers);
	};

	return (
		<div className="batchCrmMessages">
			<ToastContainer limit={1} />
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">CRM Messages</div>
					<div className="courseListContainer">
						<div className="messages" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Batch</th>
										<th>Participant Name</th>
										<th>Phone Number</th>
										<th>Date/Time</th>
										<th>Messages</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{messageList &&
										messageList.length > 0 &&
										messageList.map((ticket, index) => (
											<tr key={index}>
												<td>{ticket.batch_name}</td>
												<td>{ticket.student_name}</td>
												<td>{ticket.phone_number}</td>
												<td>
													{ticket.delivered_datetime
														.split("-")
														.reverse()
														.join("/")}
												</td>
												<td>{ticket.messages}</td>
												<td
													className={`status ${
														!ticket.is_read ? "unread" : ""
													}`}
												>
													{" "}
													<span
														className={`status ${
															!ticket.is_read === "Unread"
																? "unread-indicator"
																: ""
														}`}
													></span>
													{!ticket.is_read ? "Unread" : "Read"}
												</td>
												<td>
													<button
														onClick={() => {
															setBatchId(ticket.batch_id);
															setStudentId(ticket.student_id);
															setReadStatus(ticket.is_read);
															setShowModal(true);
														}}
														className="viewBtn"
													>
														<img src={ImageList.MessageIcon} alt="" />
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
							{showModal && (
								<CRMMessagesModal
									onClick={() => setShowModal(false)}
									batchId={batchId}
									studentId={studentId}
									role="crm"
									readStatus={readStatus}
									handleReload={handleReload}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BatchCRMMessages;
