import { useState } from "react";
import "./VideoRequestModal.scss";
// import { updateAttendance } from "../../interface/implementation/admin/updateAttendanceAPIImp";
import Cookies from "js-cookie";
import { updateVideoRequest } from "../../interface/implementation/admin/updateVideoRequestAPIImp";
import { showSuccessToast, showToasterMessage } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function VideoRequestModal({ onClose, videoRequestId }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [formData, setFormData] = useState({
		video_request_id: videoRequestId,
	});

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevCredentials) => ({
			...prevCredentials,
			[id]: value,
		}));
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleUpdate = async () => {
		await updateVideoRequest(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Request updated.");
				onClose();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleCloseClick = () => {
		onClose();
	};

	return (
		<div className="videoRequestModal">
			<ToastContainer limit={1} />
			<div className="modal">
				<article className="modal-container">
					<header className="modal-container-header">
						<h1 className="modal-container-title">Grant Request</h1>
						<button className="icon-button">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								onClick={handleCloseClick}
							>
								<path fill="none" d="M0 0h24v24H0z" />
								<path
									fill="currentColor"
									d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
								/>
							</svg>
						</button>
					</header>
					<section className="modal-container-body rtf">
						<div className="searchInputs">
							<div className="inputContainer">
								<label htmlFor="attendance">Approval Status</label>
								<div className="inputCode">
									<select
										id="video_request_status"
										onChange={handleInputChange}
										value={formData.video_request_status}
									>
										<option value="">-Select-</option>
										<option value="approved">Approved</option>
										<option value="rejected">Rejected</option>
									</select>
								</div>
							</div>

							<div className="inputContainer">
								<label htmlFor="duration">Reason for rejection</label>
								<div className="inputCourseName">
									<input
										type="text"
										id="rejected_reason"
										placeholder="Give your reason"
										onChange={handleInputChange}
										value={formData.rejected_reason}
										required
									/>
								</div>
							</div>
						</div>
					</section>
					<footer className="modal-container-footer">
						<button onClick={handleCloseClick} className="button is-ghost">
							Cancel
						</button>
						<button className="button is-primary" onClick={handleUpdate}>
							Update
						</button>
					</footer>
				</article>
			</div>
		</div>
	);
}

export default VideoRequestModal;
