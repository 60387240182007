import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CompanyDetails.scss";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { addBank } from "../../../interface/implementation/admin/addBankAPIImp";
import { editBank } from "../../../interface/implementation/admin/editBankAPIImp";
import { getBank } from "../../../interface/implementation/admin/getBankAPIImp";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";

function CompanyDetails() {
	const navigate = useNavigate();
	const { type, id } = useParams();
	const token = Cookies.get("token");
	const [dropdownOptions, setDropdownOptions] = useState([]);

	const [formData, setFormData] = useState({
		title: "",
		bank_name: "",
		bank_detail: "",
		acc_no: "",
		ifsc_code: "",
		batch_name: "",
		company_name: "",
		company_address: "",
		pan_no: "",
		gst_no: "",
	});

	useEffect(() => {
		if (type === "edit") {
			getBank(token, Number(id))
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}

					setFormData({
						bank_id: res[0].bank_id,
						title: res[0].title,
						bank_name: res[0].bank_name,
						bank_detail: res[0].bank_detail,
						acc_no: res[0].acc_no,
						ifsc_code: res[0].ifsc_code,
						batch_name: res[0].batch_name,
						company_name: res[0].company_name,
						company_address: res[0].company_address,
						pan_no: res[0].pan_no,
						gst_no: res[0].gst_no,
					});
				})
				.catch((err) => showToast("Something went wrong"));
		}
	}, []);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getBatches(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setDropdownOptions(res.batch);
			});
		};
		fetchDropdowns();
	}, []);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const createBank = () => {
		if (!formData.title || formData.title.trim().length === 0) {
			showToast("Title is empty");
			return;
		}
		if (!formData.bank_name || formData.bank_name.trim().length === 0) {
			showToast("Bank name is empty");
			return;
		}
		if (!formData.bank_detail || formData.bank_detail.trim().length === 0) {
			showToast("Bank detail is empty");
			return;
		}
		if (!formData.acc_no || formData.acc_no.trim().length === 0) {
			showToast("Account no is empty");
			return;
		}
		if (!formData.ifsc_code || formData.ifsc_code.trim().length === 0) {
			showToast("IFSC code is empty");
			return;
		}
		if (!formData.company_name || formData.company_name.trim().length === 0) {
			showToast("Company name is empty");
			return;
		}
		if (
			!formData.company_address ||
			formData.company_address.trim().length === 0
		) {
			showToast("Company address is empty");
			return;
		}
		if (!formData.pan_no || formData.pan_no.trim().length === 0) {
			showToast("PAN No is empty");
			return;
		}
		if (!formData.gst_no || formData.gst_no.trim().length === 0) {
			showToast("GST No is empty");
			return;
		}

		if (type === "edit") {
			let body = formData;
			addBank(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}

					successToast("Bank updated successfully.");
					navigate("/bank-details");
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			let body = formData;
			addBank(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					successToast("Bank added successfully.");

					navigate("/bank-details");
				})
				.catch((err) => showToast("Something went wrong"));
		}
	};

	return (
		<div className="companyDetails">
			<ToastContainer limit={1} />
			<div className="addBatch">
				<div className="dashboardBody">
					<div className="dashboardContent">
						<div className="title">
							{type === "edit" ? "Edit Bank" : "Add New Bank"}
						</div>
						<div className="companyDetailsContainer">
							<div className="companyDetailsForm">
								<div className="formInput">
									<label htmlFor="title">
										Title<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="title"
											placeholder="Enter company name"
											onChange={handleInputChange}
											value={formData.title}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="bankName">
										Bank Name<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="bank_name"
											placeholder="Enter bank name"
											onChange={handleInputChange}
											value={formData.bank_name}
										/>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="bankDetail">
										Bank Detail<span>*</span>
									</label>
									<div className="inputUserName">
										<textarea
											cols="30"
											rows="5"
											id="bank_detail"
											placeholder="Enter bank details"
											onChange={handleInputChange}
											value={formData.bank_detail}
											style={{ borderRadius: "10px" }}
										></textarea>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="accountNo">
										Account No<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="number"
											onFocus={preventWheelScroll}
											onInput={handleNumericInput}
											id="acc_no"
											placeholder="Enter account number"
											onChange={handleInputChange}
											value={formData.acc_no}
										/>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="ifsc">
										IFSC Code<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="ifsc_code"
											placeholder="Enter IFSC Code"
											onChange={handleInputChange}
											value={formData.ifsc_code}
										/>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="batchName">Batch Name</label>
									{/* <div className="inputUserName">
                    <input
                      type="text"
                      id="batch_name"
                      placeholder="Enter Batch Name"
                      onChange={handleInputChange}
                      value={formData.batch_name}
                    />
                  </div> */}
									<select
										id="batch_name"
										onChange={handleInputChange}
										value={formData.batch_name}
									>
										<option value="">Select batch</option>
										{dropdownOptions?.map((option, i) => (
											<option key={i} value={option.batch_name}>
												{option.batch_name}
											</option>
										))}
									</select>
								</div>

								<div className="formInput">
									<label htmlFor="companyName">
										Company Name<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="company_name"
											placeholder="Enter Company Name"
											onChange={handleInputChange}
											value={formData.company_name}
										/>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="companyAddress">
										Company Address<span>*</span>
									</label>
									<div className="inputUserName">
										<textarea
											cols="30"
											rows="5"
											id="company_address"
											placeholder="Enter Company Address"
											onChange={handleInputChange}
											value={formData.company_address}
											style={{ borderRadius: "10px" }}
										></textarea>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="panNo">
										PAN No<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="pan_no"
											placeholder="Enter PAN No"
											onChange={handleInputChange}
											value={formData.pan_no}
										/>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="gstNo">
										GST No<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="gst_no"
											placeholder="Enter GST number"
											onChange={handleInputChange}
											value={formData.gst_no}
										/>
									</div>
								</div>

								<div>
									<button className="saveBtn" onClick={createBank}>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CompanyDetails;
