import editProfileAPI from '../apis/editProfileAPI';


export function editProfile(token,body){
    return new Promise(async(resolve, reject) => {
        editProfileAPI.put(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}