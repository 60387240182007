import React, { useEffect, useState } from "react";
import "./StudentMessagesModal.scss";
import ImageList from "../../utils/ImageList";
import Cookies from "js-cookie";
import { getStudentMessages } from "../../interface/implementation/getStudentMessagesAPIImp";
import { crmReply } from "../../interface/implementation/crmReplyAPIImp";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { getCRMMessages } from "../../interface/implementation/student/getCRMMessagesAPIImp";

import { useNavigate } from "react-router-dom";
function StudentMessagesModal({ onClick, studentId, batchId }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [ticket, setTicket] = useState([]);
	// const [isChecked, setIsChecked] = useState(false);
	const [message, setMessage] = useState("");
	const loggedInUser = localStorage.getItem("userName");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	// useEffect(() => {
	//   let newBody = `?student_id=${studentId}`
	//   getCRMHistoryMessages(token, newBody).then((res) => {
	//     if (res.detail === "Signature has expired.") {
	//       navigate("/");
	//       showToast('Token has expired. Please log in again.');
	//       return;
	//     }
	//     if (res.status === "failure") {
	//       showToast(res.message);
	//       return;
	//     }
	//     setTicket(res)

	//   }).catch((err) => showToast("Something went wrong"))
	// }, [studentId])

	const handleCloseClick = () => {
		onClick();
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	// const handleInputChange = (e) => {
	//   setIsChecked(e.target.checked);
	// };

	const handleSendClick = () => {
		let formData = {
			student_id: studentId,
			batch_id: batchId,
			messages: message,
		};
		// studentReply(token, formData).then((res) => {
		//   if (res.detail === "Signature has expired.") {
		//     navigate("/");
		//     showToast('Token has expired. Please log in again.');
		//     return;
		//   }
		//   if (res.status === "failure") {
		//     showToast(res.message);
		//     return;
		//   }
		//   // navigate("/course-list")
		//   successToast("Message sent successfully.")

		//   onClick();
		// }).catch((err) => showToast("Something went wrong"))
	};

	return (
		<div className="modal-overlay studentMessagesModal">
			<ToastContainer limit={1} />
			<div className="modal-content">
				<div className="modalHeader">
					<div className="left">
						<img src={ImageList.TicketIcon} alt="" />
						<p className="title">Messages</p>
					</div>
					<p className="crossIcon" onClick={handleCloseClick}>
						x
					</p>
				</div>

				<div className="modalBody">
					{ticket?.map((message, index) => (
						<>
							{message.messages?.map((msg, i) => {
								return (
									<div key={index} className="chatBubble">
										<div
											className={`bubble ${
												message.username === loggedInUser
													? "userBubble"
													: "supportBubble"
											}`}
										>
											<>
												<div className="top">
													<p
														className={`name ${
															message.username === loggedInUser
																? "boldBlack"
																: ""
														}`}
													>
														{message.sent_by_full_name}
													</p>

													<p key={i} className="timeStamp">
														{msg.delivered_datetime}
													</p>
												</div>
												<p key={i} className="desc">
													{msg.messages}
												</p>
											</>
										</div>
									</div>
								);
							})}
						</>
					))}
				</div>

				<div className="modalFooter">
					<input
						type="text"
						className="msgBox"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<div className="actions">
						<button className="sendBtn" onClick={handleSendClick}>
							Send <img src={ImageList.SendIcon} alt="" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentMessagesModal;
