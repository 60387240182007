import updateCRMAPI from '../../apis/admin/updateCRMAPI';


export function updateCRM(token, body){
    return new Promise(async(resolve, reject) => {
        updateCRMAPI.put(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}