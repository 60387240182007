import searchCRMAdminStudentsAPI from "../../apis/crmAdmin/searchCRMAdminStudentsAPI";

export function searchCRMAdminStudents(token, body) {
	return new Promise(async (resolve, reject) => {
		searchCRMAdminStudentsAPI
			.sort(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
