import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./WorkshopAttendanceList.scss";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage, showSuccessToast } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { getStudentAttendance } from "../../../interface/implementation/admin/getAttendanceAPIImp";
import { searchAttendance } from "../../../interface/implementation/admin/searchAttendanceAPIImp";
import UpdateAttendanceModal from "../../../components/UpdateAttendanceModal/UpdateAttendanceModal";
import { updateAttendanceAll } from "../../../interface/implementation/admin/updateAllAttendanceAPIImp";
import { downloadAttendanceCSV } from "../../../interface/implementation/admin/downloadAttendanceAPIImp";
import { useNavigate } from "react-router-dom";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import _isEmpty from "lodash/isEmpty";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { autoSearchBatches } from "../../../interface/implementation/admin/autoSearchBatchesAPIImp";
import { getWorkshops } from "../../../interface/implementation/admin/getWorkshopsAPIImp";
import { getWorkshopAttendance } from "../../../interface/implementation/admin/getWorkshopAttendanceAPIImp";
import { searchWorkshopAttendance } from "../../../interface/implementation/admin/searchWorkshopAttendanceAPIImp";
import { downloadWorkshopAttendance } from "../../../interface/implementation/admin/downloadWorkshopAttendanceAPIImp";
import UpdateWorkshopAttendanceModal from "../../../components/UpdateWorkshopAttendanceModal/UpdateWorkshopAttendanceModal";
import { updateWorkshopAttendance } from "../../../interface/implementation/admin/updateWorkshopAttendanceAllAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { logout } from "../../../interface/implementation/logoutAPIImp";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";

function WorkshopAttendanceList() {
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const [batchname, setBatchname] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [weekNo, setWeekNo] = useState(null);
	const [crm, setCRM] = useState("");
	const [participantStatus, setParticipantStatus] = useState("");
	const [attendance, setAttendance] = useState("");
	const [attendanceList, setAttendanceList] = useState([]);
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showUpdateButton, setShowUpdateButton] = useState(false);
	const [batches, setBatches] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState(null);
	const [modalState, setModalState] = useState(false);
	const [batchId, setBatchId] = useState(0);
	const [crmValues, setCrmValues] = useState([]);
	const [selectedCRM, setSelectedCRM] = useState(null);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [searchField, setSearch] = useState("");

	const navigate = useNavigate();

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);

	useEffect(() => {
		setSearch("");
	}, [batchId]);

	useEffect(() => {
		getWorkshopAttendance(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);

					return;
				}
				setAttendanceList(res);
			})
			.catch();
	}, [modalState, reloadUsers]);

	// useEffect(() => {
	//   const fetchCRMDropdown = async () => {
	//     await getCRM(token)
	//       .then((res) => {
	//         if (res.detail === "Signature has expired.") {
	//           navigate("/");
	//           showToast('Token has expired. Please log in again.');
	//           return;
	//         }
	//         if (res.status === "failure") {
	//           showToast(res.message);
	//           return;
	//         }
	//         setCrmValues(res.users)
	//       })
	//       .catch();
	//   };
	//   fetchCRMDropdown();
	// }, []);

	useEffect(() => {
		getWorkshops(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatches(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};
	const handleAutoSearchChange = (e, setter) => {
		if (!batchId || batchId === "") {
			showToast("You need to select a batch before searching.");
			return;
		}
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (batchname) {
			newBody += `?workshop_id=${batchname}`;
		}

		if (weekNo) {
			newBody += `${newBody ? "&" : "?"}class_no=${weekNo}`;
		}

		if (attendance) {
			newBody += `${newBody ? "&" : "?"}attendance_status=${attendance}`;
		}

		if (searchField) {
			newBody += `&company_name_contact_no=${searchField}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const handleAutoSearch = async (e) => {
		if (!batchId || batchId === "") {
			showToast("You need to select a batch before searching.");
			return;
		}
		if (e.target.value) {
			let newBody = `${batchId}/${e.target.value}`;

			autoSearchBatches(token, newBody)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setAutoValues(res);
					setAutoModal(true);
				})
				.catch();
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		searchWorkshopAttendance(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);

					setAttendanceList();

					return;
				}
				const indexOfLastItem = currentPage * itemsPerPage;
				const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				setAttendanceList(res);
				setShowUpdateButton(true);
			})
			.catch();
		setShowUpdateButton(true);
	};

	const handleAttendanceAllUpdate = async () => {
		if (_isEmpty(batchname) || _isEmpty(weekNo)) {
			showToast("Please check if workshop & class are selected");
		} else {
			await updateWorkshopAttendance(token, {
				workshop_id: batchname,
				class_no: weekNo,
			})
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}

					showSuccessToast("Updated attendance successfully");
				})
				.catch((err) => showToast("Something went wrong"));
		}
	};

	const handleDownloadAttendance = async () => {
		let newBody = "";

		if (batchname) {
			newBody += `?workshop_id=${batchname}`;
		}

		if (weekNo) {
			newBody += `${newBody ? "&" : "?"}class_no=${weekNo}`;
		}

		if (searchField) {
			newBody += `&company_name_contact_no=${searchField}`;
		}

		if (attendance) {
			newBody += `${newBody ? "&" : "?"}attendance_status=${attendance}`;
		}
		await downloadWorkshopAttendance(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
				const filename = `Workshop-attendance-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleReloadUsers = () => {
		setReloadUsers(!reloadUsers);
	};

	const handleDownload = () => {
		const link = document.createElement("a");

		link.href = "";

		link.download = "attendanceSheet.csv";

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const handleCSVImport = () => {
		if (_isEmpty(batchname)) {
			showToast("Please select a batch");
		} else {
			navigate(`/attendance-csv-import/${batchId}`);
		}
	};

	const handleReset = () => {
		setSearch("");
		setBatchname("");
		setWeekNo("");
		setAttendance();
		setSelectedCRM("");
		handleReloadUsers();
	};

	const handleBatchChange = (e) => {
		const selectedBatchId = e.target.value;
		const selectedBatch = batches.find(
			(batch) => batch.workshop_id === parseInt(selectedBatchId)
		);
		if (selectedBatch) {
			setBatchId(selectedBatchId);
			setWeekNo("");
		}
	};

	const renderWeekOptions = () => {
		if (!batchId) return null; // If no batch is selected, return null

		const selectedBatch = batches.find(
			(batch) => batch.workshop_id === parseInt(batchId)
		);
		if (!selectedBatch) return null;

		const options = [];
		for (let i = 0; i < selectedBatch.total_classes; i++) {
			options.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		return options;
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		attendanceList && attendanceList.length > 0
			? attendanceList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="workshopAttendanceList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						<h4>Workshop Attendance List</h4>
						<div className="buttonContainer">
							{/* <button
                onClick={handleCSVImport}
                className="downloadBtn"
                style={{ backgroundColor: "#FF833D" }}
              >
                Participant Attendance CSV Import
              </button> */}

							<button
								onClick={handleAttendanceAllUpdate}
								className="downloadBtn"
								style={{ backgroundColor: "#00a65a" }}
							>
								Update Participant Attendance
							</button>

							{selectedRole !== 3 && (
								<button
									onClick={handleDownloadAttendance}
									className="downloadBtn"
								>
									<img src={ImageList.CloudDownload} alt="" />
									Download CSV
								</button>
							)}
						</div>
					</div>
					<div className="attendanceListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="batchname">Workshop Name</label>
									<div className="inputCode">
										<select
											id="batchname"
											onChange={(e) => {
												handleInputChange(e, setBatchname);
												handleBatchChange(e);
											}}
											value={batchname}
										>
											<option value="">Select Batch Name</option>
											{batches &&
												batches.length > 0 &&
												batches.map((batch, i) => (
													<option
														key={batch.workshop_id}
														value={batch.workshop_id}
													>
														{batch.workshop_title}
													</option>
												))}
										</select>
									</div>
								</div>

								<div className="inputContainer">
									<label htmlFor="weekNo">Class No.</label>
									<div className="inputCode">
										<select
											id="weekNo"
											onChange={(e) => handleInputChange(e, setWeekNo)}
											value={weekNo}
										>
											<option value="">Select Class</option>
											{renderWeekOptions()}
										</select>
									</div>
								</div>

								<div className="inputContainer">
									<label htmlFor="is_present">Attendance</label>
									<div className="inputCode">
										<select
											id="is_present"
											onChange={(e) => handleInputChange(e, setAttendance)}
											value={attendance}
										>
											<option value="">-Any-</option>
											<option value="Present">Present</option>
											<option value="Absent">Absent</option>
										</select>
									</div>
								</div>
								{/* 
                <div className="inputContainer">
                  <label htmlFor="selectedCRM">Select CRM</label>
                  <div className="inputCode">
                    <select
                      id="selectedCRM"
                      onChange={(e) => handleInputChange(e, setSelectedCRM)}
                      value={selectedCRM}
                    >
                      <option value="">Select CRM</option>
                      {crmValues && crmValues.length > 0 && crmValues.map((option, i) => (
                        <option key={i} value={option.user_id}>
                          {option.full_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

								{/* <div className="inputContainer">
                  <label htmlFor="crm">CRM</label>
                  <div className="inputCode">
                    <select
                      id="crm"
                      onChange={(e) => handleInputChange(e, setCRM)}
                      value={crm}
                    >
                      <option value="">-Any-</option>
                    </select>
                  </div>
                </div> */}

								{/* <div className="inputContainer">
                  <label htmlFor="participantStatus">Participant Status</label>
                  <div className="inputCode">
                    <select
                      id="participantStatus"
                      onChange={(e) =>
                        handleInputChange(e, setParticipantStatus)
                      }
                      value={participantStatus}
                    >
                      <option value="">-Any-</option>
                    </select>
                  </div>
                </div> */}

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Apply
									</button>
								</div>
								<div className="searchBtnContainer">
									<button className="resetBtn" onClick={handleReset}>
										Reset
									</button>
								</div>
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="attendanceTable">
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Workshop title</th>
										<th>Participant Name</th>
										<th>Phone Number</th>
										<th>Company Name</th>
										<th>Class</th>
										<th>Duration(Minutes)</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((student, i) => (
											<tr key={i}>
												<td>{startSerialNumber + i}</td>
												<td>{student.workshop_title}</td>
												<td>{student.student_name}</td>
												<td>{student.phone_no}</td>
												<td>{student.company_name}</td>
												<td>{student.class_no}</td>
												<td>{student.duration}</td>
												{/* <td>{student.crm}</td> */}
												<td>
													<button
														className={`${
															student.is_present === "Present"
																? "presentBtn"
																: "absentBtn"
														}`}
														onClick={() => {
															setSelectedStudent(student);
															setShowModal(true);
														}}
													>
														<div
															style={{ display: "flex", alignItems: "center" }}
														>
															<img
																src={
																	student.is_present === "Present"
																		? ImageList.ClipboardCheck
																		: ImageList.Close
																}
																alt=""
															/>
															{student.is_present === "Present"
																? "Present"
																: "Absent"}
														</div>
													</button>
												</td>
												{showModal &&
													selectedStudent &&
													selectedStudent.id === student.id && (
														<td>
															<UpdateWorkshopAttendanceModal
																onClick={() => setShowModal(false)}
																class_no={selectedStudent.class_no}
																workshop_class_type={
																	selectedStudent.workshop_class_type
																}
																zoom_duration={selectedStudent.duration}
																status={selectedStudent.is_present}
																workshop_student_id={
																	selectedStudent.workshop_student_id
																}
																workshop_attendance_id={
																	selectedStudent.workshop_attendance_id
																}
																setModalState={setModalState}
																setReloadUsers={handleReloadUsers}
															/>
														</td>
													)}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(attendanceList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WorkshopAttendanceList;
