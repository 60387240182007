import React from "react";
import "./HowToPage.scss";
import { HowToPageData } from "../../../assets/Data/Constants";
import { Link } from "react-router-dom";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";

const HowToPage = () => {
	return (
		<div className="HowToPageContainer">
			<label
				htmlFor="titleTop"
				className="titleTop"
				data-aos="fade-up"
				data-aos-once={true}
			>
				{HowToPageData && HowToPageData.subtitle}
			</label>
			<label
				htmlFor="mainTitle"
				className="mainTitle"
				data-aos="fade-up"
				data-aos-once={true}
			>
				{HowToPageData && HowToPageData.mainTitle}
			</label>
			<div className="howTolistConatiner">
				{HowToPageData &&
					HowToPageData.list &&
					HowToPageData.list.map((h, index) => (
						<div key={index} className="hpwTolist">
							{h}
						</div>
					))}
			</div>
			<Link
				to="/registrationForm"
				state={{ work_card_id: 1 }}
				data-aos="fade-up"
				data-aos-once={true}
			>
				<button>
					Click Now to Register !!! <img src={ForwardArrow} alt="" />
				</button>
			</Link>
		</div>
	);
};

export default HowToPage;
