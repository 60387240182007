import {ApiCore} from "../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: false,
    post: true,
    put: false,
    delete: false,
    url: 'api/reset/forgot-password',
    version: ''
});

export default apiTasks;