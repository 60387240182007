import { useState } from "react";
import "./UpdateAttendanceModal.scss";
import { updateAttendance } from "../../interface/implementation/admin/updateAttendanceAPIImp";
import Cookies from "js-cookie";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function UpdateAttendanceModal({
	onClick,
	student_name,
	week_no,
	class_type,
	zoom_duration,
	status,
	setModalState,
	setReloadUsers,
}) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [formData, setFormData] = useState({
		student_name: student_name,
		week_no: week_no,
		status: status || "",
		class_type: class_type || "",
		zoom_duration: zoom_duration || "",
	});

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevCredentials) => ({
			...prevCredentials,
			[id]: value,
		}));
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleUpdate = async () => {
		await updateAttendance(token, {
			student_name: student_name,
			week_no: week_no,
			status: formData.status,
			class_type: formData.class_type,
			zoom_duration: formData.zoom_duration,
		})
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Attendance updated successfully.");
			})
			.catch((err) => showToast("Something went wrong"));
		setReloadUsers();
		setModalState(true);
		handleCloseClick();
	};

	const handleCloseClick = () => {
		onClick();
	};

	return (
		<div className="updateAttendanceModal">
			<ToastContainer limit={1} />
			<div className="modal">
				<article className="modal-container">
					<header className="modal-container-header">
						<h1 className="modal-container-title">Update Attendance</h1>
						<button className="icon-button">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								onClick={handleCloseClick}
							>
								<path fill="none" d="M0 0h24v24H0z" />
								<path
									fill="currentColor"
									d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
								/>
							</svg>
						</button>
					</header>
					<section className="modal-container-body rtf">
						<div className="searchInputs">
							<div className="inputContainer">
								<label htmlFor="status">Attendance</label>
								<div className="inputCode">
									<select
										id="status"
										onChange={handleInputChange}
										value={formData.status}
									>
										<option value="">-Select-</option>
										<option value="Present">Present</option>
										<option value="Absent">Absent</option>
									</select>
								</div>
							</div>
							<div className="inputContainer">
								<label htmlFor="class_type">Class Type</label>
								<div className="inputCode">
									<select
										id="class_type"
										onChange={handleInputChange}
										value={formData.class_type}
									>
										<option value="">-Select-</option>
										<option value="online">Online</option>
										<option value="offline">Offline</option>
									</select>
								</div>
							</div>
							<div className="inputContainer">
								<label htmlFor="zoom_duration">
									ZOOM (Duration in Minutes)
								</label>
								<div className="inputCourseName">
									<input
										type="text"
										id="zoom_duration"
										placeholder="Enter class duration"
										onChange={handleInputChange}
										value={formData.zoom_duration}
										required
									/>
								</div>
							</div>
						</div>
					</section>
					<footer className="modal-container-footer">
						<button onClick={handleCloseClick} className="button is-ghost">
							Cancel
						</button>
						<button className="button is-primary" onClick={handleUpdate}>
							Update
						</button>
					</footer>
				</article>
			</div>
		</div>
	);
}

export default UpdateAttendanceModal;
