import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./NatureBusiness.scss";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { getBusiness } from "../../../interface/implementation/admin/getBusinessAPIImp";
import CreateBusinessModal from "../../../components/CreateBusinessModal/CreateBusinessModal";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
function NatureBusiness() {
	const [showModal, setShowModal] = useState(false);
	const [businessList, setBusinessList] = useState([]);
	const [business, setBusiness] = useState();
	const [type, setType] = useState("");
	const [reload, setReload] = useState(false);

	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const [status, setStatus] = useState(false);
	useEffect(() => {
		getBusiness(token)
			.then((res) => {
				setBusinessList(res);
			})
			.catch();
	}, [reload]);

	const handleReload = () => {
		setReload((prevState) => !prevState);
	};

	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = crmVideoRequestList.slice(indexOfFirstItem, indexOfLastItem);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		businessList && businessList.length > 0
			? businessList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="natureBusiness">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						<h4>Nature of Business</h4>
						<div className="buttonContainer">
							<button
								onClick={() => {
									setBusiness("");
									setShowModal(true);
								}}
								className="downloadBtn"
								style={{ backgroundColor: "#FF833D" }}
							>
								create business
							</button>
						</div>
					</div>
					<div className="attendanceListContainer">
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>No</th>
										<th>Nature of business</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{currentItems?.map((item, index) => (
										<tr key={index}>
											<td>{startSerialNumber + index}</td>
											<td style={{ whiteSpace: "normal" }}>{item.name}</td>

											<td className="actionBtn">
												<button
													onClick={() => {
														setBusiness(item);
														setType("edit");
														setShowModal(true);
													}}
													className="editBtn"
													style={{ backgroundColor: "#4367B0" }}
												>
													<img src={ImageList.EditIcon} alt="" /> Edit
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{showModal && (
								<CreateBusinessModal
									onClick={() => setShowModal(false)}
									status={status}
									business={business}
									type={type}
									handleReload={handleReload}
								/>
							)}
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(businessList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NatureBusiness;
