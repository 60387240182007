import uploadUpdateWorkshopStudentCSVAPI from "../../apis/admin/uploadUpdateWorkshopStudentCSVAPI";

export function uploadUpdateWorkshopStudentCSV(token, body) {
	return new Promise(async (resolve, reject) => {
		uploadUpdateWorkshopStudentCSVAPI
			.postFormData(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
