import {ApiCore} from "../../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: false,
    postEncoded: false,
    post: false,
    put: true,
    delete: false,
    url: 'api/admin/batches/update-batch',
    version: '',
});

export default apiTasks;