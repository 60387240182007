import getStudentBatchListAPI from "../../apis/admin/getStudentBatchListAPI";

export const getStudentBatchList = (token, id) => {
    return new Promise(async (resolve, reject) => {
        getStudentBatchListAPI
        .getSingle(token, id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  