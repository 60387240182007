import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";

import "./AddUser.scss";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { addUser } from "../../../interface/implementation/admin/addUserAPIImp";
import { getRoles } from "../../../interface/implementation/admin/getRolesAPIImp";
import { getUser } from "../../../interface/implementation/admin/getUserAPIImp";
import Cookies from "js-cookie";
import { editUser } from "../../../interface/implementation/admin/editUserAPIImp";
import FileBase64 from "react-file-base64";

function AddUser() {
	const { type, userId } = useParams();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const location = useLocation();
	const userName = location.state ? location.state.username : "";
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const initialState = {
		email_id: "",
		full_name: "",
		username: "",
		phone_number: "",
		password: "",
		company_name: "",
		confirmPassword: "",
		company_gst: "",
		profile_picture: [],
		is_active: true,
		roles: [],
	};

	const [formData, setFormData] = useState(initialState);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	useEffect(() => {
		if (
			!formData.email_id ||
			!formData.full_name ||
			!formData.username ||
			!formData.password ||
			!formData.confirmPassword ||
			!formData.phone_number ||
			// !formData.company_name ||
			// !formData.company_gst ||
			!formData.is_active ||
			formData.roles.length === 0
		) {
			showToast();
			return;
		}
		if (type === "edit") {
			getUser(token, userId)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setFormData({
						email_id: res.user.email_id,
						full_name: res.user.name,
						username: userId,
						phone_number: res.user.phone_number,
						password: res.user.password,
						company_name: res.user.company_name,
						confirmPassword: res.user.password,
						company_gst: res.user.company_gst,
						profile_picture: res.user.profile_picture,
						is_active: res.user.is_active,
						roles: res.user.roles,
					});
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			setFormData(initialState);
		}
	}, [type, userId]);

	const [userRole, setUserRole] = useState([]);
	const [profile_picture, setProfile] = useState(ImageList.ProfilePic);

	useEffect(() => {
		getRoles(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const rolesFromApi = res.roles.Roles;

				// Create a new array with isChecked property added for each role
				const rolesWithChecked = rolesFromApi.map((role) => ({
					...role,
					isChecked: false, // Set the initial value for isChecked as needed
				}));
				setUserRole(rolesWithChecked);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	useEffect(() => {
		if (type === "edit") {
			getUser(token, userName)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					const userRoleIds = res.user.roles.map((role) => role.role_id);

					// Update isChecked based on whether the role id is in userRoleIds
					setUserRole((prevRoles) =>
						prevRoles.map((role) => ({
							...role,
							isChecked: userRoleIds.includes(role.role_id),
						}))
					);

					setFormData({
						email_id: res.user.email_id,
						full_name: res.user.full_name,
						username: userName,
						phone_number: res.user.phone_number,
						password: res.user.password,
						company_name: res.user.company_name,
						confirmPassword: res.user.password,
						company_gst: res.user.company_gst,
						profile_picture: res.user.profile_picture,
						is_active: res.user.is_active,
						roles: userRoleIds.map(String),
						user_id: userId,
					});
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			setFormData(initialState);
		}
	}, [type, userId]);

	const fileInputRef = useRef(null);

	// const handleInputChange = (e) => {

	//     const { id, value, type, checked } = e.target;

	//     if (type === 'checkbox') {
	//         setFormData((prevData) => {
	//             // Check if the role ID is already present in the roles array
	//             const isRoleSelected = prevData.roles.includes(id);

	//             // Create a new roles array with updated values
	//             const updatedRoles = isRoleSelected
	//                 ? prevData.roles.filter((roleId) => roleId !== id) // Remove the ID if it was already present
	//                 : [...prevData.roles, id]; // Add the ID if not present

	//             return {
	//                 ...prevData,
	//                 roles: updatedRoles,
	//             };
	//         });

	//         setUserRole((prevRoles) =>
	//             prevRoles.map((role) =>
	//                 role.role_id === id ? { ...role, isChecked: !role.isChecked } : role
	//             )
	//         );
	//     } else {
	//         setFormData((prevData) => ({
	//             ...prevData,
	//             [id]: value,
	//         }));
	//     }
	// };

	const handleInputChange = (e) => {
		const { id, value, type, checked } = e.target;

		if (type === "checkbox") {
			setFormData((prevData) => {
				const isRoleSelected = prevData.roles.includes(id);
				const updatedRoles = isRoleSelected
					? prevData.roles.filter((roleId) => roleId !== id)
					: [...prevData.roles, id];

				return {
					...prevData,
					roles: updatedRoles,
				};
			});

			setUserRole((prevRoles) =>
				prevRoles.map((role) =>
					role.role_id === id ? { ...role, isChecked: !role.isChecked } : role
				)
			);

			// Update the checked property of the input element
			e.target.checked = !e.target.checked;
		} else if (type === "radio") {
			setFormData((prevData) => ({
				...prevData,
				is_active: value === "true" ? true : false,
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[id]: value,
			}));
		}
	};

	const handleTogglePassword = (field) => {
		if (field === "password") {
			setShowPassword((value) => !value);
		} else if (field === "confirmPassword") {
			setShowConfirmPassword((value) => !value);
		}
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};
	const handleRemoveImage = () => {
		setProfile("");
		setFormData((prevData) => ({
			...prevData,
			profile_picture: {},
		}));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			// Display image preview
			const imageUrl = URL.createObjectURL(file);
			setProfile(imageUrl);
			// imageRef.current.src = imageUrl;
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;
				setFormData((prevData) => ({
					...prevData,
					profile_picture: [
						{
							filename: file.name,
							content: base64String,
						},
					],
				}));
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = () => {
		if (!formData.email_id || formData.email_id.trim() === "") {
			showToast("Email field is empty");
			return;
		}
		if (!formData.username || formData.username.trim() === "") {
			showToast("Username field is empty");
			return;
		}

		if (!formData.full_name || formData.full_name.trim() === "") {
			showToast("Name field is empty");
			return;
		}

		if (!formData.phone_number || formData.phone_number.trim() === "") {
			showToast("Phone Number field is empty");
			return;
		} else if (formData.phone_number.length < 10) {
			showToast("Phone Number must be at least 10 digits");
			return;
		}

		if (formData.roles.length === 0) {
			showToast("Please select at least one role");
			return;
		}

		if (type === "edit") {
			if (formData.password !== formData.confirmPassword) {
				showToast("Passwords mismatch. Please check and try again.");
				return;
			}
		} else {
			if (!formData.password || formData.password.trim() === "") {
				showToast("Password field is empty");
				return;
			}
			if (!formData.confirmPassword || formData.confirmPassword.trim() === "") {
				showToast("Confirm Password field is empty");
				return;
			}

			if (formData.password !== formData.confirmPassword) {
				showToast("Passwords mismatch. Please check and try again.");
				return;
			}
		}
		// Handle form submission logic
		let body = formData;
		if (type === "edit") {
			editUser(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					successToast("User updated successfully.");
					navigate("/user-list");
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			addUser(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					successToast("User added successfully.");
					navigate("/user-list");
				})
				.catch((err) => showToast("Something went wrong"));
		}
	};

	return (
		<div className="addUser">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="blueHeader">
						<div className="title">
							{type === "edit" ? "Edit User" : "Add New User"}
						</div>
					</div>
					<div className="contentBody">
						{type === "edit" ? (
							<div className="profileUser">
								<img
									style={{ backgroundColor: "#eee" }}
									ty
									src={
										formData.profile_picture &&
										formData.profile_picture[0]?.content
											? formData.profile_picture[0].content
											: ImageList.AvatarIcon
									}
									alt=""
									className="userImgUpdated"
								/>
								<input
									type="file"
									name=""
									id="fileInput"
									ref={fileInputRef}
									onChange={handleFileChange}
									style={{ display: "none" }}
									accept=".jpg, .jpeg, .png"
								/>
								{formData.profile_picture ? (
									<div style={{ display: "flex", gap: "20px" }}>
										<button
											className="removeBtn"
											onClick={handleUploadButtonClick}
										>
											<img
												src={ImageList.EditIconRed}
												alt=""
												className="uploadIcon"
											/>
											Edit
										</button>
										{/* <button className="removeBtn" onClick={handleRemoveImage}>
                                        <img src={ImageList.DeleteIcon} alt="" className='uploadIcon' />Remove
                                    </button> */}
									</div>
								) : (
									<button
										className="uploadBtn"
										onClick={handleUploadButtonClick}
									>
										<img
											src={ImageList.UploadIcon}
											alt=""
											className="uploadIcon"
										/>
										Upload Image
									</button>
								)}

								<hr />
								<div className="details">
									<p className="name">{formData.username}</p>
									<p className="email">{formData.email_id}</p>
									<p className="status">
										<span></span> {formData.status}
									</p>
								</div>
							</div>
						) : (
							<div className="profileUser">
								<img src={profile_picture} alt="preview" className="userImg" />
								<input
									type="file"
									name=""
									id="fileInput"
									ref={fileInputRef}
									onChange={handleFileChange}
									style={{ display: "none" }}
									accept=".jpg, .jpeg, .png"
								/>
								<button className="uploadBtn" onClick={handleUploadButtonClick}>
									<img
										src={ImageList.UploadIcon}
										alt=""
										className="uploadIcon"
									/>
									Upload Image
								</button>
							</div>
						)}
						<div className="userForm">
							<div className="credentialForm">
								<p className="title">
									{/* {type === "edit" ? `${formData.username ? formData.username : "Credential details"}` : "Credential details"} */}
									Credential details
								</p>
								<div className="formFields">
									<div className="formInput">
										<label htmlFor="emailAddress">
											Email Address <span>*</span>
										</label>
										<div className="inputUserName">
											<input
												type="text"
												id="email_id"
												placeholder="Enter email address"
												onChange={handleInputChange}
												value={formData.email_id}
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="username">
											Username <span>*</span>
										</label>
										<div className="inputUserName">
											<input
												type="text"
												id="username"
												placeholder="Enter username"
												onChange={handleInputChange}
												value={formData.username}
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="password">
											Password <span>*</span>
										</label>
										<div className="inputPass">
											<input
												type={showPassword ? "text" : "password"}
												id="password"
												placeholder="Enter password"
												onChange={handleInputChange}
												value={formData.password}
											/>
											<img
												onClick={() => handleTogglePassword("password")}
												src={
													showPassword ? ImageList.EyeOpen : ImageList.EyeClose
												}
												alt="eye-icon"
												className="eyeIcon"
											/>
										</div>
									</div>

									<div className="formInput">
										<label htmlFor="confirmPassword">
											Confirm Password <span>*</span>
										</label>
										<div className="inputPass">
											<input
												type={showConfirmPassword ? "text" : "password"}
												id="confirmPassword"
												placeholder="Confirm password"
												onChange={handleInputChange}
												value={formData.confirmPassword}
											/>
											<img
												onClick={() => handleTogglePassword("confirmPassword")}
												src={
													showConfirmPassword
														? ImageList.EyeOpen
														: ImageList.EyeClose
												}
												alt="eye-icon"
												className="eyeIcon"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="personalFrom">
								<p className="title">Personal details</p>
								<div className="formFields">
									<div className="formInput">
										<label htmlFor="full_name">
											Name <span>*</span>
										</label>
										<div className="inputUserName">
											<input
												type="text"
												id="full_name"
												placeholder="Enter name"
												onChange={handleInputChange}
												value={formData.full_name}
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="phone_number">
											Phone Number <span>*</span>
										</label>
										<input
											type="tel"
											onFocus={preventWheelScroll}
											onInput={handleNumericInput}
											id="phone_number"
											placeholder="Enter phone number"
											onChange={handleInputChange}
											value={formData.phone_number}
											minLength="10"
											maxLength="15"
											pattern="[0-9]{10,15}"
											title="Please enter a valid phone number (between 10 and 15 digits)."
											required
										/>
									</div>

									<div className="formInput">
										<label htmlFor="companyName">Company Name </label>
										<div className="inputUserName">
											<input
												type="text"
												id="company_name"
												placeholder="Enter company name"
												onChange={handleInputChange}
												value={formData.company_name}
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="companyGST">Company GST </label>
										<input
											type="text"
											id="company_gst"
											placeholder="Enter company GST"
											onChange={handleInputChange}
											value={formData.company_gst}
										/>
									</div>
								</div>
							</div>
							<div className="status">
								<p>Status</p>
								<div className="radioBtns">
									<label>
										<input
											type="radio"
											id="is_active_true"
											value={true}
											checked={formData.is_active}
											onChange={handleInputChange}
										/>
										Active
									</label>
									<label>
										<input
											type="radio"
											id="is_active_false"
											value={false}
											checked={!formData.is_active}
											onChange={handleInputChange}
										/>
										Blocked
									</label>
								</div>
							</div>
							<div className="roles">
								<p>
									Roles{" "}
									<span style={{ color: "red", fontSize: "14px" }}>*</span>
								</p>
								<div className="checkboxBtn">
									{userRole.map((role) => (
										<label key={role.role_id}>
											<input
												type="checkbox"
												id={role.role_id}
												data-roleid={role.role_id}
												checked={formData.roles.includes(String(role.role_id))}
												onChange={handleInputChange}
											/>
											{role.role_name === "Student"
												? "Participant"
												: role.role_name}
										</label>
									))}
								</div>
							</div>
							<button className="nextBtn" onClick={handleSubmit}>
								{type === "edit" ? "Update User" : "Create new user"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddUser;
