import React, { useState } from "react";
import "./CreateBusinessModal.scss";
import { enableVideoRequest } from "../../interface/implementation/batchCRM/enableVideoRequestAPIImp";
import Cookies from "js-cookie";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createBusiness } from "../../interface/implementation/admin/createBusinessAPIImp";
function CreateBusinessModal({ onClick, business, type, handleReload }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [name, setName] = useState(business ? business.name : "");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const showSuccess = (errorText) => {
		showSuccessToast(errorText);
	};

	const handleCloseClick = () => {
		onClick();
	};
	const handleSaveClick = () => {
		if (!name || name.trim() === "") {
			showToast("New Business field is empty");
			return;
		}
		if (type === "edit") {
			let formData = {
				id: business.id,
				name: name,
			};
			createBusiness(token, formData)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					showSuccess("Nature of Business Edited");
					// navigate("/course-list")
					handleReload();
					onClick();
				})
				.catch(() => showToast("Something went wrong"));
		} else {
			let formData = {
				name: name,
			};
			createBusiness(token, formData)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					showSuccess("Nature of Business Created");
					// navigate("/course-list")
					handleReload();
					onClick();
				})
				.catch((err) => showToast("Something went wrong"));
		}
	};

	return (
		<div className="business-modal-overlay createBusinessModal">
			<div className="video-modal-content">
				<div className="video-modal-header">
					<p className="video-title">Create nature of business</p>
					<p className="closeBtn" onClick={handleCloseClick}>
						x
					</p>
				</div>
				<div className="body">
					<div className="inputContainer">
						<label htmlFor="selectedParticipantType"> New business</label>
						<div className="inputCode">
							<input
								type="text"
								id="name"
								placeholder="nature of business"
								onChange={(e) => setName(e.target.value)}
								value={name}
								required
							/>
						</div>
					</div>
				</div>
				<div className="saveButtonWrapper">
					<button className="approveBtn" onClick={handleSaveClick}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default CreateBusinessModal;
