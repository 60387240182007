/** @format */

import React, { useEffect, useState, useRef } from "react";
import Button from "../../../components/Button/Button";
import MCQCard from "../../../components/HWRadioButton/RadioButton";
import Layout from "../../../components/Layout/Layout";
import NotesAttachment from "../../../components/NotesAttachment/NotesAttachment";
import ToggleButtons from "../../../components/ToggleButtons/ToggleButtons";
import ImageList from "../../../utils/ImageList";
import "./HomeWorkList.scss";
import Cookies from "js-cookie";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getStudentWeekHomework } from "../../../interface/implementation/student/getStudentWeekHomeworkAPIImp";
import { updateHomework } from "../../../interface/implementation/student/updateHomeworkAPIImp";
import _isEmpty from "lodash/isEmpty";
export default function ClassList() {
	const navigate = useNavigate();
	// const [homeworkList, setHomeworkList] = useState([]);
	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const location = useLocation();
	const stateData = location.state;
	const batchId = stateData.batchId;
	const weekNo = stateData.weekNo;
	const [fileNames, setFileNames] = useState([]); // State to store uploaded file names
	const fileInputRef = useRef();
	const [formData, setFormData] = useState({
		homework_documents: [], // Initialize attendance_image as an empty array
	});
	const [homeworkListData, setHomeworkListData] = useState([]);
	const [responses, setResponses] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [urls, setUrls] = useState([]);
	const [overallScore, setOverallScore] = useState({});
	const [completion, setCompletion] = useState(false);
	useEffect(() => {
		const fetchHomework = async () => {
			const searchParameter = `${batchId}/${weekNo}`;
			getStudentWeekHomework(token, searchParameter)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						// showToast(res.message);
						return;
					}
					setHomeworkListData(res.homework_details);
					setOverallScore(res.homework_details[0].score);
					setAttachments(res.homework_details[0].attachments);
					setUrls(res.homework_details[0].youtube_links);
					setCompletion(res.homework_details[0].completion_status);
					setFormData({
						homework_documents: res.homework_details[0].homework_documents,
					});
					// setHomeworkList(res.);
				})
				.catch();
		};
		fetchHomework();
	}, []);

	// const homeworkListData = [
	//   {
	//     quest: "1 - Are you having EM with 80% staff?",
	//   },
	//   {
	//     quest:
	//       "2 - Have you calculated the existing Profit & Revenue- Monthly, Quarterly, Half Yearly, Yearly?",
	//   },
	//   {
	//     quest:
	//       "3 - Have you calculated the Target Profit & Revenue for this year ( 2X, at least) ?",
	//   },
	//   {
	//     quest:
	//       "4 - Have you calculated the Target Revenue & Profit per Month, working on work on the 5 parameters?",
	//   },
	//   {
	//     quest:
	//       "5 - Have you started working on each parameter for 2/3 months and then put it on continuous improvement and then move to the next parameter?",
	//   },
	// ];

	const [selectedClass, setSelectClass] = useState("Homework");
	const Buttons = [
		{
			label: "Homework",
			value: "Homework",
		},
		{
			label: "Notes & Attachments",
			value: "Notes&Attachments",
		},
		{
			label: "Videos",
			value: "Videos",
		},
	];

	const handleChange = (value) => {
		setSelectClass(value);
	};

	const handleAnswerChange = (updatedQuestion, weekNo) => {
		setResponses((prevResponses) => {
			const updatedResponses = [...prevResponses];
			const existingIndex = updatedResponses.findIndex(
				(response) =>
					response.id === updatedQuestion.id && response.week_no === weekNo
			);

			if (existingIndex !== -1) {
				// Update existing answer if the updatedQuestion has a non-null answer
				if (updatedQuestion.answer !== null) {
					updatedResponses[existingIndex] = {
						...updatedQuestion,
						week_no: weekNo,
					};
				} else {
					// If updatedQuestion has a null answer, retain the existing answer from the API response
					updatedResponses[existingIndex].answer = updatedQuestion.answer;
				}
			} else {
				// Add new answer
				updatedResponses.push({ ...updatedQuestion, week_no: weekNo });
			}

			return updatedResponses;
		});
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const files = e.target.files;

		// Create an array to store base64 representations of each file
		const newFiles = [];

		// Loop through each file
		for (let i = 0; i < files.length; i++) {
			const file = files[i];

			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;

				// Add the file object to the array
				newFiles.push({
					filename: file.name,
					content: base64String,
				});

				// All files have been processed, update state
				if (newFiles.length === files.length) {
					setFormData((prevData) => ({
						...prevData,
						homework_documents: [...prevData.homework_documents, ...newFiles],
					}));

					// Update file names
					setFileNames((prevFileNames) => [
						...prevFileNames,
						...newFiles.map((file) => file.fileName),
					]);
				}
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveFile = (index) => {
		const updatedFiles = [...formData.homework_documents];
		updatedFiles.splice(index, 1);
		setFormData((prevData) => ({
			...prevData,
			homework_documents: updatedFiles,
		}));

		// Remove the file name at the specified index
		setFileNames((prevFileNames) => {
			const updatedFileNames = [...prevFileNames];
			updatedFileNames.splice(index, 1);
			return updatedFileNames;
		});
	};

	const submitHomework = () => {
		const payload = {
			batch_id: batchId,
			homeworks: homeworkListData.map((week) => {
				return {
					week_no: week.week_no,
					response: week.homework_questions_answers.map((question) => {
						const existingResponse = responses.find(
							(response) =>
								response.id === question.id && response.week_no === week.week_no
						);
						return {
							id: question.id,
							question: question.question,
							options: question.options,
							answer: existingResponse
								? existingResponse.answer
								: question.answer,
						};
					}),
					homework_documents: formData.homework_documents,
				};
			}),
		};

		updateHomework(token, payload)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				successToast("Homework updated.");
				navigate("/dashboard");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<Layout
			title={"Homework List Week"}
			headerChildren={
				<>
					{/* <Button
            buttonName={"Back"}
            buttonIcon={ImageList.BlueArrowLeft}
            color={"#4367B0"}
            onClick={() => {}}
            style={{
              border: "1px solid #4367B0",
            }}
          /> */}
				</>
			}
		>
			<div className="subTitle subTitleMargin">
				<ToastContainer limit={1} />
				<p>
					<b>Batch Name:</b>{" "}
					{homeworkListData &&
						homeworkListData.length > 0 &&
						homeworkListData[0].batch_name}
				</p>
				<p>
					<b>Course Name:</b>{" "}
					{homeworkListData &&
						homeworkListData.length > 0 &&
						homeworkListData[0].course_name}
				</p>
			</div>
			<div className="subTitle">
				<p>
					<b>Start Date</b> :
					{homeworkListData &&
						homeworkListData.length > 0 &&
						homeworkListData[0].start_date.split("-").reverse().join("/")}
				</p>
				<p>
					<b>End Date</b> :{" "}
					{homeworkListData &&
						homeworkListData.length > 0 &&
						homeworkListData[0].end_date.split("-").reverse().join("/")}
				</p>
			</div>
			<div className="radioInputsContainer">
				<div className="toggleButtons">
					<ToggleButtons
						onChange={handleChange}
						value={selectedClass}
						Options={Buttons}
					/>
					{selectedClass === "Homework" && (
						<div className="toggleRightText">
							<p>
								Completed Scoring: {overallScore && overallScore.complete_score}
							</p>
							<p>
								InCompleted Scoring:{" "}
								{overallScore && overallScore.incomplete_score}
							</p>
						</div>
					)}
				</div>

				{selectedClass === "Homework" && (
					<div>
						{homeworkListData &&
							homeworkListData.length > 0 &&
							homeworkListData.map((week, weekIndex) => (
								<div key={weekIndex}>
									{week.homework_questions_answers &&
										week.homework_questions_answers.length > 0 &&
										week.homework_questions_answers.map(
											(question, questionIndex) => (
												<MCQCard
													key={questionIndex}
													question={question}
													week_no={week.week_no}
													completion_status={week.completion_status}
													onAnswerChange={(updatedQuestion) =>
														handleAnswerChange(updatedQuestion, week.week_no)
													}
												/>
											)
										)}
								</div>
							))}
						{homeworkListData && homeworkListData.length > 0 && (
							<div
								className="uploadArea"
								style={{ width: "35%", marginTop: "20px" }}
							>
								<p className="titleHomework">
									{" "}
									{!homeworkListData[0]?.upload_status
										? "Upload your homework"
										: "Uploaded files"}
								</p>
								<div className="uploadContainer">
									<input
										type="file"
										name=""
										id="fileInput"
										ref={fileInputRef}
										multiple
										onChange={handleFileChange}
										style={{ display: "none" }}
									/>
									{!homeworkListData[0]?.upload_status && (
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
									)}
									<div className="fileNames">
										{formData.homework_documents &&
											formData.homework_documents.length > 0 &&
											formData.homework_documents.map((file, index) => (
												<p key={index} className="fileName">
													<a
														href={file.content}
														target="_blank"
														rel="noopener noreferrer"
													>
														{" "}
														{file.filename}{" "}
													</a>
													{!homeworkListData[0]?.upload_status && (
														<span
															className="removeBtn"
															onClick={() => handleRemoveFile(index)}
														>
															x Remove
														</span>
													)}
												</p>
											))}
									</div>
									{formData.homework_documents &&
										formData.homework_documents.length === 0 && (
											<div>
												<p className="uploadInfo">
													<b>
														Please make sure that your homework is clearly
														visible in the photo/document.
													</b>{" "}
													<br />
													{/* <b>Click on Upload file File --- Select files from your device --- Click on Take Photo</b> <br /> */}
													Maximum size: 64 MB. File format: doc, docx, xls,
													xlsx, pdf, jpeg, jpg, png, zip, ppt, pptx.
												</p>
											</div>
										)}
								</div>
							</div>
						)}
						{!homeworkListData[0]?.upload_status && (
							<button className="saveBtn" onClick={submitHomework}>
								Save
							</button>
						)}
					</div>
				)}
				{selectedClass === "Notes&Attachments" && (
					<div className="notesContainer">
						{attachments &&
							attachments.length > 0 &&
							attachments.map((item, i) => (
								<NotesAttachment key={i} value={i} attachments={item} />
							))}
					</div>
				)}

				{selectedClass === "Videos" && (
					<div className="notesContainer">
						{urls &&
							urls.length > 0 &&
							urls.map((url, i) => {
								return (
									<p key={i}>
										YouTube video reference - {i + 1}:{" "}
										<a href={url} target="_blank" rel="noopener noreferrer">
											{url}
										</a>
									</p>
								);
							})}
					</div>
				)}
			</div>
		</Layout>
	);
}
