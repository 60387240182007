import downloadHelpTicketAPI from "../../apis/admin/downloadHelpTicketAPI";

export function downloadHelpTicket(token) {
  return new Promise(async (resolve, reject) => {
    downloadHelpTicketAPI
      .download(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
