import getStudentClassesAPI from '../../apis/student/getStudentClassesAPI';

export function getStudentClasses(token){
    return new Promise(async(resolve, reject) => {
        getStudentClassesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}