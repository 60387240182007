import React, { useEffect } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToastContainer } from "react-toastify";
import { showToasterMessage } from "./utils/helper";
import { preventWheelScroll } from "../src/utils/helper";

import Router from "./router";

function App() {
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	// useEffect(() => {
	// 	const handleWindowResize = () => {
	// 		if (window.innerWidth < 1000) {
	//
	// 			showToast("Best viewing experience is on desktop");
	// 		}
	// 	};
	// 	window.addEventListener("resize", handleWindowResize);

	// 	return () => {
	// 		window.removeEventListener("resize", handleWindowResize);
	// 	};
	// }, []);

	return (
		<div className="App">
			<ToastContainer limit={1} />
			<Router />
		</div>
	);
}

export default App;
