import getQRCodesAPI from '../../apis/admin/getQRCodesAPI';


export function getQRCodes(token){
    return new Promise(async(resolve, reject) => {
        getQRCodesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}