import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";
import "./AddStudent.scss";
import { addStudentToBatch } from "../../../interface/implementation/admin/addStudentToBatchAPIImp";
import Cookies from "js-cookie";
import { getSingleUser } from "../../../interface/implementation/admin/getSingleStudentAPIImp";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { getSalesperson } from "../../../interface/implementation/admin/getSalespersonAPIImp";
import { getHelpedBy } from "../../../interface/implementation/admin/getHelpedByAPIImp";
import { getBusiness } from "../../../interface/implementation/admin/getBusinessAPIImp";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import _isEmpty from "lodash/isEmpty";

function AddStudent() {
	const { type, userId } = useParams();
	const location = useLocation();
	const studentId = location.state ? location.state.studentId : null;
	const participantType =
		location.state && location.state.participantType
			? location.state.participantType
			: "Primary";
	const batchName = location.state ? location.state.batchName : null;
	const token = Cookies.get("token");
	const [fileNames, setFileNames] = useState([]);
	const [businessList, setBusinessList] = useState([]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const navigate = useNavigate();
	const fileInputRef = useRef();
	const [dropdownOptions, setDropdownOptions] = useState({
		crm: [],
		salesperson: [],
		helped_by: [],
	});

	const [formData, setFormData] = useState({
		participant_type: type === "add" ? "Primary" : "",
		payment_category: "National",
		status: "Registered",
		proof_of_partnerships: [],
		terms_conditions_id: "",
	});
	// const [formData, setFormData] = useState({
	//   user_id: 0,
	//   batch_id: userId,
	//   company_id: "",
	//   company_name: "",
	//   student_name: "",
	//   client_id: 0,
	//   date_time: "",
	//   time: "",
	//   offer: "",
	//   discount: 0,
	//   participant_type: "",
	//   participants_name: "",
	//   phone_no: "",
	//   updated_phone_no: "",
	//   email_id: "",
	//   accounts_mail: "",
	//   accounts_phone: "",
	//   gst_no: "",
	//   address_for_invoices: "",
	//   tan_no: "",
	//   city: "",
	//   location: "",
	//   refered_by: "",
	//   registered_by: "",
	//   helped_by: "",
	//   student_input: "",
	//   participants_old_status: "",
	//   crm: "",
	//   status: "",
	//   industry: "",
	//   turnover: 0,
	//   nature_of_business: "",
	//   product: "",
	//   proof_of_partnerships: [""],
	//   partner_verify_done: "",
	//   add_member: true,
	//   add_no_members: 0,
	//   payment_category: "",
	//   payment_type: "",
	//   member_amount: 0,
	//   participants_status: "",
	//   is_active: true,
	//   documentFiles: [],
	//   memberType: "",
	//   verificationStatus: "",
	// });

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
			batch_id: userId,
		}));
	};

	useEffect(() => {
		getBusiness(token)
			.then((res) => {
				setBusinessList(res);
			})
			.catch();
	}, []);

	useEffect(() => {
		if (type === "edit") {
			getSingleUser(token, studentId).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				let student = res.student_details[0];
				const proofOfPartnershipsArray =
					res.student_details[0]?.proof_of_partnerships || [];

				// Set proofOfPartnershipsArray to setFileNames
				setFileNames(proofOfPartnershipsArray);
				setFormData((prevData) => ({
					...prevData,
					...student,
				}));
			});
		}
	}, []);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getCRM(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					// showToast(res.message);
					return;
				}
				setDropdownOptions((prevData) => ({
					...prevData,
					crm: res.users,
				}));
			});
			await getSalesperson(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					// showToast(res.message);
					return;
				}
				setDropdownOptions((prevData) => ({
					...prevData,
					salesperson: res.users,
				}));
			});
			await getHelpedBy(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					// showToast(res.message);
					return;
				}
				setDropdownOptions((prevData) => ({
					...prevData,
					helped_by: res.users,
				}));
			});
		};
		fetchDropdowns();
	}, []);

	const submitFormData = async () => {
		// if (_isEmpty(formData.date_time)) {
		//   showToast("Date field is empty");
		//   return;
		// }

		if (_isEmpty(formData.participant_type)) {
			showToast("Participant type field is empty");
			return;
		}
		if (_isEmpty(formData.participants_name)) {
			showToast("Participant Name field is empty");
			return;
		}

		if (_isEmpty(formData.phone_no)) {
			showToast("Phone No field is empty");
			return;
		}
		if (formData.phone_no.length < 10) {
			showToast("Phone No must be at least 10 digits");
			return;
		}
		if (_isEmpty(formData.email_id)) {
			showToast("Email Id field is empty");
			return;
		}
		if (!formData.email_id.includes("@")) {
			showToast("Invalid email address.");
			return;
		}

		if (_isEmpty(formData.company_name)) {
			showToast("Company name field is empty");
			return;
		}

		if (_isEmpty(formData.add_member)) {
			showToast("Add member must be clicked");
			return;
		}
		// if (_isEmpty(formData.payment_category)) {
		//   showToast("Payment category field is empty");
		//   return;
		// }
		// if (!formData.status) {
		//   showToast("Status field is empty");
		//   return;
		// }
		if (formData.terms_conditions_id < 1) {
			showToast("Terms and conditions field is empty");
			return;
		}

		await addStudentToBatch(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Participant updated successfully.");
				if (formData.add_member === "yes") {
					navigate("/add-partner", {
						state: {
							noOfMembers: formData.add_no_members,
							primaryPhoneNo: formData.phone_no,
							batchId: userId,
							batchName: formData.batch_name,
							memberAmount: formData.member_amount,
							terms: formData.terms_conditions_id,
							studentId: studentId,
						},
					});
				}
				// else if (formData.participant_type === 'Secondary') {
				//   navigate("/batch-student-list", {
				//     state: {
				//       batchId: userId
				//     }
				//   });
				// }else{
				else {
					navigate(
						type === "edit" ? "/refund-invoice" : "/batch-student-list",
						{
							state: {
								batchId: userId,
								studentId: studentId,
								studentName: formData.participants_name,
								companyName: formData.company_name,
								participantType: formData.participant_type,
							},
						}
					);
				}
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleNext = async () => {
		await submitFormData();
		// navigate(type === "edit" ? "/batch-student-list" : "/add-partner");
	};

	const handleBack = () => {
		navigate(type === "edit" ? "/batch-student-list" : "/student-batch-list", {
			state: {
				batchId: userId,
			},
		});
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const files = e.target.files;

		// Create an array to store base64 representations of each file
		const newFiles = [];

		// Loop through each file
		for (let i = 0; i < files.length; i++) {
			const file = files[i];

			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;

				// Add the file object to the array
				newFiles.push({
					filename: file.name,
					content: base64String,
				});

				// All files have been processed, update state
				if (newFiles.length === files.length) {
					setFormData((prevData) => ({
						...prevData,
						proof_of_partnerships: [
							...prevData.proof_of_partnerships,
							...newFiles,
						],
					}));

					// Update file names
					setFileNames((prevFileNames) => [...prevFileNames, ...newFiles]);
				}
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveFile = (index) => {
		const updatedFiles = [...formData.proof_of_partnerships];
		updatedFiles.splice(index, 1);
		setFormData((prevData) => ({
			...prevData,
			proof_of_partnerships: updatedFiles,
		}));

		// Remove the file name at the specified index
		setFileNames((prevFileNames) => {
			const updatedFileNames = [...prevFileNames];
			updatedFileNames.splice(index, 1);
			return updatedFileNames;
		});
	};

	return (
		<div className="addStudent">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						{/* {type === "edit" ? (
              "Edit student"
            ) : "Add New student"} */}
						{batchName}
					</div>
					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							{/* offer */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="offer">Offer</label>
									<div className="inputUserName">
										<input
											type="text"
											id="offer"
											placeholder="Enter offer code"
											onChange={handleInputChange}
											value={formData.offer}
										/>
									</div>
								</div>
							)}

							{/* date */}
							{participantType === "Primary" && (
								<div className="dateTime">
									<div className="formInput">
										<label htmlFor="date">Date</label>
										<div className="inputUserName">
											<input
												type="date"
												id="date_time"
												onChange={handleInputChange}
												value={formData.date_time}
											/>
										</div>
									</div>
								</div>
							)}

							{/* discount */}

							<div className="formInput">
								<label htmlFor="discount">Discount % </label>
								<div className="inputUserName">
									<input
										type="number"
										id="discount"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										placeholder="Enter discount %"
										onChange={handleInputChange}
										value={formData.discount}
									/>
								</div>
							</div>

							{participantType === "Secondary" && (
								<div className="formInput">
									<label htmlFor="memberAmount">Member Amount</label>
									<div className="inputUserName">
										<input
											type="number"
											id="memberAmount"
											onFocus={preventWheelScroll}
											onInput={handleNumericInput}
											placeholder="Enter member amount"
											onChange={handleInputChange}
											value={formData.member_amount}
										/>
									</div>
								</div>
							)}

							{/* participant type */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="participant_type">
										participant type<span>*</span>
									</label>
									<div className="inputUserName">
										<select
											id="participant_type"
											onChange={handleInputChange}
											value={formData.participant_type}
										>
											{type === "add" && (
												<>
													<option value="">Select participant Type</option>
													<option value="Primary">Primary</option>
													<option value="Secondary">Secondary</option>
												</>
											)}
											{type === "edit" && (
												<>
													<option value="">Select participant Type</option>
													<option value="Primary">Primary</option>
													<option value="Secondary">Secondary</option>
												</>
											)}
										</select>
									</div>
								</div>
							)}

							{/* student name */}
							<div className="formInput">
								<label htmlFor="student_name">
									Participant name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="participants_name"
										placeholder="Enter Participant name"
										onChange={handleInputChange}
										value={formData.participants_name}
									/>
								</div>
							</div>

							{/* tan number */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="tan_no">TAN number</label>
									<div className="inputUserName">
										<input
											type="text"
											id="tan_no"
											placeholder="Enter TAN number"
											onChange={handleInputChange}
											value={formData.tan_no}
										/>
									</div>
								</div>
							)}

							{/* phone number */}

							<div className="formInput">
								<label htmlFor="phone_no">
									Phone number<span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="tel"
										id="phone_no"
										placeholder="Enter phone number"
										onChange={handleInputChange}
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										value={formData.phone_no}
										pattern="[0-9]{10,15}"
										maxLength={15}
										minLength={10}
										title="Please enter a valid phone number (between 10 and 15 digits)."
									/>
								</div>
							</div>

							{/* referred by */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">Referred by</label>
									<div className="inputUserName">
										<input
											type="text"
											id="refered_by"
											placeholder="Enter Referred by"
											onChange={handleInputChange}
											value={formData.refered_by}
										/>
									</div>
								</div>
							)}

							{/*  alternate student phone */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="updated_phone_no">
										Alternate phone number
									</label>
									<div className="inputUserName">
										<input
											type="tel"
											id="updated_phone_no"
											placeholder="Enter alternate phone number"
											onChange={handleInputChange}
											onFocus={preventWheelScroll}
											onInput={handleNumericInput}
											value={formData.updated_phone_no}
											maxLength={15}
											minLength={10}
											pattern="[0-9]{10,15}"
											title="Please enter a valid phone number (between 10 and 15 digits)."
										/>
									</div>
								</div>
							)}

							{/* registered by */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="registered_by">Registered by</label>
									<div className="inputUserName">
										<select
											id="registered_by"
											onChange={handleInputChange}
											value={formData.resgister_by}
										>
											<option value="">Select Registered by</option>
											{dropdownOptions?.salesperson?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
									</div>
								</div>
							)}

							{/* communication zoom id */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="phone_no">
										Communication zoom ID <span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="communication_zoom_id"
											placeholder="Enter communication zoom id"
											onChange={handleInputChange}
											value={formData.communication_zoom_id}
										/>
									</div>
								</div>
							)}

							{/* helped by */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="helped_by">Helped by</label>
									<div className="inputUserName">
										<select
											id="helped_by"
											onChange={handleInputChange}
											value={formData.helped_by}
										>
											<option value="">Select Helped by</option>
											{dropdownOptions?.helped_by?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
										{/* <select defaultValue={"standard"}>
                      <option value={"small"}>Small</option>
                      <option value={"standard"}>Standard</option>
                      <option value={"large"}>Large</option>
                    </select> */}
									</div>
								</div>
							)}

							{/* company mail */}
							<div className="formInput">
								<label htmlFor="email_id">
									{participantType === "Secondary"
										? "Communication zoom id"
										: "Company email"}
									<span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="email_id"
										placeholder="Enter Company email"
										onChange={handleInputChange}
										value={formData.email_id}
									/>
								</div>
							</div>

							{/* student input */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="student input">input</label>
									<div className="inputUserName">
										<input
											type="text"
											id="student_input"
											placeholder="Enter Participant input"
											onChange={handleInputChange}
											value={formData.student_input}
										/>
									</div>
								</div>
							)}

							{/* company name */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="phone_no">
										Company Name <span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="company_name"
											placeholder="Enter company name"
											onChange={handleInputChange}
											value={formData.company_name}
										/>
									</div>
								</div>
							)}

							{/* crm */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="crm">CRM</label>
									<div className="inputUserName">
										<select
											id="crm"
											onChange={handleInputChange}
											value={formData.crm}
										>
											<option value="">Select CRM</option>
											{dropdownOptions?.crm?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
									</div>
								</div>
							)}

							{/* gst number */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="tan_no">Company GST Number</label>
									<div className="inputUserName">
										<input
											type="text"
											id="gst_no"
											placeholder="Enter company GST number"
											onChange={handleInputChange}
											value={formData.gst_no}
										/>
									</div>
								</div>
							)}

							{/* payment category */}

							<div className="formInput">
								<label htmlFor="payment_category">Payment Category</label>
								<div className="inputUserName">
									<select
										id="payment_category"
										onChange={handleInputChange}
										value={formData.payment_category}
									>
										<option value="">Select Payment category</option>
										<option value="National" selected>
											National
										</option>
										<option value="International">International</option>
										{/* <option value="comp">Comp</option> */}
									</select>
								</div>
							</div>
							{/* missing payment type */}
						</div>

						<div className="courseDetailForm">
							{/* company address */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">Company Address</label>
									<div className="inputUserName">
										<input
											type="text"
											id="company_address"
											placeholder="Enter company address"
											onChange={handleInputChange}
											value={formData.company_address}
										/>
									</div>
								</div>
							)}

							{formData.participant_type === "Primary" &&
								participantType === "Primary" && (
									<div className="formInput">
										<label htmlFor="add_member" className="memberTitle">
											Do you want to add member?
										</label>
										<div className="inputUserName memberRadio">
											<label>
												<input
													type="radio"
													id="add_member"
													value="yes"
													checked={formData.add_member === "yes"}
													onChange={handleInputChange}
												/>
												Yes
											</label>
											<label>
												<input
													type="radio"
													id="add_member"
													value="no"
													checked={formData.add_member === "no"}
													onChange={handleInputChange}
												/>
												No
											</label>
										</div>
									</div>
								)}

							{/* member type */}

							{participantType === "Secondary" && (
								<div className="memberType">
									<div className="formInput">
										<label htmlFor="member_type">Member Type</label>
										<div className="inputUserName">
											<select
												id="member_type"
												onChange={handleInputChange}
												value={formData.member_type}
											>
												<option value="">Select Member Type</option>
												<option value="partner">Partner</option>
												<option value="implementer">Implementer</option>
											</select>
										</div>
									</div>
								</div>
							)}

							{/* billing address */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">Company billing address</label>
									<div className="inputUserName">
										<textarea
											type="text"
											id="company_billing_address"
											placeholder="Enter billing address"
											onChange={handleInputChange}
											value={formData.company_billing_address}
										></textarea>
										{/* <input
											type="text"
											id="company_billing_address"
											placeholder="Enter billing address"
											onChange={handleInputChange}
											value={formData.company_billing_address}
										/> */}
									</div>
								</div>
							)}

							{formData.participant_type === "Primary" &&
								participantType === "Primary" && (
									<div className="formInput">
										{formData.add_member === "yes" && (
											<>
												<label htmlFor="add_no_members">
													No of members you want to add
												</label>
												<div className="inputUserName">
													<input
														type="number"
														onFocus={preventWheelScroll}
														onInput={handleNumericInput}
														id="add_no_members"
														placeholder="Enter no of Members"
														onChange={handleInputChange}
														value={formData.add_no_members}
													/>
												</div>
											</>
										)}
									</div>
								)}

							{/* accountant email */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="email_id">Accountant Email ID</label>
									<div className="inputUserName">
										<input
											type="text"
											id="accounts_mail"
											placeholder="Enter accountant Email ID"
											onChange={handleInputChange}
											value={formData.accounts_mail}
										/>
									</div>
								</div>
							)}

							{/* status */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="is_active">
										Status<span>*</span>
									</label>
									<div className="inputUserName">
										<select
											id="status"
											onChange={handleInputChange}
											value={formData.status}
										>
											<option value="">Select Status</option>
											<option value="Registered">Registered</option>
											<option value="Expired">Expired</option>
										</select>
									</div>
								</div>
							)}
							{/* missing accountant number */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="turnover">Accountant Contact No</label>
									<div className="inputUserName">
										<input
											type="number"
											id="accountant_contact_no"
											placeholder="Enter turnover"
											onChange={handleInputChange}
											value={formData.accountant_contact_no}
										/>
									</div>
								</div>
							)}
							{/* participant old status */}
							<div className="formInput">
								<label htmlFor="participants_old_status">
									Participant old status
								</label>
								<div className="inputUserName">
									<select
										id="participants_old_status"
										onChange={handleInputChange}
										value={formData.participants_old_status}
									>
										<option value="">Select participant old status</option>
										<option value="Primary carry forward">
											Primary carry forward
										</option>
										<option value="Secondary carry forward">
											Secondary carry forward
										</option>
										<option value="Implementer carry forward">
											Implementer carry forward{" "}
										</option>
										<option value="Shifted from prev batch">
											Shifted from prev batch{" "}
										</option>
										<option value="Repeater">Repeater</option>
									</select>
								</div>
							</div>

							{/* missing courier address */}

							{/* city */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">City</label>
									<div className="inputUserName">
										<input
											type="text"
											id="city"
											placeholder="Enter city"
											onChange={handleInputChange}
											value={formData.city}
										/>
									</div>
								</div>
							)}

							{/* turnover */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="turnover">Turnover</label>
									<div className="inputUserName">
										<input
											type="text"
											id="turnover"
											placeholder="Enter turnover"
											onChange={handleInputChange}
											value={formData.turnover}
										/>
									</div>
								</div>
							)}

							{/* state */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">State</label>
									<div className="inputUserName">
										<input
											type="text"
											id="state"
											placeholder="Enter state"
											onChange={handleInputChange}
											value={formData.state}
										/>
									</div>
								</div>
							)}

							{/* technical notes */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="technical_notes" className="memberTitle">
										Technical Notes
									</label>
									<div className="inputUserName memberRadio">
										<label>
											<input
												type="radio"
												id="technical_notes"
												value="yes"
												checked={formData.technical_notes === "yes"}
												onChange={handleInputChange}
											/>
											Yes
										</label>
										<label>
											<input
												type="radio"
												id="technical_notes"
												value="no"
												checked={formData.technical_notes === "no"}
												onChange={handleInputChange}
											/>
											No
										</label>
									</div>
								</div>
							)}

							{/* nature of business */}
							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">Nature of Business</label>
									<div className="inputUserName">
										{/* <input
    type="text"
    id="nature_of_business"
    placeholder="Nature of business"
    onChange={handleInputChange}
    value={formData.nature_of_business}
  /> */}
										<select
											id="nature_of_business"
											onChange={handleInputChange}
											value={formData.nature_of_business}
										>
											<option value="">Select Nature of business</option>
											{businessList?.map((option, i) => (
												<option key={i} value={option.name}>
													{option.name}
												</option>
											))}
										</select>
									</div>
								</div>
							)}

							{/* verification docs */}
							<div className="uploadArea">
								<p className="titleHomework">Verification Files</p>
								<div className="uploadContainer">
									<input
										type="file"
										name=""
										id="fileInput"
										ref={fileInputRef}
										multiple
										onChange={(e) => {
											// Assuming you have a variable for classNo, replace it with the actual value.
											// const classNo = 1; // Replace with your actual classNo value
											handleFileChange(e);
										}}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={handleUploadButtonClick}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									<div className="fileNames">
										{formData.proof_of_partnerships &&
											formData.proof_of_partnerships.length > 0 &&
											formData.proof_of_partnerships.map((file, index) => (
												<p key={index} className="fileName">
													<a
														href={file.content}
														target="_blank"
														rel="noopener noreferrer"
													>
														{file.filename}
													</a>
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
									</div>
									{formData.proof_of_partnerships &&
										formData.proof_of_partnerships.length === 0 && (
											<div>
												<p className="uploadInfo">
													Unlimited number of files can be uploaded to this
													field. Maximum size: 64 MB. File format: doc, docx,
													xls, xlsx, pdf, jpeg, jpg, png, zip, ppt, pptx.
												</p>
											</div>
										)}
								</div>
							</div>

							{/* product */}

							{participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="refered_by">Product</label>
									<div className="inputUserName">
										<input
											type="text"
											id="product"
											placeholder="Enter product"
											onChange={handleInputChange}
											value={formData.product}
										/>
									</div>
								</div>
							)}

							{/*  verification doc */}
							<div className="formInput">
								<label htmlFor="refered_by">Verification Doc No</label>
								<div className="inputUserName">
									<input
										type="text"
										id="verification_doc_no"
										placeholder="Enter verification doc no"
										onChange={handleInputChange}
										value={formData.verification_doc_no}
									/>
								</div>
							</div>

							{/* T&C */}
							<div className="formInput">
								<label htmlFor="terms_conditions_id">
									Terms & Conditions ID<span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="terms_conditions_id"
										placeholder="ID"
										onChange={handleInputChange}
										value={formData.terms_conditions_id}
									/>
								</div>
							</div>

							{/* verification status */}
							<div className="formInput">
								<label htmlFor="verificationStatus">Verification Status</label>
								<div className="inputUserName">
									<select
										type="text"
										id="verification_status"
										placeholder="Enter verification status"
										onChange={handleInputChange}
										value={formData.verification_status}
									>
										<option value="">Select Verfication status</option>
										<option value="Pending">Pending</option>
										<option value="Completed">Completed</option>
										{/* <option value="comp">Comp</option> */}
									</select>
								</div>
							</div>
						</div>
						<div className="footerNavBtns">
							<button className="backBtn" onClick={handleBack}>
								<img
									src={ImageList.GoBackBlue}
									alt=""
									className="forwardArrow"
								/>{" "}
								Back to list
							</button>

							<button onClick={handleNext} className="nextBtn">
								Next{" "}
								<img
									src={ImageList.ArrowForwardWhite}
									alt=""
									className="forwardArrow"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddStudent;
