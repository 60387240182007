import React, { useState } from "react";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer, toast } from "react-toastify";

import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";

import ImageList from "../../../utils/ImageList";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";

import "./AddCourse.scss";
import { useNavigate, useParams } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";

import { addCourse } from "../../../interface/implementation/admin/addCourseAPIImp";
import Cookies from "js-cookie";

function AddCourse() {
	const navigate = useNavigate();
	const [durationType, setDurationType] = useState(["days", "weeks", "months"]);
	const [formData, setFormData] = useState({
		course_name: "",
		basic_amount: null,
		code: "",
		member_basic_amount: null,
		duration_type: "",
		gst_amount: null,
		duration: null,
		// total_amount: null,
		// total_classes: null,
		// total_installments: null,
	});
	const token = Cookies.get("token");
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	// const handleInputChange = (e) => {
	//     const { id, value } = e.target;
	//     setFormData((prevData) => ({
	//         ...prevData,
	//         [id]: value,
	//     }));
	// };

	const handleInputChange = (e) => {
		const { id, value } = e.target;

		if (id === "basic_amount" || id === "gst_amount") {
			setFormData((prevData) => ({
				...prevData,
				[id]: parseFloat(value),
			}));
		} else {
			// Update other fields normally
			setFormData((prevData) => ({
				...prevData,
				[id]: value,
			}));
		}
	};

	const handleSubmit = () => {
		if (!formData.course_name || formData.course_name.trim() === "") {
			showToast("Course Name field is empty");
			return;
		}

		if (!formData.code || formData.code.trim() === "") {
			showToast("Code field is empty");
			return;
		}

		if (!formData.duration_type || formData.duration_type.trim() === "") {
			showToast("Duration Type field is empty");
			return;
		}

		if (!formData.duration) {
			showToast("Duration field is empty");
			return;
		}

		if (!formData.total_classes) {
			showToast("Total Classes field is empty");
			return;
		}

		if (!formData.basic_amount) {
			showToast("Basic Amount field is empty");
			return;
		}
		if (!formData.member_basic_amount) {
			showToast("Member Basic Amount field is empty");
			return;
		}
		if (!formData.gst_amount) {
			showToast("GST Amount field is empty");
			return;
		}
		if (!formData.total_installments) {
			showToast("Total Installemnts field is empty");
			return;
		}

		const totalAmount =
			parseFloat(formData.basic_amount) +
			parseFloat(formData.basic_amount) *
				(parseFloat(formData.gst_amount) / 100);

		// Rest of your code remains unchanged
		addCourse(token, { ...formData, total_amount: totalAmount })
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Course added successfully.");
				navigate("/course-list");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="addCourse">
			<ToastContainer limit={1} />
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Add New Course</div>

					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							<div className="formInput">
								<label htmlFor="course_name">
									Course Name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="course_name"
										placeholder="Enter course name"
										onChange={handleInputChange}
										value={formData.course_name}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="basic_amount">
									Primary Basic Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										min="0"
										id="basic_amount"
										placeholder="Enter basic amount"
										onChange={handleInputChange}
										value={formData.basic_amount}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="code">
									Code <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="code"
										placeholder="Enter code"
										onChange={handleInputChange}
										value={formData.code}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="member_basic_amount">
									Member Basic Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										min="0"
										id="member_basic_amount"
										placeholder="Enter member basic amount"
										onChange={handleInputChange}
										value={formData.member_basic_amount}
									/>
								</div>
							</div>
							{/* <div  className='formInput'>
                                <label htmlFor="duration_type">Duration Type <span>*</span></label>
                                <div className="inputUserName">
                                    <input
                                        type="text"
                                        id="duration_type"
                                        placeholder="Enter duration type"
                                        onChange={handleInputChange}
                                        value={formData.duration_type}
                                    />
                                </div>
                            </div> */}
							<div className="formInput">
								<label htmlFor="duration_type">
									Duration type <span>*</span>
								</label>
								<div className="inputUserName">
									<select
										id="duration_type"
										onChange={handleInputChange}
										value={formData.duration_type}
									>
										<option value="">Select type</option>
										{durationType &&
											durationType.length > 0 &&
											durationType.map((duration, index) => (
												<option key={index} value={duration}>
													{duration}
												</option>
											))}
									</select>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="gst_amount">
									GST (%) <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="gst_amount"
										placeholder="Enter GST (%)"
										onChange={handleInputChange}
										value={formData.gst_amount}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="duration">
									Duration <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										min="0"
										id="duration"
										placeholder="Enter duration"
										onChange={handleInputChange}
										value={formData.duration}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="total_amount">
									Total Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										min="0"
										id="total_amount"
										placeholder="Enter total amount"
										// onChange={handleInputChange}
										value={
											formData.basic_amount +
												formData.basic_amount * (formData.gst_amount / 100) ||
											""
										}
										readOnly
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="total_classes">
									Total Classes <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										min="0"
										id="total_classes"
										placeholder="Enter total no. of classes"
										onChange={handleInputChange}
										value={formData.total_classes}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="total_installments">
									Total Installments <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										min="0"
										id="total_installments"
										placeholder="Enter total no. of installments"
										onChange={handleInputChange}
										value={formData.total_installments}
									/>
								</div>
							</div>
						</div>
						<button className="nextBtn" onClick={handleSubmit}>
							Submit{" "}
							<img
								src={ImageList.ArrowForward}
								alt=""
								className="forwardArrow"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddCourse;
