import getStudentCalendarDetailsAPI from '../../apis/student/getStudentCalendarDetailsAPI';

export function getStudentCalendarDetails(token){
    return new Promise(async(resolve, reject) => {
      getStudentCalendarDetailsAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}