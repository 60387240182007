import React, { useState, useEffect, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";

import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import Cross from "../../../assets/cross.png";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import ImageList from "../../../utils/ImageList";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import "./AddWorkshop.scss";
import Cookies from "js-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addWorkshop } from "../../../interface/implementation/admin/addWorkshopAPIImp";
function AddWorkshop() {
	const navigate = useNavigate();
	const location = useLocation();
	const { type } = useParams();

	const [batchList, setBatchList] = useState([]);
	const [batchListArray, setBatchListArray] = useState([]);
	const [batchDetails, setBatchDetails] = useState([]);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const [pageType, setPageType] = useState("");

	useEffect(() => {
		setPageType(type);
	}, [type]);
	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const [editFormData, setEditFormData] = useState(() => {
		if (type === "edit") {
			const editData = location.state.batch;
			setBatchDetails(editData.batch_details);
			return {
				workshop_id: editData.workshop_id,
				workshop_title: editData.workshop_title,
				workshop_type: editData.workshop_type,
				workshop_description: editData.workshop_description,
				workshop_end_date: editData.workshop_end_datetime.split(" ")[0],
				workshop_visibility: editData.workshop_visibility,
				batch_list: editData.batch_list
					? editData.batch_list.map((item) => item.toString())
					: [],
				workshop_end_time: editData.workshop_end_datetime
					.split(" ")[1]
					.slice(0, 5),
				workshop_start_date: editData.workshop_start_datetime.split(" ")[0],
				workshop_start_time: editData.workshop_start_datetime
					.split(" ")[1]
					.slice(0, 5),
				registration_link: editData.registration_link,
				venue_details: editData.venue_details,
				workshop_flyer: editData.workshop_flyer || [],
			};
		} else {
			return null;
		}
	});
	const [formData, setFormData] = useState(() => {
		if (type === "edit" && editFormData) {
			return { ...editFormData };
		} else {
			return {
				workshop_title: "",
				workshop_type: "",
				workshop_description: "",
				workshop_end_date: "",
				workshop_visibility: "",
				batch_list: [],
				workshop_end_time: "",
				workshop_start_date: "",
				workshop_start_time: "",
				registration_link: "",
				venue_details: "",
				workshop_flyer: [],
			};
		}
	});
	const fileInputRef = useRef(null);
	const token = Cookies.get("token");

	const handleInputChange = (e) => {
		const { id, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handleBatchSelect = (e) => {
		const selectedBatch = e.target.value;

		if (!formData.batch_list.includes(selectedBatch)) {
			setFormData((prevData) => ({
				...prevData,
				batch_list: [...prevData.batch_list, selectedBatch],
			}));
		}
	};

	useEffect(() => {
		const remainingBatches = batchList.filter(
			(item) => !formData.batch_list.includes(item.batch_id.toString())
		);
		setBatchListArray(remainingBatches);
	}, [formData.batch_list]);

	const handleBatchRemove = (batchId) => {
		setFormData((prevData) => ({
			...prevData,
			batch_list: prevData.batch_list.filter((batch) => batch !== batchId),
		}));

		const remainingBatches = batchList.filter(
			(item) => !formData.batch_list.includes(item.batch_id.toString())
		);
		setBatchListArray(remainingBatches);
	};

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// setCourseList()
				setBatchList(res.batch);
				setBatchListArray(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const isValidDateRange = (startDate, endDate) => {
		const start = new Date(startDate);
		const end = new Date(endDate);
		return start <= end;
	};

	const handleSubmit = () => {
		if (!formData.workshop_title || formData.workshop_title.trim() === "") {
			showToast("Workshop title field is empty");
			return;
		}

		if (!formData.workshop_type || formData.workshop_type.trim() === "") {
			showToast("Workshop type field is empty");
			return;
		}

		if (formData.workshop_type.trim().length < 4) {
			showToast("Workshop type should have at least 4 characters");
			return;
		}

		if (
			!formData.workshop_visibility ||
			formData.workshop_visibility.trim() === ""
		) {
			showToast("Please select visibility");
			return;
		}
		if (formData.workshop_visibility === "publish") {
			if (!formData.batch_list || formData.batch_list.length === 0) {
				showToast("Please select batch");
				return;
			}
		}
		if (!formData.workshop_start_date || formData.workshop_start_date === "") {
			showToast("Workshop start Date field is empty");
			return;
		}
		if (!formData.workshop_start_time || formData.workshop_start_time === "") {
			showToast("Workshop start Time field is empty");
			return;
		}

		if (!formData.workshop_end_date || formData.workshop_end_date === "") {
			showToast("Workshop end Date field is empty");
			return;
		}
		if (!formData.workshop_end_time || formData.workshop_end_time === "") {
			showToast("Workshop End Time field is empty");
			return;
		}
		if (
			!isValidDateRange(
				formData.workshop_start_date,
				formData.workshop_end_date
			)
		) {
			showToast(
				"Workshop end date must be greater than or equal to Workshop start date"
			);
			return;
		}
		if (formData.registration_link === "") {
			showToast("Registration link field is empty");
			return;
		}
		if (!formData.venue_details || formData.venue_details === "") {
			showToast("Venue details field is empty");
			return;
		}
		if (!formData.workshop_flyer || formData.workshop_flyer === "") {
			showToast("Workshop flyer field is empty");
			return;
		}

		addWorkshop(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				if (pageType === "edit") {
					successToast("Workshop updated successfully.");
				} else {
					successToast("Batch created successfully.");
				}

				navigate("/workshop-list");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			// Display image preview

			// imageRef.current.src = imageUrl;
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;
				setFormData((prevData) => ({
					...prevData,
					workshop_flyer: [
						{
							filename: file.name,
							content: base64String,
						},
					],
				}));
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleRemoveFile = (index) => {
		setFormData((prevData) => {
			const updatedFiles = [...prevData.workshop_flyer];
			updatedFiles.splice(index, 1);
			return {
				...prevData,
				workshop_flyer: updatedFiles,
			};
		});
	};

	return (
		<div className="addWorkshop">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						{pageType === "edit" ? "Edit" : "Add"} Workshop
					</div>
					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							<div className="formInput">
								<label htmlFor="batchName">
									Workshop Title <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="workshop_title"
										placeholder="Enter workshop name"
										onChange={handleInputChange}
										value={formData.workshop_title}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="code">
									Workshop Type<span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="workshop_type"
										placeholder="Enter workshop type"
										onChange={handleInputChange}
										value={formData.workshop_type}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="course">Workshop Description </label>
								<div className="inputUserName">
									<input
										type="text"
										id="workshop_description"
										placeholder="Enter workshop description"
										onChange={handleInputChange}
										value={formData.workshop_description}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="course">
									Workshop Visibility <span>*</span>{" "}
								</label>

								<div className="inputUserName">
									<select
										name="workshop_visibility"
										id="workshop_visibility"
										value={formData.workshop_visibility}
										onChange={handleInputChange}
									>
										<option value="">Select visibility</option>

										<option value={"publish"}>Publish</option>
										<option value={"unpublish"}>UnPublish</option>
									</select>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="course">
									Batches
									{formData.workshop_visibility === "publish" && <span>*</span>}
								</label>
								<div className="inputUserName">
									<select
										name="batch_list"
										id="batch_list"
										onChange={handleBatchSelect}
										value=""
									>
										<option value="" disabled>
											Select batch
										</option>
										{batchListArray &&
											batchListArray.map((b, index) => (
												<option key={index} value={b.batch_id}>
													{b.batch_name}
												</option>
											))}
									</select>
								</div>

								<div className="selectedBatches">
									{formData.batch_list.map((batchId, index) => (
										<button
											className="selectedBatch"
											key={index}
											onClick={() => handleBatchRemove(batchId)}
										>
											{
												batchList.find(
													(batch) => batch.batch_id.toString() === batchId
												)?.batch_name
											}

											<img src={Cross} alt="Remove" />
										</button>
									))}
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="startDate">
									Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="workshop_start_date"
									onChange={handleInputChange}
									value={formData.workshop_start_date}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="startDate">
									Start Time <span>*</span>
								</label>
								<input
									type="time"
									id="workshop_start_time"
									onChange={handleInputChange}
									value={formData.workshop_start_time}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Date <span>*</span>
								</label>
								<input
									type="date"
									id="workshop_end_date"
									onChange={handleInputChange}
									value={formData.workshop_end_date}
									min={formData.workshop_start_date}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Time <span>*</span>
								</label>
								<input
									type="time"
									id="workshop_end_time"
									onChange={handleInputChange}
									value={formData.workshop_end_time}
								/>
							</div>

							<div className="formInput">
								<label htmlFor="totalAmount">
									Registration Link <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="registration_link"
										placeholder="Enter registration link"
										onChange={handleInputChange}
										value={formData.registration_link}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="memberAmount">
									Venue Details <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="venue_details"
										placeholder="Enter venue details"
										onChange={handleInputChange}
										value={formData.venue_details}
									/>
								</div>
							</div>

							<div className="uploadArea">
								<p className="titleHomework">Workshop Flyer</p>
								<div className="uploadContainer">
									<input
										type="file"
										name=""
										id="fileInput"
										ref={fileInputRef}
										accept="image/*"
										onChange={(e) => {
											// Assuming you have a variable for classNo, replace it with the actual value.
											// const classNo = 1; // Replace with your actual classNo value
											handleFileChange(e);
										}}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={handleUploadButtonClick}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									<div className="fileNames">
										{formData.workshop_flyer &&
											formData.workshop_flyer.length > 0 &&
											formData.workshop_flyer.map((file, index) => (
												<p key={index} className="fileName">
													<a
														href={file.content}
														target="_blank"
														rel="noopener noreferrer"
													>
														{file.filename}
													</a>
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
									</div>
									{formData.proof_of_partnerships &&
										formData.proof_of_partnerships.length === 0 && (
											<div>
												<p className="uploadInfo">
													Unlimited number of files can be uploaded to this
													field. Maximum size: 64 MB. File format: doc, docx,
													xls, xlsx, pdf, jpeg, jpg, png, zip, ppt, pptx.
												</p>
											</div>
										)}
								</div>
							</div>
						</div>
						<div className="footerNavBtns">
							<button onClick={handleSubmit} className="nextBtn">
								Next{" "}
								<img
									src={ImageList.ArrowForwardWhite}
									alt=""
									className="forwardArrow"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddWorkshop;
