import getAdminPaymentInfoAPI from '../../apis/admin/getAdminPaymentInfoAPI';


export function getAdminPaymentInfo(token){
    return new Promise(async(resolve, reject) => {
        getAdminPaymentInfoAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}