/** @format */

import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import DashboardBanner from "../../../components/DashboardBanner/DashboardBanner";
import DashboardCard from "../../../components/DashboardCard/DashboardCard";
import Layout from "../../../components/Layout/Layout";
import ImageList from "../../../utils/ImageList";
import "./HomeWorkAction.scss";
import { Calendar } from "rsuite";
import "rsuite/Calendar/styles/index.css";
import CourseCard from "../../../components/CourseCard/CourseCard";
import Input from "../../../components/Input/Input";
import MCQCard from "../../../components/HWRadioButton/RadioButton";
import StudentTable from "../../../components/StudentTable/StudentTable";

import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";

import Cookies from "js-cookie";
import { getStudentBatches } from "../../../interface/implementation/student/getStudentBatchesAPIImp";
import { getStudentHomework } from "../../../interface/implementation/student/getStudentHomeworkAPIImp";
import { updateHomework } from "../../../interface/implementation/student/updateHomeworkAPIImp";
import { useNavigate } from "react-router-dom";

function HomeWorkAction() {
	const navigate = useNavigate();
	const token = Cookies.get("token");

	const [homeworkListData, setHomeworkListData] = useState([]);
	const [responses, setResponses] = useState([]);
	const [overallScore, setOverallScore] = useState({});
	const [batchId, setBatchId] = useState(0);
	const [completion, setCompletion] = useState(false);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	useEffect(() => {
		getStudentBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchId(res[0].batch_id);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	useEffect(() => {
		getStudentHomework(token, batchId)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setHomeworkListData(res.homework_details);
				setOverallScore(res.overall_score);
				setCompletion(res.completion_status);
			})
			.catch();
	}, [batchId]);

	const handleAnswerChange = (updatedQuestion, weekNo) => {
		setResponses((prevResponses) => {
			const updatedResponses = [...prevResponses];
			const existingIndex = updatedResponses.findIndex(
				(response) =>
					response.id === updatedQuestion.id && response.week_no === weekNo
			);

			if (existingIndex !== -1) {
				// Update existing answer if the updatedQuestion has a non-null answer
				if (updatedQuestion.answer !== null) {
					updatedResponses[existingIndex] = {
						...updatedQuestion,
						week_no: weekNo,
					};
				} else {
					// If updatedQuestion has a null answer, retain the existing answer from the API response
					updatedResponses[existingIndex].answer = updatedQuestion.answer;
				}
			} else {
				// Add new answer
				updatedResponses.push({ ...updatedQuestion, week_no: weekNo });
			}

			return updatedResponses;
		});
	};

	const submitHomework = () => {
		const payload = {
			batch_id: batchId,
			homeworks: homeworkListData.map((week) => {
				return {
					week_no: week.week_no,
					response: week.homework_questions_answers.map((question) => {
						const existingResponse = responses.find(
							(response) =>
								response.id === question.id && response.week_no === week.week_no
						);
						return {
							id: question.id,
							question: question.question,
							options: question.options,
							answer: existingResponse
								? existingResponse.answer
								: question.answer,
						};
					}),
				};
			}),
		};

		updateHomework(token, payload)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				successToast("Homework updated.");
				navigate("/dashboard");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<>
			{homeworkListData && homeworkListData.length > 0 && (
				<Layout title={"Homework Action Point"}>
					<div className="dshboardContent">
						<ToastContainer limit={1} />
						<DashboardCard
							icon={ImageList.BookIcon}
							title={"Homework Action Point"}
							subTitle={
								<div className="dashboardHomeWorkSubtitles">
									<span>
										Completed Scoring:{" "}
										{Math.floor(overallScore.overall_complete_score)}
									</span>
									<span>
										InComplete Scoring:{" "}
										{Math.floor(overallScore.overall_incomplete_score)}
									</span>
								</div>
							}
							children={
								<div className="dashboardHomeWorkContainer">
									{homeworkListData &&
										homeworkListData.length > 0 &&
										homeworkListData.map((week, weekIndex) => (
											<div key={weekIndex}>
												{week.homework_questions_answers &&
													week.homework_questions_answers.length > 0 &&
													week.homework_questions_answers.map(
														(question, questionIndex) => (
															<MCQCard
																key={questionIndex}
																question={question}
																week_no={week.week_no}
																completion_status={week.completion_status}
																onAnswerChange={(updatedQuestion) =>
																	handleAnswerChange(
																		updatedQuestion,
																		week.week_no
																	)
																}
															/>
														)
													)}
											</div>
										))}

									{!completion && (
										<Button
											buttonName={"Save"}
											color={"#FFF"}
											background={"#4367B0"}
											onClick={submitHomework}
											//   className="nextBtn"
											style={{
												width: "auto",
												background: "#4367B0",
												margin: "auto",
												padding: "15px",
												alignItems: "center",
												borderRadius: "15px",
												textAlign: "center",
												fontSize: "20px",
												fontWeight: 500,
												border: "none",
											}}
										/>
									)}
								</div>
							}
						/>
					</div>
				</Layout>
			)}
		</>
	);
}

export default HomeWorkAction;
