import filterStudentAttendancePercentageAPI from "../../apis/admin/filterStudentAttendancePercentageAPI";

export function filterStudentAttendancePercentage(token, body) {
  return new Promise(async(resolve, reject) => {
      
    filterStudentAttendancePercentageAPI.sort(token, body).then((res)=>{
      resolve(res)
    }).catch((err)=> { 
      reject(err)
    })
  })
}
