
import ImageList from "./ImageList";

export const globalMenuItems = [
  { link: "/dashboard", iconSrc: "", title: "Dashboard" },
  // { link: "/reset-password", iconSrc: "", title: "Reset Password" },
  // { link: "/forgot-password", iconSrc: "", title: "Forgot Password" },
];

export const superAdminMenuItems = [
  {
    link: "/dashboard", iconSrc: ImageList.DashboardIcon, title: "Dashboard",
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "Course",
    subMenus: [
      { link: "/add-course", title: "Add Course" },
      { link: "/course-list", title: "Course List" },
     
    ],
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "Batch",
    subMenus: [
      { link: "/add-batch", title: "Add Batch" },
      { link: "/batch-list", title: "Batch List" },
     
    ],
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "Workshop",
    subMenus: [
      { link: "/add-workshop", title: "Add Workshop" },
      { link: "/workshop-list", title: "Workshop List" }, //add class needs to be done
      { link: "/workshop-barcode-list", title: "Workshop Barcode List" }, //barcode flow - view list, filter, view qr in sliing modal
      { link: "/participant-workshop-list", title: "Participant Workshop List" }, // new & update only actions
      // { link: "/workshop-participant-list", title: "Participant List" }, // view all students from all workshops, filter students per workshop & search, donwload students
      { link: "/workshop-attendance-list", title: "Workshop Attendance List" }, // view attendance for all workshops all classes, filter based on workshop, status & class no, download csv, update sing student attendance, update bulk
      // registration page for workshops where people will land and submit phone number & selfie 
    ],
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "Participant Batch",
    subMenus: [
      { link: "/student-batch-list", title: "Participant Batch List" },
      { link: "/student-attendance-list", title: "Participant Attendance List" },
      { link: "/student-attendance-percentage", title: "Participant Attendance Percentage" },
      { link: "/barcode-list",title: 'Barcode List', },
    ],
  },
  {
    link: "/student-payment-info", iconSrc: ImageList.DashboardIcon, title: "Participant payment info",
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "BCI Calendar",
    subMenus: [
      { link: "/create-calendar", title: "Create Calendar" },
      { link: "/calendar-list", title: "Calendar List" },
      
    ],
  },
  {
    link: "/access-approval-request",
    iconSrc: ImageList.DashboardIcon, title: "Access Approval Request",
    subMenus: [],
  },
  {
    link: "/payment-overdue-list",
    iconSrc: ImageList.DashboardIcon, title: "Payment Overdue List",
    subMenus: [],
  },
  {
    link: "/student-video-requests",
    iconSrc: ImageList.DashboardIcon, title: "Video Approval",
    subMenus: [],
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "Bank Detail",
    subMenus: [
      { link: "/company-details", title: "Add Bank" },
      { link: "/bank-details", title: "Bank Details List" },
    ],
  },
  {
    link: "#",
    iconSrc: ImageList.DashboardIcon, title: "Registration",
    subMenus: [
      { link: "/user/add", title: "Add User" },
      { link: "/user-list", title: "User List" },
      
    ],
  },

  {
    link: "/nature-business",
    iconSrc: ImageList.DashboardIcon, title: "Nature of Business",
   
  },
  { link: "/admin/crm-help-ticket", iconSrc: ImageList.DashboardIcon, title: "CRM Helpdesk Ticket" },

]

export const subAdminListItems = [
  {link: "/dashboard", iconSrc: ImageList.DashboardIcon, title: "Dashboard",},
  // { link: '/add-batch', iconSrc: "", title: 'Add Batch' },
  // { link: '/batch-list', iconSrc: "", title: 'Batch List' },
  { link: '/student-batch-list', iconSrc: "", title: "Participant Batch List"},
  { link: '/student-attendance-list', iconSrc: "", title: "Participant Attendance List"},
  {
    link: "/student-payment-info", iconSrc: ImageList.DashboardIcon, title: "Participant payment info",
  },
  {
    link: "/payment-overdue-list",
    iconSrc: ImageList.DashboardIcon, title: "Payment Overdue List",
    subMenus: [],
  },
  // { link: '/student-video-requests', iconSrc: "", title: "Student Video Requests"},
  // { link: '/crm-batch/batch-list', iconSrc: "", title: 'Batch CRM Batch List' },
  { link: '/nature-business', iconSrc: "", title: 'Nature of business' },
 
  { link: '/admin/crm-help-ticket', iconSrc: "", title: 'CRM Helpdesk Ticket' },
 
]

export const batchCRMListItems = [
  {link: "/dashboard", iconSrc: ImageList.NewDashboardIcon, title: "Dashboard",},
  { link: '/crm-batch/batch-list', iconSrc: ImageList.BatchListIcon, title: 'Batch CRM Batch List' },
  {
    link: "/crm-batch/payment-info", iconSrc: ImageList.PaymentInfoIcon, title: "Participant payment info",
  },
  {
    link: "/crm-batch/payment-overdue-list",
    iconSrc: ImageList.PaymentOverdueIcon, title: "Payment Overdue List",
    subMenus: [],
  },
  { link: '/crm-batch/crm-help-ticket', iconSrc: ImageList.HelpTicketIcon, title: 'Batch CRM Help Ticket' },
  { link: '/crm-batch/student-message', iconSrc: ImageList.CrmMsgIcon, title: 'Participant Messages' },



]

export const adminCRMListItems = [
  {link: "/dashboard", iconSrc: ImageList.NewDashboardIcon, title: "Dashboard",},
  { link: '/crm-admin/batch-list', iconSrc: ImageList.BatchListIcon, title: 'CRM Batch List' },
  {
    link: "/crm-admin/payment-info", iconSrc: ImageList.PaymentInfoIcon, title: "Participant payment info",
  },
  {
    link: "/crm-admin/payment-overdue-list",
    iconSrc: ImageList.PaymentOverdueIcon, title: "Payment Overdue List",
    subMenus: [],
  },
  { link: '/crm-admin/crm-help-ticket', iconSrc: ImageList.HelpTicketIcon, title: 'CRM Help Ticket' },
  { link: '/crm-admin/student-message', iconSrc: ImageList.CrmMsgIcon, title: 'Participant Messages' },
  


]

export const studentCRMListItems = [
  {link: "/dashboard", iconSrc: ImageList.DashboardIcon, title: "Dashboard",},
  // { link: '/student/student-batch', iconSrc: "", title: 'Weekly Homeworks & Videos' },
  { link: '/student/homework-action', iconSrc: "", title: 'Homework Action Point' },
  // { link: '/student/course/ticket', iconSrc:"", title: 'Create Help Ticket'},
  { link: '/student/crm-help-ticket', iconSrc: "", title: ' Help Ticket List' },
  { link: '/student/crm-message', iconSrc: "", title: 'CRM Messages' },
  // { link: '/student/bci-calendar', iconSrc: "", title: 'BCI Calendar' },
  // { link: '/student/payment-history', iconSrc: "", title: 'Payment Info' },
  { link: '/student/terms', iconSrc: "", title: 'Terms & Conditions' },
  

]

const AdminMenuItems = [

    { link: '/dashboard', iconSrc: "", title: 'Dashboard' },
    { link: '/course-list', iconSrc: "", title: 'Course List' },
    { link: '/add-course', iconSrc: "", title: 'Add Course' },
    { link: '/batch-list', iconSrc: "", title: 'Batch List' },
    { link: '/add-batch', iconSrc: "", title: 'Add Batch' },
    { link: '/user/add', iconSrc: "", title: 'Add User' },
    { link: '/user-list', iconSrc: "", title: 'User List' },
    // { link: '/barcode-list', iconSrc: "", title: 'Barcode List' },
    // { link: '/crm-help-ticket', iconSrc: "", title: 'CRM Help Ticket' },
    { link: '/student-batch-list', iconSrc: "", title: "Participant Batch List"},
    { link: '/student-attendance-list', iconSrc: "", title: "Participant Attendance List"},
    { link: '/update-class', iconSrc: "", title: "Update class"},
    { link: '/company-details', iconSrc: "", title: "Company Details"},
    { link: '/bank-details', iconSrc: "", title: "Bank Details List"},
    { link: '/student-video-requests', iconSrc: "", title: "Participant Video Requests"},
    { link: '/calendar-list', iconSrc: "", title: "Calendar List"},
    { link: '/create-calendar', iconSrc: "", title: "Create Calendar"},
    { link: '/admin/crm-help-ticket', iconSrc: "", title: 'Admin Help Ticket' },
    { link: '/crm-batch/crm-help-ticket', iconSrc: "", title: 'Batch CRM Help Ticket' },
    { link: '/crm-batch/student-message', iconSrc: "", title: 'Batch CRM Message' },
    { link: '/crm-admin/crm-help-ticket', iconSrc: "", title: 'CRM Help Ticket' },
    { link: '/crm-admin/student-message', iconSrc: "", title: 'CRM Message' },
    { link: '/student/crm-help-ticket', iconSrc: "", title: 'Participant CRM Help Ticket' },
    { link: '/student/crm-message', iconSrc: "", title: 'Participant Message' },
    { link: "/student/student-batch", iconSrc: "", title: "Participant Batch" },
  ];



export default AdminMenuItems;
