import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./BatchStudentList.scss";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { getStudentBatchList } from "../../../interface/implementation/admin/getStudentBatchListAPIImp";
import { searchBatches } from "../../../interface/implementation/admin/searchBatchesAPIImp";
import { autoSearchBatches } from "../../../interface/implementation/admin/autoSearchBatchesAPIImp";
import { useNavigate, useLocation } from "react-router-dom";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { downloadFilteredStudents } from "../../../interface/implementation/admin/donwloadFilteredStudentsAPIImp";
import ReplaceModal from "../../../components/ReplaceModal/ReplaceModal";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { logout } from "../../../interface/implementation/logoutAPIImp";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";
function BatchStudentList() {
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const navigate = useNavigate();
	const location = useLocation();
	const batchId = location.state.batchId;
	const [search, setSearch] = useState("");
	const [participant, setParticipant] = useState("");
	const [crm, setCrm] = useState("");
	const [participantOldStatus, setParticipantOldStatus] = useState("");
	const [status, setStatus] = useState("");
	const [activeStatus, setActiveStatus] = useState("");
	const [technicalNotes, setTechnicalNotes] = useState("");
	const token = Cookies.get("token");
	const [batchStudentList, setBatchStudentList] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	// const [currentItems, setCurrentItems] = useState([]);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState({
		crm: [],
	});
	const [showModal, setShowModal] = useState(false);
	const [replaceData, setReplaceData] = useState({});

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);

	useEffect(() => {
		getStudentBatchList(token, batchId)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchStudentList(res.student_details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getCRM(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setDropdownOptions((prevData) => ({
					...prevData,
					crm: res.users,
				}));
			});
		};
		fetchDropdowns();
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleReload = () => {
		setReloadUsers((prevReloadUsers) => !prevReloadUsers);
	};

	const handleSearch = async () => {
		let newBody = `?batch_id=${batchId}`;

		if (search) {
			newBody += `&search=${search}`;
		}

		if (participant) {
			newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
		}

		if (participantOldStatus) {
			newBody += `${
				newBody ? "&" : "?"
			}participants_old_status=${participantOldStatus}`;
		}

		if (crm) {
			newBody += `${newBody ? "&" : "?"}crm=${crm}`;
		}

		if (status) {
			newBody += `${newBody ? "&" : "?"}refund_type=${status}`;
		}

		if (activeStatus) {
			newBody += `${newBody ? "&" : "?"}is_active=${activeStatus}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const handleAutoSearch = async (e) => {
		if (e.target.value) {
			let newBody = `${batchId}/${e.target.value}`;

			autoSearchBatches(token, newBody)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setAutoValues(res);
					setAutoModal(true);
				})
				.catch();
		}
	};

	const searchFilter = (newBody) => {
		searchBatches(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setBatchStudentList(res.student_details);

				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.batch.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch();
	};

	function openInNewTab(url) {
		window.open(url, "_blank");
	}

	const isBase64URL = (str) => {
		// Regular expression to match data URLs
		const dataURLPattern = /^data:([A-Za-z0-9-+/]+);base64,(.+)$/;
		return dataURLPattern.test(str);
	};

	const handleDownload = () => {
		let newBody = `?batch_id=${batchId}`;

		if (search) {
			newBody += `&search=${search}`;
		}

		if (participant) {
			newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
		}

		if (crm) {
			newBody += `${newBody ? "&" : "?"}crm=${crm}`;
		}

		if (participantOldStatus) {
			newBody += `${
				newBody ? "&" : "?"
			}participants_old_status=${participantOldStatus}`;
		}

		if (activeStatus) {
			newBody += `${newBody ? "&" : "?"}is_active=${activeStatus}`;
		}

		downloadFilteredStudents(token, `?batch_id=${batchId}&search=${search}`)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
				const filename = `Participants-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch();
	};

	const handleReset = () => {
		setSearch("");
		setParticipant("");
		setCrm("");
		setParticipantOldStatus("");
		setStatus("");
		setActiveStatus("");
		setReloadUsers((prevReloadUsers) => !prevReloadUsers);
	};

	const handleBackPage = () => {
		navigate("/student-batch-list");
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		batchStudentList && batchStudentList.length > 0
			? batchStudentList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="batchStudentList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Batch Participants List</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs batchStudentSearch">
								<div className="inputContainer">
									<label htmlFor="search">
										Search <span>*</span>
									</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder="Company code/Company name/Student name/ Phone"
											onChange={(e) => {
												handleAutoSearch(e);
												handleInputChange(e, setSearch);
											}}
											value={search}
										/>
										{autoValues.length > 0 && autoModal && (
											<div className="searchDropdown">
												{autoValues.map((item, index) => (
													<div
														key={index}
														onClick={() => {
															setSearch(item);
															setAutoModal(false);
														}}
													>
														<p>{item}</p>
													</div>
												))}
											</div>
										)}
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="participant">Participant</label>
									<div className="inputCode">
										<select
											id="participant"
											onChange={(e) => handleInputChange(e, setParticipant)}
											value={participant}
										>
											<option value="">Select Participant</option>
											<option value="primary">Primary</option>
											<option value="secondary">Secondary</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="crm">CRM</label>
									<div className="inputCode">
										<select
											id="crm"
											onChange={(e) => handleInputChange(e, setCrm)}
											value={crm}
										>
											<option value="">Select CRM</option>
											{dropdownOptions?.crm?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="participantOldStatus">
										Participant Old Status
									</label>
									<div className="inputCode">
										<select
											id="participantOldStatus"
											onChange={(e) =>
												handleInputChange(e, setParticipantOldStatus)
											}
											value={participantOldStatus}
										>
											<option value="">Select Old Status</option>
											<option value="Primary carry forward">
												Primary carry forward
											</option>
											<option value="Secondary carry forward">
												Secondary carry forward
											</option>
											<option value="Implementer carry forward">
												Implementer carry forward{" "}
											</option>
											<option value="shifted_from_prev_batch">
												Shifted from prev batch{" "}
											</option>
											<option value="Repeater">Repeater</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="status">
										Registered/Adjust/Shift/Refund Status
									</label>
									<div className="inputCode">
										<select
											id="status"
											onChange={(e) => handleInputChange(e, setStatus)}
											value={status}
										>
											<option value="">Select Status</option>
											<option value="registered">Registered</option>
											<option value="adjust">Adjust</option>
											<option value="shift">Shift</option>
											<option value="refund">Refund</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="activeStatus">Active Status</label>
									<div className="inputCode">
										<select
											id="activeStatus"
											onChange={(e) => handleInputChange(e, setActiveStatus)}
											value={activeStatus}
										>
											<option value="">Select Active Status</option>
											<option value="active">Active </option>
											<option value="inactive">Deactivate </option>
										</select>
									</div>
								</div>
								{/* <div className="inputContainer">
                  <label htmlFor="technicalNotes">Technical Notes</label>
                  <div className="inputCode">
                    <select
                      id="technicalNotes"
                      onChange={(e) => handleInputChange(e, setTechnicalNotes)}
                      value={technicalNotes}
                    >
                      <option value="">Select Technical Notes</option>
                      <option value="notesOption1">Notes Option 1</option>
                      <option value="notesOption2">Notes Option 2</option>
                    </select>
                  </div>
                </div> */}

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleReset}>
										Reset
									</button>
								</div>

								{selectedRole !== 3 && (
									<div className="searchBtnContainer">
										<button className="searchBtn" onClick={handleDownload}>
											Download
										</button>
									</div>
								)}

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleBackPage}>
										Back to list
									</button>
								</div>
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="batchStudentTable">
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Company Code</th>
										<th>Company Name</th>
										<th>Participant Type</th>
										<th>Member Type</th>
										<th>Participant Old Status</th>
										<th>Participant Name</th>
										<th>Phone</th>
										<th>Participant Mail</th>
										{/* <th>Active Status</th> */}
										{/* <th>Payment category</th> */}
										<th>CRM</th>
										<th>Status</th>
										{/* <th>Approved File</th> */}
										<th>Edit / Pay / Installment / Member</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((batchData, index) => (
											<tr key={index}>
												<td>{startSerialNumber + index}</td>
												<td>{batchData.company_id}</td>
												<td style={{ whiteSpace: "wrap" }}>
													{batchData.company_name}
												</td>
												<td>{batchData.participant_type}</td>
												<td style={{ textTransform: "capitalize" }}>
													{batchData.member_type}
												</td>
												<td style={{ textTransform: "capitalize" }}>
													{batchData.participants_old_status
														? batchData.participants_old_status.replace(
																/_/g,
																" "
														  )
														: batchData.participants_old_status}
												</td>
												<td style={{ whiteSpace: "wrap" }}>
													{batchData.student_name}
												</td>
												<td>{batchData.phone_no}</td>
												<td style={{ whiteSpace: "wrap" }}>
													{batchData.email_id}
												</td>
												{/* <td>{batchData.active_status}</td> */}
												{/* <td>{batchData.payment_category}</td> */}
												<td style={{ whiteSpace: "wrap" }}>
													{batchData.crm_name}
												</td>
												<td>{batchData.status}</td>

												<td className="btnRow">
													<Link
														to={{
															pathname: `/student/edit/${batchData.batch_id}`,
														}}
														state={{
															studentId: batchData.student_id,
															participantType: batchData.participant_type,
														}}
														className="batchTableBtn"
													>
														<img src={ImageList.PlusSquare} alt="" />
														Edit profile
													</Link>
													{batchData.active_status === "Active" && (
														<Link
															to={{
																pathname: `/refund-invoice`,
															}}
															style={{ backgroundColor: "#4367B0" }}
															state={{
																studentId: batchData.student_id,
																batchId: batchData.batch_id,
																studentName: batchData.student_name,
																companyName: batchData.company_name,
																participantType: batchData.participant_type,
															}}
															className="batchTableBtn"
														>
															<img src={ImageList.PlusSquare} alt="" />
															Adjust/Refund
														</Link>
													)}
													{batchData.participant_type === "Primary" &&
														batchData.active_status === "Active" && (
															<>
																{
																	batchData.add_member === "yes" ? (
																		<Link
																			to={{ pathname: `/add-partner` }}
																			className="batchTableBtn"
																			style={{ backgroundColor: "#C6469F" }}
																			state={{
																				primaryPhoneNo: batchData.phone_no,
																				noOfMembers: batchData.add_no_members,
																				mode: "view",
																				batchId: batchData.batch_id,
																				batchName: batchData.batch_name,
																				memberAmount: batchData.member_amount,
																				terms: batchData.terms_conditions_id,
																			}}
																		>
																			<img
																				src={ImageList.InstallmentIcon}
																				alt=""
																			/>
																			View Members
																		</Link>
																	) : null
																	// <Link
																	//   to={{ pathname: `/add-partner` }}
																	//   className="batchTableBtn"
																	//   style={{ backgroundColor: "#C6469F" }}
																	//   state={{
																	//     primaryPhoneNo:
																	//       batchData.phone_no,
																	//     noOfMembers: 1,
																	//     mode: "add",
																	//     batchId: batchData.batch_id,
																	//     batchName: batchData.batch_name,
																	//     memberAmount: batchData.member_amount,
																	//     terms: batchData.terms_conditions_id
																	//   }}
																	// >
																	//   <img src={ImageList.PlusIcon} alt="" />
																	//   Add member
																	// </Link>
																}
															</>
														)}
													{batchData.participant_type === "Primary" &&
														batchData.active_status === "Active" && (
															<Link
																to={{ pathname: `/payment-detail` }}
																className="batchTableBtn"
																style={{ backgroundColor: "#C6469F" }}
																state={{
																	studentId: batchData.student_id,
																	batchId: batchData.batch_id,
																	primaryPhoneNo: batchData.phone_no,
																	batchName: batchData.batch_name,
																}}
															>
																<img src={ImageList.InstallmentIcon} alt="" />
																Pay Now
															</Link>
														)}
													{/* <Link
                            to={{ pathname: `/payment-detail` }}
                            className="batchTableBtn"
                            style={{ backgroundColor: "#C6469F" }}
                          >
                            <img src={ImageList.InstallmentIcon} alt="" />
                            Pay Now
                          </Link>
                          <Link
                            to={{ pathname: `` }}
                            className="batchTableBtn"
                            style={{ backgroundColor: "#4367B0" }}
                          >
                            <img src={ImageList.InstallmentIcon} alt="" />
                            Refund/Invoice
                          </Link>
                          <Link
                            to={{ pathname: `` }}
                            className="batchTableBtn"
                            style={{ backgroundColor: "#DE4B39" }}
                          >
                            <img src={ImageList.EyeIcon} alt="" />
                            View Member
                          </Link> */}

													<button
														onClick={() => {
															setReplaceData({
																primaryPhoneNo: batchData.phone_no,
																memberType: batchData.participant_type,
																studentName: batchData.student_name,
																phoneNumber: batchData.phone_no,
																batchId: batchData.batch_id,
																emailId: batchData.email_id,
															});
															setShowModal(true);
														}}
														className="batchTableBtn"
														style={{ backgroundColor: "#FF742E" }}
													>
														Replace
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
							{showModal && (
								<ReplaceModal
									onClick={() => setShowModal(false)}
									replaceData={replaceData}
									handleReload={handleReload}
								/>
							)}
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(batchStudentList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BatchStudentList;
