import React from "react";
import "./ImageModal.scss"; // Add styling for the modal if needed

const ImageModal = ({ isOpen, closeModal, imageUrl }) => {
  return (
    <div className={`image-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <img src={imageUrl} alt="Enlarged View" />
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default ImageModal;
