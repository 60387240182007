import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./AdminHelpTicket.scss";
import ImageList from "../../../utils/ImageList";
import CRMTicketModal from "../../../components/CRMTicketModal/CRMTicketModal";
import Cookies from "js-cookie";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { getTickets } from "../../../interface/implementation/getTicketsAPIImp";
import { getCRMTickets } from "../../../interface/implementation/crmAdmin/getCRMTicketsAPIImp";
import { getStudentTickets } from "../../../interface/implementation/student/getStudentTicketsAPIImp";
import { downloadHelpTicket } from "../../../interface/implementation/admin/downloadHelpTicketAPIImp";
import { useNavigate } from "react-router-dom";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { searchBatchCRMTickets } from "../../../interface/implementation/batchCRM/searchBatchCRMTicketsAPIimp";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";
import { logout } from "../../../interface/implementation/logoutAPIImp";

function AdminHelpTicket() {
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [selectedBatch, setSelectedBatch] = useState("");
	const [selectedCRM, setSelectedCRM] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [userRole, setUserRole] = useState("");
	const token = Cookies.get("token");
	const [ticketList, setTicketList] = useState([]);
	const [ticket, setTicket] = useState("");
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [crmBatchList, setCRMBatchList] = useState([]);
	const [reloadState, setReloadState] = useState(false);

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);
	useEffect(() => {
		getTickets(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setTicketList(res.details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadState]);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getCRM(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					// showToast(res.message);
					return;
				}
				setDropdownOptions(res.users);
			});
		};
		fetchDropdowns();
	}, []);

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCRMBatchList(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleReloadStateToggle = () => {
		setReloadState((prevState) => !prevState); // Toggle the state
	};

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		ticketList && ticketList.length > 0
			? ticketList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleDownload = () => {
		downloadHelpTicket(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10);
				const filename = `help-ticket-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleSearch = async () => {
		let newBody = "";

		if (selectedBatch) {
			newBody += `${newBody ? "&" : "?"}batch_id=${selectedBatch}`;
		}

		if (selectedStatus) {
			newBody += `${newBody ? "&" : "?"}ticket_status=${selectedStatus}`;
		}

		if (selectedCRM) {
			newBody += `${newBody ? "&" : "?"}crm_id=${selectedCRM}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			handleReloadStateToggle();
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const searchFilter = (newBody) => {
		searchBatchCRMTickets(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setTicketList(res.details);

				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.batch.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch();
	};
	return (
		<div className="adminHelpTicket">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">CRM Help Ticket</div>
					<div className="courseListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="batchName">Select batch</label>
									<div className="inputCourseName">
										<select
											id="batch_name"
											onChange={(e) => handleInputChange(e, setSelectedBatch)}
											value={selectedBatch}
										>
											<option value="">Select batch</option>
											{crmBatchList?.map((option, i) => (
												<option key={i} value={option.batch_id}>
													{option.batch_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="crm">Select CRM</label>
									<div className="inputCode">
										<select
											id="crm"
											onChange={(e) => handleInputChange(e, setSelectedCRM)}
											value={selectedCRM}
										>
											<option value="">Select</option>
											{dropdownOptions?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="status">Ticket status</label>
									<div className="inputCode">
										<select
											id="status"
											onChange={(e) => handleInputChange(e, setSelectedStatus)}
											value={selectedStatus}
										>
											<option value="">Select</option>
											<option value="open">Open</option>
											<option value="closed">Closed</option>
										</select>
									</div>
								</div>
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
									{selectedRole !== 3 && (
										<button className="searchBtn" onClick={handleDownload}>
											Download
										</button>
									)}
								</div>
							</div>
						</div>

						<div className="barcodes" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch name</th>
										<th>Created by</th>
										<th>Subject</th>
										<th>Ticket text</th>
										<th>Created date</th>
										<th>Status</th>
										<th>CRM</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((ticket, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{ticket.batch_name}</td>
												<td>{ticket.created_by}</td>
												<td>{ticket.ticket_subject}</td>
												<td>{ticket.ticket_description}</td>
												<td>
													{ticket.created_on &&
														new Date(ticket.created_on)
															.toISOString()
															.split("T")[0]
															.split("-")
															.reverse()
															.join("/")}
												</td>
												<td>{ticket.ticket_status}</td>
												<td>{ticket.crm}</td>
												<td className="actionBtn">
													<button
														onClick={() => {
															setTicket(ticket);
															setShowModal(true);
														}}
														className="viewBtn"
													>
														<img src={ImageList.MessageIcon} alt="" />
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>

							{showModal && (
								<CRMTicketModal
									onClick={() => setShowModal(false)}
									ticket={ticket}
									role={"admin"}
								/>
							)}
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(ticketList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminHelpTicket;
