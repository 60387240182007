import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./ParticipantWorkshopList.scss";
import { Link } from "react-router-dom";
import { getStudentBatchList } from "../../../interface/implementation/admin/getStudentBatchListAPIImp";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { getWorkshops } from "../../../interface/implementation/admin/getWorkshopsAPIImp";

import Cookies from "js-cookie";
import { searchJustBatches } from "../../../interface/implementation/admin/serachJustBatchesAPIImp";
import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
function ParticipantWorkshopList() {
	const navigate = useNavigate();
	const [batchname, setBatchname] = useState("");
	const [code, setCode] = useState("");
	const [course, setCourse] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [batchList, setBatchList] = useState([]);
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getWorkshops(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setBatchList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (batchname) {
			newBody += `?batch_name=${batchname}`;
		}

		if (course) {
			newBody += `${newBody ? "&" : "?"}course=${course}`;
		}

		if (startDate) {
			newBody += `${newBody ? "&" : "?"}start_date=${startDate}`;
		}

		if (endDate) {
			newBody += `${newBody ? "&" : "?"}end_date=${endDate}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		getWorkshops(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const missingFields = [
		{
			// "salesperson",
			// alternatePhoneNumber,
			// alternateEmail,
			// address,
			// billingCompanyDetails,
			// memberType,
			// addressToCourier,
			// technicalNotes,
			// verificationDocNumber,
			// verificationStatus
		},
	];

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const currentItems =
		batchList && batchList.length > 0
			? batchList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="studentBatchList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Participant Workshop List</div>
					<div className="batchListContainer">
						{/* <div className="topSearchBar">
              <div className="searchInputs">
                <div className="inputContainer">
                  <label htmlFor="batchname">Batch Name</label>
                  <div className="inputCourseName">
                    <input
                      type="text"
                      id="batchname"
                      placeholder="Search by batch name"
                      onChange={(e) => handleInputChange(e, setBatchname)}
                      value={batchname}
                    />
                  </div>
                </div>
                <div className="inputContainer">
                  <label htmlFor="course">Course</label>
                  <div className="inputCode">
                    <input
                      type="text"
                      id="course"
                      placeholder="Search by course"
                      onChange={(e) => handleInputChange(e, setCourse)}
                      value={course}
                    />
                  </div>
                </div>

                <div className="inputContainer">
                  <label htmlFor="startDate">Start Date</label>
                  <div className="inputCode">
                    <input
                      type="date"
                      id="startDate"
                      placeholder="Search by start date"
                      onChange={(e) => handleInputChange(e, setStartDate)}
                      value={startDate}
                    />
                  </div>
                </div>

                <div className="inputContainer">
                  <label htmlFor="endDate">End Date</label>
                  <div className="inputCode">
                    <input
                      type="date"
                      id="endDate"
                      placeholder="Search by end date"
                      onChange={(e) => handleInputChange(e, setEndDate)}
                      value={endDate}
                    />
                  </div>
                </div>

                <div className="searchBtnContainer">
                  <button className="searchBtn" onClick={handleSearch}>
                    Search
                  </button>
                </div>
     
              </div>
            </div> */}
						<div className="courses" style={{ overflowX: "auto" }}>
							<table
								style={{ width: "100%" }}
								className="studentBatchTable studentBatchTableNew"
							>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Workshop Name</th>
										<th>Workshop Type</th>
										<th>Start date</th>
										<th>End Date</th>

										<th>
											Participant list/CSV Import Participant/Update Participant
										</th>
										{/* <th>Payment Update</th> */}
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((batch, index) => (
											<tr key={batch.batch_id}>
												<td>{startSerialNumber + index}</td>
												<td>{batch.workshop_title}</td>
												<td>{batch.workshop_type}</td>
												<td>
													{batch.workshop_start_datetime &&
														batch.workshop_start_datetime !== null && (
															<>
																{new Date(
																	batch.workshop_start_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																})}
																<br />
																{new Date(
																	batch.workshop_start_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleTimeString("en-GB", {
																	hour: "2-digit",
																	minute: "2-digit",
																})}
															</>
														)}
												</td>

												<td>
													{batch.workshop_end_datetime &&
														batch.workshop_end_datetime !== null && (
															<>
																{new Date(
																	batch.workshop_end_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																})}
																<br />
																{new Date(
																	batch.workshop_end_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleTimeString("en-GB", {
																	hour: "2-digit",
																	minute: "2-digit",
																})}
															</>
														)}
												</td>
												<td
													style={{
														textAlign: "center",
													}}
												>
													<button style={{ backgroundColor: "#00A65A" }}>
														<Link
															to={{ pathname: `/workshop-participant-list` }}
															state={{
																workshopId: batch.workshop_id,
																batchName: batch.workshop_title,
															}}
														>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	gap: "2px",
																}}
															>
																<img src={ImageList.FilePlus} alt="" />
																Participant list
															</div>
														</Link>
													</button>
													<button style={{ backgroundColor: "#00A65A" }}>
														<Link
															to={{ pathname: `/new-workshop-student-csv` }}
															state={{
																workshopId: batch.workshop_id,
																batchName: batch.workshop_title,
																startDate: new Date(
																	batch.workshop_start_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																}),
																endDate: new Date(
																	batch.workshop_end_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																}),
															}}
														>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	gap: "2px",
																}}
															>
																<img src={ImageList.FilePlus} alt="" />
																New
															</div>
														</Link>
													</button>
													<button>
														<Link
															to={{ pathname: `/update-workshop-student-csv` }}
															state={{
																workshopId: batch.workshop_id,
																batchName: batch.workshop_title,
																startDate: new Date(
																	batch.workshop_start_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																}),
																endDate: new Date(
																	batch.workshop_end_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																}),
															}}
														>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	gap: "2px",
																}}
															>
																<img src={ImageList.EditIcon} alt="" />
																Update
															</div>
														</Link>
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(batchList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ParticipantWorkshopList;
