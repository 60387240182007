import getUpcomingCoursesAPI from '../../apis/student/getUpcomingCoursesAPI';

export function getUpcomingCourses(token){
    return new Promise(async(resolve, reject) => {
        getUpcomingCoursesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}