import React, { useEffect } from "react";
import "./ProfitsPage.scss";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const ProfitsPage = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);
	return (
		<div className="ProfitsContainer">
			<label
				htmlFor="mainTitle"
				className="mainTitle"
				data-aos="fade-up"
				data-aos-once={true}
			>
				DOUBLE YOUR PROFITS
			</label>
			<label
				htmlFor="titleTop"
				className="titleTop"
				data-aos="fade-up"
				data-aos-once={true}
			>
				EVERY YEAR
			</label>
			<div className="profitCardsContainer">
				<div className="card">
					<p>
						Build the 3 fundamentals of a{" "}
						<span>winning marketing campaign.</span>
					</p>
				</div>
				<div className="card">
					<p>
						Create an
						<span>unlimited Market Budget.</span>
					</p>
				</div>
				<div className="card">
					<p>
						Finally, discover the{" "}
						<span>
							5 critical parameters, which will Double your sales in a year.
						</span>
					</p>
				</div>
				<div className="card">
					<p>
						Understanding that it is impossible to{" "}
						<span>grow sales by asking your sales team</span>
					</p>
				</div>
				<div className="card">
					<p>
						<span>Create a Sales Engine</span> which will sustain this growth in
						Sales.
					</p>
				</div>
				<div className="card">
					<p>
						How to increase the results from{" "}
						<span>your existing sales team by more than 100%.</span>
					</p>
				</div>
				<div className="card">
					<p>
						How to set up a <span>regular supply of fresh prospects.</span>
					</p>
				</div>
				<div className="card">
					<p>
						<span>Wipe outall competition, putting</span> yourself in a
						different league where
					</p>
				</div>
			</div>
			<Link
				to="/registrationForm"
				state={{ work_card_id: 1 }}
				data-aos="fade-up"
				data-aos-once={true}
			>
				<button>
					Get Sarted <img src={ForwardArrow} alt="" />
				</button>
			</Link>
		</div>
	);
};

export default ProfitsPage;
