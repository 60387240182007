import getHomeworkAPI from "../../apis/batchCRM/CRMHomeworkAPI";

export function gethomework(token, body) {
	return new Promise(async (resolve, reject) => {
		getHomeworkAPI
			.sort(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
