import editBatchAPI from '../../apis/admin/editBatchAPI';


export function editBatch(token, body){
    return new Promise(async(resolve, reject) => {
        editBatchAPI.put(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}