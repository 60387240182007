import getOverduePaymentInfoAPI from '../../apis/admin/getOverduePaymentInfoAPI';


export function getOverduePaymentInfo(token, body){
    return new Promise(async(resolve, reject) => {
      getOverduePaymentInfoAPI.sort(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}