import { ApiCore } from "../apiCore";

const apiTasks = new ApiCore({
	getAll: true,
	getSingle: false,
	postEncoded: false,
	post: false,
	put: false,
	delete: false,
	url: `api/workshop/get-all-states `,
	version: "",
});

export default apiTasks;
