import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { ToastContainer } from "react-toastify";
import "./CourseList.scss";
import { Link } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import { adminCourseList } from "../../../interface/implementation/admin/getAdminCoursesAPIImp";
import { searchCourse } from "../../../interface/implementation/admin/seacrhCourseAPIImp";
import { showToasterMessage } from "../../../utils/helper";

import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function CourseList() {
	const navigate = useNavigate();
	const [courseName, setCourseName] = useState("");
	const [code, setCode] = useState("");
	// const [currentPage, setCurrentPage] = useState(1);
	const [courseList, setCourseList] = useState([]);
	// const [currentItems, setCurrentItems] = useState([])
	// const itemsPerPage = 15;
	const [body, setBody] = useState("");
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (courseName) {
			newBody += `?course_name=${courseName}`;
		}

		if (code) {
			newBody += `${newBody ? "&" : "?"}code=${code}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const search = (newBody) => {
		searchCourse(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCourseList(res.Courses);
				setCurrentPage(1);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	useEffect(() => {
		adminCourseList(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// setCourseList()
				setCourseList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		courseList && courseList.length > 0
			? courseList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="courseList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Course List</div>
					<div className="courseListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="courseName">Course name</label>
									<div className="inputCourseName">
										<input
											type="text"
											id="courseName"
											placeholder="Search by course name"
											onChange={(e) => setCourseName(e.target.value)}
											value={courseName}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="code">Code</label>
									<div className="inputCode">
										<input
											type="text"
											id="code"
											placeholder="Search by code"
											onChange={(e) => setCode(e.target.value)}
											value={code}
										/>
									</div>
								</div>
								<div className="searchBtnContainer ">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Course name</th>
										<th>Code</th>
										<th>Total classes</th>
										<th>Total installments</th>
										<th>Duration type</th>
										<th>Duration</th>
										<th>Basic amount</th>
										<th>
											Member basic <br /> amount
										</th>
										<th>GST (%)</th>
										<th>Add / Update</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((course, index) => (
											<tr key={index}>
												<td>{startSerialNumber + index}</td>
												<td>{course.course_name}</td>
												<td>{course.code}</td>
												<td>{course.total_classes}</td>
												<td>{course.total_installments}</td>
												<td>{course.duration_type}</td>
												<td>{course.duration}</td>
												<td>{course.basic_amount}</td>
												<td>{course.member_basic_amount}</td>
												<td>{course.gst_amount}</td>
												<td className="actionBtn">
													<Link
														to={`/course-details/${course.course_id}`}
														className="courseBtn"
														style={{ backgroundColor: "#4367B0" }}
													>
														<img src={ImageList.EditIcon} alt="" />
														Edit
													</Link>
													{/* <Link to={`/homework/${course.id}`} className='homeworkBtn'><img src={ImageList.PlusIcon} alt="" />Home Work</Link>
                                                <Link to={`/materials/${course.id}`} className='materialBtn'><img src={ImageList.PlusIcon} alt="" />Material</Link> */}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(courseList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CourseList;
