import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CrmAdminAttendance.scss";
import { Link } from "react-router-dom";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import ImageList from "../../../utils/ImageList";
import { getCRMBatches } from "../../../interface/implementation/getCRMBatchesAPIImp";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { getCRMAttendance } from "../../../interface/implementation/crmAdmin/getCRMAttendanceAPIImp";

function CrmAdminAttendance() {
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;
	const token = Cookies.get("token");
	const location = useLocation();

	const batchId = location.state.batchId;
	const studentId = location.state.studentId;

	const [crmAttendanceList, setCrmAttendanceList] = useState([]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		let body = `${batchId}/${studentId}`;
		getCRMAttendance(token, body)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCrmAttendanceList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = crmBatchList.slice(indexOfFirstItem, indexOfLastItem);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="crmAdminAttendance">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Batch List</div>
					<div className="batchListContainer">
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Class No.</th>
										<th>Class Date/Time</th>
										<th>Status</th>
										<th>Type</th>
										<th>Zoom (Duration In Minutes)</th>
									</tr>
								</thead>
								<tbody>
									{crmAttendanceList &&
										crmAttendanceList.length > 0 &&
										crmAttendanceList.map((attendance, index) => (
											<tr key={index}>
												<td>{attendance.week_no}</td>
												<td>
													{attendance.class_date &&
														`${attendance.class_date
															.split("-")
															.reverse()
															.join("/")} - ${attendance.class_time}`}
												</td>
												<td>{attendance.is_present}</td>
												<td>{attendance.class_type}</td>
												<td>{attendance.duration}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CrmAdminAttendance;
