import getBatchesAPI from '../../apis/admin/getBatchesAPI';


export function getBatches(token){
    return new Promise(async(resolve, reject) => {
        getBatchesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}