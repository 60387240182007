import getAccessPaymentDetailsAPI from '../../apis/admin/getAccessPaymentDetailsAPI';


export function getAccessPaymentDetails(token, body){
    return new Promise(async(resolve, reject) => {
      getAccessPaymentDetailsAPI.sort(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}