import React from "react";
import "./NoDataFound.scss";
import ImageList from "../../utils/ImageList";

const NoDataFound = () => {
  return (
    <div className="NoDataFoundContainer">
      <div className="NoDataFoundContentWrapper">
        <span>
          <img src={ImageList.NoDataFoundIcon} alt="" />
        </span>
        <span>No data found!</span>
      </div>
    </div>
  );
};

export default NoDataFound;
