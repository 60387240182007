import React, { useState, useEffect, useCallback, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CrmBatchHomework.scss";
import { Link, useLocation } from "react-router-dom";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import ImageList from "../../../utils/ImageList";
import Button from "../../../components/Button/Button";

// import CRMTicketModal from '../../../components/CRMTicketModal/CRMTicketModal';
import CRMMessagesModal from "../../../components/CRMMesagesModal/CRMMessagesModal";
import Cookies from "js-cookie";
import { getCRMHomework } from "../../../interface/implementation/batchCRM/getCRMHomeworkAPIImp";
import { updateCRMHomework } from "../../../interface/implementation/crmAdmin/updateCRMHomeworkAPIImp";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function CrmBatchHomework() {
	const navigate = useNavigate();
	const handle = useFullScreenHandle();
	const [isFullScreenActive, setIsFullScreenActive] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [studentId, setStudentId] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const token = Cookies.get("token");
	const batchId = location.state.batchId;
	const batchName = location.state.batchName;
	const [selectedValues, setSelectedValues] = useState({});
	const [crmBatchHomework, setcrmBatchHomework] = useState([]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState();
	const itemsPerPage = isFullScreenActive ? 10 : 10;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	useEffect(() => {
		let newBody = `?batch_id=${batchId}&page=${currentPage}&size=${itemsPerPage}`;

		setIsLoading(true); // Start loading

		getCRMHomework(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setcrmBatchHomework(res.student_details);
				setTotalPage(res.total_pages);
			})
			.catch((err) => showToast("Something went wrong"))
			.finally(() => {
				setIsLoading(false);
			});
	}, [isFullScreenActive, currentPage]);

	const handleInputChange = (
		updatedQuestion,
		studentId,
		weekNo,
		selectedAnswer
	) => {
		setSelectedValues((prevValues) => ({
			...prevValues,
			[`${studentId}-${weekNo}-${updatedQuestion.id}`]: selectedAnswer,
		}));

		setcrmBatchHomework((prevResponses) => {
			return prevResponses.map((student) => {
				if (student.student_id === studentId) {
					const updatedHomeworks = student.homeworks.map((homework) => {
						if (homework.week_no === weekNo) {
							const updatedQuestionsAnswers =
								homework.homework_questions_answers.map((question) => {
									if (question.id === updatedQuestion.id) {
										return { ...question, answer: selectedAnswer };
									}
									return question;
								});
							return {
								...homework,
								homework_questions_answers: updatedQuestionsAnswers,
							};
						}
						return homework;
					});
					return { ...student, homeworks: updatedHomeworks };
				}
				return student;
			});
		});
	};

	const submitHomework = (studentId) => {
		const studentToUpdate = crmBatchHomework.find(
			(student) => student.student_id === studentId
		);

		if (!studentToUpdate) {
			showToast("Student not found.");
			return;
		}

		const payload = {
			batch_id: batchId,
			student_id: studentId,
			homeworks: studentToUpdate.homeworks.map((week) => {
				return {
					week_no: week.week_no,
					response: week.homework_questions_answers.map((question) => {
						const existingResponse = crmBatchHomework.find(
							(response) =>
								response.id === question.id && response.week_no === week.week_no
						);
						return {
							id: question.id,
							question: question.question,
							options: question.options,
							answer: existingResponse
								? existingResponse.answer
								: question.answer,
						};
					}),
				};
			}),
		};

		updateCRMHomework(token, payload)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Homework updated.");
				// Assuming you want to stay on the same page after updating
				// You can put any other logic here if you want to redirect to a different page
			})
			.catch((err) => showToast("Something went wrong"));
	};

	let totalQuestionsAcrossWeeks = 0;
	let totalWeeks = 0; // Variable to store total weeks
	if (crmBatchHomework && crmBatchHomework[0]?.homeworks) {
		totalQuestionsAcrossWeeks = crmBatchHomework[0].homeworks.reduce(
			(total, homework) => {
				totalWeeks++; // Increment total weeks for each homework
				return total + homework.homework_questions_answers.length;
			},
			0
		);
	}

	useEffect(() => {
		const initialSelectedValues = {};
		crmBatchHomework.forEach((student) => {
			student.homeworks.forEach((homework) => {
				homework.homework_questions_answers.forEach((question) => {
					initialSelectedValues[
						`${student.student_id}-${homework.week_no}-${question.id}`
					] = question.answer || "";
				});
			});
		});
		setSelectedValues(initialSelectedValues);
	}, [crmBatchHomework]);

	const getAnswerForQuestion = (studentId, weekNo, questionId) => {
		return selectedValues[`${studentId}-${weekNo}-${questionId}`] || "";
	};

	const onFullScreenChange = useCallback((state) => {
		if (!state) {
			setIsFullScreenActive(false);
		}
	}, []);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
				event.preventDefault();
				const scrollAmount = 50;
				const container = document.querySelector(".myHomeworkTable");

				if (container) {
					if (event.key === "ArrowRight") {
						container.scrollLeft += scrollAmount;
					} else if (event.key === "ArrowLeft") {
						container.scrollLeft -= scrollAmount;
					}
				}
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	/// shift + arrows navigation fun

	const tableRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (event) => {
			const { key, shiftKey } = event;

			if (shiftKey) {
				const activeElement = document.activeElement;
				const currentRow = activeElement.closest("tr");
				const currentCell = activeElement.closest("td, th");

				let nextCell;

				const findNextFocusableCell = (cell, direction) => {
					while (cell) {
						if (cell.querySelector("select, input, button")) {
							return cell;
						}
						cell =
							direction === "next"
								? cell.nextElementSibling
								: cell.previousElementSibling;
					}
					return null;
				};

				const findNextFocusableCellInRow = (row, cellIndex, direction) => {
					let nextRow =
						direction === "down"
							? row.nextElementSibling
							: row.previousElementSibling;
					while (nextRow) {
						const nextCell = nextRow.children[cellIndex];
						if (nextCell && nextCell.querySelector("select, input, button")) {
							return nextCell;
						}
						nextRow =
							direction === "down"
								? nextRow.nextElementSibling
								: nextRow.previousElementSibling;
					}
					return null;
				};

				switch (key) {
					case "ArrowRight":
						nextCell = findNextFocusableCell(
							currentCell?.nextElementSibling,
							"next"
						);
						break;
					case "ArrowLeft":
						nextCell = findNextFocusableCell(
							currentCell?.previousElementSibling,
							"prev"
						);
						break;
					case "ArrowDown":
						nextCell = findNextFocusableCellInRow(
							currentRow,
							currentCell.cellIndex,
							"down"
						);
						break;
					case "ArrowUp":
						nextCell = findNextFocusableCellInRow(
							currentRow,
							currentCell.cellIndex,
							"up"
						);
						break;
					default:
						break;
				}

				if (nextCell) {
					nextCell.querySelector("select, input, button")?.focus();
					event.preventDefault();
				}
			}
		};

		const tableElement = tableRef.current;
		if (tableElement) {
			tableElement.addEventListener("keydown", handleKeyDown);
		}

		return () => {
			if (tableElement) {
				tableElement.removeEventListener("keydown", handleKeyDown);
			}
		};
	}, []);

	return (
		<div className="crmBatchHomework">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="fullScreenConatiner">
						<div className="title">{batchName} </div>
						<p
							className="fullScreenAction"
							onClick={() => {
								handle.enter();
								setIsFullScreenActive(true);
							}}
						>
							<img src={ImageList.FullScreenIcon} alt="" />
							Full Screen View
						</p>
					</div>

					<FullScreen handle={handle} onChange={onFullScreenChange}>
						<div className={`${isFullScreenActive ? "fullScreenActive" : ""}`}>
							{isFullScreenActive && (
								<div className="hidefullScreenConatiner">
									<p
										className="fullScreenAction"
										onClick={() => {
											handle.exit();
											setIsFullScreenActive(false);
										}}
									>
										<img src={ImageList.FullScreenIcon} alt="" />
										Exit Full Screen View
									</p>
								</div>
							)}
							<div className="batchListContainer">
								<div
									className="courses myHomeworkTable"
									style={{ overflowX: "auto" }}
								>
									<table ref={tableRef}>
										<thead
											style={{
												position: "sticky",
												top: 0,
												backgroundColor: "#fff",
												zIndex: 1,
											}}
										>
											<tr className="stickyColumn centeredText">
												<th className="" rowSpan={3}>
													Company Name
												</th>
												<th className="" rowSpan={3}>
													Participant name
												</th>
												<th rowSpan={3}>Participant type</th>
												<th rowSpan={3}>Phone number</th>
												<th rowSpan={3}>Email</th>
												<th rowSpan={3}>Status</th>
												<th rowSpan={3}>Actions</th>
												<th rowSpan={3}>Completed score</th>
												<th rowSpan={3}>Incomplete score</th>
												<th
													style={{ width: "3400px", textAlign: "center" }}
													colSpan={totalQuestionsAcrossWeeks + totalWeeks}
													className="homeworkHead"
												>
													Homework
												</th>
												<th rowSpan={3}>Actions</th>
											</tr>
											<tr>
												{crmBatchHomework &&
													crmBatchHomework[0]?.homeworks &&
													crmBatchHomework[0].homeworks.map(
														(homework, index) => {
															const totalQuestions =
																homework.homework_questions_answers.length;

															const colspan =
																totalQuestions > 0 ? totalQuestions + 1 : 1;

															return (
																<td
																	style={{
																		textAlign: "center",
																		fontWeight: "700",
																	}}
																	key={`week-${index}`}
																	colSpan={colspan}
																>{`Week ${homework.week_no}`}</td>
															);
														}
													)}
											</tr>
											<tr>
												{crmBatchHomework &&
													crmBatchHomework[0]?.homeworks &&
													crmBatchHomework[0].homeworks.map(
														(homework, index) => (
															<React.Fragment key={`week-${index}`}>
																{homework.homework_questions_answers.map(
																	(item, idx) => {
																		const isFirstQuestionOfWeek = idx === 0;
																		const shouldApplyBackground =
																			isFirstQuestionOfWeek ||
																			(idx + 2) % 2 === 0;

																		return (
																			<td
																				style={{
																					whiteSpace: "wrap",
																					backgroundColor: shouldApplyBackground
																						? "#F5F7FA"
																						: "transparent",
																				}}
																				key={`question-${idx}`}
																			>
																				{item.question}
																			</td>
																		);
																	}
																)}
																<td key={`attachments-${index}`}>Attachment</td>
															</React.Fragment>
														)
													)}
											</tr>
										</thead>
										<tbody>
											{crmBatchHomework &&
												crmBatchHomework.map((batch, index) => (
													<tr key={batch.student_id} className="stickyColumn">
														<td style={{ whiteSpace: "wrap" }}>
															{batch.company_name}
														</td>
														<td>{batch.student_name}</td>
														<td>{batch.participant_type}</td>
														<td>{batch.phone_no}</td>
														<td style={{ whiteSpace: "wrap" }}>
															{batch.email}
														</td>
														<td>{batch.status}</td>
														<td className="actionBtn">
															<button
																className="editBtn"
																onClick={() => {
																	setStudentId(batch.student_id);

																	setShowModal(true);
																}}
																style={{ backgroundColor: "#4367B0" }}
															>
																<img src={ImageList.MessageIcon} alt="" />
															</button>
														</td>
														<td>
															<div
																style={{
																	display: "flex",
																	justifyContent: "start",
																	alignItems: "center",
																	gap: "8px",
																}}
															>
																<div style={{ width: 30, height: 30 }}>
																	<CircularProgressbar
																		styles={
																			batch.overall_score &&
																			batch.overall_score
																				.overall_complete_score !== undefined &&
																			Math.abs(
																				batch.overall_score
																					.overall_complete_score
																			) < 20
																				? buildStyles({
																						pathColor: "#F3960C",
																						trailColor: "#FDE9CE",
																				  })
																				: buildStyles({
																						pathColor: "#9cf971",
																						trailColor: "#ddfdcf",
																				  })
																		}
																		value={Math.abs(
																			batch.overall_score.overall_complete_score
																		)}
																	/>
																</div>
																<p>
																	{batch.overall_score.overall_complete_score}
																</p>
															</div>
														</td>
														<td>
															{" "}
															<div
																style={{
																	display: "flex",
																	justifyContent: "start",
																	alignItems: "center",
																	gap: "8px",
																}}
															>
																<div style={{ width: 30, height: 30 }}>
																	<CircularProgressbar
																		styles={
																			batch.overall_score &&
																			batch.overall_score
																				.overall_incomplete_score !==
																				undefined &&
																			Math.abs(
																				batch.overall_score
																					.overall_incomplete_score
																			) >= 80
																				? buildStyles({
																						pathColor: "#F3960C",
																						trailColor: "#FDE9CE",
																				  })
																				: buildStyles({
																						pathColor: "#9cf971",
																						trailColor: "#ddfdcf",
																				  })
																		}
																		value={Math.abs(
																			batch.overall_score
																				.overall_incomplete_score
																		)}
																	/>
																</div>
																<p>
																	{batch.overall_score.overall_incomplete_score}
																</p>
															</div>
														</td>

														{crmBatchHomework &&
															crmBatchHomework[0]?.homeworks &&
															crmBatchHomework[0].homeworks.map(
																(homework, indexNew) => (
																	<React.Fragment key={`week-${indexNew}`}>
																		{homework.homework_questions_answers.map(
																			(item, idx) => (
																				<React.Fragment key={`question-${idx}`}>
																					<td>
																						<div className="formInput">
																							<div className="inputUserName">
																								<select
																									id={idx}
																									onChange={(e) =>
																										handleInputChange(
																											item,
																											batch.student_id,
																											homework.week_no,
																											e.target.value
																										)
																									}
																									value={getAnswerForQuestion(
																										batch.student_id,
																										homework.week_no,
																										item.id
																									)}
																									style={{
																										borderColor:
																											selectedValues[
																												`${batch.student_id}-${homework.week_no}-${item.id}`
																											] !== "" && "black", // Default border color
																										backgroundColor:
																											selectedValues[
																												`${batch.student_id}-${homework.week_no}-${item.id}`
																											] === "Yes"
																												? "#9cf971"
																												: selectedValues[
																														`${batch.student_id}-${homework.week_no}-${item.id}`
																												  ] === "No"
																												? "#fe0000bb"
																												: selectedValues[
																														`${batch.student_id}-${homework.week_no}-${item.id}`
																												  ] === "N/A"
																												? "#ffea00"
																												: "transparent",
																									}}
																								>
																									<option value="">
																										-Any-
																									</option>
																									{item.options?.map(
																										(option, i) => (
																											<option
																												key={i}
																												value={option}
																											>
																												{option}
																											</option>
																										)
																									)}
																								</select>
																							</div>
																						</div>
																					</td>
																				</React.Fragment>
																			)
																		)}
																		<td
																			key={`extra-column-${indexNew}`}
																			style={{
																				whiteSpace: "wrap",
																			}}
																		>
																			{/* {homework.homework_documents && homework.homework_documents.length > 0 && homework.homework_documents.map((file, idx) =>(
                              <a key={idx} href={file.content}>{file.filename}</a>
                            
                            ))} */}
																			{batch.homeworks.map(
																				(newHomeWork, newIndex) =>
																					newHomeWork.week_no ===
																					homework.week_no ? (
																						newHomeWork.homework_documents
																							?.length > 0 ? (
																							newHomeWork.homework_documents.map(
																								(file, idx) => (
																									<a
																										key={idx}
																										href={file.content}
																										style={{
																											whiteSpace: "wrap",
																										}}
																									>
																										{file.filename}
																									</a>
																								)
																							)
																						) : (
																							<span>No File</span>
																						)
																					) : null
																			)}

																			{/* {homework.homework_documents && batch.homeworks.homework_documents?.length > 0 && batch.homeworks.homework_documents.map((file, idx) =>(
                              <a key={idx} href={file.content}>{file.filename}</a>
                            
                            ))} */}
																			{/* <p>hello</p/> */}

																			{/* {homework.homework_documents &&
                                    homework.homework_documents.length === 0 &&
                                    "No File"} */}
																		</td>
																	</React.Fragment>
																)
															)}

														<td>
															{/* <Button
                                buttonName={"Save"}
                                color={"#FFF"}
                                background={"#4367B0"}
                                onClick={() => submitHomework(batch.student_id)}
                                //   className="nextBtn"
                                style={{
                                  // width: "auto",
                                  background: "#4367B0",
                                  margin: "auto",
                                  padding: "10px 20px",
                                  alignItems: "center",
                                  borderRadius: "15px",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  border: "none",
                                }}
                              /> */}

															<button
																className="editBtn"
																onClick={() => submitHomework(batch.student_id)}
																title="Save"
															>
																<img
																	src={ImageList.SaveIcon}
																	alt=""
																	style={{ width: "20px", height: "20px" }}
																/>
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
									{showModal && (
										<CRMMessagesModal
											onClick={() => setShowModal(false)}
											studentId={studentId}
											batchId={batchId}
											role={"crm"}
											readStatus={"unread"}
										/>
									)}
								</div>
							</div>
							<div
								className={`${
									isFullScreenActive ? "" : "InnerpaginationWrapper"
								}`}
							>
								<Pagination
									key={currentPage}
									totalPages={totalPage ? totalPage : ""}
									currentPage={currentPage}
									onPageChange={handlePageChange}
									isLoading={isLoading}
								/>
							</div>
						</div>
					</FullScreen>
				</div>
				<div
					className={`${isFullScreenActive ? "" : "OuterpaginationWrapper"}`}
				>
					<Pagination
						key={currentPage}
						totalPages={totalPage ? totalPage : ""}
						currentPage={currentPage}
						onPageChange={handlePageChange}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
}

export default CrmBatchHomework;
