import React, { useState } from "react";
import "./TermsModal.scss";
import Cookies from "js-cookie";
import { submitTerms } from "../../interface/implementation/submitTermsAPIImp";

function VideoApprovModal({ onClick, terms, terms_conditions_id }) {
	const [isChecked, setIsChecked] = useState(false);
	const token = Cookies.get("token");

	const handleCloseClick = () => {
		let body = {
			terms_conditions_id: terms_conditions_id,
			is_terms_accepted: true,
		};
		submitTerms(token, body)
			.then(() => {})
			.catch();
		onClick();
	};

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	return (
		<div className="terms-modal-overlay TermsModal">
			<div className="terms-modal-content">
				<div className="tandc-header">
					<p className="tandc-title">Terms & Conditions</p>
				</div>
				<div className="body">
					<p dangerouslySetInnerHTML={{ __html: terms }} />
				</div>
				<div className="saveButtonWrapper">
					<label htmlFor="termCheckbox" className="termCheckbox">
						<input
							type="checkbox"
							name=""
							id="termCheckbox"
							checked={isChecked}
							onChange={handleCheckboxChange}
						/>
						Agree to our terms and condition
					</label>

					<button
						className="approveBtn"
						onClick={handleCloseClick}
						disabled={!isChecked} // Disable the button if the checkbox is not checked
					>
						Agree
					</button>
				</div>
			</div>
		</div>
	);
}

export default VideoApprovModal;
