import searchCRMPaymentInfoAPI from "../../apis/crmAdmin/searchCRMPaymentInfoAPI";

export function searchCRMPaymentInfo(token, body) {
	return new Promise(async (resolve, reject) => {
		searchCRMPaymentInfoAPI
			.sort(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
