import addStudentToBatchAPI from "../../apis/admin/addStudentToBatchAPI";

export function addStudentToBatch(token, body) {
  return new Promise(async (resolve, reject) => {
    addStudentToBatchAPI
      .post(token, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
