import getCRMPaymentInfoAPI from '../../apis/crmAdmin/getCRMPaymentInfoAPI';


export function getCRMPaymentInfo(token){
    return new Promise(async(resolve, reject) => {
      getCRMPaymentInfoAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}