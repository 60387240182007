import React, { useState, useEffect, useCallback } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./AllStudents.scss";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
// import ImageList from "../../../utils/ImageList";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getAllStudents } from "../../../interface/implementation/batchCRM/getAllStudentsAPIImp";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { getSalesperson } from "../../../interface/implementation/admin/getSalespersonAPIImp";
import { searchBatchCRMAllStudents } from "../../../interface/implementation/batchCRM/searchBatchCRMAllStudentsAPIImp";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ImageList from "../../../utils/ImageList";
import ForwardArrow from "../../../assets/arrow_forward.svg";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { getAbsentRemarks } from "../../../interface/implementation/batchCRM/getAbsentRemarksAPIImp";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
function AllStudents() {
	const navigate = useNavigate();
	const location = useLocation();

	const token = Cookies.get("token");

	const handle = useFullScreenHandle();
	const [isFullScreenActive, setIsFullScreenActive] = useState(false);

	const batchId = location.state.batchId;
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = isFullScreenActive ? 15 : 10;

	const [allStudentList, setAllStudentList] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	const [search, setSearch] = useState("");
	const [selectedParticipantType, setSelectedParticipantType] = useState("");
	const [status, setStatus] = useState("");
	const [selectedParticipantOldType, setSelectedParticipantOldType] =
		useState("");
	const [selectedSales, setSelectedSales] = useState("");
	const [selectedCRM, setSelectedCRM] = useState("");
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [crms, setCRMs] = useState([]);
	const [company, setCompany] = useState("");
	const [student, setStudent] = useState("");
	const [phone, setPhone] = useState("");
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const [showAbsentReasonModal, setShowAbsentReasonModal] = useState(false);
	// const [absentDetails, setAbsentDetails] = useState({
	// 	dates: [],
	// 	remarks: "",
	// });

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const isBase64URL = (str) => {
		// Regular expression to match data URLs
		const dataURLPattern = /^data:([A-Za-z0-9-+/]+);base64,(.+)$/;
		return dataURLPattern.test(str);
	};
	function openInNewTab(url) {
		window.open(url, "_blank");
	}

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getSalesperson(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					// showToast(res.message);
					return;
				}
				setDropdownOptions(res.users);
			});
		};

		const fetchCRMs = async () => {
			await getCRM(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					// showToast(res.message);
					return;
				}
				setCRMs(res.users);
			});
		};
		fetchDropdowns();
		fetchCRMs();
	}, []);
	useEffect(() => {
		getAllStudents(token, batchId)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setAllStudentList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);
	const handleSearch = async () => {
		let newBody = `?batch_id=${batchId}`;

		if (search) {
			newBody += `${newBody ? "&" : "?"}search=${search}`;
		}

		if (selectedSales) {
			newBody += `${newBody ? "&" : "?"}sales_person=${selectedSales}`;
		}

		if (selectedCRM) {
			newBody += `${newBody ? "&" : "?"}crm_admin=${selectedCRM}`;
		}
		if (company) {
			newBody += `${newBody ? "&" : "?"}company_name=${company}`;
		}
		if (student) {
			newBody += `${newBody ? "&" : "?"}student_name=${student}`;
		}
		if (phone) {
			newBody += `${newBody ? "&" : "?"}phone_no=${phone}`;
		}

		if (status) {
			newBody += `${newBody ? "&" : "?"}status=${status}`;
		}
		if (selectedParticipantOldType) {
			newBody += `${
				newBody ? "&" : "?"
			}participant_old_status=${selectedParticipantOldType}`;
		}
		if (selectedParticipantType) {
			newBody += `${
				newBody ? "&" : "?"
			}participant_type=${selectedParticipantType}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const searchFilter = (newBody) => {
		searchBatchCRMAllStudents(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setAllStudentList(res);
				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.batch.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch();
	};

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleReset = () => {
		setSelectedParticipantType("");
		setSearch("");
		setStatus("");
		setSelectedSales("");
		setSelectedParticipantOldType("");
		setPhone("");
		setStudent("");
		setCompany("");
		setSelectedCRM("");
		setReloadUsers((prevReloadUsers) => !prevReloadUsers);
	};
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems =
		allStudentList && allStudentList.length > 0
			? allStudentList.slice(indexOfFirstItem, indexOfLastItem)
			: [];

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const onFullScreenChange = useCallback((state) => {
		if (!state) {
			setIsFullScreenActive(false);
		}
	}, []);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
				event.preventDefault();
				const scrollAmount = 50;
				const container = document.querySelector(".courses");

				if (container) {
					if (event.key === "ArrowRight") {
						container.scrollLeft += scrollAmount;
					} else if (event.key === "ArrowLeft") {
						container.scrollLeft -= scrollAmount;
					}
				}
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);
	const [absentDetails, setAbsentDetails] = useState([
		{
			dates: [],
			remarks: [],
		},
	]);

	const GetAbsentDetails = (batch_id, student_id) => {
		const body = `/${batch_id}/${student_id}`;
		getAbsentRemarks(token, body)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				const newDetails = res.map((d) => ({
					dates: d.class_dates,
					remarks: d.remarks,
				}));

				setAbsentDetails((prevDetails) => [...prevDetails, ...newDetails]);

				setShowAbsentReasonModal(true);
			})
			.catch();
	};
	return (
		<div className="allStudents">
			<ToastContainer limit={1} />
			{showAbsentReasonModal && (
				<div
					className="modal-overlay"
					onClick={() => setShowAbsentReasonModal(false)}
				>
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<h1 className="reasonTitle">Absence Details</h1>

						<div className="DetailsContainer">
							<div className="details">
								<table className="absentDetailsTable">
									<thead>
										<tr>
											<th>Dates</th>
											<th>Remarks</th>
										</tr>
									</thead>
									<tbody>
										{absentDetails &&
										absentDetails.length > 0 &&
										absentDetails.every(
											(d) => d.dates.length === 0 && d.remarks.length === 0
										) ? (
											<tr>
												<td
													colSpan={2}
													style={{
														padding: "1rem 0",
														borderTop: "1px solid black",
													}}
												>
													<NoDataFound />
												</td>
											</tr>
										) : (
											absentDetails.map((d, index) => (
												<tr key={index}>
													<td
														className="AbsentDates"
														style={{
															borderBottom: "1px solid #bfbfbf",
															padding: "0.5rem",
															borderRight: "1px solid #f5f5f5",
														}}
													>
														{d.dates.map((f, i) => (
															<span key={i}>{f} </span>
														))}
													</td>
													<td
														style={{
															borderBottom: "1px solid #bfbfbf",
															padding: "0.5rem",
														}}
													>
														{d.remarks}
													</td>
												</tr>
											))
										)}
									</tbody>
								</table>
							</div>
						</div>
						<button
							onClick={() => setShowAbsentReasonModal(false)}
							className="closeBtn"
						>
							X
						</button>
					</div>
				</div>
			)}
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">All Participants</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="selectedBatch">Search </label>
									<div className="inputCourseName">
										<input
											type="text"
											id="search"
											placeholder="Company code/name, Participant name/phone"
											onChange={(e) => handleInputChange(e, setSearch)}
											value={search}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedBatch">Participant Name </label>
									<div className="inputCourseName">
										<input
											type="text"
											id="student_name"
											placeholder="Participant name"
											onChange={(e) => handleInputChange(e, setStudent)}
											value={student}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedBatch">Company Name </label>
									<div className="inputCourseName">
										<input
											type="text"
											id="company_name"
											placeholder="Company name"
											onChange={(e) => handleInputChange(e, setCompany)}
											value={company}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedBatch">Phone Number </label>
									<div className="inputCourseName">
										<input
											type="number"
											id="phone_no"
											placeholder="Phone number"
											onChange={(e) => handleInputChange(e, setPhone)}
											value={phone}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedParticipantType"> CRM</label>
									<div className="inputCode">
										<select
											id="selectedParticipantType"
											onChange={(e) => handleInputChange(e, setSelectedCRM)}
											value={selectedCRM}
										>
											<option value="">Select CRM</option>
											{crms?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedParticipantType"> Sales Person</label>
									<div className="inputCode">
										<select
											id="selectedParticipantType"
											onChange={(e) => handleInputChange(e, setSelectedSales)}
											value={selectedSales}
										>
											<option value="">Select sales person</option>
											{dropdownOptions?.map((option, i) => (
												<option key={i} value={option.user_id}>
													{option.full_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedParticipantType">
										{" "}
										Participant Type
									</label>
									<div className="inputCode">
										<select
											id="selectedParticipantType"
											onChange={(e) =>
												handleInputChange(e, setSelectedParticipantType)
											}
											value={selectedParticipantType}
										>
											<option value="">Select Participant Type</option>
											<option value="primary">Primary</option>
											<option value="secondary">Secondary</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedParticipantType">
										{" "}
										Participant Old Status
									</label>
									<div className="inputCode">
										<select
											id="status"
											onChange={(e) =>
												handleInputChange(e, setSelectedParticipantType)
											}
											value={status}
										>
											<option value="">Select Old Part Status</option>
											<option value=" Primary carry forward">
												Primary carry forward
											</option>
											<option value=" Secondary carry forward">
												Secondary carry forward
											</option>
											<option value="Implementer carry forward">
												Implementer carry forward
											</option>
											<option value="Shifted from previous batch">
												Shifted from previous batch
											</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="Status">
										{" "}
										Registered/Adjust/Shift/Refund{" "}
									</label>
									<div className="inputCode">
										<select
											id="status"
											onChange={(e) => handleInputChange(e, setStatus)}
											value={status}
										>
											<option value="">Select Adjustment Type</option>
											<option value="Adjustment">Adjustment</option>
											<option value="Shift">Shift</option>
											<option value="Refunded">Refunded</option>
											<option value="Dropout">Dropout</option>
											<option value="Next">Next</option>
											<option value="Discontinued">Discontinued</option>
										</select>
									</div>
								</div>

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Apply
									</button>
									<button
										style={{ marginLeft: "20px" }}
										className="searchBtn "
										onClick={handleReset}
									>
										Reset
									</button>
								</div>
							</div>
						</div>

						<div className="crmListContainer">
							<div className="fullScreenConatiner">
								<div className="title">
									Batch Name:{" "}
									{allStudentList && allStudentList.length > 0
										? allStudentList[0].batch_name
										: ""}
								</div>

								<p
									className="fullScreenAction"
									onClick={() => {
										handle.enter();
										setIsFullScreenActive(true);
									}}
								>
									<img src={ImageList.FullScreenIcon} alt="" />
									Full Screen View
								</p>
							</div>

							<FullScreen handle={handle} onChange={onFullScreenChange}>
								<div
									className={`${isFullScreenActive ? "fullScreenActive" : ""}`}
								>
									{isFullScreenActive && (
										<div className="fullScreenConatiner">
											<p
												className="fullScreenAction"
												onClick={() => {
													handle.exit();
													setIsFullScreenActive(false);
												}}
											>
												<img src={ImageList.FullScreenIcon} alt="" />
												Exit Full Screen View
											</p>
										</div>
									)}
									<div className="courses" style={{ overflowX: "auto" }}>
										<table className="batchTable">
											<thead>
												<tr>
													<th>Sl. No.</th>
													<th>Course Name</th>
													<th>Company Name</th>
													<th>Company Code</th>
													<th>Participant Full Name</th>
													<th>Participant Mail</th>
													<th>Phone Number</th>
													<th>Participant Type</th>
													<th>CRM</th>
													<th>Absences</th>
													<th>Participant Old Status</th>
													<th>Status</th>
													<th>Refund Approved File</th>
													{/* <th>Actions</th> */}
													{/* <th>Member Type</th>
                      <th>Payment Category</th> */}
												</tr>
											</thead>
											<tbody>
												{currentItems &&
													currentItems.length > 0 &&
													currentItems.map((student, index) => (
														<tr key={index}>
															<td>{startSerialNumber + index}</td>
															{/* <td>{student.companyCode}</td> */}
															<td>{student.course_name}</td>
															<td style={{ whiteSpace: "wrap" }}>
																{student.company_name}
															</td>
															<td>{student.company_id}</td>
															<td>{student.student_name}</td>
															<td>{student.email_id}</td>
															<td>{student.phone_no}</td>
															<td>{student.participant_type}</td>
															<td>{student.crm_name}</td>
															<td
																style={{
																	backgroundColor: student.consecutive_absences
																		? "#ffc7c7"
																		: "	#a3e5c4",
																}}
															>
																{student.consecutive_absences ? (
																	<button
																		className="absenceBtn"
																		onClick={() =>
																			GetAbsentDetails(
																				student.batch_id,
																				student.student_id
																			)
																		}
																	>
																		Yes
																		<img src={ForwardArrow} />
																	</button>
																) : (
																	<p>None</p>
																)}
															</td>

															<td>{student.participants_old_status}</td>
															<td>{student.participants_status}</td>
															<td>
																{student.refund_approved_file?.map(
																	(file, index) => (
																		<span
																			key={index}
																			style={{
																				marginRight: "10px",
																				display: "block",
																			}}
																		>
																			{file && isBase64URL(file) ? (
																				<a
																					href={file}
																					download={`File-${index}`}
																					style={{
																						textDecoration: "none",
																						color: "blue",
																						cursor: "pointer",
																					}}
																				>
																					File-{index}
																				</a>
																			) : (
																				<a
																					href="#"
																					onClick={(e) => {
																						e.preventDefault();
																						openInNewTab(file);
																					}}
																					style={{
																						textDecoration: "none",
																						color: "blue",
																						cursor: "pointer",
																					}}
																				>
																					File-{index}
																				</a>
																			)}
																		</span>
																	)
																)}
															</td>
															<td></td>
															{/* <td>{student.member_type}</td>
                        <td>{student.participants_status}</td>
                        <td>{student.paymentCategory}</td> */}
															{/* <td
                            className="btnRow"
                          >
                            <Link to={{ pathname: `` }} className="batchTableBtn">
                              <img src={ImageList.CommentIcon} alt="" />
                              Comment
                            </Link>
                            <Link
                              to={{ pathname: `` }}
                              className="batchTableBtn"
                              style={{ backgroundColor: "#C6469F" }}
                            >
                              <img src={ImageList.RupeeIcon} alt="" />

                              Payment
                            </Link>
                            <Link
                              to={{ pathname: `/crm-batch/batch-list/${student.id}/homework` }}
                              className="batchTableBtn"
                              style={{ backgroundColor: "#4367B0" }}
                            >
                              <img src={ImageList.HomeworkIcon} alt="" />

                              Homework
                            </Link>
                            <Link
                              to={{ pathname: `` }}
                              className="batchTableBtn"
                              style={{ backgroundColor: "#DE4B39" }}
                            >
                              <img src={ImageList.AttendanceIcon} alt="" />

                              Attendance
                            </Link>
                            <Link
                              to={{ pathname: `/crm-batch/batch-list/${student.id}/video-request` }}
                              className="batchTableBtn"
                              style={{ backgroundColor: "#F30705" }}
                            >
                              <img src={ImageList.YoutubeIcon} alt="" />
                              Video Request
                            </Link>
                          </td> */}
														</tr>
													))}
											</tbody>
										</table>
									</div>
									<div className="paginationWrapper">
										<Pagination
											key={currentPage}
											totalPages={Math.ceil(
												allStudentList?.length / itemsPerPage
											)}
											currentPage={currentPage}
											onPageChange={handlePageChange}
										/>
									</div>
								</div>
							</FullScreen>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AllStudents;
