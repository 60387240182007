import searchBatchCRMPaymentInfoAPI from "../../apis/batchCRM/searchBatchCRMPaymentInfoAPI";

export function searchBatchCRMPaymentInfo(token, body) {
	return new Promise(async (resolve, reject) => {
		searchBatchCRMPaymentInfoAPI
			.sort(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
