import searchBatchCRMTicketsAPI from "../../apis/batchCRM/searchBatchCRMTicketsAPI";

export function searchBatchCRMTickets(token, body) {
	return new Promise(async (resolve, reject) => {
		searchBatchCRMTicketsAPI
			.sort(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
