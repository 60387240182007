import getAdminOverdueDetailsAPI from '../../apis/admin/getAdminOverdueDetailsAPI';


export function getAdminOverdueDetails(token){
    return new Promise(async(resolve, reject) => {
      getAdminOverdueDetailsAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}