import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./BatchCRMPaymentInfo.scss";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { getCRMBatches } from "../../../interface/implementation/getCRMBatchesAPIImp";
import { getBatchCRMPaymentInfo } from "../../../interface/implementation/batchCRM/getBatchCRMPaymentInfoAPIImp";
import { searchBatchCRMPaymentInfo } from "../../../interface/implementation/batchCRM/searchBatchCRMPaymentInfoAPIImp";
function BatchCRMPaymentInfo() {
	const navigate = useNavigate();
	const location = useLocation();
	const [search, setSearch] = useState("");
	const [participant, setParticipant] = useState("");
	const [crm, setCrm] = useState("");
	const [participantOldStatus, setParticipantOldStatus] = useState("");
	const [status, setStatus] = useState("");
	const [activeStatus, setActiveStatus] = useState("");
	const [filterBatchId, setFilterBatchId] = useState();
	const [technicalNotes, setTechnicalNotes] = useState("");
	const token = Cookies.get("token");
	const [batchStudentList, setBatchStudentList] = useState([]);
	const [batchList, setBatchList] = useState([]);
	const [paymentList, setPaymentList] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	// const [currentItems, setCurrentItems] = useState([]);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState({
		crm: [],
	});
	const [showModal, setShowModal] = useState(false);
	const [replaceData, setReplaceData] = useState({});

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getBatchCRMPaymentInfo(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setPaymentList(res.payments_details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getCRMBatches(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setBatchList(res.batch);
			});
		};
		fetchDropdowns();
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (search) {
			newBody += `${newBody ? "&" : "?"}search_data=${search}`;
		}

		if (participant) {
			newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
		}

		if (filterBatchId) {
			newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	// const handleAutoSearch = async (e) => {
	//   if (e.target.value) {
	//     let newBody = `${batchId}/${e.target.value}`;

	//     autoSearchBatches(token, newBody)
	//       .then((res) => {
	//         if (res.detail === "Signature has expired.") {
	//           navigate("/");
	//           showToast("Token has expired. Please log in again.");
	//           return;
	//         }
	//         if (res.status === "failure") {
	//           showToast(res.message);
	//           return;
	//         }
	//         setAutoValues(res)
	//         setAutoModal(true)

	//       })
	//       .catch();

	//   }
	// };

	const searchFilter = (newBody) => {
		searchBatchCRMPaymentInfo(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setPaymentList(res.payments_details);

				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.batch.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch();
	};

	const handleDownload = () => {
		if (filterBatchId) {
			let newBody = ``;

			if (search) {
				newBody += `&search=${search}`;
			}

			if (participant) {
				newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
			}
			if (filterBatchId) {
				newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
			}
		} else {
			showToasterMessage("Please select a batch");
		}
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;

	const currentItems =
		paymentList && paymentList.length > 0
			? paymentList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="batchCRMPaymentInfo">
			<ToastContainer limit={1} />
			{/* <TopHeader /> */}
			<div className="dashboardBody">
				{/* <SidebarNav menuItems={SidebarMenuItems} /> */}
				<div className="dashboardContent">
					<div className="title">Payment Info</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs batchStudentSearch">
								<div className="inputContainer">
									<label htmlFor="batch">Select batch</label>
									<div className="inputCode">
										<select
											id="batchId"
											onChange={(e) => handleInputChange(e, setFilterBatchId)}
											value={filterBatchId}
										>
											<option value="">Select Batch</option>
											{batchList &&
												batchList.length > 0 &&
												batchList.map((batch, index) => {
													return (
														<option key={index} value={batch.batch_id}>
															{batch.batch_name}
														</option>
													);
												})}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="search">Company name / Phone number</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder=""
											onChange={(e) => {
												// handleAutoSearch(e)
												handleInputChange(e, setSearch);
											}}
											value={search}
										/>
									</div>
								</div>
								{/* <div className="inputContainer">
                  <label htmlFor="participant">Participant</label>
                  <div className="inputCode">
                    <select
                      id="participant"
                      onChange={(e) => handleInputChange(e, setParticipant)}
                      value={participant}
                    >
                      <option value="">Select Participant</option>
                      <option value="primary">
                        Primary
                      </option>
                      <option value="secondary">
                        Secondary
                      </option>
                    </select>
                  </div>
                </div> */}

								{/* refund status filter */}
								{/* <div className="inputContainer">
                  <label htmlFor="status">
                    Registered/Adjust/Shift/Refund Status
                  </label>
                  <div className="inputCode">
                    <select
                      id="status"
                      onChange={(e) => handleInputChange(e, setStatus)}
                      value={status}
                    >
                      <option value="">Select Status</option>
                      <option value="registered">Registered</option>
                      <option value="adjust">Adjust</option>
                      <option value="shift">Shift</option>
                      <option value="refund">Refund</option>
                    </select>
                  </div>
                </div> */}
								{/* status filter */}
								{/* <div className="inputContainer">
                  <label htmlFor="activeStatus">Active Status</label>
                  <div className="inputCode">
                    <select
                      id="activeStatus"
                      onChange={(e) => handleInputChange(e, setActiveStatus)}
                      value={activeStatus}
                    >
                      <option value="">Select Active Status</option>
                      <option value="active">Active </option>
                      <option value="inactive">Inactive </option>
                    </select>
                  </div>
                </div> */}

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>

								{/* <div className="searchBtnContainer">
                  <button className="searchBtn" onClick={handleDownload}>
                    Download
                  </button>
                </div> */}
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="batchStudentTable">
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Company Code</th>
										<th>Participant Name</th>
										<th>Company Name</th>
										<th>Phone number</th>
										<th>Participant Mail</th>
										<th>Participant Type</th>
										<th>Payment Month</th>
										<th>Installment Amount</th>
										<th>Payable Amount</th>
										{/* <th>Adjust/Shift/Refund</th> */}
										<th>Paid Amount</th>
										<th>Received Amount</th>
										<th>TDS Amount</th>
										<th>Discount Amount</th>
										<th>Due Amount</th>
										<th>Received date</th>
										<th>Mode of Payment</th>

										<th>Payment Description</th>
										<th>Remarks</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((payment, index) => (
											<tr key={index}>
												<td>{startSerialNumber + index}</td>
												<td>{payment.company_id}</td>
												<td>{payment.student_name}</td>
												<td style={{ whiteSpace: "wrap" }}>
													{payment.company_name}
												</td>
												<td>{payment.phone_number}</td>
												<td>{payment.student_mail}</td>
												<td>{payment.participant_type}</td>
												<td>{payment.payment_month}</td>
												<td>{payment.installment_amount}</td>
												<td>{payment.payable_amount}</td>
												{/* <td>{payment.shift}</td> */}

												<td>{payment.paid_amount}</td>
												<td>{payment.paid_amount}</td>
												<td>{payment.tds_amount}</td>
												<td>{payment.discount_amount}</td>
												<td>{payment.due_amount}</td>
												<td>{payment.payment_date}</td>
												<td>{payment.mode_of_payment}</td>
												<td>{payment.payment_description}</td>
												<td>{payment.remarks}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						{currentItems && currentItems.length > 0 && (
							<div className="pagination">
								<button
									onClick={() => paginate(currentPage - 1)}
									disabled={currentPage === 1}
								>
									{"<"}
								</button>
								{Array.from(
									{ length: Math.ceil(paymentList.length / itemsPerPage) },
									(_, index) => (
										<button
											key={index}
											onClick={() => paginate(index + 1)}
											className={currentPage === index + 1 ? "active" : ""}
										>
											{index + 1}
										</button>
									)
								)}
								<button
									onClick={() => paginate(currentPage + 1)}
									disabled={
										currentPage === Math.ceil(paymentList.length / itemsPerPage)
									}
								>
									{">"}
								</button>
							</div>
						)}
					</div>

					{/* <div className="pagination">
            <div></div>
            <div style={{ gap: "10px" }}>
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {Array.from(
                { length: Math.ceil(batchStudentList?.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                )
              )}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(batchStudentList?.length / itemsPerPage)
                }
              >
                {">"}
              </button>
            </div>
          </div> */}
				</div>
			</div>
		</div>
	);
}

export default BatchCRMPaymentInfo;
