import getStudentAttendancePercentageAPI from '../../apis/admin/getStudentAttendancePercentageAPI';


export function getStudentAttendancePercentage(token){
    return new Promise(async(resolve, reject) => {
        getStudentAttendancePercentageAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}