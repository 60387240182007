import React, { useState, useRef } from "react";
import "./WorkshopRegistration.scss";
import ImageList from "../../utils/ImageList";
import { preventWheelScroll, handleNumericInput } from "../../utils/helper";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { registerClass } from "../../interface/implementation/registerClassAPIImp";
import Webcam from "react-webcam"; // Import react-webcam
import { registerWorkshopClass } from "../../interface/implementation/registerWorkshopClassAPIImp";
function WorkshopRegistration() {
	const [fileNames, setFileNames] = useState([]); // State to store uploaded file names
	const [formData, setFormData] = useState({
		attendance_image: [], // Initialize attendance_image as an empty array
	});
	const [phoneNumber, setPhoneNumber] = useState("");
	const [showWebcam, setShowWebcam] = useState(false); // State to control webcam visibility
	const webcamRef = useRef(null);
	const navigate = useNavigate();
	const { id } = useParams();

	const handleUploadButtonClick = () => {
		setShowWebcam(true); // Show webcam when upload button is clicked
	};

	const handleCapture = () => {
		const imageSrc = webcamRef.current.getScreenshot();
		if (imageSrc) {
			const fileName = `photo_${Date.now()}.png`; // Generate a filename for the captured image
			// setFormData((prevData) => ({
			//   ...prevData,
			//   attendance_image: [
			//     ...prevData.attendance_image,
			//     {
			//       filename: fileName,
			//       content: imageSrc
			//     }
			//   ]
			// }));
			setFormData(() => ({
				attendance_image: [
					{
						filename: fileName,
						content: imageSrc,
					},
				],
			}));
			setFileNames(() => [fileName]);
			setShowWebcam(false); // Close webcam after capturing image
		} else {
			showToasterMessage("Please allow camera & take your selfie");
		}
	};

	const handleRemoveFile = (index) => {
		const updatedFiles = [...formData.attendance_image];
		updatedFiles.splice(index, 1);
		// setFormData((prevData) => ({
		//   ...prevData,
		//   attendance_image: updatedFiles,
		// }));
		setFormData(() => ({
			attendance_image: [],
		}));

		// Remove the file name at the specified index
		setFileNames((prevFileNames) => {
			const updatedFileNames = [...prevFileNames];
			updatedFileNames.splice(index, 1);
			return updatedFileNames;
		});
	};

	const handleSave = () => {
		if (formData.attendance_image.length > 0 && phoneNumber) {
			let body = {
				workshop_class_id: id,
				contact_no: phoneNumber,
				attendance_image: formData.attendance_image,
			};
			registerWorkshopClass("", body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToasterMessage("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToasterMessage(res.message);
						return;
					}
					showSuccessToast("Registered successfully");
					navigate("/thank-you");
				})
				.catch((err) => showToasterMessage("Something went wrong"));
		} else {
			showToasterMessage("Please take a selfie");
		}
	};

	return (
		<div className="workshopRegistartionForm">
			<ToastContainer limit={1} />
			<div className="registartionFormContainer">
				<h1 className="formHeading">Workshop Check-in</h1>
				{/* <p className="info">You will get a Whatsapp message on your registered mobile number. </p> */}
				<p className="info">
					Please increase the brightness of your phone and show the message to
					us when you enter the Workshop Hall.{" "}
				</p>
				<p className="info">
					This system is made to enable quick and hassle-free check-in.
				</p>
				<hr />

				<div className="inputFields">
					<div className="formInput">
						<label htmlFor="phone_no">
							Phone number<span>*</span>
						</label>
						<div className="inputUserName">
							<input
								type="tel"
								id="phone_no"
								placeholder="Enter phone number"
								onChange={(e) => setPhoneNumber(e.target.value)}
								onFocus={preventWheelScroll}
								onInput={handleNumericInput}
								value={phoneNumber}
								pattern="[0-9]{10,15}"
								maxLength={10}
								// minLength={10}
								title="Please enter a valid phone number (between 10 and 15 digits)."
							/>
						</div>
					</div>
					<div className="uploadArea">
						<p className="titleHomework">Upload your selfie</p>
						<div className="uploadContainer">
							{showWebcam ? (
								<div className="webcamContainer">
									<Webcam
										audio={false}
										ref={webcamRef}
										screenshotFormat="image/jpeg"
										className="webcam"
									/>
									<button className="captureBtn" onClick={handleCapture}>
										Capture
									</button>
								</div>
							) : (
								<button
									className="uploadHomeworkBtn"
									onClick={handleUploadButtonClick}
								>
									<img src={ImageList.WhiteUploadIcon} alt="" />
									Upload
								</button>
							)}
							<div className="fileNames">
								{fileNames.map((fileName, index) => (
									<p key={index} className="fileName">
										{fileName}
										<span
											className="removeBtn"
											onClick={() => handleRemoveFile(index)}
										>
											x Remove
										</span>
									</p>
								))}
							</div>
							{fileNames.length === 0 && !showWebcam && (
								<div>
									<p className="uploadInfo">
										<b>
											Please make sure that your face is clearly visible in the
											photo.
										</b>{" "}
										<br />
										{/* <b>Click on Upload file File --- Select files from your device --- Click on Take Photo</b> <br /> */}
										Maximum size: 64 MB. File format: doc, docx, xls, xlsx, pdf,
										jpeg, jpg, png, zip, ppt, pptx.
									</p>
								</div>
							)}
						</div>
					</div>
					<button onClick={handleSave} className="nextBtn">
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default WorkshopRegistration;
