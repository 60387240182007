import {ApiCore} from "../../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: false,
    postEncoded: false,
    post: false,
    put: false,
    delete: false,
    sort: true,
    url: `api/admin/payments/filter-payment`,
    version: ''
});

export default apiTasks;