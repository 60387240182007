import React, { useEffect, useState } from "react";
import SidebarNav from "../../../components/SideBar/SideBar";
import TopHeader from "../../../components/TopHeader/TopHeader";
import "./StudentTicket.scss";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import Cookies from "js-cookie";
import { getStudentBatches } from "../../../interface/implementation/student/getStudentBatchesAPIImp";
import { createTicket } from "../../../interface/implementation/student/createTicketAPIImp";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const StudentTicket = () => {
	const navigate = useNavigate();
	const [ticket_subject, setTicketSubject] = useState("");
	const [ticket_description, setTicketDescription] = useState("");
	const [batchList, setBatchList] = useState([]);
	const [selectedBatch, setSelectedBatch] = useState("");

	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	useEffect(() => {
		getStudentBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchList(res);
			})
			.catch();
	}, []);

	const submitTicket = () => {
		let body = {
			batch_id: selectedBatch,
			ticket_subject: ticket_subject,
			ticket_description: ticket_description,
		};
		createTicket(token, body)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				successToast("Ticket generated.");
				navigate("/student/crm-help-ticket");
			})
			.catch();
	};
	return (
		<Layout title={"Ticket"}>
			<div className="studentTicketContainer">
				<ToastContainer limit={1} />
				<div className="studentTicketFormGroup">
					<div className="subjectName">
						<Input
							label={"Subject"}
							placeholder="Enter Subject"
							inputType={"text"}
							onChange={(e) => {
								setTicketSubject(e.target.value);
							}}
							value={ticket_subject}
						/>
					</div>
					<div className="courseName">
						<label htmlFor="batchSelect">Select Course</label>
						<select
							id="batchSelect"
							onChange={(e) => {
								setSelectedBatch(e.target.value);
							}}
							value={selectedBatch}
						>
							<option value="">-Select Course-</option>
							{batchList.map((batch) => (
								<option key={batch.batch_id} value={batch.batch_id}>
									{batch.batch_name}
								</option>
							))}
						</select>
					</div>
					{/* <div className="courseName">
            <Input
              label={"Select Course"}
              placeholder="-Select Course-"
              inputType={"select"}
              onChange={() => {}}
            />
          </div> */}
				</div>
				<div>
					<Input
						placeholder={"Type here"}
						onChange={(e) => {
							setTicketDescription(e.target.value);
						}}
						value={ticket_description}
						label={"Ticket Text"}
						inputType={"textarea"}
					/>
				</div>
				<div>
					<Button
						style={{
							padding: "10px 20px 10px 20px",
						}}
						buttonName={"Submit"}
						background={"#4367B0"}
						color={"#fff"}
						onClick={submitTicket}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default StudentTicket;
