import React, { useState, useRef } from "react";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./UpdateWorkshopClass.scss";
import { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { getBatch } from "../../../interface/implementation/admin/getBatchAPIImp";
import { addClass } from "../../../interface/implementation/admin/addClassAPIImp";
import { getClass } from "../../../interface/implementation/admin/getClassAPIImp";
import { showSuccessToast } from "../../../utils/helper";
import { getCourse } from "../../../interface/implementation/admin/getCourseAPIImp";

import { getWorkshopClass } from "../../../interface/implementation/admin/getWorkshopClassAPIImp";
import { addWorkshopClass } from "../../../interface/implementation/admin/addWorkshopClassAPIImp";

function WeekCard({
	weekNo,
	isCollapsed,
	onClassDataChange,
	classData,
	initialClassStartDate,
	// onMailNotificationChange,
	// onTechnicalClassesChange,
	// initialClassData
}) {
	// const [classData, setClassData] = useState(initialClassData || {});
	const [editorVisibility, setEditorVisibility] = useState(false);
	const [classType, setClassType] = useState(["Online", "Offline", "Hybrid"]);

	useEffect(() => {
		setEditorVisibility(false);
	}, [isCollapsed]);

	const handleToggleEditor = () => {
		setEditorVisibility((prevVisibility) => !prevVisibility);
	};

	const handleInputChange = (e, weekNo) => {
		// Update classData array in the parent component
		onClassDataChange(weekNo, e);
	};

	return (
		<div className="UpdateWorkshopClass">
			<ToastContainer limit={1} />
			<div className="weekCard">
				<div className="heading" onClick={() => handleToggleEditor()}>
					<p className="weekCardTitle">Class No. {weekNo}</p>
					<p className="icon">{editorVisibility ? "-" : "+"}</p>
				</div>
				{editorVisibility && (
					<div className="classDetails">
						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="weekNo">
									Class No <span>*</span>
								</label>
								<div>
									<input type="text" id="weekNo" value={weekNo} disabled />
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="title">
									Class title <span>*</span>
								</label>
								<div>
									<input
										type="text"
										id="workshop_class_title"
										value={classData.workshop_class_title}
										placeholder="Workshop Title"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="startDate">
									Class Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="class_start_date"
									onChange={(e) => handleInputChange(e, weekNo)}
									value={classData.class_start_date}
									min={initialClassStartDate}
									// value={formatDateForInput(formData.start_date)}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="time">
									Time <span>*</span>
								</label>
								<input
									type="time"
									id="class_start_time"
									onChange={(e) => handleInputChange(e, weekNo)}
									value={classData.class_start_time.split(".")[0]}
								/>
							</div>
						</div>
						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="zoomId">
									Zoom meeting ID (If Online)
									{classData.workshop_class_type !== "Offline" && (
										<span>*</span>
									)}
								</label>
								<div>
									<input
										type="text"
										id="zoom_meeting_id"
										value={classData.zoom_meeting_id}
										placeholder="Meeting id"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="passcode">
									Security passcode
									{classData.workshop_class_type !== "Offline" && (
										<span>*</span>
									)}
								</label>

								<div>
									<input
										type="text"
										id="security_passcode"
										value={classData.security_passcode}
										placeholder="Passcode in numbers"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="meetUrl">
									Zoom meeting registration URL
									{classData.workshop_class_type !== "Offline" && (
										<span>*</span>
									)}
								</label>
								<div>
									<input
										type="text"
										id="zoom_meeting_registration_link"
										value={classData.zoom_meeting_registration_link}
										placeholder="Meting URL"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
						</div>
						<div style={{ width: "50%" }}>
							<div className="formInput">
								<label htmlFor="venue">
									Venue Details (If offline)
									{classData.workshop_class_type !== "Online" && <span>*</span>}
								</label>
								<div>
									<textarea
										id="venue_details"
										cols="30"
										rows="5"
										value={classData.venue_details}
										placeholder="Enter venue details"
										onChange={(e) => handleInputChange(e, weekNo)}
										style={{ borderRadius: "10px" }}
									></textarea>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="workshop_class_type">
									Class Type <span>*</span>
								</label>
								<select
									id="workshop_class_type"
									onChange={(e) => handleInputChange(e, weekNo)}
									value={classData.workshop_class_type}
								>
									<option value="">Select Class Type</option>
									{classType &&
										classType.length > 0 &&
										classType.map((classType, index) => (
											<option key={index} value={classType}>
												{classType}
											</option>
										))}
								</select>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

function UpdateWorkshopClass() {
	const { workshopId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const startDate = location.state?.startDate;
	const endDate = location.state?.endDate;
	const isInitialRender = useRef(true);
	const [classStartDate, setClassStartDate] = useState("");

	const [classData, setClassData] = useState([
		{
			class_no: 0,
			workshop_class_title: "",
			class_start_date: "",
			class_start_time: "",
			// zoom_meeting_id: "",
			// security_passcode: "",
			// zoom_meeting_registration_link: "",
			// venue_details: "",
			workshop_class_type: "",
			// vimeo_id: [], // Initialize an empty array for Vimeo IDs
			// mail_notification: false,
			// technical_classes: false,
			// mail_notification_date: "",
			// mail_notification_time: "",
		},
	]);
	const [formData, setFormData] = useState({
		batch_name: "",
		course_id: "",
		running_week_day: "",
		start_date: "",
		end_date: "",
	});
	const token = Cookies.get("token");

	// const [vimeoIds, setVimeoIds] = useState([]);

	// const [noOfClasses, setNoOfClasses] = useState(1);
	// const [vimeoIds, setVimeoIds] = useState(
	//   Array.from({ length: noOfClasses }, () => [""])
	// );
	const [weeks, setWeeks] = useState([0]);

	const [collapseAll, setCollapseAll] = useState(false);
	const [type, setType] = useState("add");

	const addWeek = () => {
		setWeeks((prevWeeks) => [...prevWeeks, prevWeeks.length]);

		setClassData((prevData) => [
			...prevData,
			{
				// Initialize the new week's data with default values
				class_no: prevData.length,
				workshop_class_title: "",
				class_start_date: "",
				class_start_time: "",
				// zoom_meeting_id: "",
				// security_passcode: "",
				// zoom_meeting_registration_link: "",
				// venue_details: "",
				workshop_class_type: "",
				// vimeo_id: [], // Initialize an empty array for Vimeo IDs
				// mail_notification: false,
				// technical_classes: false,
				// mail_notification_date: "",
				// mail_notification_time: "",
			},
		]);
	};

	//  const [finalClassData, setFinalClassData] = useState({
	//   batch_id: workshopId,
	//   classes: []
	//  })
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		// getBatch(token, workshopId)
		//   .then((res) => {
		//     if (res.detail === "Signature has expired.") {
		//       navigate("/");
		//       showToast("Token has expired. Please log in again.");
		//       return;
		//     }
		//     if (res.status === "failure") {
		//       showToast(res.message);
		//       return;
		//     }
		//     // -------------------------Course Api----------
		//     getCourse(token, res.batch[0].course_id)
		//       .then((courseRes) => {
		//         if (courseRes.detail === "Signature has expired.") {
		//           navigate("/");
		//           showToast("Token has expired. Please log in again.");
		//           return;
		//         }
		//         if (courseRes.status === "failure") {
		//           showToast(courseRes.message);
		//           return;
		//         }
		//       })
		//       .catch((err) => showToast("Something went wrong"));

		//     setFormData(res.batch[0]);
		//     setClassStartDate(res.batch[0].start_date);
		//   })
		//   .catch();

		getWorkshopClass(token, workshopId)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// setFormData(res.batch[0])
				if (res.WorkshopClasses.length > 0) {
					setType("edit");
					setClassData(res.WorkshopClasses);
					setWeeks(
						Array.from(
							{ length: res.WorkshopClasses.length },
							(_, index) => index
						)
					);
					const vimeoIdsArray = res.classes.map(
						(classItem) => classItem.vimeo_id || []
					);
				}
			})
			.catch();
	}, []);

	const handleClassDataChange = (weekNo, e) => {
		const { id, value } = e.target;

		setClassData((prevData) => {
			const updatedData = [...prevData];
			const weekIndex = weekNo;
			const updatedWeekData = {
				...updatedData[weekIndex],
				[id]: value,
				class_no: weekNo,
			};
			updatedData[weekIndex] = updatedWeekData;
			return updatedData;
		});
	};

	const handleCollapseAll = () => {
		setCollapseAll(true);

		setTimeout(() => {
			setCollapseAll(false);
		}, 1000);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleSubmit = () => {
		// if (!formData.batch_name || formData.batch_name.trim() === "") {
		//   showToast("Batch Name field is empty");
		//   return;
		// }

		// if (!formData.course_name || formData.course_name.trim() === "") {
		//   showToast("Course Name field is empty");
		//   return;
		// }

		const hasErrors = classData.some((weekData, weekIndex) => {
			if (weekData.class_no === "") {
				showToast(`Class ${weekIndex}: Week No is empty`);
				return true;
			}
			if (
				!weekData.workshop_class_title ||
				weekData.workshop_class_title.trim() === ""
			) {
				showToast(`Class ${weekIndex}: Week Title is empty`);
				return true;
			}
			if (!weekData.class_start_date) {
				showToast(`Class ${weekIndex}: Class Start Date is empty`);
				return true;
			}
			if (!weekData.class_start_time) {
				showToast(`Class ${weekIndex}: Time is empty`);
				return true;
			}

			if (!weekData.workshop_class_type) {
				showToast(`Class ${weekIndex}: Class Type is empty`);
				return true;
			}
			// if (!weekData.vimeoIds || weekData.vimeoIds.length === 0) {
			//   showToast(`Week ${weekIndex}: Vimeo Id is empty`);
			//   return true;
			// }

			if (
				weekData.workshop_class_type === "Offline" ||
				weekData.workshop_class_type === "Hybrid"
			) {
				if (!weekData.venue_details || weekData.venue_details.trim() === "") {
					showToast(`Class ${weekIndex + 1}: Venue Details is empty`);
					return true;
				}
			}
			if (
				weekData.workshop_class_type === "Online" ||
				weekData.workshop_class_type === "Hybrid"
			) {
				if (
					!weekData.zoom_meeting_id ||
					weekData.zoom_meeting_id.trim() === ""
				) {
					showToast(`Class ${weekIndex + 1}: Zoom Meeting Id is empty`);
					return true;
				}
				if (
					!weekData.security_passcode ||
					weekData.security_passcode.trim() === ""
				) {
					showToast(`Class ${weekIndex}: Security Passcode is empty`);
					return true;
				}
				if (
					!weekData.zoom_meeting_registration_link ||
					weekData.zoom_meeting_registration_link.trim() === ""
				) {
					showToast(`Class ${weekIndex}: Zoom Meeting Url is empty`);
					return true;
				}
			}
			return false;
		});

		if (!hasErrors) {
			// Proceed with your form submission logic here
			let finalClassData = {
				workshop_id: workshopId,
				workshop_classes: classData,
			};
			addWorkshopClass(token, finalClassData)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					successToast("Class added successfully.");

					navigate(`/workshop-list`);
				})
				.catch((err) => showToast("Error submitting form. Please try again."));
		}
	};

	function formatDateForInput(apiDate) {
		if (apiDate) {
			const date = new Date(apiDate);
			const formattedDate = date.toISOString().split("T")[0];

			return formattedDate;
		}
	}

	return (
		<div className="updateClass">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Update Workshop classes</div>
					<div className="updateClassContainer">
						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="startDate">
									Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="start_date"
									value={formatDateForInput(startDate)}
									disabled
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Date <span>*</span>
								</label>
								<input
									type="date"
									id="end_date"
									value={formatDateForInput(endDate)}
									disabled
								/>
							</div>
						</div>

						<div className="classDetails">
							<div className="classHeader">
								<h2>Class details batch wise</h2>

								<div>
									<p onClick={handleCollapseAll} className="collapseAllBtn">
										Collapse All
									</p>
									<div className="addClass">
										<button className="addClassBtn" onClick={addWeek}>
											+ Add class details
										</button>
									</div>
								</div>
							</div>

							{weeks &&
								weeks.length > 0 &&
								weeks.map((week, index) => (
									<WeekCard
										key={week}
										weekNo={week}
										isCollapsed={collapseAll}
										onClassDataChange={handleClassDataChange}
										classData={classData[week]}
										initialClassStartDate={classStartDate}
										// initialClassData={classData[week] || {}}
									/>
								))}
						</div>

						<div className="addClass">
							<button className="addClassBtn" onClick={addWeek}>
								+ Add class details
							</button>
						</div>

						{/* <div style={{ display: "flex", justifyContent: "center" }}>
              <div onClick={handleSubmit} className="nextBtn">
                <button>Next</button>
                <img src={ImageList.ArrowRight} alt="" />
              </div>
      
            </div> */}

						<div className="navigationBtns">
							<button className="nextButton" onClick={handleSubmit}>
								Next
								<img src={ImageList.ArrowForwardWhite} alt="" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateWorkshopClass;
