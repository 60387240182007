import getWorkshopClassAPI from '../../apis/admin/getWorkshopClassAPI';


export function getWorkshopClass(token,id){
    return new Promise(async(resolve, reject) => {
      getWorkshopClassAPI.getSingle(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}