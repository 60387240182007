import getCalendarAPI from '../../apis/admin/getCalendarAPI';

export function getCalendar(token, id){
    return new Promise(async(resolve, reject) => {
        getCalendarAPI.getSingle(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}