import React, { useState } from "react";
import "./GrantAccessModal.scss";
import { enableVideoRequest } from "../../interface/implementation/batchCRM/enableVideoRequestAPIImp";
import Cookies from "js-cookie";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { grantAccessAPI } from "../../interface/implementation/admin/grantAccessAPIImp";

function GrantAccessModal({ onClick, requestDetails }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	// const { comments, access_request_id } = requestDetails;

	const [formData, setFormData] = useState({
		access_request_id: requestDetails.access_request_id,
		access_status: "",
		comments: "",
		access_approved_date: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === "access_status" && value !== "approved") {
			setFormData({ ...formData, [name]: value, access_approved_date: "" });
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	const handleCloseClick = () => {
		onClick();
	};

	const handleSaveClick = () => {
		const { access_status, access_approved_date, comments, ...rest } = formData;

		if (access_status === "approved" && !access_approved_date) {
			showToast("Next Due Date is empty");
			return;
		}

		const updatedFormData = {
			access_request_id: requestDetails.access_request_id,
			access_status,
			comments,
			...(access_status === "approved" && { access_approved_date }),
		};

		grantAccessAPI(token, updatedFormData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Access granted");
				onClick();
			})
			.catch(() => showToast("Something went wrong"));
	};

	const showToast = (message) => {
		showToasterMessage(message);
	};

	const successToast = (message) => {
		showSuccessToast(message);
	};

	return (
		<div className="video-modal-overlay VideoApprovModal">
			<div className="video-modal-content">
				<div className="video-modal-header">
					<p className="video-title">Grant Access</p>
					<p className="closeBtn" onClick={handleCloseClick}>
						x
					</p>
				</div>
				<div className="body">
					{requestDetails.comments &&
						requestDetails.comments.length > 0 &&
						requestDetails.comments.map((request, index) => (
							<div key={index}>
								<p>
									<b>{request.commented_by}</b>{" "}
									<span style={{ opacity: "60%", fontSize: "14px" }}>
										{request.commented_on
											.split(" ")[0]
											.split("-")
											.reverse()
											.join("/")}{" "}
										{request.commented_on.split(" ")[1]}
									</span>
								</p>
								<p className="commentView">
									<b>Comment:</b>
									<br />
									{request.comment}
								</p>
							</div>
						))}

					<div className="inputContainer">
						<label htmlFor="selectedParticipantType"> Grant Access</label>
						<div className="inputCode">
							<select
								id="selectedParticipantType"
								name="access_status"
								onChange={handleInputChange}
								value={formData.access_status}
							>
								<option value="">Access Status</option>
								<option value="approved">Approved</option>
								<option value="rejected">Rejected</option>
							</select>
						</div>
					</div>
					{formData.access_status === "approved" && (
						<div className="inputContainer">
							<label htmlFor="nextDueDate"> New Due date</label>
							<div className="inputCode">
								<input
									type="date"
									id="nextDueDate"
									name="access_approved_date"
									onChange={handleInputChange}
									value={formData.access_approved_date}
								/>
							</div>
						</div>
					)}
					<div className="inputContainer">
						<label htmlFor="comment">Comment</label>
						<div className="inputCode">
							<textarea
								id="comment"
								name="comments"
								onChange={handleInputChange}
								value={formData.comments}
							></textarea>
						</div>
					</div>
				</div>
				<div className="saveButtonWrapper">
					<button className="approveBtn" onClick={handleSaveClick}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default GrantAccessModal;
