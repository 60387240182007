import React from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentCalendarList.scss";
import ImageList from "../../../utils/ImageList";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { getStudentCalendar } from "../../../interface/implementation/student/getStudentCalendarAPIImp";
import ImageModal from "../../../components/ImageModal/ImageModal";
import { useNavigate } from "react-router-dom";

function StudentCalendarList() {
	const navigate = useNavigate();
	const [calendarList, setCalendarList] = useState([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");
	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getStudentCalendar(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setCalendarList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const openModal = (imageUrl) => {
		setSelectedImage(imageUrl);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setSelectedImage("");
		setModalIsOpen(false);
	};
	return (
		<div className="studentCalendarList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">BCI Calendar List</div>

					<div className="calendarListContainer">
						<div className="calendarListTable">
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Calendar</th>
										<th>Calendar Title</th>
									</tr>
								</thead>
								<tbody>
									{calendarList &&
										calendarList.length > 0 &&
										calendarList.map((calendar, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>
													<img
														src={calendar.calendar_file}
														alt=""
														onClick={() => openModal(calendar.calendar_file)}
													/>
												</td>
												<td>{calendar.calendar_title}</td>
												{/* <td>{calendar.batch_name}</td> */}
												{/* <td className="">
                         
                          <Link to={{ pathname: `/calendar/edit/${calendar.calendar_id}`}}  className='editBtn'>
                                                    <img src={ImageList.EditIcon} alt="" />Edit
                                                </Link>
                        </td> */}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<ImageModal
				isOpen={modalIsOpen}
				closeModal={closeModal}
				imageUrl={selectedImage}
			/>
		</div>
	);
}

export default StudentCalendarList;
