import getSalespersonAPI from "../../apis/admin/getSalespersonAPI";

export function getSalesperson(token) {
  return new Promise(async (resolve, reject) => {
    getSalespersonAPI
      .getAll(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
