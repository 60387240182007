import TopHeader from '../../../components/TopHeader/TopHeader';
import SidebarNav from '../../../components/SideBar/SideBar';
import SidebarMenuItems from '../../../utils/SidebarMenuItems';
// import ImageList from '../../../utils/ImageList';
import './PaymentUpdate.scss'

function PaymentUpdate() {
  

  return (
    <div className="paymentUpdate">
       
      <div className="dashboardBody">
         
        <div className="dashboardContent">
          <div className="title">Payment Update</div>
          <div className="batchStudentListContainer">
            <div className="first row">
              <p className="firstTitle">We are using this process for payment due/Overdue update process for individual Students.</p>
              <div className="downloadBtn">
                <button>
                Update Due Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentUpdate;
