import getAproveRequestListAPI from '../../apis/admin/getAproveRequestListAPI';

export function getAproveRequestList(token){
    return new Promise(async(resolve, reject) => {
      getAproveRequestListAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}