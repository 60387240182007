import searchCRMAdminTicketsAPI from "../../apis/crmAdmin/searchCRMAdminTicketsAPI";

export function searchCRMAdminTickets(token, body) {
	return new Promise(async (resolve, reject) => {
		searchCRMAdminTicketsAPI
			.sort(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
