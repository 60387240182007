import getStudentMessagesAPI from '../apis/getStudentMessagesAPI';

export function getStudentMessages(token, body){
    return new Promise(async(resolve, reject) => {
        getStudentMessagesAPI.sort(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}