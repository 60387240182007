import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { useNavigate, useParams } from "react-router-dom";
import "./TechnicalNotes.scss";
import { getBatch } from "../../../interface/implementation/admin/getBatchAPIImp";
import Cookies from "js-cookie";
import { addAdminHomework } from "../../../interface/implementation/admin/addAdminHomeworkAPIImp";
import { getHomework } from "../../../interface/implementation/admin/getHomeworkAPIImp";
import { showSuccessToast } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { showToasterMessage } from "../../../utils/helper";
import { getCourse } from "../../../interface/implementation/admin/getCourseAPIImp";
import { addAdminTechnicalNotes } from "../../../interface/implementation/admin/addAdminTechnicalNotesAPIImp";
import { getTechnicalNotes } from "../../../interface/implementation/admin/getTechnicalNotesAPIImp";
function ClassCard({
	classNo,
	onHandleFile,
	onRemoveFile,
	fileName,
	title,
	attachments,
	attachmentLabel,
	technicalNotesUrl,
	onTitleChange,
	onAttachmentLabelChange,
	onTechnicalNotesUrlChange,
}) {
	const fileInputRef = useRef(null);

	const handleTitle = (value) => {
		// Update vimeoIds array in the parent component

		onTitleChange(value);
	};

	// const handleUrl = (value) => {
	//   // Update vimeoIds array in the parent component

	//   onUrlChange(value);
	// };

	const handleAttachmentLabel = (value) => {
		// Update vimeoIds array in the parent component

		onAttachmentLabelChange(value);
	};

	// const handleUrlLabel = (value) => {
	//   // Update vimeoIds array in the parent component

	//   onUrlLabelChange(value);
	// };

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		onHandleFile(e);
	};

	const handleRemoveFile = (indexToRemove) => {
		onRemoveFile(indexToRemove);
	};

	const handleUrlLabelChange = (value, index) => {
		const updatedUrls = [...technicalNotesUrl];
		updatedUrls[index].url_label = value;
		onTechnicalNotesUrlChange(updatedUrls);
	};

	const handleUrlChange = (value, index) => {
		const updatedUrls = [...technicalNotesUrl];
		updatedUrls[index].technical_url = value;
		onTechnicalNotesUrlChange(updatedUrls);
	};

	const handleAddUrl = () => {
		onTechnicalNotesUrlChange([
			...technicalNotesUrl,
			{ url_label: "", technical_url: "" },
		]);
	};

	return (
		<div className="homeworkContainer">
			<table>
				{/* <thead>
          <tr>
            <th>No</th>
            <th>Title</th>
            <th>Technical Notes</th>
            <th>Technical Notes URL</th>
          </tr>
        </thead> */}
				<tbody>
					<tr valign="top">
						<td>{classNo}</td>
						<td>
							<input
								style={{ width: "200px" }}
								type="text"
								id="class_title"
								placeholder="Enter title here..."
								className="inputTitle"
								value={title}
								onChange={(e) => handleTitle(e.target.value)}
							/>
						</td>
						{/* <td>
              <div className='homeworkQuestion'>
                <p className='titleHomework'>Questions</p>
                 
                  <div className='questionInputes'>
                    <input
                      type="text"
                      placeholder={`Type your title`}
                      value={title}
                      onChange={(e) => {
                        handleTitle(e.target.value)
                      }}
                    />
                  </div>
              
                <p className='addItem'
                  onClick={() => { handleAddAnotherHomework(classNo) }}
                ><span>+</span>Add another item</p>
              </div>
            </td> */}
						<td>
							<div className="uploadArea">
								<p className="titleHomework">Attach Technical notes</p>
								{/* <input
                  style={{ width: "60%", marginLeft: "20%" }}
                  type="text"
                  id="attachmentLabel"
                  placeholder="Enter file label here..."
                  className="inputTitle"
                  value={attachmentLabel}
                  onChange={(e) => handleAttachmentLabel(e.target.value)}
                /> */}
								<div className="uploadContainer">
									<input
										type="file"
										name=""
										id="fileInput"
										ref={fileInputRef}
										onChange={(e) => {
											handleFileChange(e);
										}}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={handleUploadButtonClick}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									<div className="fileNames">
										{attachments &&
											attachments.length > 0 &&
											attachments.map((file, index) => (
												<p key={index} className="fileName">
													<a
														href={file.content}
														target="_blank"
														rel="noopener noreferrer"
													>
														{" "}
														{file.filename}{" "}
													</a>
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
									</div>
									{attachments && attachments.length === 0 && (
										<div>
											<p className="uploadInfo">
												Unlimited number of files can be uploaded to this field.
												Maximum size: 64 MB. File format: doc, docx, xls, xlsx,
												pdf, jpeg, jpg, png, zip, ppt, pptx.
											</p>
										</div>
									)}
								</div>
							</div>
						</td>
						<td>
							<div className="youtubeSection">
								<p className="titleHomework">Technical Notes URL</p>
								{technicalNotesUrl.map((urlObj, index) => (
									<div className="youtubeURLContainer" key={index}>
										<div className="drag&dropContainer"></div>
										<div className="urlInput">
											<div className="inputContainer">
												<input
													type="text"
													placeholder="Enter URL label Here..."
													value={urlObj.url_label}
													onChange={(e) =>
														handleUrlLabelChange(e.target.value, index)
													}
												/>
											</div>
											<div
												className="inputContainer"
												style={{ marginTop: "10px" }}
											>
												<input
													type="text"
													placeholder="Enter URL Here..."
													value={urlObj.technical_url}
													onChange={(e) =>
														handleUrlChange(e.target.value, index)
													}
												/>
											</div>
										</div>
									</div>
								))}
							</div>
							<p className="addItem" onClick={handleAddUrl}>
								<span>+</span>Add URL
							</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function TechnicalNotes() {
	const { batchId } = useParams();
	const navigate = useNavigate();
	const [title, setTitle] = useState("");
	const [urlLabel, setUrlLabel] = useState("");
	const [url, setUrl] = useState("");
	const [attachmentLabel, setAttachmentLabel] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [technicalId, setTechnicalId] = useState(null);

	const [technicalNotesUrl, setTechnicalNotesUrl] = useState([
		{ url_label: "", technical_url: "" },
	]);

	const [fileName, setFileName] = useState("");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	// const fileInputRef = useRef(null);
	const [batchData, setBatchData] = useState([]);
	const token = Cookies.get("token");
	useEffect(() => {
		let batch_id = parseInt(batchId);
		getBatch(token, batch_id)
			.then((res) => {
				setBatchData(res.batch[0]);

				// -------------------------Course Api----------
			})
			.catch();
	}, []);

	useEffect(() => {
		let batch_id = parseInt(batchId);
		let body = `?batch_id=${batch_id}`;
		getTechnicalNotes(token, body)
			.then((res) => {
				const technicalNotesDetails =
					res.technical_notes_details[res.technical_notes_details.length - 1];
				setTitle(technicalNotesDetails.title || "");
				setTechnicalNotesUrl(
					technicalNotesDetails.technical_notes_url || [
						{ url_label: "", technical_url: "" },
					]
				);
				setAttachments(technicalNotesDetails.technical_notes_attachments || []);
				setTechnicalId(technicalNotesDetails.technical_id || null);

				// -------------------------Course Api----------
			})
			.catch();
	}, []);

	const handleNextButtonClick = () => {
		let finalData;
		if (!technicalId) {
			finalData = {
				batch_id: batchId,
				title: title,
				technical_notes_url: technicalNotesUrl,
				technical_notes_attachments: attachments,
			};
		} else {
			finalData = {
				technical_id: technicalId,
				batch_id: batchId,
				title: title,
				technical_notes_url: technicalNotesUrl,
				technical_notes_attachments: attachments,
			};
		}

		addAdminTechnicalNotes(token, finalData)
			.then((res) => {
				successToast("Technical notes added successfully.");
			})
			.catch();
		navigate("/batch-list");
	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		const newAttachments = [];

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the file
				const base64String = reader.result;
				newAttachments.push({
					filename: file.name,
					content: base64String,
				});

				// If all files have been processed, update the state
				if (i === files.length - 1) {
					setAttachments((prevAttachments) => [
						...prevAttachments,
						...newAttachments,
					]);
				}
			};

			reader.readAsDataURL(file);
		}
	};

	const handleRemoveFile = (indexToRemove) => {
		setAttachments((prevAttachments) =>
			prevAttachments.filter((_, index) => index !== indexToRemove)
		);
	};

	const handleTechnicalNotesUrlChange = (updatedUrls) => {
		setTechnicalNotesUrl(updatedUrls);
	};

	function formatDateForInput(apiDate) {
		if (apiDate) {
			const date = new Date(apiDate);
			const formattedDate = date.toISOString().split("T")[0];
			return formattedDate;
		}
	}

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	return (
		<div className="technicalNotes">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Update Technical Notes</div>

					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							<div className="formInput">
								<label htmlFor="batchName">
									Batch Name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="batchName"
										placeholder="Enter Batch name"
										disabled
										// onChange={handleInputChange}
										value={batchData.batch_name}
									/>
								</div>
							</div>

							<div className="formInput">
								<label htmlFor="course">
									Course<span>*</span>
								</label>
								<input
									type="text"
									id="course_id"
									// placeholder="Enter batch name"
									disabled
									value={batchData.course_name}
								/>
							</div>

							<div className="formInput">
								<label htmlFor="startDate">
									Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="start_date"
									value={formatDateForInput(batchData.start_date)}
									disabled
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Date <span>*</span>
								</label>
								<input
									type="date"
									id="end_date"
									value={formatDateForInput(batchData.end_date)}
									disabled
								/>
							</div>

							{/* <div className='formInput'>
                <label htmlFor="totalClasses">Total no of classes <span>*</span></label>
                <div className="inputUserName">
                  <input
                    type="text"
                    id="totalClasses"
                    placeholder="Enter total classes"
                    // onChange={handleInputChange}
                    disabled
                    value={batchData.totalClasses}
                  />
                </div>
              </div> */}
						</div>

						<ClassCard
							// classNo={1}
							// title={title}
							// urlLabel={urlLabel}
							// url={url}
							// attachmentLabel={attachmentLabel}
							// attachments={attachments}
							// onHandleFile={handleFileChange}
							// onRemoveFile={handleRemoveFile}
							// fileName={fileName}
							// onTitleChange={(value) => setTitle(value)}
							// onUrlChange={(val) => setUrl(val)}
							// onAttachmentLabelChange={(val) => setAttachmentLabel(val)}
							// onUrlLabelChange={(val) => setUrlLabel(val)}

							classNo={1}
							title={title}
							attachmentLabel={attachmentLabel}
							attachments={attachments}
							technicalNotesUrl={technicalNotesUrl}
							onHandleFile={handleFileChange}
							onRemoveFile={handleRemoveFile}
							onTitleChange={(value) => setTitle(value)}
							onAttachmentLabelChange={(val) => setAttachmentLabel(val)}
							onTechnicalNotesUrlChange={handleTechnicalNotesUrlChange}
						/>

						<div className="navigationBtns">
							<button className="nextButton" onClick={handleNextButtonClick}>
								Save
								<img src={ImageList.ArrowForwardWhite} alt="" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TechnicalNotes;
