import React, { useEffect, useState } from "react";
import "./RegistrationForm.scss";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import AddMemberIcon from "../../../assets/userAdd.svg";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { registerFormAPI } from "../../../interface/implementation/registerOneDayWorkshopStudentAPIImp";
import { getAllStatesList } from "../../../interface/implementation/getAllStateListsAPIImp";
import Cookies from "js-cookie";
import { Box, Modal } from "@mui/material";
import { apiCreateOrderAPI } from "../../../interface/implementation/createOrderAPIImp";
import { workshopLocationAPI } from "../../../interface/implementation/getWorkshopLocationIImp";

const RegistrationForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const token = Cookies.get("token");
	const [statesList, setStateLists] = useState([]);
	const [locationDetails, setLocationDetails] = useState([]);
	const [orderId, setOrderId] = useState("");
	const [paymentSessionId, setPaymentSessionId] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [modalData, setModalData] = useState([
		{
			base_price: "",
			memberXCount_price: "",
			subtotal: "",
			gst: "",
			total: "",
			registrants_id: "",
		},
	]);
	const [workshopLocationId, setWorkshopLocationId] = useState("");
	const [formData, setFormData] = useState({
		workshop_location_id: location.state?.city ? "" : workshopLocationId,
		student_name: "",
		student_email_id: "",
		student_contact_no: "",
		student_state: "",
		company_name: "",
		gst_number: "",
		company_address: "",
		pin_code: "",
		gst_invoice_required: true,
		add_members: false,
		member_details: [],
		workshop_card_id: location.state ? location.state.work_card_id : "",
	});

	useEffect(() => {
		if (location.state?.city && locationDetails) {
			const foundLocation = locationDetails.find(
				(detail) => detail.city === location.state.city
			);
			const workshopId = foundLocation?.workshop_location_id || "";
			setWorkshopLocationId(workshopId);
		}
	}, [location.state?.city, locationDetails]);

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			workshop_location_id: workshopLocationId,
		}));
	}, [workshopLocationId]);

	// Add this function to extract the state code from the GST number
	const getStateCodeFromGST = (gstNumber) => {
		return gstNumber.substring(0, 2);
	};

	// Update the handleChange function to include the GST state code logic
	const handleChange = (e) => {
		const { name, value } = e.target;

		// Check if the changed field is gst_number
		if (name === "gst_number" && value.length >= 2) {
			const gstStateCode = getStateCodeFromGST(value);
			const matchingState = statesList.find(
				(state) => state.state_code === gstStateCode
			);

			if (matchingState) {
				setFormData({
					...formData,
					[name]: value,
					student_state: matchingState.state_code,
				});
				return;
			}
		}

		if (value === "yes") {
			setFormData({
				...formData,
				[name]: true,
			});
		} else if (value === "no") {
			setFormData({
				...formData,
				[name]: false,
			});
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	const handleChangeNewMember = (e) => {
		const { name, value } = e.target;
		if (value === "yes") {
			setFormData({
				...formData,
				[name]: true,
				member_details: [
					{ student_name: "", student_email_id: "", student_contact_no: "" },
				],
			});
		}
		if (value === "no") {
			setFormData({
				...formData,
				[name]: false,
				member_details: [],
			});
		}
	};

	const handleMemberChange = (index, e) => {
		const { name, value } = e.target;
		const updatedMembers = formData.member_details.map((member, i) =>
			i === index ? { ...member, [name]: value } : member
		);
		setFormData({
			...formData,
			member_details: updatedMembers,
		});
	};

	const addMember = () => {
		setFormData({
			...formData,
			member_details: [
				...formData.member_details,
				{ student_name: "", student_email_id: "", student_contact_no: "" },
			],
		});
	};

	const removeMember = (index) => {
		setFormData({
			...formData,
			member_details: formData.member_details.filter((_, i) => i !== index),
		});
	};

	const isValidEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const {
			workshop_location_id,
			student_name,
			student_email_id,
			student_contact_no,
			student_state,
			company_name,
			gst_number,
			company_address,
			pin_code,
			gst_invoice_required,
			add_members,
			member_details,
		} = formData;

		// Validate email
		if (!isValidEmail(student_email_id)) {
			showToasterMessage("Please enter a valid email address.");
			return;
		}
		// Validate contact number
		const isValidPhoneNumber = (number) => {
			return /^\d{10}$/.test(number);
		};

		// Validate GST number with pattern
		const isValidGSTNumber = (number) => {
			const gstPattern =
				/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
			return gstPattern.test(number);
		};
		if (!workshop_location_id) {
			showToasterMessage("Please select workshop location.");
			return;
		}
		if (!student_name) {
			showToasterMessage("Please fill in the Name field.");
			return;
		}
		if (!student_email_id) {
			showToasterMessage("Please fill in the Email field.");
			return;
		}
		if (!student_contact_no) {
			showToasterMessage("Please fill in the Contact Number field.");
			return;
		}
		if (!isValidPhoneNumber(student_contact_no)) {
			showToasterMessage("Contact Number must be a 10-digit number.");
			return;
		}
		if (student_name.length < 3) {
			showToasterMessage("Name must be at least 3 characters long");
			return;
		}
		if (gst_invoice_required === true) {
			if (!gst_number) {
				showToasterMessage("Please fill in the GST Number field.");
				return;
			}
			if (!isValidGSTNumber(gst_number)) {
				showToasterMessage(
					"GST Number must be 15 characters long and follow the format: 12ABCDE3445F6G7."
				);
				return;
			}
			if (!company_name) {
				showToasterMessage("Please fill in the Company Name field.");
				return;
			}

			if (!company_address) {
				showToasterMessage("Please fill in the Company Address field.");
				return;
			}
			if (!pin_code) {
				showToasterMessage("Please fill in the Pin Code field.");
				return;
			}
		}
		if (!student_state) {
			showToasterMessage("Please fill in the State field.");
			return;
		}

		// If additional members are required, check their fields
		if (add_members === true) {
			for (let i = 0; i < member_details.length; i++) {
				if (!member_details[i].student_name) {
					showToasterMessage(`Please fill in the Member ${i + 1} Name field.`);
					return;
				}
				if (!member_details[i].student_email_id) {
					showToasterMessage(`Please fill in the Member ${i + 1} Email field.`);
					return;
				}
				if (!isValidEmail(member_details[i].student_email_id)) {
					showToasterMessage(
						`Please enter a valid email address for Member ${i + 1}.`
					);
					return;
				}
				if (!member_details[i].student_contact_no) {
					showToasterMessage(
						`Please fill in the Member ${i + 1} Phone Number field.`
					);
					return;
				}
				if (!isValidPhoneNumber(member_details[i].student_contact_no)) {
					showToasterMessage(
						`Member ${i + 1} Phone Number must be a 10-digit number.`
					);
					return;
				}
			}
		}

		// Prepare the body for API call
		const body = {
			workshop_location_id,
			student_name,
			student_email_id,
			student_contact_no,
			student_state,
			...(formData.gst_invoice_required && {
				company_name,
				gst_number,
				company_address,
				pin_code,
			}),
			gst_invoice_required: formData.gst_invoice_required,
			add_members: formData.add_members,
			member_details,
			workshop_card_id: formData.workshop_card_id,
		};

		registerFormAPI("", body)
			.then((res) => {
				if (res.detail) {
					showToasterMessage(res.detail);
					return;
				}

				if (res.status === "failure") {
					showToasterMessage(res.message);
					return;
				}

				const basePrice = res.order_amount / res.no_of_members;
				setModalData([
					{
						base_price: basePrice,
						memberXCount_price: res.order_amount - basePrice,
						subtotal: res.order_amount,
						gst:
							res.igst_amount !== null
								? res.igst_amount
								: res.cgst_amount + res.sgst_amount !== null
								? res.cgst_amount + res.sgst_amount
								: 0,
						total: res.total_workshop_fees,
						registrants_id: res.registrants_id,
					},
				]);
				showSuccessToast("Registered successfully");
				setOpenModal(true);
			})
			.catch((err) => {
				console.error(err);
				showToasterMessage("Registration failed. Please try again later.");
			});
	};

	const CallCreateOrderAPI = (r) => {
		const body = {
			registrants_id: r,
		};
		apiCreateOrderAPI(token, body)
			.then((res) => {
				loginHandler(res.order_id, res.payment_session_id);
				setOpenModal(false);
			})
			.catch((err) => {
				console.error(err);
				showToasterMessage("Registration failed. Please try again later.");
			});
	};

	const cashfree = window.Cashfree({
		mode: "production", // or sandbox
	});

	const loginHandler = (id, sessionId) => {
		let checkoutOptions = {
			paymentSessionId: sessionId,
			returnUrl: `https://bugfix.d33vtahdjdnswp.amplifyapp.com/payment-status/${id}`,
			// notifyUrl: 'https://a4d2-2406-7400-51-f62-dc09-b12e-af1e-8b83.ngrok-free.app/investor/invest/payment/status',
			redirectTarget: "_self", // optional (_self or _blank)
		};

		cashfree.checkout(checkoutOptions);
	};
	useEffect(() => {
		getAllStatesList(token)
			.then((res) => {
				if (res) {
					setStateLists(res);
				}
			})
			.catch((err) => showToasterMessage("Something went wrong"));

		const body = location.state ? location.state.work_card_id : "";

		workshopLocationAPI(token, body)
			.then((res) => {
				if (res) {
					setLocationDetails(res.workshop_location_details);
				}
			})
			.catch((err) => showToasterMessage("Something went wrong"));
	}, [token]);

	//--------------modal styles-----------------
	const containerWidth = window.innerWidth <= 768 ? "90%" : "60%";
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 600,
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		padding: "24px",
	};

	const mobileModalStyle = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 340,
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		padding: "20px",
	};

	const modalSubDetails = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		width: containerWidth,
		background: "#e1e2e3",
		padding: "0.3rem 0.5rem 0.5rem",
	};

	return (
		<div className="RegistrationContainer">
			<form onSubmit={handleSubmit}>
				<h1>Register</h1>
				<div className="InputFields locationfield">
					<label>
						Select Venue: <span>*</span>
					</label>
					<select
						name="workshop_location_id"
						value={formData.workshop_location_id}
						onChange={(e) => {
							const selectedLocationId = e.target.value;
							setWorkshopLocationId(selectedLocationId);
						}}
					>
						<option value="">Select location</option>
						{locationDetails &&
							locationDetails.map((l) => (
								<option
									key={l.workshop_location_id}
									value={l.workshop_location_id}
								>
									{l.city}
								</option>
							))}
					</select>
				</div>
				<div className="InputFields">
					<label>
						Name: <span>*</span>
					</label>
					<input
						type="text"
						name="student_name"
						value={formData.student_name}
						onChange={handleChange}
						placeholder="Enter your name"
						onInput={(e) => {
							e.target.value = e.target.value.replace(/[^a-zA-Z.\s]/g, "");
						}}
					/>
				</div>
				{formData.gst_invoice_required === true && (
					<div className="InputFields">
						<label>
							Company Name: <span>*</span>
						</label>
						<input
							type="text"
							name="company_name"
							placeholder="Enter your company name"
							value={formData.company_name}
							onChange={handleChange}
						/>
					</div>
				)}
				<div className="InputFields">
					<label>
						Email:<span>*</span>
					</label>
					<input
						type="email"
						name="student_email_id"
						placeholder="Enter your email"
						value={formData.student_email_id}
						onChange={handleChange}
					/>
				</div>
				{formData.gst_invoice_required === true && (
					<div className="InputFields">
						<label>
							GST Number: <span>*</span>
						</label>
						<input
							type="text"
							name="gst_number"
							maxLength={15}
							placeholder="Enter your GST number"
							value={formData.gst_number}
							onChange={handleChange}
						/>
					</div>
				)}
				<div className="InputFields">
					<label>
						Contact Number: <span>*</span>
					</label>
					<input
						type="text"
						maxLength={10}
						name="student_contact_no"
						value={formData.student_contact_no}
						placeholder="Enter your number"
						onChange={handleChange}
						onInput={(e) => {
							e.target.value = e.target.value.replace(/[^0-9.\s]/g, "");
						}}
					/>
				</div>
				{formData.gst_invoice_required === true && (
					<div className="InputFields">
						<label>
							Company Address: <span>*</span>
						</label>
						<input
							type="text"
							name="company_address"
							placeholder="Enter your company address"
							value={formData.company_address}
							onChange={handleChange}
						/>
					</div>
				)}
				<div className="InputFields">
					<label>
						State: <span>*</span>
					</label>
					<select
						name="student_state"
						value={formData.student_state}
						onChange={handleChange}
						disabled={formData.gst_invoice_required === true}
					>
						<option value="">Select State</option>
						{statesList &&
							statesList.map((state) => (
								<option key={state.state_code} value={state.state_code}>
									{state.state_name}
								</option>
							))}
					</select>
				</div>
				{formData.gst_invoice_required === true && (
					<div className="InputFields">
						<label>
							Pin Code: <span>*</span>
						</label>
						<input
							type="text"
							name="pin_code"
							value={formData.pin_code}
							onChange={handleChange}
							placeholder="Enter your pincode"
							onInput={(e) => {
								e.target.value = e.target.value.replace(/[^0-9.\s]/g, "");
							}}
						/>
					</div>
				)}
				<div className="InputFields optionsInputFieldsContainer">
					<label>
						Do you want to add more members?<span>*</span>
					</label>
					<div className="optionsInputFields">
						<div className="optionsContainer">
							<label>Yes</label>
							<input
								type="radio"
								name="add_members"
								value="yes"
								checked={formData.add_members === true}
								onChange={handleChangeNewMember}
							/>
						</div>
						<div className="optionsContainer">
							<label>No</label>
							<input
								type="radio"
								name="add_members"
								value="no"
								checked={formData.add_members === false}
								onChange={handleChangeNewMember}
							/>
						</div>
					</div>
				</div>
				<div className="InputFields optionsInputFieldsContainer">
					<label>
						GST Invoice Required: <span>*</span>
					</label>
					<div className="optionsInputFields">
						<div className="optionsContainer">
							<label>Yes</label>
							<input
								type="radio"
								name="gst_invoice_required"
								value="yes"
								checked={formData.gst_invoice_required === true}
								onChange={handleChange}
							/>
						</div>
						<div className="optionsContainer">
							<label>No</label>
							<input
								type="radio"
								name="gst_invoice_required"
								value="no"
								checked={formData.gst_invoice_required === false}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
				{formData.add_members === true && (
					<div className="newMemberContainer">
						{formData.member_details.map((member, index) => (
							<div key={index} className="newMember">
								<h4>Member {index + 1}</h4>
								<div className="newMemberInputFields">
									<div className="InputFields">
										<label>
											Member Name: <span>*</span>
										</label>
										<input
											type="text"
											name="student_name"
											value={member.student_name}
											placeholder="Enter your name"
											onChange={(e) => handleMemberChange(index, e)}
											onInput={(e) => {
												e.target.value = e.target.value.replace(
													/[^a-zA-Z.\s]/g,
													""
												);
											}}
										/>
									</div>
									<div className="InputFields">
										<label>
											Member Email: <span>*</span>
										</label>
										<input
											type="email"
											name="student_email_id"
											placeholder="Enter your email"
											value={member.student_email_id}
											onChange={(e) => handleMemberChange(index, e)}
										/>
									</div>
									<div className="InputFields">
										<label>
											Member Phone Number: <span>*</span>
										</label>
										<input
											type="text"
											maxLength={10}
											name="student_contact_no"
											value={member.student_contact_no}
											placeholder="Enter your number"
											onChange={(e) => handleMemberChange(index, e)}
											onInput={(e) => {
												e.target.value = e.target.value.replace(
													/[^0-9.\s]/g,
													""
												);
											}}
										/>
									</div>
								</div>

								{formData.add_members === true && (
									<div className="newMemberFunBtns">
										{index === formData.member_details.length - 1 && (
											<button
												className="newMemberAddBtn"
												type="button"
												onClick={addMember}
											>
												Add More <img src={AddMemberIcon} alt="" />
											</button>
										)}

										{formData.member_details.length > 1 && (
											<button
												className="newMemberDelBtn"
												type="button"
												onClick={() => removeMember(index)}
											>
												<img src={DeleteIcon} alt="" />
											</button>
										)}
									</div>
								)}
							</div>
						))}
					</div>
				)}

				<div className="submitBtnContainer">
					<button className="Submit" type="submit">
						Register
					</button>
				</div>
			</form>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="modal"
			>
				<Box sx={window.innerWidth <= 768 ? mobileModalStyle : style}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							position: "relative",
						}}
					>
						<button
							style={{
								position: "absolute",
								border: "none",
								right: "-1rem",
								top: "-1rem",
								backgroundColor: "transparent",
								padding: "0.3rem  0.6rem",
								borderRadius: "50%",
							}}
							onClick={() => setOpenModal(false)}
						>
							X
						</button>
						<h2 style={{ letterSpacing: "1px", textAlign: "center" }}>
							Double Your Profits WorkShop
						</h2>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								margin: "0rem 0 2rem",
								color: "#515358",
							}}
						>
							<p
								style={{
									fontSize: "1.1rem",
									letterSpacing: "0.7px",
									textAlign: "center",
								}}
							>
								You will run your business operations working one day a week.
							</p>
							<p
								style={{
									fontSize: "1.1rem",
									letterSpacing: "0.7px",
									textAlign: "center",
								}}
							>
								Your profits will double.
							</p>
						</div>
						{modalData &&
							modalData.map((m, index) => {
								return (
									<>
										<div
											key={index}
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
												width: "100%",
											}}
										>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													flexDirection: "column",
													gap: "0.5rem",
													width: "100%",
												}}
											>
												<div style={modalSubDetails}>
													<p>Base Price</p>
													<span>₹ {m.base_price}</span>
												</div>
												<div style={modalSubDetails}>
													<p>member x {formData.member_details.length}</p>
													<span>₹ {m.memberXCount_price}</span>
												</div>
												<div style={modalSubDetails}>
													<p>Subtotal</p>
													<span>₹ {m.subtotal}</span>
												</div>
												<div style={modalSubDetails}>
													<p>Tax(GST)</p>
													<span>{m.gst}</span>
												</div>
											</div>
											{containerWidth && containerWidth !== "90%" ? (
												<div>
													------------------------------------------------
												</div>
											) : (
												<div>---------------------------------------</div>
											)}
											<div
												style={{
													display: "flex",
													justifyContent: "end",
													alignItems: "center",
													gap: "1rem",
													flexDirection: "row",
													width: containerWidth,
													background: "#e1e2e3",
													padding: "0.3rem 0.5rem 0.5rem",
												}}
											>
												<p>Total:</p>
												<span>₹ {m.total}</span>
											</div>
											{containerWidth && containerWidth !== "90%" ? (
												<div>
													------------------------------------------------
												</div>
											) : (
												<div>---------------------------------------</div>
											)}
										</div>
										<button
											style={{
												backgroundColor: "#658fe3",
												border: "none",
												borderRadius: "0.3125rem",
												color: "#ffffff",
												fontSize: containerWidth !== "90%" ? "1.5rem" : "1rem",
												fontWeight: "600",
												lineHeight: "1.125rem",
												padding:
													containerWidth !== "90%"
														? "0.5rem 4rem"
														: "0.5rem 2rem",
												marginTop: "1rem",
											}}
											onClick={(e) => CallCreateOrderAPI(m.registrants_id)}
										>
											Pay now
										</button>
									</>
								);
							})}
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default RegistrationForm;
