import getCrmOverduePaymentInfoAPI from '../../apis/batchCRM/getCrmOverduePaymentInfoAPI';


export function getCrmOverduePaymentInfo(token, body){
    return new Promise(async(resolve, reject) => {
      getCrmOverduePaymentInfoAPI.sort(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}