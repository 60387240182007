import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./StudentProcess.scss";

function StudentProcess() {
	const navigate = useNavigate();
	const fileInputRef = useRef();

	const [formData, setFormData] = useState({
		studentBatchId: "",
		batchName: "",
		paymentType: "Installment",
		studentName: "",
		companyName: "",
		documentFiles: [],
		adjustRefundShiftAmount: "",
		adjustRefundShiftDate: "",
		salesPerson: "",
		adjustRefundShiftRemarks: "",
	});

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handlerSave = () => {};

	const handleBack = () => {
		navigate("/member-list");
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		const fileNames = Array.from(files).map((file) => file.name);
		setFormData((prevData) => ({
			...prevData,
			documentFiles: fileNames,
		}));
	};

	const handleRemoveFile = (index) => {
		const updatedFiles = [...formData.documentFiles];
		updatedFiles.splice(index, 1);
		setFormData((prevData) => ({
			...prevData,
			documentFiles: updatedFiles,
		}));
	};

	return (
		<div className="addStudent">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Complete Student Process</div>
					<div className="installmentInfo">
						<p>
							<b>Course Amount :</b> 120124
						</p>
						<p>
							<b>Total Due Amount :</b> 120124 <b>(Test)</b>
						</p>
					</div>
					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							<div className="topFormFields">
								<div className="formInput">
									<label htmlFor="studentBatchId">Student batch ID</label>
									<div className="inputUserName">
										<input
											type="text"
											id="studentBatchId"
											onChange={handleInputChange}
											value={"TST-Jan-2024-3"}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="batchName">Select batch name</label>
									<div className="inputUserName">
										<input
											type="text"
											id="batchName"
											onChange={handleInputChange}
											value={"Test"}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="paymentType">Payment type</label>
									<div className="inputUserName">
										<input
											type="text"
											id="paymentType"
											onChange={handleInputChange}
											value={"Installment"}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="studentName">Student name</label>
									<div className="inputUserName">
										<input
											type="text"
											id="studentName"
											onChange={handleInputChange}
											value={formData.studentName}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="companyName">Company name</label>
									<div className="inputUserName">
										<input
											type="text"
											id="companyName"
											onChange={handleInputChange}
											value={formData.companyName}
											disabled
										/>
									</div>
								</div>
							</div>

							<div className="uploadAreaWrapper">
								<div className="uploadArea">
									<p className="titleHomework">Verification Files</p>
									<div className="uploadContainer">
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
										<div className="fileNames">
											{formData.documentFiles.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
										{formData.documentFiles.length === 0 && (
											<div>
												<p className="uploadInfo">
													Unlimited number of files can be uploaded to this
													field. Maximum size: 64 MB. File format: doc, docx,
													xls, xlsx, pdf, jpeg, jpg, png, zip, ppt, pptx.
												</p>
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="midFormSection">
								<div className="formInput">
									<label htmlFor="adjustRefundShiftAmount">
										Adjust/Refund/Shift amount
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="adjustRefundShiftAmount"
											placeholder="Enter amount"
											onChange={handleInputChange}
											value={formData.adjustRefundShiftAmount}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="adjustRefundShiftDate">
										Adjust/Refund/Shift date
									</label>
									<div className="inputUserName">
										<input
											type="date"
											id="adjustRefundShiftDate"
											onChange={handleInputChange}
											value={formData.adjustRefundShiftDate}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="salesPerson">Adjust/Refund/Shift</label>
									<div className="inputUserName">
										<select
											id="salesPerson"
											onChange={handleInputChange}
											value={formData.salesPerson}
										>
											<option value="">Select Sales Person</option>
											<option value="salesPerson1">Sales Person 1</option>
											<option value="salesPerson2">Sales Person 2</option>
										</select>
									</div>
								</div>
							</div>
							<div className="uploadAreaWrapper">
								<div className="uploadArea">
									<p className="titleHomework">Adjust/Refund/Shift Files</p>
									<div className="uploadContainer">
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
										<div className="fileNames">
											{formData.documentFiles.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
										{formData.documentFiles.length === 0 && (
											<div>
												<p className="uploadInfo">
													Unlimited number of files can be uploaded to this
													field. Maximum size: 64 MB. File format: doc, docx,
													xls, xlsx, pdf, jpeg, jpg, png, zip, ppt, pptx.
												</p>
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="textboxWrapper">
								<div className="formInput">
									<label htmlFor="adjustRefundShiftRemarks">
										Adjust/Refund/Shift remarks
									</label>
									<div className="inputUserName">
										<textarea
											id="adjustRefundShiftRemarks"
											placeholder="Enter remarks"
											onChange={handleInputChange}
											value={formData.adjustRefundShiftRemarks}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="footerNavBtns">
							<button className="backBtn" onClick={handleBack}>
								<img
									src={ImageList.GoBackBlue}
									alt=""
									className="forwardArrow"
								/>{" "}
								Previous
							</button>
							<button className="nextBtn" onClick={handlerSave}>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentProcess;
