import React from "react";
import "./FeaturedInPage.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FeaturedIn } from "../../../assets/Data/Constants";
import rightArrow from "../../../assets/arrow-right.svg";
import leftArrow from "../../../assets/arrow-left.svg";

function ArrowRight(props) {
	const { className, onClick } = props;
	return <img src={rightArrow} className={className} onClick={onClick} />;
}
function ArrowLeft(props) {
	const { className, onClick } = props;
	return <img src={leftArrow} className={className} onClick={onClick} />;
}

const FeaturedInPage = () => {
	var settings = {
		autoplay: true,
		arrows: true,
		initialSlide: 0,
		swipeToSlide: true,
		dots: true,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplaySpeed: 2000,
		pauseOnHover: true,
		nextArrow: <ArrowRight />,
		prevArrow: <ArrowLeft />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
				},
			},
		],
	};
	return (
		<div className="FeaturedInPageContainer slider-container">
			<h2>Featured in</h2>
			<Slider {...settings}>
				{FeaturedIn &&
					FeaturedIn.map((f, index) => (
						<div key={index} className="Featureditem">
							<img src={f.img} alt="" />
						</div>
					))}
			</Slider>
		</div>
	);
};

export default FeaturedInPage;
