import updateHomeworkAPI from '../../apis/student/updateHomeworkAPI';


export function updateHomework(token,body){
    return new Promise(async(resolve, reject) => {
        updateHomeworkAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}