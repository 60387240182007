import { ApiCore } from "../../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: false,
    postEncoded: false,
    post: false,
    put: true,
    delete: false,
    sort: false,
    url: `api/admin/students/assign-crm`,
    version: ''
});

export default apiTasks;