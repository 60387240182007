import React, { useEffect, useState } from "react";
import "./Login.scss";
import ImageList from "../../utils/ImageList";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { showToasterMessage } from "../../utils/helper";
import { signIn } from "../../interface/implementation/loginAPIImp";
import Cookies from "js-cookie";

function Login() {
	const navigate = useNavigate();
	useEffect(() => {
		const expiry = Cookies.get("expiry") ? Cookies.get("expiry") : " ";
		if (expiry === "true") {
			showToasterMessage("Token expired, please login again");
		}
		localStorage.removeItem("selectedRole");
	}, []);
	const [credentials, setCredentials] = useState({
		userEmail: "",
		userPass: "",
	});
	const [showPassword, setShowPassword] = useState(false);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setCredentials((prevCredentials) => ({
			...prevCredentials,
			[id]: value,
		}));
	};

	const handleTogglePassword = () => {
		setShowPassword((value) => !value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			loginHandler();
		}
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const loginHandler = () => {
		if (credentials.userEmail && credentials.userPass) {
			let body = {
				username: credentials.userEmail,
				password: credentials.userPass,
			};

			signIn(body)
				.then((res) => {
					if (res.status === "failure") {
						showToast(res.message);
					} else {
						Cookies.set("token", res.access_token, { expires: 30 });
						localStorage.setItem("userRoles", JSON.stringify(res.roles));
						localStorage.setItem("userName", res.username);
						localStorage.setItem("userID", res.user_id);
						Cookies.remove("expiry");
						navigate("/dashboard");
					}
				})
				.catch((err) => {
					showToast(err.detail);
				});
		} else {
			showToast("One or more fields are empty/wrong");
		}
	};

	return (
		<div
			className="login"
			style={{ backgroundImage: `url(${ImageList.LoginImage})` }}
		>
			<div className="loginContainer">
				<img src={ImageList.BCILogo} alt="BCI Logo" className="logo" />
				<div className="loginForm">
					<div>
						<label htmlFor="userEmail">
							Username <span>*</span>
						</label>
						<div className="inputUserName">
							<img
								src={ImageList.EmailIcon}
								alt="eye-icon"
								className="emailIcon"
							/>
							<input
								type="text"
								id="userEmail"
								placeholder="Enter your BCI CRM username"
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								value={credentials.userEmail}
							/>
						</div>
					</div>
					<div>
						<label htmlFor="userPass">
							Password <span>*</span>
						</label>
						<div className="inputPass">
							<img
								src={ImageList.Password}
								alt="eye-icon"
								className="passIcon"
							/>
							<input
								type={showPassword ? "text" : "password"}
								id="userPass"
								placeholder="Password"
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								value={credentials.userPass}
							/>
							<img
								onClick={handleTogglePassword}
								src={ImageList.EyeClose}
								alt="eye-icon"
								className="eyeIcon"
							/>
						</div>
					</div>
				</div>
				<p className="bottomText">
					Don't attempt login over 5 times. If you have trouble logging in,
					please reset your password.
				</p>
				<Link to={"/forgot-password"} className="resetPass">
					Reset your password?
				</Link>
				<button type="submit" className="loginBtn" onClick={loginHandler}>
					Log in{" "}
					<img src={ImageList.ArrowForward} alt="" className="forwardArrow" />
				</button>
			</div>
			<ToastContainer limit={1} />
		</div>
	);
}

export default Login;
