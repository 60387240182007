import downloadPaymentAPI from "../../apis/admin/downloadPaymentAPI";

export function downloadPayment(token) {
  return new Promise(async (resolve, reject) => {
    downloadPaymentAPI
      .download(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
