import React, { useState, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

import "./UpdateStudentCSV.scss";
import { uploadAttendanceCSV } from "../../../interface/implementation/admin/uploadAttendanceCSVAPIImp";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";

import Cookies from "js-cookie";
import { downloadAttendanceCSV } from "../../../interface/implementation/admin/downloadAttendanceAPIImp";
import SampleStudentFile from "../../../assets/files/student_template.csv";

import { useLocation, useNavigate } from "react-router-dom";
import { uploadUpdateStudentCSV } from "../../../interface/implementation/admin/uploadUpdateStudentCSVAPIImp";
import { downloadStudents } from "../../../interface/implementation/admin/downloadStudentsAPIImp";

function UpdateStudentCSV() {
	const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const batchId = location.state.batchId;
	const fileInputRef = useRef(null);
	const [failureModal, setFailureModal] = useState(false);
	const [failedUploadData, setFailedUploadData] = useState();
	const [formData, setFormData] = useState({
		documentFiles: [],
	});
	const token = Cookies.get("token");
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const batchName = location.state.batchName;
	const startDate = location.state.startDate.split("-").reverse().join("/");
	const endDate = location.state.endDate.split("-").reverse().join("/");

	const handleFileUpload = async () => {
		const fileInput = fileInputRef.current;
		if (fileInput.files.length > 0) {
			const formData = new FormData();
			formData.append("csv_file", fileInput.files[0]);
			formData.append("batch_id", batchId);
			setIsSpinnerLoading(true);
			try {
				const res = await uploadUpdateStudentCSV(token, formData);
				if (
					(res.details || res.detail) &&
					(res.details !== "" || res.detail !== "")
				) {
					setIsSpinnerLoading(false);
					if (res.details) {
						setFailedUploadData(res.details);
					}
					if (res.detail) {
						setFailedUploadData(res.detail);
					}
					setFailureModal(true);
					return;
				}
				if (res.status === "success") {
					const failedStatuses = res.upload_status.filter(
						(status) =>
							status.includes("not uploaded") ||
							status.includes("not valid") ||
							status.includes("already present")
					);
					if (failedStatuses.length > 0) {
						setFailedUploadData({
							message: `${res.message}, but.. `,
							upload_status: failedStatuses,
						});
						successToast("File uploaded.");
						setFailureModal(true);
					} else {
						successToast("File uploaded.");
						navigate("/student-batch-list");
					}

					setIsSpinnerLoading(false);
				}
				if (res.status === "failure") {
					showToast("Error uploading file");
					setIsSpinnerLoading(false);
					setFailedUploadData({
						message: res.message,
						upload_status: res.upload_status,
					});
					setFailureModal(true);
				}
			} catch (error) {
				showToast("Error uploading file");
				console.error("Error uploading file:", error);
			} finally {
				setIsSpinnerLoading(false);
			}
		} else {
			console.warn("No file selected for upload.");
			showToast("No file selected for upload.");
		}
	};

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files).map((file) => file.name);
		setFormData((prevData) => ({
			...prevData,
			documentFiles: [...prevData.documentFiles, ...files],
		}));
	};

	const handleRemoveFile = (index) => {
		setFormData((prevData) => {
			const updatedFiles = [...prevData.documentFiles];
			updatedFiles.splice(index, 1);
			return {
				...prevData,
				documentFiles: updatedFiles,
			};
		});
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	// const handleDownloadStudent = async () => {
	//   await downloadStudents(token)
	//     .then((res) => {
	//       if (res.detail === "Signature has expired.") {
	//         navigate("/");
	//         showToast('Token has expired. Please log in again.');
	//         return;
	//     }
	//     if (res.status === "failure") {
	//         showToast(res.message);
	//         return;
	//     }
	//       const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
	//       const filename = `Participants-${currentDate}.csv`;
	//       const blob = new Blob([res], { type: "text/csv" });
	//       const url = window.URL.createObjectURL(blob);
	//       const a = document.createElement("a");
	//       a.href = url;
	//       a.download = filename;
	//       document.body.appendChild(a);
	//       a.click();
	//       document.body.removeChild(a);
	//       window.URL.revokeObjectURL(url);

	//     })
	//     .catch((err) => showToast("Something went wrong"));
	// };
	return (
		<div className="updateStudentCSV">
			<ToastContainer limit={1} />
			{failureModal && (
				<div className="modal-overlay">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						{failedUploadData && (
							<h1 className="reasonTitle">{failedUploadData.message}</h1>
						)}
						<div className="statusContainer">
							<ul className="orderedList">
								{failedUploadData &&
									failedUploadData.upload_status?.map((d, index) => (
										<li key={index}>{d}</li>
									))}
							</ul>
						</div>
						<button onClick={() => setFailureModal(false)} className="closeBtn">
							X
						</button>
					</div>
				</div>
			)}
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						{batchName} {`(${startDate} - ${endDate})`} :: CSV Import Batch
						Student
					</div>
					<div className="batchStudentListContainer">
						<div className="first row">
							<p className="bullet">1:</p>
							<p className="firstTitle">Download “Template CSV File”</p>
							<div className="downloadBtn">
								<a
									href="https://bcitemplates.s3.ap-south-1.amazonaws.com/new_student_template.csv" // Replace 'yourFileUrl' with the actual URL of your CSV file
									target="_blank" // Opens the link in a new tab/window
									download="student_template.csv" // Specifies the name of the downloaded file
								>
									<img src={ImageList.DownloadIcon} alt="" />
									Download
								</a>
							</div>
						</div>
						<div className="second row">
							<p className="bullet">2:</p>
							<div className="content">
								<h6>Add or Edit info CSV data according to the below fields</h6>
								<p>
									<b>Required fields: </b>Date/Timing, Offer, Discount, Company
									Name, Company Billing Name, Participant Type, Old Participant
									Status, Participant Status, Payment Category, Member Type,
									Verification DOC, Verification DOC No, Verification Status,
									Primary Email ID, Company ID, Participant Name, Participant
									Mail, Phone Number, Alternate Phone Number, Alternate Email
									ID, Accountant Name, Accountant Contact No, Accountant Email
									ID, Company GST Number, Address, Address to send courier, TAN
									number, City, State, Referred by, Sales Person, Helped By,
									Input, CRM Admin, Nature of Your Business, Product, Payment
									Type, Do you want to Add Member, Add Number of Members,
									Turnover
								</p>
							</div>
						</div>
						<div className="third row">
							<p className="bullet">3:</p>
							<div className="studentDetails" style={{ overflowX: "auto" }}>
								<table className="studentTable">
									<thead>
										<tr>
											<th>Date/Timing</th>
											<th>Offer</th>
											<th>Discount</th>
											<th>Company Name</th>
											<th>Company Billing Name</th>
											<th>Participant Type</th>
											<th>Old Participant Status</th>
											<th>Payment Category</th>
											<th>Member Type</th>
											<th>Verification DOC</th>
											<th>Verification DOC No</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Date/Timing</td>
											<td>Offer</td>
											<td>Discount</td>
											<td>Company Name</td>
											<td>Company Billing Name</td>
											<td>Participant Type</td>
											<td>Old Participant Status</td>
											<td>Payment Category</td>
											<td>Member Type</td>
											<td>Verification DOC</td>
											<td>Verification DOC No</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						{/* <div className="first row">
              <p className="bullet">4:</p>
              <p className="firstTitle">Last uploaded file:</p>
              <div className="downloadBtn">
                <button className="white" onClick={handleDownloadStudent}>
                  <img src={ImageList.DownloadIconBlue} alt="" />
                  Download
                </button>
              </div>
            </div> */}
						<div className="fourth row">
							<p className="bullet">4:</p>
							<div className="uploadContent">
								<p className="title">
									Note: *First create Primary Participants CSV, then secondary
									Participants CSV.
								</p>
								<div className="uploadArea">
									<p className="titleHomework">Upload CSV File *</p>
									<div className="uploadContainer">
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
										<div className="fileNames">
											{formData.documentFiles.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
										{formData.documentFiles.length === 0 && (
											<div>
												<p className="uploadInfo">No file chosen</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<button onClick={handleFileUpload} className="proceedBtn">
								Proceed
							</button>
							{isSpinnerLoading && <LoadingSpinner isLoading={true} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateStudentCSV;
