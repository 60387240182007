import AlokPrakeh from "../../assets/AlokParekh.png";
import AlokPrakehLogo from "../../assets/AlokParekhLogo.png";
import DarshanShah from "../../assets/DarshanShah.png";
import DarshanShahLogo from "../../assets/DarshanShahLogo.png";
import SumitMaini from "../../assets/SumitMaini.png";
import SumitMainiLogo from "../../assets/SumitMainiLogo.png";
import SurinderPahwa from "../../assets/SurinderPahwa.png";
import SurinderPahwaLogo from "../../assets/SurinderPahwaLogo.png";
import SauravBafna from "../../assets/SauravBafna.png";
import SauravBafnaLogo from "../../assets/SauravBafnaLogo.png";
import AmanTalla from "../../assets/AmanTalla.png";
import AmanTallaLogo from "../../assets/AmanTallaLogo.png";
import RajivManglani from "../../assets/RajivManglani.png";
import RajivManglaniLogo from "../../assets/RajivManglaniLogo.png";
import TirumalKumar from "../../assets/TirumalKumar.png";
import TirumalKumarLogo from "../../assets/TirumalKumarLogo.png";
import MehulPatodia from "../../assets/MehulPatodia.png";
import MehulPatodiaLogo from "../../assets/MehulPatodiaLogo.png";
import SachinTalwar from "../../assets/SachinTalwar.png";
import SachinTalwarLogo from "../../assets/SachinTalwarLogo.png";
import NitinChopra from "../../assets/NitinChopra.png";
import NitinChopraLogo from "../../assets/NitinChopraLogo.png";
import SatyenJain from "../../assets/SatyenJain.png";
import SatyenJainLogo from "../../assets/SatyenJainLogo.png";
import AboutImg from "../../assets/Rahul-Jain-About.svg";
import NewsIndia from "../../assets/news-one-india.jpeg";
import PunjabKeshri from "../../assets/punjab-kesari.jpeg";
import LatestLY from "../../assets/latestly.jpeg";
import ZeeMedia from "../../assets/zeemedia.jpeg";
import ET from "../../assets/et.jpeg";
import DT from "../../assets/delhi-times.jpeg";
import DB from "../../assets/dainik.jpeg";
import ThePrint from "../../assets/theprint.jpeg";
import AajTak from "../../assets/aajtak.jpeg";
import OL from "../../assets/outlook.jpeg";
import WP1 from "../../assets/wp1.svg";
import WP2 from "../../assets/wp2.svg";
import WP3 from "../../assets/wp3.svg";
import WP4 from "../../assets/wp4.svg";
import WP5 from "../../assets/wp5.svg";
import WP6 from "../../assets/wp6.svg";
import WP7 from "../../assets/wp7.svg";
import WP8 from "../../assets/wp8.svg";
import WP9 from "../../assets/wp9.svg";
import WP10 from "../../assets/wp10.svg";
import WP11 from "../../assets/wp11.svg";
import WP12 from "../../assets/wp12.svg";
import WP13 from "../../assets/wp13.svg";
import WP14 from "../../assets/wp14.svg";
import WP15 from "../../assets/wp15.svg";
import WP16 from "../../assets/wp16.svg";
import WP17 from "../../assets/wp17.svg";
import WP18 from "../../assets/wp18.svg";
import WP19 from "../../assets/wp19.svg";

export const Alumni = [
	{
		name: "Alok Parekh",
		dp: AlokPrakeh,
		company: "National Polyplast India Pvt. Ltd.",
		position: "Manufacturer - OEM",
		logo: AlokPrakehLogo,
		reviews: [
			"Went from Losses to a record Profit Year. ",
			"Doubled his capacity from 30K to 55K tonne ",
			"Working 1.5 Days a Week",
			"Corporate Clients handled by Staff",
		],
	},
	{
		name: "Darshan Shah",
		dp: DarshanShah,
		company: "Harkesh Rubber LLP",
		position: "Manufacturer - OEM",
		logo: DarshanShahLogo,
		reviews: [
			"Sales handled mostly by staff only looks at key clients.",
			"From about 4 Cr to 21 Cr.",
			"Grew GP% to 60% in a commodity ( Golden Circle)",
		],
	},
	{
		name: "Sumit Maini",
		dp: SumitMaini,
		company: "Weston Westway Electronics.Ltd",
		position: "Manufacturer - OEM",
		logo: SumitMainiLogo,
		reviews: [
			"Went from 30 Cr to 400Cr",
			"Working just One Day a Week in Operations",
		],
	},
	{
		name: "Surinder Pahwa",
		dp: SurinderPahwa,
		company: "Pahwa Buildcon Private Limited",
		position: "Builder",
		logo: SurinderPahwaLogo,
		reviews: [
			"Before the Course, they were doing 25-28 Houses (Kothis), working 7 days a week.",
			"After Course does 50-54 Houses,",
			"Earlier they took 18-19 months each project,",
			"Now In 12-13 months each. ",
			"That too on Auto-Pilot.",
		],
	},
	{
		name: "Saurav Bafna",
		dp: SauravBafna,
		company: "Oswal Properties Pvt Ltd",
		position: "Builder",
		logo: SauravBafnaLogo,
		reviews: [
			"Before the course they did 2 Lakh Sq Ft of builder projects, working 6 days a week, ",
			"After the Course they do 6 Lakhs Sq Ft,",
			"targeted 25 Lakh Sq Ft.",
		],
	},
	{
		name: "Aman Talla",
		dp: AmanTalla,
		company: "Talla Jewellers Private Limited",
		position: "Retail & Trading",
		logo: AmanTallaLogo,
		reviews: [
			"Earlier was attending to all Clients himself, ",
			"Now on Auto-Pilot, doesn’t attend to Clients",
			"They have Doubled Profits.",
			"Opened Another Store.",
		],
	},
	{
		name: "Rajiv Manglani",
		dp: RajivManglani,
		company: "Meena Bazaar ",
		position: "Retailer",
		logo: RajivManglaniLogo,
		reviews: [
			"When they started with us they were at 10 stores, now it’s 61 stores",
			"Opening 1 new store every 37 days, on Auto-Pilot.",
		],
	},
	{
		name: "Tirumal Kumar",
		dp: TirumalKumar,
		company: "Sridurga Retail Pvt Ltd",
		position: "Garments Retail",
		logo: TirumalKumarLogo,
		reviews: [
			"Went from Debts to Profit",
			"Rotation up from 2.5 to 5 turns",
			"GP- hiked price double",
			"Sales Auto-Pilot",
		],
	},
	{
		name: "Mehul Patodia",
		dp: MehulPatodia,
		company: "Fabcars Pvt. Ltd.",
		position: "Services",
		logo: MehulPatodiaLogo,
		reviews: [
			"Expanded Profits by 3.5x in 20 Months",
			"Services started in London & Dubai",
			"Business is on Auto-Pilot",
		],
	},
	{
		name: "Sachin Talwar",
		dp: SachinTalwar,
		company: "WOW Events",
		position: "Services",
		logo: SachinTalwarLogo,
		reviews: [
			"Profits more than Double",
			"Does not even go for Events",
			"Debts are down by 50%",
		],
	},
	{
		name: "Nitin Chopra",
		dp: NitinChopra,
		company: "Handa & Chopra",
		position: "Services",
		logo: NitinChopraLogo,
		reviews: [
			"Had a team of 2 People and used to do Everything",
			"From 4-5 Projects, today does over 35-40 Projects",
			"Size of the Projects vary from 2000 ft2 to 10000 ft2",
			"Now working only 2.5 Days a Week",
		],
	},
	{
		name: "Satyen Jain",
		dp: SatyenJain,
		company: "Pride Hotels & Resorts",
		position: "Hospitality Services",
		logo: SatyenJainLogo,
		reviews: [
			"Went from 15 Hotels to 32 Hotels ",
			"Opening 20 more Hotels next 1.5 years",
		],
	},
];

export const About = [
	{
		name: "Rahul Jain",
		bio: "is the pioneer who got the concept of Business Coaching to India, 20 years ago and the Founder Director of Business Coaching India.",
		edu: "A professional (Engg & M.B.A) with over 29 years’ hands-on experience, with blue chip companies such as TATAMOTORS and then as a serial entrepreneur.",
		img: AboutImg,
		exp: "Over the last 20 years, he has coached more than 21,000 business owners, providing them the following 2 Results:",
		points: [
			{
				p: "RUN YOUR BUSINESS BY WORKING JUST ONE DAY FROM OFFICE",
			},
			{
				p: "DOUBLE YOUR PROFITS EVERY YEAR.",
			},
		],
		BusinessDesc: [
			{
				desc: "The business he has coached are already operating across industries like Manufacturing, IT, Distribution, Retail, Education, Architecture, Hospitality, Biotechnology, Travel, Import & Exports, Event Management and other Service Providers.",
			},
			{
				desc: "Rahul Jain has been taking care of businesses as well as the families of business owners by providing very powerful and life altering sessions of Manifestation & Law of Attraction.",
			},
		],
	},
];

export const Testimonial = [
	{
		URL: "https://www.youtube.com/embed/rGX34Vgyog0",
		title: "Rashu Chander",
		subtitle: "Adequate Steel Fabricators",
	},
	{
		URL: "https://www.youtube.com/embed/k8768oHRd-U",
		title: "Ravinder Goel",
		subtitle: "Manufacturing & Retails",
	},
	{
		URL: "https://www.youtube.com/embed/_8cPy7ZEeEc",
		title: "Ankur Kedia",
		subtitle: "Trader-Wholesaler/Distributoe-Kedia Pipes",
	},
	{
		URL: "https://www.youtube.com/embed/akqWqOkH0R0",
		title: "Utkasrh Ahuja",
		subtitle: "Retail & Distribution (Raro Hoouse Of Fashion)",
	},
	{
		URL: "https://www.youtube.com/embed/7KuhoMEzJAk",
		title: "Kul Bhushan Gupta",
		subtitle: "Services (KBG Engineers)",
	},
	{
		URL: "https://www.youtube.com/embed/XZA86At52Wc",
		title: "CA Sajal Goyal",
		subtitle: "Services(CA)-SHA & Associates",
	},
	{
		URL: "https://www.youtube.com/embed/fvkB8sVh_-c",
		title: "Sumit Maini",
		subtitle: "West Way Electronics",
	},
	{
		URL: "https://www.youtube.com/embed/yWjuB5-NRv8",
		title: "Vineet Channa",
		subtitle: "Projects",
	},
];

export const BusinessesStats = [
	{
		count: 21000,
		term: "+",
		desc: "businesses transformed",
	},
	{
		count: 80000,
		term: "+ Crores",
		desc: "Combined turnover of all these companies",
	},
	{
		count: 50000,
		term: "+ Crores",
		desc: "Profits generated by these companies after attending our programs",
	},
	{
		count: 110,
		term: "+",
		desc: "Cities with our Participants",
	},
];

export const FeaturedIn = [
	{
		img: NewsIndia,
	},
	{
		img: PunjabKeshri,
	},
	{
		img: LatestLY,
	},
	{
		img: ZeeMedia,
	},
	{
		img: ET,
	},
	{
		img: DT,
	},
	{
		img: DB,
	},
	{
		img: ThePrint,
	},
	{
		img: AajTak,
	},
	{
		img: OL,
	},
];

export const BusinessOwnerSolutionsData = {
	title: "Are you A Business Owner who is struggling with…",
	list: [
		"Are you Working 24* 7  constantly chasing employees",
		"Sales are not growing",
		"Sales team does not achieve targets",
		"Competition is bad with Discount & Credit",
		"Margins are shrinking,  Salary Costs are high",
		"Clients don’t pay, Cash flow problems & Loans are growing ",
		"Earlier Profits have vanished, feel like changing business",
		"You don’t  find good people",
		"Company has become a training center; people get trained and leave",
		"Increment is a big struggle, seniors blackmail you",
		"You just have to keep following up",
		"Most of the work you have to do yourself",
		"You leave office & work stops, you feel you are a ONE MAN ARMY",
		"There are emergencies and fire fighting all day",
		"Kids are growing up & you feel like your best years are passing you by",
		"Business has become a struggle &  ",
		"Even after working 24*7 business is not growing ",
		"You wonder how long will you have to Struggle with Life, Money and Business.",
	],
	soluiton:
		"The solution: Double your Profits Webinar (One Day Webinar) by Rahul Jain",
	desc: "Start putting your business on Auto-Pilot (Work One Day a week) & Double your Profits. Over 21,000 Business Owners have used these systems for the last 20 Years to Double their Profits & Auto-Pilot their Business. ",
};
export const WorkshopData = {
	subTitlr: "Run Your Business Operations",
	Maintitle: " working 1 day a week!!!",
	cardsData: [
		"Get your team to deliver results, without having to chase them",
		"Set up systems, so that your business works with or without you",
		"Auto-Pilot all the Regular Work in Business",
		"Get your team to take responsibility and start performing",
		"Build an efficient Dream Team for structured management ",
		"3 Pillars of Financial Mastery in Business.",
		"Finish all your work by Lunch, even without Auto-Pilot",
		"Powerful Work Delegation System ",
		"Manage your Time and all the Tasks you have to do",
		"Total Peace of Mind.  Time with your Family, Friends.",
		"Eventually Double your Profits, while  RUNNING YOUR BUSINESS OPS WORKING ONE DAY A WEEK.",
	],
};
export const HowToPageData = {
	subtitle: "After this workshop, you will know how to",
	mainTitle: "Double Your Profits!!!",
	list: [
		"Create a Regular supply of new leads.",
		"Discover the hidden avenues of sales generation ",
		"Double your Profits without a sales team.",
		"11 amazing Strategies to Double Your Profits, with immediate effect",
		"Discounts & Credit no longer trouble you",
		"3 steps Retirement Passive Income Strategy, making you independent of your Business Income.",
		"Create your Profit Plan for this Year",
		"Create & Follow Your 10 Year Business Plan",
	],
};
export const WpData = {
	maintitle: "You are not the first",
	subtitle: "to attend this Workshop",
	desc: "23,000+ business & entrepreneur owners have taken the workshop and have doubled their profit",
	imgs: [
		WP1,
		WP2,
		WP3,
		WP4,
		WP5,
		WP6,
		WP7,
		WP8,
		WP9,
		WP10,
		WP11,
		WP12,
		WP13,
		WP14,
		WP15,
		WP16,
		WP17,
		WP18,
		WP19,
	],
};
