import React, { useState } from "react";
import "./RequestAccessModal.scss";
import { enableVideoRequest } from "../../interface/implementation/batchCRM/enableVideoRequestAPIImp";
import Cookies from "js-cookie";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { grantAccessAPI } from "../../interface/implementation/admin/grantAccessAPIImp";
import { accessRequest } from "../../interface/implementation/student/accessRequestAPIImp";

function RequestAccessModal({ onClick, requestDetails }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [comments, setComments] = useState("");

	// const [status, setStatus] = useState("")
	const handleInputChange = (e) => {
		setComments(e.target.value);
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleCloseClick = () => {
		onClick();
	};
	const handleSaveClick = () => {
		if (!comments || comments.length === 0) {
			showToast("Comment is empty");
			return;
		}
		let formData = {
			access_request_id: requestDetails.access_request_id,
			student_id: requestDetails.student_id,
			batch_id: requestDetails.batch_id,
			comments: comments,
			payment_due_date: requestDetails.payment_due_date,
		};
		accessRequest(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Access requested");
				// navigate("/course-list")
				onClick();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="video-modal-overlay VideoApprovModal">
			<div className="video-modal-content">
				<div className="video-modal-header">
					<p className="video-title">Request Access</p>
					<p className="closeBtn" onClick={handleCloseClick}>
						x
					</p>
				</div>
				<div className="body">
					<div className="inputContainer">
						<label htmlFor="comment">Comment</label>
						<div className="inputCode">
							<textarea
								id="comment"
								onChange={(e) => handleInputChange(e)}
								value={comments}
							></textarea>
						</div>
					</div>
				</div>
				<div className="saveButtonWrapper">
					<button className="approveBtn" onClick={handleSaveClick}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default RequestAccessModal;
