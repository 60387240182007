import getCRMBatchesAPI from '../apis/getCRMBatchesAPI';


export function getCRMBatches(token){
    return new Promise(async(resolve, reject) => {
        getCRMBatchesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}