/** @format */

import React, { useEffect, useState } from "react";
import TopHeader from "../../components/TopHeader/TopHeader";
import SidebarNav from "../../components/SideBar/SideBar";
import SidebarMenuItems from "../../utils/SidebarMenuItems";
import "./Dashboard.scss";
import ImageList from "../../utils/ImageList";
import { useNavigate } from "react-router-dom";
import { logout } from "../../interface/implementation/logoutAPIImp";
import { showSuccessToast, showToasterMessage } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import TermsModal from "../../components/TermsModal/TermsModal";
import { userDetails } from "../../interface/implementation/userDetailsAPIImp";
import { getTermsStatus } from "../../interface/implementation/getTermsStatusAPIImp";
import { getTerms } from "../../interface/implementation/getTermsAPIImp";

import { getStudentBatchDetails } from "../../interface/implementation/student/getStudentBatchDetailsAPIImp";
import { getStudentClassDetails } from "../../interface/implementation/student/getStudentClassDetailsAPIImp";
import { getStudentCalendarDetails } from "../../interface/implementation/student/getStudentCalendarDetailsAPIImp";
import { getUnreadMsg } from "../../interface/implementation/student/getUnreadMsgAPIImp";
import UnreadMsgModal from "../../components/UnreadMsgModal/UnreadMsgModal";
import ImageModal from "../../components/ImageModal/ImageModal";
import { getUpcomingCourses } from "../../interface/implementation/student/getUpcomingCoursesAPIImp";
import { getStudentTechnicalNotes } from "../../interface/implementation/student/getStudentTechnicalNotesAPIImp";

import Button from "../../components/Button/Button";
import { getStudentAbsent } from "../../interface/implementation/student/getStudentAbsentAPIImp";
import { submitAbsentReason } from "../../interface/implementation/student/submitAbsentReasonAPIImp";
import { checkRolePermission } from "../../interface/implementation/checkRolePermissionAPIImp";

function Dashboard() {
	const [userRoles, setUserRoles] = useState([]);
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [terms, setTerms] = useState([]);
	const [terms_conditions_id, setTermsId] = useState(0);
	const [count, setCount] = useState(0);
	const [upcomingDetails, setUpcomingDetails] = useState([]);
	const [technicalNotes, setTechnicalNotes] = useState([]);

	// For Student
	const [batchDetails, setBatchDetails] = useState([]);
	const [batchName, setBatchName] = useState("");
	const [crmName, setCrmName] = useState("");
	const [crmNumber, setCrmNumber] = useState("");
	const [showAbsentReasonModal, setShowAbsentReasonModal] = useState();

	const [classDetails, setClassDetails] = useState([]);
	const [calendarDetails, setCalendarDetails] = useState([]);
	const [selectedImage, setSelectedImage] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const userID = localStorage.getItem("userID");

	const [selectedRole, setSelectedRole] = useState("");

	// for student dashboard
	useEffect(() => {
		if (selectedRole && selectedRole === "1") {
			getStudentBatchDetails(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setBatchDetails(res);
					setBatchName(res[0]?.batch_name || "");
					setCrmName(res[0].crm_data?.batch_crm_name || "");
					setCrmNumber(res[0].crm_data?.batch_crm_phone_number || "");
				})
				.catch((err) => showToast("Something went wrong"));

			getStudentClassDetails(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setClassDetails(res);
				})
				.catch((err) => showToast("Something went wrong"));

			getUpcomingCourses(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToasterMessage(res.message);
						return;
					}
					setUpcomingDetails(res.upcoming_workshop_details);
				})
				.catch((err) => showToasterMessage("Something went wrong"));
			getStudentTechnicalNotes(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToasterMessage(res.message);
						return;
					}
					setTechnicalNotes(res);
				})
				.catch((err) => showToasterMessage("Something went wrong"));

			getStudentCalendarDetails(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setCalendarDetails(res);
				})
				.catch((err) => showToast("Something went wrong"));

			getUnreadMsg(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					if (res.message_count > 0) {
						setCount(res.message_count);
						setShowModal(true);
					}
				})
				.catch((err) => showToast("Something went wrong"));

			getStudentAbsent(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setShowAbsentReasonModal(res?.details[0]?.show_pop_up);
					setFormData((prevData) => ({
						...prevData,
						class_dates: res?.details[0]?.class_dates,
					}));
				})
				.catch((err) => showToast("Something went wrong"));
		}
	}, [selectedRole]);

	useEffect(() => {
		const roles = JSON.parse(localStorage.getItem("userRoles"));
		if (roles.includes(1)) {
			getTermsStatus(token)
				.then((res) => {
					if (!res[0].is_terms_accepted) {
						setTerms(res[0].terms_and_conditions);
						setTermsId(res[0].terms_conditions_id);
						setShowTermsModal(true);
						// getTerms(token).then((res)=>{
						//   setTerms(res[0])
						//   setShowTermsModal(true);
						// }).catch(()=>{})
					}
				})
				.catch(() => {});
		}

		setUserRoles(roles);
	}, [token]);

	useEffect(() => {
		const selectrole = localStorage.getItem("selectedRole");
		checkRolePermission(token, selectrole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
		setSelectedRole(localStorage.getItem("selectedRole") || "");
	}, []);

	// useEffect(() => {
	//   const roles = JSON.parse(localStorage.getItem("userRoles"));
	//   setUserRoles(roles);
	//   const storedSelectedRole = localStorage.getItem("selectedRole") || "";
	//   setSelectedRole(storedSelectedRole);

	//   const handleStorageChange = () => {
	//     const newSelectedRole = localStorage.getItem("selectedRole") || "";
	//     setSelectedRole(newSelectedRole);
	//   };

	//   window.addEventListener("storage", handleStorageChange);

	//   return () => {
	//     window.removeEventListener("storage", handleStorageChange);
	//   };
	// }, []);

	useEffect(() => {
		if (userRoles.includes(1)) {
			if (showTermsModal) {
				setShowModal(false); // Ensure the second modal doesn't show until the first one is handled
			} else if (showModal) {
				setShowTermsModal(false); // Ensure the first modal doesn't show if the second one is active
			}
		}
	}, [userRoles, showTermsModal, showModal]);

	const signOut = () => {
		logout(token)
			.then((res) => {
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				Cookies.remove("token");
				navigate("/");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const openModal = (imageUrl) => {
		setSelectedImage(imageUrl);
		setModalIsOpen(true);
	};
	const closeModal = () => {
		setSelectedImage("");
		setModalIsOpen(false);
	};

	const handleCopyLinkClick = (url) => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				showSuccessToast("URL copied to clipboard");
			})
			.catch((error) => {
				showToast("Failed to copy URL to clipboard");
			});
	};

	//absent funs
	const [formData, setFormData] = useState({
		class_dates: [],
		absent_reason: "",
	});
	const handleInput = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const hangleSubmitReason = () => {
		if (!formData.absent_reason || formData.absent_reason.trim() === "") {
			showToast("Please enter reason");
			return;
		}
		submitAbsentReason(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				showSuccessToast("Reason Submitted successfully.");
				setShowAbsentReasonModal(false);
			})
			.catch((err) => showToast("Something went wrong"));
	};
	return (
		<div className="dashboard">
			<ToastContainer limit={1} />
			{showAbsentReasonModal && (
				<div className="modal-overlay">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<p className="reasonTitle">
							You were absent for 2 consecutive classes, Please provide a valid
							reason.
						</p>
						<div className="InputFields">
							<label htmlFor="reason">Enter Reason</label>
							<textarea
								style={{ marginTop: "0.5rem" }}
								value={formData.absent_reason}
								id="absent_reason"
								onChange={handleInput}
								rows={5}
								placeholder="Reason..."
							></textarea>
						</div>
						<button onClick={hangleSubmitReason} className="submitReasonBtn">
							Submit
						</button>
					</div>
				</div>
			)}
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						<div className="detailsLeft">
							<div
								style={{
									display: "flex",
									gap: "10px",
									flexDirection: "column",
								}}
							>
								<h2>Welcome to Dashboard</h2>
								{selectedRole && selectedRole === "1" && (
									<>
										<p className="desc">{batchName}</p>
										<p className="desc">
											<b>CRM Name: </b>
											{crmName}
										</p>
										<p className="desc">
											<b>Contact No: </b>
											{crmNumber}
										</p>
									</>
								)}
							</div>
						</div>
						<div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
							{selectedRole && selectedRole === "1" && (
								<Button
									buttonName={"Create CRM Help Ticket"}
									background={"#4367B0"}
									buttonIcon={ImageList.ButtonTicketIcon}
									color={"#fff"}
									style={{
										height: "35px",
									}}
									onClick={() => {
										navigate("/student/course/ticket");
									}}
								/>
							)}

							<button onClick={signOut} className="logoutBtn">
								<img src={ImageList.PowerIcon} alt="" />
								Logout
							</button>
						</div>
					</div>

					{selectedRole && selectedRole === "2" && (
						<div className="shortcutCards">
							<div onClick={() => navigate("/course-list")} className="card">
								<div className="left">
									<h4>Course List</h4>
									<p>View all the available courses</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div onClick={() => navigate("/user-list")} className="card">
								<div className="left">
									<h4>User List</h4>
									<p>View all the available users</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate("/student-attendance-list")}
								className="card"
							>
								<div className="left">
									<h4>Participant Attendance List</h4>
									<p>View the Participant attendance list</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate("/student-batch-list")}
								className="card"
							>
								<div className="left">
									<h4>Participant Batch List</h4>
									<p>View the Participant batch list</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div onClick={() => navigate("/batch-list")} className="card">
								<div className="left">
									<h4>Batch List</h4>
									<p>View the global batch list</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div onClick={() => navigate("/calendar-list")} className="card">
								<div className="left">
									<h4>Calendar List</h4>
									<p>View the available calendar list</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
						</div>
					)}

					{selectedRole && selectedRole === "3" && (
						<div className="shortcutCards">
							{/* <div onClick={() => navigate("/batch-list")} className="card">
                <div className="left">
                  <h4>Batch List</h4>
                  <p>View the global batch list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div> */}
							<div
								onClick={() => navigate("/student-batch-list")}
								className="card"
							>
								<div className="left">
									<h4>Participant Batch List</h4>
									<p>View the Participant batch list</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>

							<div
								onClick={() => navigate("/student-attendance-list")}
								className="card"
							>
								<div className="left">
									<h4>Participant Attendance List</h4>
									<p>View the Participant attendance list</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
						</div>
					)}

					{selectedRole && selectedRole === "1" && (
						<>
							{/* <div className="shortcutCards">
                <div
                  onClick={() => navigate("/student/homework-action")}
                  className="card"
                >
                  <div className="left">
                    <h4>Homework Action</h4>
                    <p>View/submit homework</p>
                  </div>
                  <div className="right">
                    <div>
                      <img src={ImageList.RightVector} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/student/crm-help-ticket")}
                  className="card"
                >
                  <div className="left">
                    <h4>Participant CRM Help Ticket</h4>
                    <p>Raise CRM Help Tickets</p>
                  </div>
                  <div className="right">
                    <div>
                      <img src={ImageList.RightVector} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/student/crm-message")}
                  className="card"
                >
                  <div className="left">
                    <h4>Participant Message</h4>
                    <p>Send Messages</p>
                  </div>
                  <div className="right">
                    <div>
                      <img src={ImageList.RightVector} alt="" />
                    </div>
                  </div>
                </div>
              </div> */}

							<div className="infoDetails">
								{/* <div className="batchDetails">
                  <h2>Batch Details</h2>
                  <div className="details" style={{ overflowX: "auto" }}>
                    <table className="dashboardDetailsTable">
                      <thead>
                        <tr>
                          <th>Batch Name</th>
                          <th>Start date</th>
                          <th>CRM Name / CRM Phone Number</th>
                          <th>Payment due date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {batchDetails &&
                          batchDetails.length > 0 &&
                          batchDetails.map((batch, index) => (
                            <tr key={index}>
                              <td>{batch.batch_name}</td>
                              <td>
                                {batch.batch_start_date
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {batch.crm_data.batch_crm_name} -{" "}
                                {batch.crm_data.batch_crm_phone_number}
                              </td>
                              <td>
                                {batch.payment_due_date
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
								<div className="classDetails">
									<h2>Upcoming Class Details</h2>
									<div className="details" style={{ overflowX: "auto" }}>
										<table className="dashboardDetailsTable">
											<thead>
												<tr>
													{/* <th>Sl. No.</th> */}
													<th>Date</th>
													<th>Week</th>
													<th>Zoom Link</th>
													<th>Venue details</th>
												</tr>
											</thead>
											<tbody>
												{classDetails &&
													classDetails.length > 0 &&
													classDetails.map((classItem, index) => (
														<tr key={index}>
															{/* <td>{index + 1}</td> */}
															<td>
																{classItem.class_start_date
																	.split("-")
																	.reverse()
																	.join("/")}
															</td>
															<td>{classItem.class_week_no}</td>
															<td>
																{classItem.class_zoom_link &&
																	classItem.class_zoom_link !== "" && (
																		<a
																			href={classItem.class_zoom_link}
																			target="_self"
																		>
																			Join
																		</a>
																	)}
															</td>
															<td>
																{classItem.class_venue_details ||
																classItem.class_venue_details !== ""
																	? classItem.class_venue_details
																	: "-"}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
								<div
									className="classDetails"
									style={{ cursor: "pointer" }}
									onClick={() => {
										navigate("/student/student-batch");
									}}
								>
									<h2>Weekly Homeworks & Videos</h2>
									{/* <Button
                  buttonName={"Weekly Homeworks & Videos"}
                  background={"#4367B0"}
                  // buttonIcon={ImageList.ButtonTicketIcon}
                  color={"#fff"}
                  style={{
                    height: "35px",
                  }}
                  onClick={() => {
                    navigate("/student/student-batch");
                  }}
                /> */}
								</div>
								<div className="calendarDetails">
									<h2>BCI Calendar</h2>
									<div className="details" style={{ overflowX: "auto" }}>
										<table className="dashboardDetailsTable">
											<thead>
												<tr>
													{/* <th>Sl. No.</th> */}
													<th>Calendar Title</th>
													{/* <th>Batch Name</th> */}
													<th>Calender File</th>
												</tr>
											</thead>
											<tbody>
												{calendarDetails &&
													calendarDetails.length > 0 &&
													calendarDetails.map((calendarItem, index) => (
														<tr key={index}>
															{/* <td>{index + 1}</td> */}
															<td>{calendarItem.calendar_title}</td>
															{/* <td>{calendarItem.batch_name}</td> */}
															<td
																onClick={() =>
																	openModal(
																		calendarItem?.calendar_file[0]?.content
																	)
																}
															>
																{calendarItem.calendar_file &&
																	calendarItem.calendar_file !== "" && (
																		<div className="imageHolder">
																			<img
																				src={ImageList.EnlargeIcon}
																				alt=""
																				className="enlarge"
																			/>
																			<img
																				style={{
																					width: "200px",
																					height: "150px",
																					objectFit: "cover",
																				}}
																				src={
																					calendarItem.calendar_file[0]?.content
																				}
																				alt=""
																				className="calendarFile"
																			/>
																		</div>
																	)}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
								<div className="classDetails">
									<h2>Upcoming Courses/Workshops</h2>
									<div className="details" style={{ overflowX: "auto" }}>
										<table className="dashboardDetailsTable">
											<thead>
												<tr>
													{/* <th>Sl. No.</th> */}
													<th>Workshop title</th>
													<th>Work Flyer</th>
													<th colSpan={2}>Registration link</th>
												</tr>
											</thead>
											<tbody>
												{upcomingDetails &&
													upcomingDetails.length > 0 &&
													upcomingDetails.map((upcomingDetail, index) => (
														<tr key={index}>
															{/* <td>{index + 1}</td> */}
															<td>{upcomingDetail.workshop_title}</td>

															<td
																onClick={() =>
																	openModal(
																		upcomingDetail.workshop_flyer[0]?.content
																	)
																}
															>
																<img
																	style={{
																		width: "300px",
																		height: "300px",
																		objectFit: "contain",
																	}}
																	src={
																		upcomingDetail.workshop_flyer[0]?.content
																	}
																	alt=""
																	className="calendarFile"
																/>
															</td>
															<td>
																<a
																	href={upcomingDetail.registration_link}
																	target="_blank"
																>
																	Register
																</a>
															</td>
															<td>
																<p
																	style={{
																		textDecoration: "underline",
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		handleCopyLinkClick(
																			upcomingDetail.registration_link
																		)
																	}
																>
																	Copy link
																</p>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
								<div className="classDetails">
									<h2>Technical Notes</h2>
									<div className="details" style={{ overflowX: "auto" }}>
										<table className="dashboardDetailsTable">
											<thead>
												<tr>
													<th>Title</th>
													<th>Technical Attachment</th>
													<th>Technical View URL</th>
												</tr>
											</thead>
											<tbody>
												{technicalNotes &&
													technicalNotes.length > 0 &&
													technicalNotes.map((upcomingDetail, index) => (
														<tr key={index}>
															<td>{upcomingDetail.title}</td>

															<td>
																{upcomingDetail.technical_notes_attachments.map(
																	(attachment, index) => (
																		<a
																			href={attachment.content}
																			download={attachment.filename}
																			key={index}
																		>
																			{attachment.filename}
																		</a>
																	)
																)}
															</td>
															<td>
																{upcomingDetail.technical_dt_url.map(
																	(url, index) => (
																		<a
																			href={url.technical_url}
																			key={index}
																			target="_blank"
																		>
																			{url.url_label}
																		</a>
																	)
																)}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</>
					)}

					{selectedRole && selectedRole === "5" && (
						<div className="shortcutCards">
							<div
								onClick={() => navigate("/crm-admin/batch-list")}
								className="card"
							>
								<div className="left">
									<h4> CRM Batch List</h4>
									<p>View all the CRM Batch List</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate("/crm-admin/student-message")}
								className="card"
							>
								<div className="left">
									<h4> CRM Message</h4>
									<p>View all the CRM Message</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate("/crm-admin/crm-help-ticket")}
								className="card"
							>
								<div className="left">
									<h4> CRM Help Ticket</h4>
									<p>View the CRM Help Ticket</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
						</div>
					)}

					{selectedRole && selectedRole === "4" && (
						<div className="shortcutCards">
							<div
								onClick={() => navigate("/crm-batch/batch-list")}
								className="card"
							>
								<div className="left">
									<h4>Batch CRM Batch List</h4>
									<p>View all the Batch CRM Batch List</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate("/crm-batch/crm-help-ticket")}
								className="card"
							>
								<div className="left">
									<h4>Batch CRM Help Ticket</h4>
									<p>View all the Batch CRM Help Ticket</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate("/crm-batch/student-message")}
								className="card"
							>
								<div className="left">
									<h4>Batch CRM Message</h4>
									<p>View the Participant Batch CRM Message</p>
								</div>
								<div className="right">
									<div>
										<img src={ImageList.RightVector} alt="" />
									</div>
								</div>
							</div>
						</div>
					)}
					{/* {userRoles && userRoles.length > 0 && userRoles.includes(2) && (
            <div className="shortcutCards">
              <div onClick={() => navigate("/course-list")} className="card">
                <div className="left">
                  <h4>Course List</h4>
                  <p>View all the available courses</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div onClick={() => navigate("/user-list")} className="card">
                <div className="left">
                  <h4>User List</h4>
                  <p>View all the available users</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/student-attendance-list")}
                className="card"
              >
                <div className="left">
                  <h4>Student Attendance List</h4>
                  <p>View the student attendance list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/student-batch-list")}
                className="card"
              >
                <div className="left">
                  <h4>Student Batch List</h4>
                  <p>View the student batch list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div onClick={() => navigate("/batch-list")} className="card">
                <div className="left">
                  <h4>Batch List</h4>
                  <p>View the global batch list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div onClick={() => navigate("/calendar-list")} className="card">
                <div className="left">
                  <h4>Calendar List</h4>
                  <p>View the available calendar list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {userRoles && userRoles.length > 0 && userRoles.includes(3) && (
            <div className="shortcutCards">
              <div onClick={() => navigate("/batch-list")} className="card">
                <div className="left">
                  <h4>Batch List</h4>
                  <p>View the global batch list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/student-batch-list")}
                className="card"
              >
                <div className="left">
                  <h4>Student Batch List</h4>
                  <p>View the student batch list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>

              <div
                onClick={() => navigate("/student-attendance-list")}
                className="card"
              >
                <div className="left">
                  <h4>Student Attendance List</h4>
                  <p>View the student attendance list</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {userRoles && userRoles.length > 0 && userRoles.includes(1) && (
            <div className="shortcutCards">
              <div
                onClick={() => navigate("/student/homework-action")}
                className="card"
              >
                <div className="left">
                  <h4>Homework Action</h4>
                  <p>View/submit homework</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/student/crm-help-ticket")}
                className="card"
              >
                <div className="left">
                  <h4>Student CRM Help Ticket</h4>
                  <p>Raise CRM Help Tickets</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/student/crm-message")}
                className="card"
              >
                <div className="left">
                  <h4>Student Message</h4>
                  <p>Send Messages</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {userRoles && userRoles.length > 0 && userRoles.includes(5) && (
            <div className="shortcutCards">
              <div
                onClick={() => navigate("/crm-admin/batch-list")}
                className="card"
              >
                <div className="left">
                  <h4>Admin CRM Batch List</h4>
                  <p>View all the Admin CRM Batch List</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/crm-admin/student-message")}
                className="card"
              >
                <div className="left">
                  <h4>Admin CRM Message</h4>
                  <p>View all the Admin CRM Message</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/crm-admin/crm-help-ticket")}
                className="card"
              >
                <div className="left">
                  <h4>Admin CRM Help Ticket</h4>
                  <p>View the Admin CRM Help Ticket</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {userRoles && userRoles.length > 0 && userRoles.includes(4) && (
            <div className="shortcutCards">
              <div
                onClick={() => navigate("/crm-batch/batch-list")}
                className="card"
              >
                <div className="left">
                  <h4>Batch CRM Batch List</h4>
                  <p>View all the Batch CRM Batch List</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/crm-batch/crm-help-ticket")}
                className="card"
              >
                <div className="left">
                  <h4>Batch CRM Help Ticket</h4>
                  <p>View all the Batch CRM Help Ticket</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/crm-batch/student-message")}
                className="card"
              >
                <div className="left">
                  <h4>Batch CRM Message</h4>
                  <p>View the student Batch CRM Message</p>
                </div>
                <div className="right">
                  <div>
                    <img src={ImageList.RightVector} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )} */}

					{userRoles.includes(1) && showTermsModal && (
						<TermsModal
							onClick={() => setShowTermsModal(false)}
							terms={terms}
							terms_conditions_id={terms_conditions_id}
						/>
					)}

					{userRoles.includes(1) && !showTermsModal && showModal && (
						<UnreadMsgModal
							onClick={() => {
								setShowModal(false);
								// navigate('/student/crm-message')
							}}
							count={count}
						/>
					)}

					<ImageModal
						isOpen={modalIsOpen}
						closeModal={closeModal}
						imageUrl={selectedImage}
					/>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
