import React, { useEffect, useState } from "react";
import "./AboutPage.scss";
import { About } from "../../../assets/Data/Constants";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";
import Down from "../../../assets/down.svg";
import Up from "../../../assets/up.svg";

const AboutPage = () => {
	const [showMore, setShowMore] = useState(false);
	return (
		<>
			{About.map((a, index) => (
				<div className="AboutCobtainer" key={index}>
					<h2
						htmlFor="subTitle"
						data-aos={window.innerWidth > 768 ? "fade-up" : ""}
						data-aos-once={true}
					>
						About {a.name}
					</h2>
					<div className="AboutDetails">
						<div className="AboutDesc">
							<p>
								<span>{a.name}</span> {a.bio}
							</p>
							<p
								// className={
								// 	window.innerWidth <= 768 && showMore ? "toShow" : "toHide"
								// }
								data-aos={window.innerWidth > 768 ? "fade-up" : ""}
								data-aos-once={true}
							>
								{a.edu}
							</p>
							<p
								className={
									window.innerWidth <= 768 && showMore ? "toShow" : "toHide"
								}
								data-aos={window.innerWidth > 768 ? "fade-up" : ""}
								data-aos-once={true}
							>
								{a.exp}
							</p>
							<ol
								className={
									window.innerWidth <= 768 && showMore ? "toShow" : "toHide"
								}
								data-aos={window.innerWidth > 768 ? "fade-up" : ""}
								data-aos-once={true}
							>
								{a.points.map((p, index) => (
									<li key={index}>{p.p}</li>
								))}
							</ol>
						</div>
						<img
							className={`aboutImg ${
								window.innerWidth <= 768 && showMore ? "toShow" : "toHide"
							}`}
							src={a.img}
							alt=""
						/>
						<React.Fragment>
							<div
								className={`AboutDesc AboutDescDiv ${
									window.innerWidth <= 768 && showMore ? "toShow" : "toHide"
								}`}
								data-aos={window.innerWidth > 768 ? "fade-up" : ""}
								data-aos-once={true}
							>
								{a.BusinessDesc.map((b, index) => (
									<p key={index}>{b.desc}</p>
								))}

								{/* <button className="btn" data-aos={window.innerWidth > 768 ? "fade-up" : ""}  data-aos-once={true}>
								Read More <img src={ForwardArrow} alt="" />
							</button> */}
							</div>
							{window.innerWidth <= 768 && (
								<span
									onClick={() => setShowMore(!showMore)}
									className="Readmore"
								>
									{showMore ? (
										<div className="readLess">
											<p>Read less</p>
											<img src={Up} alt="" />
										</div>
									) : (
										<div className="readMore">
											<p>Read more</p>
											<img src={Down} alt="" />
										</div>
									)}
								</span>
							)}
						</React.Fragment>
					</div>
				</div>
			))}
		</>
	);
};

export default AboutPage;
