/** @format */

import React from "react";
import "./NotesAttachment.scss";
import DownloadIcon from "@mui/icons-material/Download";

export default function NotesAttachment({ value, attachments }) {
	// const  download = () => {
	//    const base64Data = item; // Assuming `item` contains the base64-encoded data

	//    // Convert base64 to Blob
	//    const byteCharacters = atob(base64Data);
	//    const byteNumbers = new Array(byteCharacters.length);
	//    for (let i = 0; i < byteCharacters.length; i++) {
	//      byteNumbers[i] = byteCharacters.charCodeAt(i);
	//    }
	//    const byteArray = new Uint8Array(byteNumbers);
	//    const blob = new Blob([byteArray], { type: "application/octet-stream" });

	//    // Create download link and trigger click
	//    const downloadLink = document.createElement("a");
	//    downloadLink.href = URL.createObjectURL(blob);
	//    downloadLink.download = `Notes-${key}`; // You can set the desired filename here
	//    downloadLink.click();
	// }
	return (
		<div key={value} className="attachmentCard">
			<p className="attachmentHeading">{attachments.filename}</p>
			{/* <button className="downloadBtn" onClick={download}>
            <DownloadIcon />
            Download
         </button> */}
			<a
				href={attachments.content}
				target="_blank"
				download={`File-${value}`}
				className="downloadBtn"
			>
				<DownloadIcon />
				Download
			</a>{" "}
		</div>
	);
}
