import filterStudentCRMAPI from "../../apis/admin/filterStudentCRMAPI";

export function filterStudentCRM(token, body) {
  return new Promise(async(resolve, reject) => {
      
    filterStudentCRMAPI.sort(token, body).then((res)=>{
      resolve(res)
    }).catch((err)=> { 
      reject(err)
    })
  })
}
