import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentAttendancePercentage.scss";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage, showSuccessToast } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { getStudentAttendance } from "../../../interface/implementation/admin/getAttendanceAPIImp";
import { searchAttendance } from "../../../interface/implementation/admin/searchAttendanceAPIImp";
import UpdateAttendanceModal from "../../../components/UpdateAttendanceModal/UpdateAttendanceModal";
import { updateAttendanceAll } from "../../../interface/implementation/admin/updateAllAttendanceAPIImp";
import { downloadAttendanceCSV } from "../../../interface/implementation/admin/downloadAttendanceAPIImp";
import { useNavigate } from "react-router-dom";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import _isEmpty from "lodash/isEmpty";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";
import { autoSearchBatches } from "../../../interface/implementation/admin/autoSearchBatchesAPIImp";
import { getStudentAttendancePercentage } from "../../../interface/implementation/admin/getStudentAttendancePercentageAPIImp";
import { filterStudentAttendancePercentage } from "../../../interface/implementation/admin/filterStudentAttendancePercentageAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { logout } from "../../../interface/implementation/logoutAPIImp";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";

function StudentAttendancePercentage() {
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const [batchname, setBatchname] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [weekNo, setWeekNo] = useState(null);
	const [crm, setCRM] = useState("");
	const [participantStatus, setParticipantStatus] = useState("");
	const [attendance, setAttendance] = useState("");
	const [attendanceList, setAttendanceList] = useState([]);
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showUpdateButton, setShowUpdateButton] = useState(false);
	const [batches, setBatches] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState(null);
	const [modalState, setModalState] = useState(false);
	const [batchId, setBatchId] = useState(0);
	const [crmValues, setCrmValues] = useState([]);
	const [selectedCRM, setSelectedCRM] = useState(null);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [searchField, setSearchField] = useState("");
	const [percentage, setPercentage] = useState("");
	const navigate = useNavigate();

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);

	useEffect(() => {
		getStudentAttendancePercentage(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);

					return;
				}
				setAttendanceList(res.student_details);
			})
			.catch();
	}, [reloadUsers]);

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setBatches(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = `?batch_id=${batchname}`;

		if (searchField) {
			newBody += `&search_data=${searchField}`;
		}

		if (percentage) {
			newBody += `&percentage=${percentage}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	// const handleAutoSearch = async (e) => {
	//   if (!batchId || batchId === "") {
	//     showToast("You need to select a batch before searching.");
	//     return;
	//   }
	//   if (e.target.value) {
	//     let newBody = `${batchId}/${e.target.value}`;

	//     autoSearchBatches(token, newBody)
	//       .then((res) => {
	//         if (res.detail === "Signature has expired.") {
	//           navigate("/");
	//           showToast("Token has expired. Please log in again.");
	//           return;
	//         }
	//         if (res.status === "failure") {
	//           showToast(res.message);
	//           return;
	//         }
	//         setAutoValues(res)
	//         setAutoModal(true)

	//       })
	//       .catch();

	//   }
	// };

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		filterStudentAttendancePercentage(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);

					setAttendanceList();

					return;
				}
				const indexOfLastItem = currentPage * itemsPerPage;
				const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				setAttendanceList(res.student_details);
				setShowUpdateButton(true);
			})
			.catch();
		setShowUpdateButton(true);
	};

	const handleReloadUsers = () => {
		setReloadUsers(!reloadUsers);
	};

	const handleReset = () => {
		setSearchField("");
		setBatchname("");
		setPercentage("");
		handleReloadUsers();
	};

	const handleBatchChange = (e) => {
		const selectedBatchId = e.target.value;
		const selectedBatch = batches.find(
			(batch) => batch.batch_id === parseInt(selectedBatchId)
		);
		if (selectedBatch) {
			setBatchId(selectedBatchId);
			// setBatchname(selectedBatch.batch_name);
		}
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		attendanceList && attendanceList.length > 0
			? attendanceList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="studentAttendancePercentage">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						<h4>Participant Attendance List</h4>
					</div>
					<div className="attendanceListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="batchname">Batch Name</label>
									<div className="inputCode">
										<select
											id="batchname"
											onChange={(e) => {
												handleInputChange(e, setBatchname);
												handleBatchChange(e);
											}}
											value={batchname}
										>
											<option value="">Select Batch Name</option>
											{batches &&
												batches.length > 0 &&
												batches.map((batch, i) => (
													<option key={batch.batch_id} value={batch.batch_id}>
														{batch.batch_name}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="search">Company name / Phone number</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder=""
											onChange={(e) => {
												// handleAutoSearch(e)
												handleInputChange(e, setSearchField);
											}}
											value={searchField}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="search">Attendance Percentage</label>
									<select
										type="text"
										id="percentage"
										placeholder="Select adjustment type"
										onChange={(e) => {
											// handleAutoSearch(e)
											handleInputChange(e, setPercentage);
										}}
										value={percentage}
									>
										<option value="">Select percentage</option>
										<option value={20}> &lt; 20%</option>
										<option value={40}>&lt; 40%</option>
										<option value={60}>&lt; 60%</option>
										<option value={80}>&lt; 80%</option>
										<option value={100}>&lt; 100%</option>
										{/* <option value="Registered">Registered & Next</option> */}
									</select>
								</div>

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Apply
									</button>
								</div>
								<div className="searchBtnContainer">
									<button className="resetBtn" onClick={handleReset}>
										Reset
									</button>
								</div>
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="attendanceTable">
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Company Code</th>
										<th>Participant Name</th>
										<th>Participant Status</th>
										<th>Phone Number</th>
										<th>Company Name</th>

										<th>Attendance Percentage</th>
										{/* <th>CRM</th> */}
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((student, i) => (
											<tr key={i}>
												<td>{startSerialNumber + i}</td>
												<td>{student.batch_name}</td>
												<td>{student.student_name}</td>
												<td>{student.participants_status}</td>
												<td>{student.phone_no}</td>
												<td>{student.company_name}</td>
												<td>{student.attendance_percentage} %</td>

												{showModal &&
													selectedStudent &&
													selectedStudent.id === student.id && (
														<td>
															<UpdateAttendanceModal
																student_name={selectedStudent.student_name}
																onClick={() => setShowModal(false)}
																week_no={selectedStudent.week_no}
																class_type={selectedStudent.class_type}
																zoom_duration={selectedStudent.duration}
																status={selectedStudent.is_present}
																setModalState={setModalState}
																setReloadUsers={handleReloadUsers}
															/>
														</td>
													)}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(attendanceList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentAttendancePercentage;
