import uploadStudentCSVAPI from "../../apis/admin/uploadStudentCSVAPI";

export function uploadStudentCSV(token, body) {
	return new Promise(async (resolve, reject) => {
		uploadStudentCSVAPI
			.postFormData(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
