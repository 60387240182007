import React, { useEffect } from "react";
import "./WorkshopPage.scss";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { WorkshopData } from "../../../assets/Data/Constants";

const WorkshopPage = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);
	return (
		<div className="workshopContainer">
			<label
				htmlFor="titleTop"
				className="titleTop"
				data-aos="fade-up"
				data-aos-once={true}
			>
				{WorkshopData && WorkshopData.subTitlr}
			</label>
			<label
				htmlFor="mainTitle"
				className="mainTitle"
				data-aos="fade-up"
				data-aos-once={true}
			>
				{WorkshopData && WorkshopData.Maintitle}
			</label>
			{/* <div className="workshopContainer">
				<label htmlFor="titleTop" className="titleTop">
					RESULTS OF
				</label>
				<label htmlFor="mainTitle" className="mainTitle">
					THE WORKSHOP
				</label>
				<p>
					Typically, most business owners want to grow their business and
					although they have a sense of what to do and in some cases how to do
					it, they are still not able to implement it and most important to
					sustain it.
				</p>
				<p>
					To address this Rahul Jain, Business Coach is conducting a workshop.
					At the end of this workshop the participants will have a clear
					understanding and a clearly defined action plan to achieve the
					following results:
				</p>
				<div className="cardsConatiner">
					<div className="WorkshopCardsB WorkshopCards">
						<span>How to set up systems so that the</span>
						<br />
						<span className="tBold">
							Business works and you don’t have to work
						</span>
					</div>
					<div className="WorkshopCardsW WorkshopCards">
						<span className="tBold">
							Your team delivers the result you want,
						</span>
						<br />
						<span>every time, without having to chase them!</span>
					</div>
					<div className="WorkshopCardsB WorkshopCards">
						<span>While you are in</span>
						<br />
						<span className="tBold">
							total control of every aspect of your business!
						</span>
					</div>
					<div className="WorkshopCardsW WorkshopCards">
						<span className="tBold">No phone calls, no e-mail.</span>
					</div>
					<div className="WorkshopCardsB WorkshopCards">
						<span>Imagine, you will be free from</span>
						<br />
						<span className="tBold">operations for 5 days a week.</span>
					</div>
				</div>
				<Link
					to="/registrationForm"
					state={{ work_card_id: 1 }}
					data-aos="fade-up"
					data-aos-once={true}
				>
					<button>
						Get Sarted <img src={ForwardArrow} alt="" />
					</button>
				</Link>
			</div> */}
			<div className="listContainer">
				{WorkshopData &&
					WorkshopData.cardsData &&
					WorkshopData.cardsData.map((c, index) => (
						<React.Fragment key={index}>
							<div className="list" data-aos="fade-up" data-aos-once={true}>
								<span>{c}</span>
							</div>
						</React.Fragment>
					))}
			</div>

			<Link
				to="/registrationForm"
				state={{ work_card_id: 1 }}
				data-aos="fade-up"
				data-aos-once={true}
			>
				<button>
					Click Now to Register !!! <img src={ForwardArrow} alt="" />
				</button>
			</Link>
		</div>
	);
};

export default WorkshopPage;
