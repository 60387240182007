import React, { useState, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

import "./UpdateWorkshopStudentCSV.scss";
import { uploadAttendanceCSV } from "../../../interface/implementation/admin/uploadAttendanceCSVAPIImp";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";

import Cookies from "js-cookie";
import { downloadAttendanceCSV } from "../../../interface/implementation/admin/downloadAttendanceAPIImp";
import SampleStudentFile from "../../../assets/files/student_template.csv";

import { useLocation, useNavigate } from "react-router-dom";
import { uploadUpdateStudentCSV } from "../../../interface/implementation/admin/uploadUpdateStudentCSVAPIImp";
import { uploadUpdateWorkshopStudentCSV } from "../../../interface/implementation/admin/uploadUpdateWorkshopStudentCSVAPIImp";
import { downloadStudents } from "../../../interface/implementation/admin/downloadStudentsAPIImp";

function UpdateWorkshopStudentCSV() {
	const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const workshopId = location.state.workshopId;
	const fileInputRef = useRef(null);
	const [formData, setFormData] = useState({
		documentFiles: [],
	});
	const token = Cookies.get("token");
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const batchName = location.state.batchName;
	const startDate = location.state.startDate.split("-").reverse().join("/");
	const endDate = location.state.endDate.split("-").reverse().join("/");

	const handleFileUpload = async () => {
		const fileInput = fileInputRef.current;
		if (fileInput.files.length > 0) {
			const formData = new FormData();
			formData.append("csv_file", fileInput.files[0]);
			formData.append("workshop_id", workshopId);
			setIsSpinnerLoading(true);
			try {
				await uploadUpdateWorkshopStudentCSV(token, formData);
				successToast("File uploaded.");
				navigate("/participant-workshop-list");
				setIsSpinnerLoading(false);
			} catch (error) {
				showToast("Error uploading file");
				console.error("Error uploading file:", error);
			} finally {
				setIsSpinnerLoading(false);
			}
		} else {
			console.warn("No file selected for upload.");
			showToast("No file selected for upload.");
		}
	};

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files).map((file) => file.name);
		setFormData((prevData) => ({
			...prevData,
			documentFiles: [...prevData.documentFiles, ...files],
		}));
	};

	const handleRemoveFile = (index) => {
		setFormData((prevData) => {
			const updatedFiles = [...prevData.documentFiles];
			updatedFiles.splice(index, 1);
			return {
				...prevData,
				documentFiles: updatedFiles,
			};
		});
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	// const handleDownloadStudent = async () => {
	//   await downloadStudents(token)
	//     .then((res) => {
	//       if (res.detail === "Signature has expired.") {
	//         navigate("/");
	//         showToast('Token has expired. Please log in again.');
	//         return;
	//     }
	//     if (res.status === "failure") {
	//         showToast(res.message);
	//         return;
	//     }
	//       const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
	//       const filename = `Participants-${currentDate}.csv`;
	//       const blob = new Blob([res], { type: "text/csv" });
	//       const url = window.URL.createObjectURL(blob);
	//       const a = document.createElement("a");
	//       a.href = url;
	//       a.download = filename;
	//       document.body.appendChild(a);
	//       a.click();
	//       document.body.removeChild(a);
	//       window.URL.revokeObjectURL(url);

	//     })
	//     .catch((err) => showToast("Something went wrong"));
	// };
	return (
		<div className="updateStudentCSV">
			<ToastContainer limit={1} />
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						{batchName} {`(${startDate} - ${endDate})`} :: CSV Import Batch
						Student
					</div>
					<div className="batchStudentListContainer">
						<div className="first row">
							<p className="bullet">1:</p>
							<p className="firstTitle">Download “Template CSV File”</p>
							<div className="downloadBtn">
								<a
									href="https://bcitemplates.s3.ap-south-1.amazonaws.com/workshop_student_template+(1).csv"
									// Replace 'yourFileUrl' with the actual URL of your CSV file
									target="_blank" // Opens the link in a new tab/window
									download="student_template.csv" // Specifies the name of the downloaded file
								>
									<img src={ImageList.DownloadIcon} alt="" />
									Download
								</a>
							</div>
						</div>
						<div className="second row">
							<p className="bullet">2:</p>
							<div className="content">
								<h6>Add or Edit info CSV data according to the below fields</h6>
								<p>
									<b>Required fields: </b>Participant Name,Participant Email
									ID,Participant Phone No,Company Name
								</p>
							</div>
						</div>
						<div className="third row">
							<p className="bullet">3:</p>
							<div className="studentDetails" style={{ overflowX: "auto" }}>
								<table className="studentTable">
									<thead>
										<tr>
											<th>Participant Name</th>
											<th>Participant Email ID</th>
											<th>Participant Phone No</th>
											<th>Company Name</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Participant Name</td>
											<td>Participant Email ID</td>
											<td>Participant Phone No</td>
											<td>Company Name</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						{/* <div className="first row">
              <p className="bullet">4:</p>
              <p className="firstTitle">Last uploaded file:</p>
              <div className="downloadBtn">
                <button className="white" onClick={handleDownloadStudent}>
                  <img src={ImageList.DownloadIconBlue} alt="" />
                  Download
                </button>
              </div>
            </div> */}
						<div className="fourth row">
							<p className="bullet">4:</p>
							<div className="uploadContent">
								{/* <p className="title">
                  Note: *First create Primary Participants CSV, then secondary
                  Participants CSV.
                </p> */}
								<div className="uploadArea">
									<p className="titleHomework">Upload CSV File *</p>
									<div className="uploadContainer">
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
											accept=".csv"
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
										<div className="fileNames">
											{formData.documentFiles.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
										{formData.documentFiles.length === 0 && (
											<div>
												<p className="uploadInfo">No file chosen</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<button onClick={handleFileUpload} className="proceedBtn">
								Proceed
							</button>
							{isSpinnerLoading && <LoadingSpinner isLoading={true} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateWorkshopStudentCSV;
