import React from "react";
import "./Button.scss";
import ImageList from "../../utils/ImageList";

const Button = ({
  buttonName,
  onClick,
  style,
  buttonIcon,
  background,
  color,
}) => {
  return (
    <div
      style={{
        ...style,
        color: color,
        backgroundColor: background,
        borderRadius: style?.borderRadius ? style?.borderRadius : "15px",
      }}
      className="buttonContainer"
      onClick={onClick}
    >
      {buttonIcon && <img src={buttonIcon} />}
      {buttonName}
    </div>
  );
};

export default Button;
