import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./UserList.scss";
import { Link } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { getUsers } from "../../../interface/implementation/admin/getUsersAPIImp";
import Cookies from "js-cookie";
import { getRoles } from "../../../interface/implementation/admin/getRolesAPIImp";
import { searchUsers } from "../../../interface/implementation/admin/searchUsersAPIImp";
import { downloadUsers } from "../../../interface/implementation/admin/downloadUsersAPIImp";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";

import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
function UserList() {
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [activeStatus, setActiveStatus] = useState("yes");
	const [roles, setRoles] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const [reloadUsers, setReloadUsers] = useState(false);
	const [userList, setUserList] = useState([]);
	const [roleList, setRoleList] = useState([]);
	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getUsers(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setUserList(res.users);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	useEffect(() => {
		getRoles(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setRoleList(res.roles.Roles);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = `?active=${activeStatus}`;

		if (username) {
			newBody += `&full_name=${username}`;
		}

		if (email) {
			newBody += `&email_id=${email}`;
		}

		if (phone) {
			newBody += `&phone_no=${phone}`;
		}

		if (roles) {
			newBody += `&roles=${roles}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (activeStatus) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const handleDownload = async () => {
		let newBody = `?active=${activeStatus}`;

		if (username) {
			newBody += `&full_name=${username}`;
		}

		if (email) {
			newBody += `&email_id=${email}`;
		}

		if (phone) {
			newBody += `&phone_no=${phone}`;
		}

		if (roles) {
			newBody += `&roles=${roles}`;
		}
		await downloadUsers(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
				const filename = `Users-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => showToast("Something went wrong"));
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		searchUsers(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setUserList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = userList?.slice(indexOfFirstItem, indexOfLastItem);
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="userList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">User List</div>
					<div className="userListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="username">Username</label>
									<div className="inputCourseName">
										<input
											type="text"
											id="username"
											placeholder="Search by username"
											onChange={(e) => handleInputChange(e, setUsername)}
											value={username}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="email">Email address</label>
									<div className="inputCode">
										<input
											type="text"
											id="email"
											placeholder="Search by email address"
											onChange={(e) => handleInputChange(e, setEmail)}
											value={email}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="phone">Phone number</label>
									<div className="inputCode">
										<input
											type="tel"
											id="phone"
											placeholder="Search by phone number"
											onChange={(e) => handleInputChange(e, setPhone)}
											onFocus={preventWheelScroll}
											onInput={handleNumericInput}
											maxLength={15}
											minLength={10}
											value={phone}
											pattern="[0-9]{10,15}"
											title="Please enter a valid phone number (between 10 and 15 digits)."
										/>
									</div>
								</div>

								<div className="inputContainer">
									<label htmlFor="activeStatus">Active</label>
									<div className="inputCode">
										<select
											id="activeStatus"
											onChange={(e) => handleInputChange(e, setActiveStatus)}
											value={activeStatus}
										>
											<option value="">Select Active Status</option>
											<option value="yes">Yes</option>
											<option value="no">No</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="roles">Roles</label>
									<div className="inputCode">
										<select
											id="roles"
											onChange={(e) => handleInputChange(e, setRoles)}
											value={roles}
										>
											<option value="">Select Roles</option>
											{roleList &&
												roleList.length > 0 &&
												roleList.map((role, index) => (
													<option key={index} value={role.role_id}>
														{role.role_name === "Student"
															? "Participant"
															: role.role_name}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Apply
									</button>
								</div>
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleDownload}>
										Download
									</button>
								</div>
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Picture</th>
										<th>Username</th>
										<th>Email address</th>
										<th>Phone</th>
										<th>Company GST</th>
										<th>Company name</th>
										<th>Register date</th>
										<th>Roles</th>
										<th>CRM Admin</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((user) => (
											<tr key={user.user_id}>
												<td className="userImg">
													<img
														src={
															user.profile_picture
																? user.profile_picture[0].content
																: ImageList.AvatarIcon
														}
														alt=""
													/>
												</td>
												<td>{user.username}</td>
												<td>{user.email_id}</td>
												<td>{user.phone_number}</td>
												<td>{user.company_gst}</td>
												<td>{user.company_name}</td>
												<td>
													{user.registered_date &&
														user.registered_date.split("-").join("/")}
												</td>
												<td>
													{" "}
													{user.roles &&
														user.roles.length > 0 &&
														user.roles.map((role, index) => (
															<span key={index}>
																{role.role_name}
																{index < user.roles.length - 1 && ", "}
															</span>
														))}
												</td>
												<td>{user.crm_name}</td>
												<td className="actionBtn">
													<Link
														to={{ pathname: `/user/edit/${user.user_id}` }}
														state={{ username: user.username }}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Edit
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(userList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserList;
