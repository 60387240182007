import getCRMAPI from "../../apis/admin/getCRMAPI";

export function getCRM(token) {
  return new Promise(async (resolve, reject) => {
    getCRMAPI
      .getAll(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
