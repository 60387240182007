import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showToasterMessage, showSuccessToast } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./AddPartner.scss";
import { getMembers } from "../../../interface/implementation/admin/getMembersAPIImp";
import Cookies from "js-cookie";
import ImageList from "../../../utils/ImageList";
import { addMember } from "../../../interface/implementation/admin/addMemberAPIImp";
import _isEmpty from "lodash/isEmpty";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";
import { getMemberAmount } from "../../../interface/implementation/admin/getMemberAmountAPIImp";
function AddPartner() {
	const navigate = useNavigate();
	const stateData = useLocation();
	const noOfMembers = stateData?.state?.noOfMembers
		? stateData?.state?.noOfMembers
		: 0;

	const primaryParticipantPhoneNo = stateData?.state?.primaryPhoneNo;

	const batchId = stateData?.state?.batchId;
	const batchName = stateData?.state?.batchName;
	const studentId = stateData?.state?.studentId;
	const [memberAmount, setMemberAmount] = useState(
		stateData?.state?.memberAmount
	);
	const terms = stateData?.state?.terms;

	const [fileNames, setFileNames] = useState(
		Array.from({ length: noOfMembers }, () => [])
	);

	const [formData, setFormData] = useState({
		proof_of_partnerships: [],
		member_type: "partner",
		payment_category: "National",
		terms_conditions_id: terms,
		member_amount: memberAmount,
	});

	const token = Cookies.get("token");
	const itemsPerPage = 5;
	const [currentPage, setCurrentPage] = useState(1);
	const [currentItems, setCurrentItems] = useState([]);
	const [memberList, setMemberList] = useState([]);

	const fileInputRef = useRef();

	const mode = stateData.state.mode;

	const [noOfrows, setRows] = useState(0);

	const showToast = useCallback((errorText) => {
		showToasterMessage(errorText);
	}, []);

	const [rowData, setRowData] = useState(
		Array.from({ length: noOfrows }, (_, i) => ({
			member_amount: memberAmount || "",
			member_type: "partner",
			payment_category: "national",
		}))
	);
	const [updated, setUpdated] = useState(false);
	useEffect(() => {
		getMembers(token, primaryParticipantPhoneNo)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					showToast("Token has expired. Please log in again.");
					navigate("/");
					return;
				}
				// if (res.status === "failure") {
				//   showToast(res.message);
				//   return;
				// }
				setMemberList(res.member_details);
				if (mode === "add") {
					// noOfrows = noOfMembers;
					setRows(noOfMembers);
				} else {
					// noOfrows = noOfMembers -  0;
					setRows(
						noOfMembers - (res.member_details ? res.member_details.length : 0)
					);
				}
				const indexOfLastItem = currentPage * itemsPerPage;
				const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				setCurrentItems(memberList.slice(indexOfFirstItem, indexOfLastItem));
				setUpdated(false);
			})
			.catch((err) => {
				showToast("Something went wrong");
			});
	}, [updated]);

	useEffect(() => {
		if (terms) {
			getMemberAmount(token, terms)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						showToast("Token has expired. Please log in again.");
						navigate("/");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}

					// setMemberAmount(res.member_amount)
					setFormData((prevData) => ({
						...prevData,
						member_amount: res.member_amount,
					}));
				})
				.catch((err) => {
					showToast("Something went wrong");
				});
		}
	}, []);

	const handleInputChange = (e, rowIndex) => {
		const { id, value } = e.target;
		setFormData({
			...formData,
			[id]: value,
			batch_id: batchId,
			primary_participant_phone_no: primaryParticipantPhoneNo,
			terms_conditions_id: terms,
		});

		setRowData((prevData) => {
			const updatedRowData = [...prevData];
			updatedRowData[rowIndex] = {
				...updatedRowData[rowIndex],
				[id]: value,
			};
			return updatedRowData;
		});
	};

	// const handleFileChange = (e) => {
	//   const { id, files } = e.target;

	//   // setFormData({ ...formData, [id]: files });
	// };

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleAddMember = async () => {
		if (_isEmpty(formData.participants_name)) {
			showToast("Name field is empty");
			return;
		}
		if (_isEmpty(formData.email_id)) {
			showToast("Email field is empty");
			return;
		}
		if (!formData.email_id.includes("@")) {
			showToast("Invalid email address.");
			return;
		}
		if (_isEmpty(formData.phone_no)) {
			showToast("Phone field is empty");
			return;
		}
		if (formData.phone_no.length < 10) {
			showToast("Phone No must be at least 10 digits");
			return;
		}
		if (!formData.member_amount) {
			showToast("Amount field is empty");
			return;
		}
		if (formData.terms_conditions_id < 0) {
			showToast("T&C field is empty");
			return;
		}
		if (_isEmpty(formData.member_type)) {
			showToast("Member type field is empty");
			return;
		}
		// if (_isEmpty(formData.proof_of_partnerships) || formData.proof_of_partnerships.length === 0) {
		//   showToast("Partnership Form field is empty");
		//   return;
		// }

		await addMember(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setUpdated(true);
				setRowData((prevData) => {
					const updatedRowData = [...prevData];
					updatedRowData[0] = {};
					return updatedRowData;
				});
				successToast("Member added successfully.");

				// setNoOfRows((prevNoOfRows) => prevNoOfRows - 1);
			})
			.catch();
	};

	const handleFileChange = (e) => {
		const files = e.target.files;

		// Create an array to store base64 representations of each file
		const newFiles = [];

		// Loop through each file
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the file
				const base64String = reader.result;

				// Add the file object to the array
				newFiles.push({
					filename: file.name,
					content: base64String,
				});

				// All files have been processed, update state
				if (newFiles.length === files.length) {
					setFormData((prevData) => ({
						...prevData,
						proof_of_partnerships: [
							...prevData.proof_of_partnerships,
							...newFiles,
						],
					}));
				}
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	return (
		<div className="addPartner">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Add Partner/Implementer</div>
					{/* <div className="topDetails">
            <div className="row">
              <p>
                <b>Brand Name :</b> Test
              </p>
              <p>
                <b>Course Name :</b> Test
              </p>
              <p>
                <b>Course Amount :</b> 118,000{" "}
              </p>
            </div>
            <div className="row">
              <p>
                <b>Start Date :</b> 08-01-2024
              </p>
              <p>
                <b>End Date :</b> 15-04-2024
              </p>
              <p>
                <b>Partner Verification Doc :</b> GST/DIN No./Partnership deed{" "}
              </p>
            </div>
            <div className="row">
              <p>
                <b>Partner Verification Doc :</b> GST/DIN No./Partnership deed{" "}
              </p>
              <p>
                <b>Implement Doc :</b> Salary slip/Appointment letter
              </p>
            </div>
          </div> */}
					<div className="addMemberContainer">
						<div className="addMemberList" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>
											Member Name <span>*</span>
										</th>
										<th>
											Member Email<span>*</span>
										</th>
										<th>
											Phone Number<span>*</span>
										</th>
										<th>Old Part Status</th>
										<th>
											Member Amount<span>*</span>
										</th>
										<th>
											T&C<span>*</span>
										</th>
										{/* <th>Payment Type</th> */}
										<th>
											Member Type<span>*</span>
										</th>
										<th>Partner Doc/Implementer Doc</th>
										<th>Verification Doc No</th>
										<th>Payment Category</th>
										<th>Verification</th>
									</tr>
								</thead>
								<tbody>
									{Array.from({ length: noOfrows }, (_, i) => (
										<tr key={i}>
											<td>{i + 1}</td>
											<td>
												<div className="formInput">
													<input
														type="text"
														id={`participants_name`}
														placeholder="Member Name"
														onChange={(e) => handleInputChange(e, i)}
														value={rowData[i]?.participants_name || ""}
													/>
												</div>
											</td>
											<td>
												<div className="formInput">
													<input
														type="text"
														id={`email_id`}
														placeholder="Member Email"
														onChange={(e) => handleInputChange(e, i)}
														value={rowData[i]?.email_id || ""}
													/>
												</div>
											</td>
											<td>
												<div className="formInput">
													<input
														type="tel"
														id={`phone_no`}
														onFocus={preventWheelScroll}
														onInput={handleNumericInput}
														placeholder="Phone"
														minLength="10"
														maxLength="15"
														pattern="[0-9]{10,15}"
														onChange={(e) => handleInputChange(e, i)}
														value={rowData[i]?.phone_no || ""}
													/>
												</div>
											</td>
											<td>
												<div className="formInput">
													<select
														id={`participants_old_status`}
														onChange={(e) => handleInputChange(e, i)}
														value={rowData[i]?.participants_old_status || ""}
													>
														<option value="">Select Old Part Status</option>
														<option value="Primary carry forward">
															Primary carry forward
														</option>
														<option value="Secondary carry forward">
															Secondary carry forward
														</option>
														<option value="Implementer carry forward">
															Implementer carry forward
														</option>
														<option value="Shifted from previous batch">
															Shifted from previous batch
														</option>
													</select>
												</div>
											</td>
											<td>
												<div className="formInput">
													<input
														type="number"
														onFocus={preventWheelScroll}
														onInput={handleNumericInput}
														id={`member_amount`}
														placeholder="Member Amount"
														onChange={(e) => handleInputChange(e, i)}
														value={formData.member_amount || ""}
														// disabled
													/>
												</div>
											</td>
											<td>
												<div className="formInput">
													<input
														type="text"
														id={`terms_conditions_id`}
														placeholder="T&C ID"
														onChange={(e) => handleInputChange(e, i)}
														value={formData.terms_conditions_id || ""}
														// disabled
													/>
												</div>
											</td>
											{/* <td>
                        <select
                          id={`payment_type`}
                          onChange={(e) => handleInputChange(e, i)}
                          value={rowData[i]?.payment_type}
                        >
                          <option value="">Select Payment Type</option>
                          
                          <option value="installment">Installment</option>
                          <option value="full">Full </option>
                          <option value="comp">Comp</option>
                        </select>
                      </td> */}
											<td>
												<select
													id={`member_type`}
													onChange={(e) => handleInputChange(e, i)}
													value={rowData[i]?.member_type}
												>
													<option value="partner" selected>
														Partner
													</option>
													<option value="implementer">Implementer</option>
												</select>
											</td>
											<td>
												<div className="formInput">
													<div className="formInput">
														<input
															type="file"
															id={`proof_of_partnerships`}
															placeholder="Partner Doc"
															ref={fileInputRef}
															onChange={(e) => handleFileChange(e, i)}
															multiple
															accept="application/pdf"
														/>
													</div>
												</div>
											</td>
											<td>
												<div className="formInput">
													<div className="formInput">
														<input
															type="text"
															id={`verification_doc_no`}
															placeholder="Verificatin Doc Number"
															onChange={(e) => handleInputChange(e, i)}
															value={rowData[i]?.verification_doc_no || ""}
														/>
													</div>
												</div>
											</td>
											<td>
												<select
													id={`payment_category`}
													onChange={(e) => handleInputChange(e, i)}
													value={rowData[i]?.payment_category || ""}
												>
													<option value="National" selected>
														National
													</option>
													<option value="International">International</option>
												</select>
											</td>
											<td>
												<div className="formInput">
													<input
														type="text"
														id={`verification_doc_id`}
														placeholder="Verification doc id"
														onChange={(e) => handleInputChange(e, i)}
														value={rowData[i]?.verification_doc_id || ""}
													/>
												</div>
											</td>
											<td>
												<button onClick={handleAddMember} className="addBtn">
													<img src={ImageList.PlusSquare} alt="" />
													Add
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="addMemberContainer">
						<div className="titleBox">
							<p>Partner/Implementer Created List</p>
							{/* <Link className="memberListBtn" to={"/member-list"}>
                  Member List
                </Link> */}
						</div>
						<div className="addMemberList" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										{/* <th>Batch Name</th> */}
										<th>Participant Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Member Type</th>
										{/* <th>Profile / Installment</th> */}
									</tr>
								</thead>
								<tbody>
									{memberList &&
										memberList.length > 0 &&
										memberList.map((member, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												{/* <td>{batchName}</td> */}
												<td>{member.student_name}</td>
												<td>{member.email_id}</td>
												<td>{member.phone_no}</td>
												<td>{member.member_type}</td>
												{/* <td>{member.payment_category}</td> */}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddPartner;
