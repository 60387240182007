import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./WorkshopBarcodeList.scss";
import ImageList from "../../../utils/ImageList";
import BarcodeSideModal from "../../../components/BarcodeSideModal/BarcodeSideModal";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { showToasterMessage } from "../../../utils/helper";
import { getQRCodes } from "../../../interface/implementation/admin/getQRCodesAPIImp";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { searchQRCode } from "../../../interface/implementation/admin/searchQRCodeAPIImp";
import { getWorkshops } from "../../../interface/implementation/admin/getWorkshopsAPIImp";
import { getWorkshopQRCodes } from "../../../interface/implementation/admin/getWorkshopQRCodesAPIImp";
import { searchWorkshopQRCode } from "../../../interface/implementation/admin/searchWorkshopQRCodeAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function WorkshopBarcodeList() {
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
	const [selectedQR, setSelectedQR] = useState("");
	const token = Cookies.get("token");
	// const [batchName, setBatchName] = useState('');
	const [weekNumber, setWeekNumber] = useState("");
	const [batches, setBatches] = useState([]);
	const [batchId, setBatchId] = useState(0);
	const [batchname, setBatchname] = useState("");
	const [reloadUsers, setReloadUsers] = useState(false);

	const [workshopName, setWorkshopName] = useState("");
	const [workshopClassName, setWorkshopClassName] = useState("");

	const [barcodeList, setBarcodeList] = useState([]);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatches(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const [workshopList, setWorkshopList] = useState([]);
	const [workshopId, setWorkshopId] = useState("");
	const [workshopClassList, setWorkshopClassList] = useState([]);
	const [workshopClassId, setWorkshopClassId] = useState("");
	useEffect(() => {
		getWorkshopQRCodes(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBarcodeList(res.details);
				const filteredList = res.details?.map((workshop) => ({
					workshop_class_title: workshop.workshop_class_title,
					workshop_class_id: workshop.workshop_class_id,
				}));
				setWorkshopClassList(filteredList);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	useEffect(() => {
		getWorkshops(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// setCourseList()
				setWorkshopList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	//   const handleWorkshopChange = (workshopId) => {
	//     const filteredWorkshopClassList = workshopClassList.filter(workshop => workshop.workshop_id === workshopId);
	//     setWorkshopClassList(filteredWorkshopClassList);
	// };
	//   const handleWorkshopClassChange = (workshopClassId) => {
	//     const filteredWorkshopList = workshopClassList.filter(workshop => workshop.workshop_id === workshopId);
	//     setWorkshopClassList(filteredWorkshopList);
	// };

	const handleSearch = async () => {
		let newBody = "";
		if (!workshopName || workshopName === "") {
			showToast("Workshop name is missing");
			return;
		} else {
			if (workshopName) {
				newBody += `?workshop_id=${workshopId}`;
			}

			if (workshopClassName) {
				newBody += `${newBody ? "&" : "?"}workshop_class_id=${workshopClassId}`;
			}
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		searchWorkshopQRCode(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);

					//   setAttendanceList();
					setBarcodeList([]);

					return;
				}

				setCurrentPage(1);
				setBarcodeList(res.details);
				// setShowUpdateButton(true);
			})
			.catch();
		// setShowUpdateButton(true);
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		barcodeList && barcodeList.length > 0
			? barcodeList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="barcodeList">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Workshop Barcode List</div>
					<div className="courseListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="batchname">
										Workshop Name <span style={{ color: "red" }}>*</span>
									</label>
									<div className="inputCode">
										<select
											id="batchname"
											onChange={(e) => {
												setWorkshopId(e.target.value);
												handleInputChange(e, setWorkshopName);
											}}
											value={workshopName}
										>
											<option value="">Select Workshop Name</option>
											{workshopList &&
												workshopList.length > 0 &&
												workshopList.map((workshop, i) => (
													<option key={i} value={workshop.workshop_id}>
														{workshop.workshop_title}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="workshopClassName">
										Workshop Class Name{" "}
									</label>
									<div className="inputCode">
										<select
											id="workshopClassName"
											onChange={(e) => {
												setWorkshopClassId(e.target.value);
												handleInputChange(e, setWorkshopClassName);
											}}
											value={workshopClassName}
										>
											<option value="">Select Workshop Class Name</option>
											{workshopClassList &&
												workshopClassList.length > 0 &&
												workshopClassList.map((workshop, i) => (
													<option key={i} value={workshop.workshop_class_id}>
														{workshop.workshop_class_title}
													</option>
												))}
										</select>
									</div>
								</div>
								{/* <div className='inputContainer'>
                  <label htmlFor="weekNumber">Week number</label>
                  <div className="inputCode">
                    <input
                      type="text"
                      id="weekNumber"
                      placeholder="Search by week number"
                      onChange={(e) => handleInputChange(e, setWeekNumber)}
                      value={weekNumber}
                    />
                  </div>
                </div> */}
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>
							</div>
						</div>
						<div className="barcodes" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Workshop Name</th>
										<th>Class title</th>
										<th>Class number</th>
										<th>Barcode view</th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map((barcode, index) => (
										<tr key={index}>
											<td>{barcode.workshop_title}</td>
											<td>{barcode.workshop_class_title}</td>
											<td>{barcode.class_no}</td>
											<td className="actionBtn">
												<button
													onClick={() => {
														setSelectedQR(barcode.qr_code);
														setShowModal(true);
													}}
													className="viewBtn"
												>
													<img src={ImageList.EyeIcon} alt="" />
													View
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							{showModal && (
								<BarcodeSideModal
									onClick={() => setShowModal(false)}
									qrCode={selectedQR}
								/>
							)}
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(barcodeList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WorkshopBarcodeList;
