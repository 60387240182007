import React, { useEffect, useState } from "react";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./CrmPaymentOverdueList.scss";
import { Link } from "react-router-dom";
import { getStudentBatchList } from "../../../interface/implementation/admin/getStudentBatchListAPIImp";
import { getAproveRequestList } from "../../../interface/implementation/admin/getAproveRequestListAPIImp";
import { autoSearchAccess } from "../../../interface/implementation/admin/autoSearchAccessAPIImp";
import { searchAccess } from "../../../interface/implementation/admin/searchAccessAPIImp";
import GrantAccessModal from "../../../components/GrantAccessModal/GrantAccessModal";
import { getCRMBatches } from "../../../interface/implementation/getCRMBatchesAPIImp";

import { downloadOverduePayment } from "../../../interface/implementation/admin/downloadOverduePaymentAPIImp";

import { getCrmOverdueDetails } from "../../../interface/implementation/batchCRM/getCrmOverdueDetailsAPIImp";
import { searchCrmOverduePayment } from "../../../interface/implementation/batchCRM/searchCrmOverduePaymentAPIImp";

import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import Cookies from "js-cookie";
import { searchJustBatches } from "../../../interface/implementation/admin/serachJustBatchesAPIImp";
import { useNavigate } from "react-router-dom";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";
import { logout } from "../../../interface/implementation/logoutAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function CrmPaymentOverdueList() {
	const navigate = useNavigate();
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const [batchname, setBatchname] = useState("");
	const [code, setCode] = useState("");
	const [course, setCourse] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [batchList, setBatchList] = useState([]);
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	const [requestList, setRequestList] = useState([]);
	const [overdueList, setOverdueList] = useState([]);
	const [filterBatchId, setFilterBatchId] = useState();

	const [grantAccessModal, setGrantAccessModal] = useState(false);
	const [selectedRequest, setSelectedRequest] = useState([]);

	// ---------------------------Auto Search Access ---------------------------------
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [search, setSearch] = useState("");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);

	useEffect(() => {
		getCrmOverdueDetails(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again. ");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setOverdueList(res.payments_details);
			})
			.catch((err) => showToast("Something went wrong", err));
	}, [reloadUsers]);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getCRMBatches(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setBatchList(res.batch);
			});
		};
		fetchDropdowns();
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (search) {
			newBody += `${newBody ? "&" : "?"}search_data=${search}`;
		}

		if (filterBatchId) {
			newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const handleDownload = async () => {
		let newBody = "";

		if (filterBatchId) {
			if (search) {
				newBody += `${newBody ? "&" : "?"}search_data=${search}`;
			}

			if (filterBatchId) {
				newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
			}
		} else {
			showToasterMessage("Please select a batch");
			return;
		}

		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			downloadHandle(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const searchFilter = (newBody) => {
		searchCrmOverduePayment(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setOverdueList(res.payments_details);

				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.batch.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch();
	};
	const downloadHandle = (newBody) => {
		downloadOverduePayment(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10);
				const filename = `overdue-info-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const currentItems =
		overdueList && overdueList.length > 0
			? overdueList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
				event.preventDefault();
				const scrollAmount = 50;
				const container = document.querySelector(".courses");

				if (container) {
					if (event.key === "ArrowRight") {
						container.scrollLeft += scrollAmount;
					} else if (event.key === "ArrowLeft") {
						container.scrollLeft -= scrollAmount;
					}
				}
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<div className="batchList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Over Due All Student</div>
					<div className="batchListContainer">
						<div className="topSearchBar">
							<div className="searchInputs batchStudentSearch">
								<div className="inputContainer">
									<label htmlFor="batch">Select batch</label>
									<div className="inputCode">
										<select
											id="batchId"
											onChange={(e) => handleInputChange(e, setFilterBatchId)}
											value={filterBatchId}
										>
											<option value="">Select Batch</option>
											{batchList &&
												batchList.length > 0 &&
												batchList.map((batch, index) => {
													return (
														<option key={index} value={batch.batch_id}>
															{batch.batch_name}
														</option>
													);
												})}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="search">Company name / Phone number</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder=""
											onChange={(e) => {
												// handleAutoSearch(e)
												handleInputChange(e, setSearch);
											}}
											value={search}
										/>
									</div>
								</div>
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>
								{/* {selectedRole !== 3 && 
                  (
                    <div className="searchBtnContainer">
                      <button className="searchBtn" onClick={handleDownload}>
                        Download
                      </button>
                    </div>
                      )
                  } */}
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="studentBatchTable studentBatchTableNew">
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch Name</th>
										<th>Student Name</th>
										<th>Phone</th>
										<th>Email</th>
										<th>Company code</th>
										<th>Company name</th>
										<th>Total Due</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((request, index) => (
											<tr key={index}>
												<td>{startSerialNumber + index}</td>
												<td>{request.batch_name}</td>
												<td style={{ whiteSpace: "pre-line" }}>
													{request.student_name}
												</td>
												<td>{request.phone_number}</td>
												<td>{request.student_mail}</td>
												<td>{request.company_id}</td>
												<td style={{ whiteSpace: "pre-line" }}>
													{request.company_name}
												</td>
												<td>{request.due_amount}</td>
												{/* <td>{batch.end_date && (
                          (batch.end_date).split('-').reverse().join('/')
                        )}</td> */}
												<td
													style={{
														// width: "30em",
														display: "flex",
														gap: "4px",
														justifyContent: "flex-start",
													}}
												>
													{/* <Link
                            className="batchTableBtn"
                            onClick={() => handleGrantRequest(request)}
                          >
                            Download
                          </Link> */}
													<Link
														to={{
															pathname: `/crm-overdue-payment-info/${request.student_id}`,
														}}
														state={{
															studentId: request.student_id,
															batchId: request.batch_id,
														}}
														className="batchTableBtn"
														style={{ backgroundColor: "#1251F3" }}
													>
														Payment Info
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						{/* {grantAccessModal && (
              <GrantAccessModal onClick={() => setGrantAccessModal(false)} requestDetails={selectedRequest} />
            )
            } */}
					</div>

					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(overdueList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CrmPaymentOverdueList;
