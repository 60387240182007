import createTicketAPI from '../../apis/student/createTicketAPI';


export function createTicket(token,body){
    return new Promise(async(resolve, reject) => {
        createTicketAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}