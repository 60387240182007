import React, { useState, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./UpdateClass.scss";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getBatch } from "../../../interface/implementation/admin/getBatchAPIImp";
import { addClass } from "../../../interface/implementation/admin/addClassAPIImp";
import { getClass } from "../../../interface/implementation/admin/getClassAPIImp";
import { showSuccessToast } from "../../../utils/helper";
import { getCourse } from "../../../interface/implementation/admin/getCourseAPIImp";

function getDayName(runningWeekDay) {
	const days = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	return days[runningWeekDay] || "";
}

function WeekCard({
	weekNo,
	isCollapsed,
	onClassDataChange,
	onVimeoIdChange,
	onAddVimeoId,
	onDeleteVimeoId,
	onMailOptionsChange,
	classData,
	vimeoIds,
	initialClassStartDate,
	onMailNotificationChange,
	onTechnicalClassesChange,
	// initialClassData
}) {
	// const [classData, setClassData] = useState(initialClassData || {});

	const [editorVisibility, setEditorVisibility] = useState(false);
	const [classType, setClassType] = useState(["Online", "Offline", "Hybrid"]);

	const [mailOptions, setMailOptions] = useState({
		mail_notification: false,
		technical_classes: false,
	});

	useEffect(() => {
		setEditorVisibility(false);
	}, [isCollapsed]);

	const handleVimeoIdChange = (weekNo, index, value) => {
		// Update vimeoIds array in the parent component
		onVimeoIdChange(weekNo, index, value);
	};

	const handleAddVimeoId = (weekNo) => {
		// Add a new Vimeo ID to the vimeoIds array in the parent component
		onAddVimeoId(weekNo);
	};

	const handleDeleteVimeoId = (weekNo, index) => {
		// Delete a Vimeo ID from the vimeoIds array in the parent component
		onDeleteVimeoId(weekNo, index);
	};

	const handleMailOptions = (e) => {
		// Handle changes to mail options and update classData in the parent component
		onMailOptionsChange(weekNo, e);
	};

	const handleToggleEditor = () => {
		setEditorVisibility((prevVisibility) => !prevVisibility);
	};

	const handleInputChange = (e, weekNo) => {
		// Update classData array in the parent component
		onClassDataChange(weekNo, e);
	};

	return (
		<div className="updateClass">
			<ToastContainer limit={1} />
			<div className="weekCard">
				<div className="heading" onClick={() => handleToggleEditor()}>
					<p className="weekCardTitle">Week No. {weekNo}</p>
					<p className="icon">{editorVisibility ? "-" : "+"}</p>
				</div>
				{editorVisibility && (
					<div className="classDetails">
						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="weekNo">
									Week No <span>*</span>
								</label>
								<div>
									<input type="text" id="weekNo" value={weekNo} disabled />
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="title">
									Week title <span>*</span>
								</label>
								<div>
									<input
										type="text"
										id="week_title"
										value={classData.week_title}
										placeholder="Week title"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="startDate">
									Class Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="class_start_date"
									onChange={(e) => handleInputChange(e, weekNo)}
									value={classData.class_start_date}
									min={initialClassStartDate}
									// value={formatDateForInput(formData.start_date)}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="time">
									Time <span>*</span>
								</label>
								<input
									type="time"
									id="class_start_time"
									onChange={(e) => handleInputChange(e, weekNo)}
									value={classData.class_start_time}
								/>
							</div>
						</div>
						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="zoomId">
									Zoom meeting ID (If Online)
									{classData.class_type !== "Offline" && <span>*</span>}
								</label>
								<div>
									<input
										type="text"
										id="zoom_meeting_id"
										value={classData.zoom_meeting_id}
										placeholder="Meeting id"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="passcode">
									Security passcode
									{classData.class_type !== "Offline" && <span>*</span>}
								</label>
								<div>
									<input
										type="number"
										id="security_passcode"
										value={classData.security_passcode}
										placeholder="Passcode in numbers"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="meetUrl">
									Zoom meeting registration URL
									{classData.class_type !== "Offline" && <span>*</span>}
								</label>
								<div>
									<input
										type="text"
										id="zoom_meeting_registration_link"
										value={classData.zoom_meeting_registration_link}
										placeholder="Meting URL"
										onChange={(e) => handleInputChange(e, weekNo)}
									/>
								</div>
							</div>
						</div>
						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="mailNotification">Mail notification</label>
								<select
									id="mailNotification"
									onChange={(e) =>
										onMailNotificationChange(weekNo, e.target.value)
									}
									value={classData.mail_notification ? "true" : "false"}
								>
									<option value="">Select mail notification</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</select>
							</div>
							<div className="formInput">
								<label htmlFor="mailNotificationDate">
									Mail notification Date<span>*</span>
								</label>
								<div>
									<input
										type="date"
										id="mailNotificationDate"
										onChange={(e) => handleInputChange(e, weekNo)}
										value={classData.mail_notification_date}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="mailNotificationTime">
									Mail notification Time<span>*</span>
								</label>
								<div>
									<input
										type="time"
										id="mailNotificationTime"
										onChange={(e) => handleInputChange(e, weekNo)}
										value={classData.mail_notification_time}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="technicalClass">Technical Class</label>
								<select
									id="technicalClass"
									onChange={(e) =>
										onTechnicalClassesChange(weekNo, e.target.value)
									}
									value={classData.technical_classes ? "true" : "false"}
								>
									<option value="">Select technical class</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</select>
							</div>
						</div>
						<div style={{ width: "50%" }}>
							<div className="formInput">
								<label htmlFor="venue">
									Venue Details (If offline)
									{classData.class_type !== "Online" && <span>*</span>}
								</label>
								<div>
									<textarea
										id="venue_details"
										cols="30"
										rows="5"
										value={classData.venue_details}
										placeholder="Enter venue details"
										onChange={(e) => handleInputChange(e, weekNo)}
										style={{ borderRadius: "10px" }}
									></textarea>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="course">
									Class Type <span>*</span>
								</label>
								<select
									id="class_type"
									onChange={(e) => handleInputChange(e, weekNo)}
									value={classData.class_type}
								>
									<option value="">Select Class Type</option>
									{classType &&
										classType.length > 0 &&
										classType.map((classType, index) => (
											<option key={index} value={classType}>
												{classType}
											</option>
										))}
								</select>
							</div>
							<div className="vimeoIdContainer">
								<div>
									{" "}
									<label htmlFor={`vimeoId`}>
										Class video (Vimeo ID) <span>*</span>
									</label>
									<div className="formInput">
										{vimeoIds &&
											vimeoIds.length > 0 &&
											vimeoIds[weekNo] &&
											vimeoIds[weekNo].map((vimeoId, index) => (
												<div key={index} className="inputContainer">
													<input
														name="vimeo"
														type="text"
														id={`vimeoId-${index}`}
														value={vimeoId}
														placeholder="Enter ID"
														onChange={(e) =>
															handleVimeoIdChange(weekNo, index, e.target.value)
														}
													/>
													{index > 0 && (
														<button
															className="deleteBtn"
															type="button"
															onClick={() => handleDeleteVimeoId(weekNo, index)}
														>
															<img src={ImageList.MaterialDelete} alt="" />
														</button>
													)}
												</div>
											))}
									</div>
								</div>
								{/* Button to add more Vimeo IDs */}

								<div
									style={{
										width: "20%",
										display: "flex",
										alignItems: "end",
										position: "relative",
									}}
								>
									<button
										className="addBtn"
										type="button"
										onClick={() => handleAddVimeoId(weekNo)}
									>
										+
									</button>
								</div>
							</div>
						</div>
						{/* <div className="searchBar">
              <div className="formInput">
                <label htmlFor="mailNotification">
                  Mail notification<span>*</span>
                </label>
                <div>
                  <div className="radioBtns">
                    <div>
                      <input
                        name="notification"
                        type="radio"
                        id="mail_notification"
                        value="Yes"
                        checked={classData.mail_notification === true}
                        style={{ margin: "0" }}
                        onChange={(e) => handleMailOptions(e, weekNo)}
                        />
                      <label htmlFor="mail_notification">Yes</label>
                    </div>
                    <div>
                      <input
                        name="notification"
                        type="radio"
                        id="mail_notification"
                        value="No"
                        style={{ margin: "0" }}
                        checked={classData.mail_notification === false}
                        onChange={(e) => handleMailOptions(e, weekNo)}
                        />
                      <label htmlFor="mail_notification">No</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formInput">
                <label htmlFor="technical_classes">Technical class <span>*</span></label>
                <div>
                  <div className="radioBtns">
                    <div>
                      <input
                        name="class"
                        type="radio"
                        id="technical_classes"
                        value="Yes"
                        style={{ margin: "0" }}
                        checked={classData.technical_classes === true}
                        onChange={(e) => handleMailOptions(e, weekNo)}
                        />
                      <label htmlFor="class">Yes</label>
                    </div>
                    <div>
                      <input
                        name="class"
                        type="radio"
                        id="technical_classes"
                        value="No"
                        style={{ margin: "0" }}
                        checked={classData.technical_classes === false}
                        onChange={(e) => handleMailOptions(e, weekNo)}
                        />
                      <label htmlFor="class">No</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formInput">
                <label htmlFor="date">Mail notification Date <span>*</span></label>
                <input
                  type="date"
                  id="mail_notification_date"
                  onChange={(e) => handleInputChange(e, weekNo)}
                  value={classData.mail_notification_date}
                />
              </div>
              <div className="formInput">
                <label htmlFor="time">Mail notification Time <span>*</span></label>
                <input
                  type="time"
                  id="mail_notification_time"
                  onChange={(e) => handleInputChange(e, weekNo)}
                  value={classData.mail_notification_time}
                />
              </div>
            </div> */}
					</div>
				)}
			</div>
		</div>
	);
}

function UpdateClass() {
	const { batchId } = useParams();
	const navigate = useNavigate();
	const isInitialRender = useRef(true);
	const [classStartDate, setClassStartDate] = useState("");

	const [classData, setClassData] = useState([
		{
			week_no: 0,
			week_title: "",
			class_start_date: "",
			class_start_time: "",
			// zoom_meeting_id: "",
			// security_passcode: "",
			// zoom_meeting_registration_link: "",
			// venue_details: "",
			class_type: "",
			// vimeo_id: [], // Initialize an empty array for Vimeo IDs
			// mail_notification: false,
			// technical_classes: false,
			// mail_notification_date: "",
			// mail_notification_time: "",
		},
	]);
	const [formData, setFormData] = useState({
		batch_name: "",
		course_id: "",
		running_week_day: "",
		start_date: "",
		end_date: "",
	});
	const token = Cookies.get("token");

	// const [vimeoIds, setVimeoIds] = useState([]);

	const [noOfClasses, setNoOfClasses] = useState(1);
	const [vimeoIds, setVimeoIds] = useState(
		Array.from({ length: noOfClasses }, () => [""])
	);
	const [weeks, setWeeks] = useState([0]);

	const [collapseAll, setCollapseAll] = useState(false);
	const [type, setType] = useState("add");

	const addWeek = () => {
		if (weeks.length < noOfClasses) {
			setWeeks((prevWeeks) => [...prevWeeks, prevWeeks.length]);

			setClassData((prevData) => [
				...prevData,
				{
					// Initialize the new week's data with default values
					week_no: prevData.length,
					week_title: "",
					class_start_date: "",
					class_start_time: "",
					// zoom_meeting_id: "",
					// security_passcode: "",
					// zoom_meeting_registration_link: "",
					// venue_details: "",
					class_type: "",
					// vimeo_id: [], // Initialize an empty array for Vimeo IDs
					// mail_notification: false,
					// technical_classes: false,
					// mail_notification_date: "",
					// mail_notification_time: "",
				},
			]);

			setVimeoIds((prevIds) => [...prevIds, [""]]);
		} else {
			showToast("Can't add more then number of classes.");
		}
	};

	//  const [finalClassData, setFinalClassData] = useState({
	//   batch_id: batchId,
	//   classes: []
	//  })
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getBatch(token, batchId)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				// -------------------------Course Api----------
				getCourse(token, res.batch[0].course_id)
					.then((courseRes) => {
						if (courseRes.detail === "Signature has expired.") {
							navigate("/");
							showToast("Token has expired. Please log in again.");
							return;
						}
						if (courseRes.status === "failure") {
							showToast(courseRes.message);
							return;
						}
						setNoOfClasses(courseRes.total_classes);
					})
					.catch((err) => showToast("Something went wrong"));

				setFormData(res.batch[0]);
				setClassStartDate(res.batch[0].start_date);
			})
			.catch();

		getClass(token, batchId)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				// setFormData(res.batch[0])
				if (res.classes.length > 0) {
					setType("edit");
					setClassData(res.classes);
					setWeeks(
						Array.from({ length: res.classes.length }, (_, index) => index)
					);
					const vimeoIdsArray = res.classes.map(
						(classItem) => classItem.vimeo_id || []
					);

					setVimeoIds(vimeoIdsArray);
				}
			})
			.catch();
	}, []);

	const handleVimeoIdChange = (weekNo, index, value) => {
		setVimeoIds((prevIds) => {
			const updatedIds = [...prevIds];

			// Ensure the inner array is initialized
			if (!updatedIds[weekNo]) {
				updatedIds[weekNo] = [];
			}

			// Update the Vimeo ID at the specified index
			updatedIds[weekNo][index] = value;

			// Update classData with the new Vimeo IDs
			setClassData((prevData) => {
				const updatedData = [...prevData];
				const weekIndex = weekNo;
				const updatedWeekData = {
					...updatedData[weekIndex],
					vimeo_id: updatedIds[weekNo],
				};
				updatedData[weekIndex] = updatedWeekData;
				return updatedData;
			});

			return updatedIds;
		});
	};

	const handleAddVimeoId = (weekNo) => {
		setVimeoIds((prevIds) => {
			const updatedIds = [...prevIds];
			updatedIds[weekNo] = [...(updatedIds[weekNo] || []), ""];
			return updatedIds;
		});
	};

	const handleDeleteVimeoId = (weekNo, index) => {
		setVimeoIds((prevIds) => {
			const updatedIds = [...prevIds];
			const weekIds = [...updatedIds[weekNo]];
			weekIds.splice(index, 1);
			updatedIds[weekNo] = weekIds;

			setClassData((prevData) => {
				const updatedData = [...prevData];
				const weekIndex = weekNo;
				const updatedWeekData = {
					...updatedData[weekIndex],
					vimeo_id: weekIds, // Assuming the vimeoIds are stored in classData as an array
				};
				updatedData[weekIndex] = updatedWeekData;
				return updatedData;
			});

			return updatedIds;
		});
	};

	const handleMailOptionsChange = (weekNo, e) => {
		const { id, value } = e.target;

		setClassData((prevData) => {
			const updatedData = [...prevData];
			const weekIndex = weekNo;
			const updatedWeekData = {
				...updatedData[weekIndex],
				[id]: value === "Yes",
			};
			updatedData[weekIndex] = updatedWeekData;

			return updatedData;
		});
	};

	const handleClassDataChange = (weekNo, e) => {
		const { id, value } = e.target;

		setClassData((prevData) => {
			const updatedData = [...prevData];
			const weekIndex = weekNo;
			const updatedWeekData = {
				...updatedData[weekIndex],
				[id]: value,
				week_no: weekNo,
			};
			updatedData[weekIndex] = updatedWeekData;

			return updatedData;
		});
	};

	const handleMailNotificationChange = (weekNo, value) => {
		setClassData((prevData) => {
			const updatedData = [...prevData];
			const weekIndex = weekNo;
			const updatedWeekData = {
				...updatedData[weekIndex],
				mail_notification: value === "true",
			};
			updatedData[weekIndex] = updatedWeekData;
			return updatedData;
		});
	};

	const handleTechnicalClassesChange = (weekNo, value) => {
		setClassData((prevData) => {
			const updatedData = [...prevData];
			const weekIndex = weekNo;
			const updatedWeekData = {
				...updatedData[weekIndex],
				technical_classes: value === "true",
			};
			updatedData[weekIndex] = updatedWeekData;
			return updatedData;
		});
	};

	const handleCollapseAll = () => {
		setCollapseAll(true);

		setTimeout(() => {
			setCollapseAll(false);
		}, 1000);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleSubmit = () => {
		if (!formData.batch_name || formData.batch_name.trim() === "") {
			showToast("Batch Name field is empty");
			return;
		}

		if (!formData.course_name || formData.course_name.trim() === "") {
			showToast("Course Name field is empty");
			return;
		}

		const hasErrors = classData.some((weekData, weekIndex) => {
			if (weekData.week_no === "") {
				showToast(`Week ${weekIndex}: Week No is empty`);
				return true;
			}
			if (!weekData.week_title || weekData.week_title.trim() === "") {
				showToast(`Week ${weekIndex}: Week Title is empty`);
				return true;
			}
			if (!weekData.class_start_date) {
				showToast(`Week ${weekIndex}: Class Start Date is empty`);
				return true;
			}
			if (!weekData.class_start_time) {
				showToast(`Week ${weekIndex}: Time is empty`);
				return true;
			}

			if (!weekData.class_type) {
				showToast(`Week ${weekIndex}: Class Type is empty`);
				return true;
			}
			// if (!weekData.vimeoIds || weekData.vimeoIds.length === 0) {
			//   showToast(`Week ${weekIndex}: Vimeo Id is empty`);
			//   return true;
			// }

			if (
				weekData.class_type === "Offline" ||
				weekData.class_type === "Hybrid"
			) {
				if (!weekData.venue_details || weekData.venue_details.trim() === "") {
					showToast(`Week ${weekIndex + 1}: Venue Details is empty`);
					return true;
				}
			}
			if (
				weekData.class_type === "Online" ||
				weekData.class_type === "Hybrid"
			) {
				if (
					!weekData.zoom_meeting_id ||
					weekData.zoom_meeting_id.trim() === ""
				) {
					showToast(`Week ${weekIndex + 1}: Zoom Meeting Id is empty`);
					return true;
				}
				if (
					!weekData.security_passcode ||
					weekData.security_passcode.trim() === ""
				) {
					showToast(`Week ${weekIndex}: Security Passcode is empty`);
					return true;
				}
				if (
					!weekData.zoom_meeting_registration_link ||
					weekData.zoom_meeting_registration_link.trim() === ""
				) {
					showToast(`Week ${weekIndex}: Zoom Meeting Url is empty`);
					return true;
				}
			}
			return false;
		});

		if (!hasErrors) {
			// Proceed with your form submission logic here

			let finalClassData = {
				batch_id: batchId,
				classes: classData,
			};
			addClass(token, finalClassData)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}

					successToast("Class added successfully.");

					navigate(`/homework/${batchId}`);
				})
				.catch((err) => showToast("Error submitting form. Please try again."));
		}
	};

	function formatDateForInput(apiDate) {
		if (apiDate) {
			const date = new Date(apiDate);
			const formattedDate = date.toISOString().split("T")[0];

			return formattedDate;
		}
	}
	const handlePrevButtonClick = () => {
		navigate(`/batch/edit/${batchId}`);
	};

	return (
		<div className="updateClass">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Update classes</div>
					<div className="topNavigation">
						<div className="item active">
							<p>Edit Batch</p>
						</div>
						<div className="item active">
							<p>Class/Webinar</p>
						</div>
						<div className="item">
							<p>Homework</p>
						</div>
					</div>
					<div className="updateClassContainer">
						<div>
							<h4>Course no of classes: {noOfClasses}</h4>
						</div>

						<div className="searchBar">
							<div className="formInput">
								<label htmlFor="batchName">
									Batch Name <span>*</span>
								</label>
								<div>
									<input
										type="text"
										id="batch_name"
										// placeholder="Enter batch name"
										disabled
										value={formData.batch_name}
									/>
								</div>
							</div>

							<div className="formInput">
								<label htmlFor="course">
									Course<span>*</span>
								</label>
								<input
									type="text"
									id="course_id"
									// placeholder="Enter batch name"
									disabled
									value={formData.course_name}
								/>
							</div>

							<div className="formInput">
								<label htmlFor="runningWeek">Running week day name</label>
								<input
									type="text"
									id="running_week_day"
									// placeholder="Enter batch name"
									disabled
									value={getDayName(formData.running_week_day)}
								/>
							</div>

							<div className="formInput">
								<label htmlFor="startDate">
									Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="start_date"
									value={formatDateForInput(formData.start_date)}
									disabled
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Date <span>*</span>
								</label>
								<input
									type="date"
									id="end_date"
									value={formatDateForInput(formData.end_date)}
									disabled
								/>
							</div>
						</div>

						<div className="classDetails">
							<div className="classHeader">
								<h2>Class details batch wise</h2>

								<div>
									<p onClick={handleCollapseAll} className="collapseAllBtn">
										Collapse All
									</p>
									<div className="addClass">
										<button className="addClassBtn" onClick={addWeek}>
											+ Add class details
										</button>
									</div>
								</div>
							</div>

							{weeks &&
								weeks.length > 0 &&
								weeks.map((week, index) => (
									<WeekCard
										key={week}
										weekNo={week}
										isCollapsed={collapseAll}
										onClassDataChange={handleClassDataChange}
										onVimeoIdChange={handleVimeoIdChange}
										onAddVimeoId={handleAddVimeoId}
										onDeleteVimeoId={handleDeleteVimeoId}
										onMailOptionsChange={handleMailOptionsChange}
										classData={classData[week]}
										vimeoIds={vimeoIds}
										initialClassStartDate={classStartDate}
										// initialClassData={classData[week] || {}}
										onMailNotificationChange={handleMailNotificationChange}
										onTechnicalClassesChange={handleTechnicalClassesChange}
									/>
								))}
						</div>

						<div className="addClass">
							<button className="addClassBtn" onClick={addWeek}>
								+ Add class details
							</button>
						</div>

						{/* <div style={{ display: "flex", justifyContent: "center" }}>
              <div onClick={handleSubmit} className="nextBtn">
                <button>Next</button>
                <img src={ImageList.ArrowRight} alt="" />
              </div>
      
            </div> */}

						<div className="navigationBtns">
							<button className="prevBtn" onClick={handlePrevButtonClick}>
								<img src={ImageList.GoBackBlue} alt="" />
								Previous
							</button>
							<button className="nextButton" onClick={handleSubmit}>
								Next
								<img src={ImageList.ArrowForwardWhite} alt="" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateClass;
