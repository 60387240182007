import React from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentVideoRequstList.scss";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getVideoRequests } from "../../../interface/implementation/admin/getVideoRequestsAPIImp";
import VideoRequestModal from "../../../components/VideoRequestModal/VideoRequestModal";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";

import { useNavigate } from "react-router-dom";

function StudentVideoRequestList() {
	const navigate = useNavigate();
	const [videoRequests, setVideoRequests] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const token = Cookies.get("token");
	const [videoId, setVideoId] = useState(0);
	const [reloadUsers, setReloadUsers] = useState(false);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	useEffect(() => {
		getVideoRequests(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setVideoRequests(res.video_request_details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	const handleClose = () => {
		setShowModal(false);
		setReloadUsers(!reloadUsers);
	};

	return (
		<div className="videoRequest">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Participant Video Request Approval</div>

					<div className="videoRequestContainer">
						<div className="videoRequestTable">
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch Name</th>
										<th>Week No</th>
										<th>Participant Name</th>
										<th>Phone Number</th>
										<th>Update</th>
									</tr>
								</thead>
								<tbody>
									{videoRequests &&
										videoRequests.length > 0 &&
										videoRequests.map((student, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{student.batch_name}</td>
												<td>{student.week_no}</td>
												<td>{student.student_name}</td>
												<td>{student.student_phone_no}</td>
												<td className="updateBtn">
													<button
														className="requestBtn"
														onClick={() => {
															setVideoId(student.request_id);
															setShowModal(true);
														}}
													>
														Grant Request
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
							{showModal && (
								<VideoRequestModal
									onClose={handleClose}
									videoRequestId={videoId}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentVideoRequestList;
