import React, { useState, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./CSVImport.scss";
import AttendanceFile from "../../../assets/files/Attendance_template.csv";
import { uploadAttendanceCSV } from "../../../interface/implementation/admin/uploadAttendanceCSVAPIImp";
import Cookies from "js-cookie";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router";
import { showSuccessToast } from "../../../utils/helper";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

function CSVImport() {
	const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);

	const params = useParams();
	const fileInputRef = useRef(null);
	const [formData, setFormData] = useState({
		documentFiles: [],
	});
	const token = Cookies.get("token");

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files).map((file) => file.name);
		setFormData((prevData) => ({
			...prevData,
			documentFiles: [...prevData.documentFiles, ...files],
		}));
	};
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleRemoveFile = (index) => {
		setFormData((prevData) => {
			const updatedFiles = [...prevData.documentFiles];
			updatedFiles.splice(index, 1);
			return {
				...prevData,
				documentFiles: updatedFiles,
			};
		});
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};
	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleFileUpload = async () => {
		const fileInput = fileInputRef.current;
		if (fileInput.files.length > 0) {
			const formData = new FormData();
			formData.append("csv_file", fileInput.files[0]);
			formData.append("batch_id", params.batchId);
			setIsSpinnerLoading(true);
			try {
				await uploadAttendanceCSV(token, formData);
				successToast("File uploaded successfully");
				setIsSpinnerLoading(false);
			} catch (error) {
				showToast("Error uploading file");
				console.error("Error uploading file:", error);
			} finally {
				setIsSpinnerLoading(false);
			}
		} else {
			console.warn("No file selected for upload.");
		}
	};

	return (
		<div className="attendanceCSV">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">CSV Import Batch Participant attendance</div>
					<div className="attendanceStudentListContainer">
						<div className="first row">
							<p className="bullet">1:</p>
							<p className="firstTitle">Download “Template CSV File”</p>
							<div className="downloadBtn">
								<a
									href="https://bcitemplates.s3.ap-south-1.amazonaws.com/Attendance_template.csv"
									target="_blank"
									download="payment_template.csv"
								>
									<img src={ImageList.DownloadIcon} alt="" />
									Download
								</a>
							</div>
						</div>
						<div className="second row">
							<p className="bullet">2:</p>
							<div className="content">
								<h6>Add or Edit info CSV data according to the below fields</h6>
								<p>
									<b>Required fields: </b> Sl. No., Week No, Contact No, Type,
									ZOOM(Duration in Minutes) and HOTEL (Offline)
								</p>
							</div>
						</div>
						<div className="third row">
							<p className="bullet">3:</p>
							<div className="studentDetails" style={{ overflowX: "auto" }}>
								<table className="studentTable">
									<thead>
										<tr>
											<th>Sl. No.</th>
											<th>Week No</th>
											<th>Contact No</th>
											<th>Type</th>
											<th>ZOOM (Duration in Minutes) </th>
											<th>HOTEL (Offline)</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1 </td>
											<td>1 </td>
											<td>9090954654</td>
											<td>Offline/Online </td>
											<td>0 or min </td>
											<td>A/P</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="first row">
							{/* <p className="bullet">4:</p> */}
							{/* <p className="firstTitle">Last uploaded file:</p>
              <div className="downloadBtn">
                <button className="white">
                  <img src={ImageList.DownloadIconBlue} alt="" />
                  Download
                </button>
              </div> */}
						</div>
						<div className="fourth row">
							<p className="bullet">4:</p>
							<div className="uploadContent">
								<p className="title">
									Note: Import Participants Partial bifurcation payment with
									Primary account.
								</p>
								<div className="uploadArea">
									<p className="titleHomework">Upload CSV File *</p>
									<div className="uploadContainer">
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
										<div className="fileNames">
											{formData.documentFiles.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
										{formData.documentFiles.length === 0 && (
											<div>
												<p className="uploadInfo">No file chosen</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<button onClick={handleFileUpload} className="proceedBtn">
								Proceed
							</button>
							{isSpinnerLoading && <LoadingSpinner isLoading={true} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CSVImport;
