import { ApiCore } from "../apiCore";

const apiCreateOrder = new ApiCore({
	getAll: false,
	getSingle: false,
	postEncoded: false,
	post: true,
	put: false,
	delete: false,
	url: `api/workshop-payments/create-order`,
	version: "",
});

export default apiCreateOrder;
