import loginAPI from "../apis/loginAPI";

export function signIn(body) {
	return new Promise(async (resolve, reject) => {
		loginAPI
			.postEncoded(body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
