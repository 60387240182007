import getHelpedByAPI from "../../apis/admin/getHelpedByAPI";

export function getHelpedBy(token) {
  return new Promise(async (resolve, reject) => {
    getHelpedByAPI
      .getAll(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
