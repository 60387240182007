import React, { useEffect, useState } from "react";
import "./Payment.scss";
import ImageList from "../../utils/ImageList";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { showToasterMessage } from "../../utils/helper";
import { signIn } from "../../interface/implementation/loginAPIImp";
import Cookies from "js-cookie";

function Payment() {
	const [orderId, setOrderId] = useState("");
	const cashfree = window.Cashfree({
		mode: "production", //or production
	});

	const loginHandler = () => {
		let checkoutOptions = {
			// paymentSessionId: paymentSessionId, //need to add static paymentsession id
			returnUrl: `http://localhost:3000/workshop-registration/${orderId}`,
			// notifyUrl: 'https://a4d2-2406-7400-51-f62-dc09-b12e-af1e-8b83.ngrok-free.app/investor/invest/payment/status',
			redirectTarget: "_self", //optional (_self or _blank)
		};

		cashfree.checkout(checkoutOptions);
	};

	return (
		<div
			className="payment"
			style={{ backgroundImage: `url(${ImageList.LoginImage})` }}
		>
			<div className="loginContainer">
				<img src={ImageList.BCILogo} alt="BCI Logo" className="logo" />
				{/* <p className="title">Please enter your BCI username and password to login</p> */}

				<button className="loginBtn" onClick={loginHandler}>
					Test payment{" "}
					<img src={ImageList.ArrowForward} alt="" className="forwardArrow" />
				</button>
			</div>
			<ToastContainer limit={1} />
		</div>
	);
}

export default Payment;
