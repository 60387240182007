import autoSearchAccessAPI from "../../apis/admin/autoSearchAccessAPI";

export function autoSearchAccess(token, searchStr) {
	return new Promise(async (resolve, reject) => {
		autoSearchAccessAPI
			.getSingle(token, searchStr)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
