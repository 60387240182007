import getWorkshopsAPI from '../../apis/admin/getWorkshopsAPI';


export function getWorkshops(token){
    return new Promise(async(resolve, reject) => {
        getWorkshopsAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}