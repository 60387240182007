import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import Button from "../../../components/Button/Button";
import ImageList from "../../../utils/ImageList";
import "./StudentClassList.scss";
import ToggleButtons from "../../../components/ToggleButtons/ToggleButtons";
import StudentTable from "../../../components/StudentTable/StudentTable";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getStudentClasses } from "../../../interface/implementation/student/getStudentClassesAPIImp";
import VideoModal from "../../../components/VideoModal/VideoModal";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import VideoRequestStudentModal from "../../../components/VideoRequestStudentModal/VideoRequestStudentModal";

const TechnicalClassData = [];

const StudentClassList = () => {
	const [NormalClassData, setNormalClassData] = useState([]);
	const Headers = [
		"Weeks",
		"Class Date/Time",
		"Meeting/Venue Details",
		"Online class link",
		"Class Videos",
		"Homework",
	];

	const location = useLocation();
	const stateData = location.state;
	const batchId = stateData.batchId;
	const batchName = stateData.batchName;

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [screenIsOpen, setScreenIsOpen] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState("");
	const [classId, setClassId] = useState("");
	const [reloadUsers, setReloadUsers] = useState(false);

	const navigate = useNavigate();
	const token = Cookies.get("token");

	useEffect(() => {
		getStudentClasses(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setNormalClassData(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);
	const Buttons = [
		{
			label: "Normal Class",
			value: "normal_class",
		},
		{
			label: "Technical Class",
			value: "technical_class",
		},
	];

	const [selectedClass, setSelectClass] = useState("normal_class");

	const handleChange = (value) => {
		setSelectClass(value);
	};

	const getData = () => {
		switch (selectedClass) {
			case "normal_class":
				return NormalClassData;
			case "technical_class":
				return TechnicalClassData;
			default:
				return [];
		}
	};

	const openModal = (video, classId) => {
		setSelectedVideo(video);
		setClassId(classId);
		setModalIsOpen(true);
	};

	const openScreen = (classId) => {
		setClassId(classId);
		setScreenIsOpen(true);
	};

	const closeScreen = () => {
		setScreenIsOpen(false);
		setReloadUsers(!reloadUsers);
	};

	const closeModal = () => {
		setSelectedVideo("");
		setModalIsOpen(false);
	};

	return (
		<Layout
			title={batchName}
			// headerChildren={
			//   <>
			//     <Button
			//       buttonName={"Back"}
			//       buttonIcon={ImageList.BlueArrowLeft}
			//       color={"#4367B0"}
			//       onClick={() => { }}
			//       style={{
			//         border: "1px solid #4367B0",
			//       }}
			//     />
			//   </>
			// }
		>
			{/*   */}
			<div className="studentClassListContainer">
				<ToastContainer limit={1} />
				<ToggleButtons
					onChange={handleChange}
					value={selectedClass}
					Options={Buttons}
				/>
				<div className="warningText">
					Whenever you click on a video, it will be available for 3 days from
					that day.
				</div>
				<StudentTable
					headers={Headers}
					isEmpty={getData().length === 0}
					children={
						<>
							{getData()?.map((data, index) => (
								<tr key={index}>
									<td>{data.class_week_title}</td>
									<td>
										<div className="CRMWrapper">
											<div className="crmNameWrapper">
												<span className="crmIconWrapper">
													<img
														className="crmIcon"
														src={ImageList.CalenderSmallIcon}
													/>
												</span>
												{data.class_start_date && (
													<span className="crmContent">
														{data.class_start_date
															.split("-")
															.reverse()
															.join("/")}
													</span>
												)}
											</div>{" "}
											<div className="crmNameWrapper">
												<span className="crmIconWrapper">
													<img className="crmIcon" src={ImageList.TimeIcon} />
												</span>
												<span className="crmContent">
													{data.class_start_time}
												</span>
											</div>
										</div>
									</td>
									<td>{data.class_venue_details}</td>

									<td>
										{data.class_zoom_link ? (
											<button
												className="batchTableBtn"
												onClick={() => {
													window.open(data.class_zoom_link, "_blank");
												}}
											>
												<img src={ImageList.YoutubeIcon} alt="" /> Join class
											</button>
										) : (
											<p>Not available</p>
										)}
									</td>
									<td>
										<div className="classVideosMessageWrapper">
											{data.class_videos && data.class_videos.length > 0 ? (
												<>
													{data.class_videos[0].show_video_request_button && (
														<button
															className="batchTableBtn"
															onClick={() => {
																openScreen(data.class_id);
															}}
														>
															<img src={ImageList.YoutubeIcon} alt="" /> Request
														</button>
													)}

													{data.class_videos[0].vimeo_id &&
														data.class_videos[0].vimeo_id.length > 0 &&
														data.class_videos[0].vimeo_id.map((video, i) => {
															return (
																<span
																	onClick={() => {
																		openModal(video, data.class_id);
																	}}
																>
																	<img
																		src={ImageList.YoutubeIcon}
																		alt=""
																		className="youtubeIcon"
																	/>
																</span>
															);
														})}
													{!data.class_videos[0].show_video_request_button &&
														data.class_videos[0].vimeo_id.length === 0 &&
														data.class_videos[0].message}
												</>
											) : (
												"No videos available"
											)}
										</div>
									</td>

									<td>
										{/* <img src={ImageList.BackgroundedBookIcon} alt="" /> */}
										<Link
											to={{ pathname: `/student/class-List/homework` }}
											state={{ batchId: batchId, weekNo: data.class_week_no }}
											className=""
										>
											<img src={ImageList.BackgroundedBookIcon} alt="" />
										</Link>
									</td>
								</tr>
							))}
						</>
					}
				/>

				{modalIsOpen && selectedVideo && (
					<VideoModal
						isOpen={modalIsOpen}
						closeModal={closeModal}
						videoId={selectedVideo}
						batchId={batchId}
						classId={classId}
					/>
				)}
				{screenIsOpen && (
					<VideoRequestStudentModal
						isOpen={screenIsOpen}
						closeScreen={closeScreen}
						// videoId={selectedVideo}
						batchId={batchId}
						classId={classId}
					/>
				)}
			</div>
		</Layout>
	);
};

export default StudentClassList;
