import ticketReplyAPI from '../../apis/crmAdmin/ticketReplyAPI';


export function ticketReply(token, body){
    return new Promise(async(resolve, reject) => {
        ticketReplyAPI.put(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}