import { useState } from "react";
import "./ReplaceModal.scss";
// import { updateAttendance } from "../../interface/implementation/admin/updateAttendanceAPIImp";
import Cookies from "js-cookie";
import { updateVideoRequest } from "../../interface/implementation/admin/updateVideoRequestAPIImp";
import { showSuccessToast, showToasterMessage } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { replaceStudent } from "../../interface/implementation/admin/replaceStudentAPIImp";
import { preventWheelScroll, handleNumericInput } from "../../utils/helper";

function ReplaceModal({ onClick, replaceData, handleReload }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [formData, setFormData] = useState({
		batch_id: replaceData.batchId,
		student_name: replaceData.studentName,
		student_email_id: replaceData.emailId,
		student_phone_number: replaceData.phoneNumber,
		student_member_type: replaceData.memberType,
	});

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevCredentials) => ({
			...prevCredentials,
			[id]: value,
		}));
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleUpdate = async () => {
		debugger;
		if (
			!formData.replaced_student_name ||
			formData.replaced_student_name.trim() === ""
		) {
			showToast("Participant name field is empty");
			return;
		}
		if (
			!formData.replaced_student_email_id ||
			formData.replaced_student_email_id.trim() === ""
		) {
			showToast("Participant email field is empty");
			return;
		}
		if (
			!formData.replaced_student_phone_number ||
			formData.replaced_student_phone_number.trim() === ""
		) {
			showToast("Participant phone field is empty");

			return;
		}
		if (formData.replaced_student_phone_number.length < 10) {
			showToast("Phone No must be at least 10 digits");
			return;
		}

		await replaceStudent(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Participant replaced");
				handleReload();
				onClick();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleCloseClick = () => {
		onClick();
	};

	return (
		<div className="replaceModal">
			<ToastContainer limit={1} />
			<div className="modal">
				<article className="modal-container">
					<header className="modal-container-header">
						<h1 className="modal-container-title">Replace participant</h1>
						<button className="icon-button">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								onClick={handleCloseClick}
							>
								<path fill="none" d="M0 0h24v24H0z" />
								<path
									fill="currentColor"
									d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
								/>
							</svg>
						</button>
					</header>
					<section className="modal-container-body rtf">
						<div className="searchInputs">
							<div className="inputContainer">
								<label htmlFor="duration">
									New participant name <span>*</span>
								</label>
								<div className="inputCourseName">
									<input
										type="text"
										id="replaced_student_name"
										placeholder="Student name"
										onChange={handleInputChange}
										value={formData.replaced_student_name}
										required
									/>
								</div>
							</div>
							<div className="inputContainer">
								<label htmlFor="duration">
									New participant email <span>*</span>
								</label>
								<div className="inputCourseName">
									<input
										type="text"
										id="replaced_student_email_id"
										placeholder="Student email id"
										onChange={handleInputChange}
										value={formData.replaced_student_email_id}
										required
									/>
								</div>
							</div>
							<div className="inputContainer">
								<label htmlFor="duration">
									New participant phone <span>*</span>
								</label>
								<div className="inputCourseName">
									<input
										type="tel"
										id="replaced_student_phone_number"
										placeholder="Enter student phone number"
										onChange={handleInputChange}
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										value={formData.replaced_student_phone_number}
										minLength="10"
										maxLength="15"
										pattern="[0-9]{10,15}"
										title="Please enter a valid participant phone number (between 10 and 15 digits)."
										required
									/>
								</div>
							</div>
						</div>
					</section>
					<footer className="modal-container-footer">
						<button onClick={handleCloseClick} className="button is-ghost">
							Cancel
						</button>
						<button className="button is-primary" onClick={handleUpdate}>
							Update
						</button>
					</footer>
				</article>
			</div>
		</div>
	);
}

export default ReplaceModal;
