import { ApiCore } from "../../apiCore";

const apiTasks = new ApiCore({
	getAll: false,
	getSingle: false,
	postEncoded: false,
	post: true,
	put: false,
	delete: false,
	url: "api/studentCourse/insert-absentees-reason",
	version: "",
});

export default apiTasks;
