import getCRMHomeworkAPI from '../../apis/batchCRM/getCRMHomeworkAPI';

export function getCRMHomework(token, body){
    return new Promise(async(resolve, reject) => {
        getCRMHomeworkAPI.sort(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}