import React, { Children } from "react";
import "./DashboardCard.scss";

export default function DashboardCard({
  icon,
  title,
  subTitle,
  children,
  headerChildren,
}) {
  return (
    <div className="dashboardHeader">
      <div className="headerContainer">
        <div className="headerLeftContainer">
          <img className="headerIcon" src={icon} />
          <div className="heading">
            <p className="headingTitle">{title}</p>
            {subTitle && <p className="headingSubTitle">{subTitle}</p>}
          </div>
        </div>
        {headerChildren && (
          <div className="headerRightContainer">{headerChildren}</div>
        )}
      </div>
      {children}
    </div>
  );
}
