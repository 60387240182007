import React from "react";
import './LoadingSpinner.scss'
import ClipLoader from "react-spinners/ClipLoader";

function LoadingSpinner({isLoading}) {
  return (
    <div className="loadingSpinner">
      <ClipLoader
        color={"#4367B0"}
        loading={isLoading}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default LoadingSpinner;
