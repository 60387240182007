import editSinglePaymentAPI from '../../apis/admin/editSinglePaymentAPI';


export function editSinglePayment(token, body){
    return new Promise(async(resolve, reject) => {
        editSinglePaymentAPI.put(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}