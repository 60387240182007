import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentMessages.scss";
import ImageList from "../../../utils/ImageList";
import Cookies from "js-cookie";
import { getCRMMessages } from "../../../interface/implementation/student/getCRMMessagesAPIImp";
// import ImageList from '../../utils/ImageList';
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import StudentMessagesModal from "../../../components/StudentMesagesModal/StudentMessagesModal";
import CRMMessagesModal from "../../../components/CRMMesagesModal/CRMMessagesModal";
import { getStudentBatches } from "../../../interface/implementation/student/getStudentBatchesAPIImp";
function StudentMessages() {
	const navigate = useNavigate();
	// const [selectedBatch, setSelectedBatch] = useState('');
	// const [selectedCRM, setSelectedCRM] = useState('');
	// const [selectedStatus, setSelectedStatus] = useState('');
	const token = Cookies.get("token");

	const [userRole, setUserRole] = useState("");
	const [messageList, setMessagesList] = useState([]);
	const [ticket, setTicket] = useState("");
	const [reloadState, setReloadState] = useState(false);
	const [batchId, setBatchId] = useState();
	const [studentId, setStudentId] = useState();
	const [showModal, setShowModal] = useState(false);
	const [crmId, setCrmId] = useState();
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getStudentBatches(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setBatchId(res[0].batch_id);
			});
		};
		fetchDropdowns();
		getCRMMessages(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setMessagesList(res);
			})
			.catch();
	}, []);

	return (
		<div className="studentMessages">
			<ToastContainer limit={1} />
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">CRM Messages</div>
					<div className="courseListContainer">
						<div className="messages" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Batch</th>
										<th>Participant Name</th>
										<th>Phone Number</th>
										<th>Date/Time</th>
										<th>Messages</th>
										{/* <th>Status</th> */}
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{messageList &&
										messageList.length > 0 &&
										messageList.map((ticket, index) => (
											<tr key={index}>
												<td>{ticket.batch_name}</td>
												<td>{ticket.student_name}</td>
												<td>{ticket.phone_number}</td>
												{ticket.delivered_datetime && (
													<td>
														{ticket.delivered_datetime
															.split("-")
															.reverse()
															.join("/")}
													</td>
												)}
												<td>{ticket.messages}</td>
												{/* <td className={`status ${!ticket.is_read ? 'unread' : ''}`}> <span className={`status ${!ticket.is_read === 'Unread' ? 'unread-indicator' : ''}`}></span>{!ticket.is_read ? "Unread" : "Read"}</td> */}
												<td>
													<button
														onClick={() => {
															setStudentId(ticket.student_id);
															setCrmId(ticket.crm_id);
															setShowModal(true);
														}}
														className="viewBtn"
													>
														<img src={ImageList.MessageIcon} alt="" />
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
							{showModal && (
								<CRMMessagesModal
									onClick={() => setShowModal(false)}
									batchId={batchId}
									studentId={studentId}
									crmId={crmId}
									role="student"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentMessages;
