import getRolesAPI from '../../apis/admin/getRolesAPI';


export function getRoles(token){
    return new Promise(async(resolve, reject) => {
        getRolesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}