import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./CourseDetails.scss";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { getCourse } from "../../../interface/implementation/admin/getCourseAPIImp";
import { editCourse } from "../../../interface/implementation/admin/editCourseAPIImp";
import Cookies from "js-cookie";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";

function CourseDetails(props) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [formData, setFormData] = useState({
		course_name: "",
		basic_amount: 0,
		code: "",
		member_basic_amount: 0,
		duration_type: "",
		gst_amount: 0,
		duration: 0,
		total_amount: 0,
		total_classes: 0,
		total_installments: 0,
	});
	const [durationType, setDurationType] = useState(["days", "weeks", "months"]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	useEffect(() => {
		getCourse(token, props.course_id)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setFormData(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	const handleInputChange = (e) => {
		const { id, value } = e.target;

		setFormData((prevData) => {
			const newFormData = {
				...prevData,
				[id]: value,
			};

			if (id === "basic_amount" || id === "gst_amount") {
				const basicAmount = parseFloat(newFormData.basic_amount);
				const gstAmount = parseFloat(newFormData.gst_amount);
				const totalAmount =
					isNaN(basicAmount) || isNaN(gstAmount)
						? ""
						: basicAmount + basicAmount * (gstAmount / 100);

				newFormData.total_amount = totalAmount;
			}

			return newFormData;
		});
	};

	const handleSubmit = () => {
		if (!formData.course_name || formData.course_name.trim() === "") {
			showToast("Course Name field is empty");
			return;
		}

		if (!formData.code || formData.code.trim() === "") {
			showToast("Code field is empty");
			return;
		}

		if (!formData.duration_type || formData.duration_type.trim() === "") {
			showToast("Duration Type field is empty");
			return;
		}

		if (!formData.duration) {
			showToast("Duration field is empty");
			return;
		}

		if (!formData.total_classes) {
			showToast("Total Classes field is empty");
			return;
		}

		if (!formData.basic_amount) {
			showToast("Basic Amount field is empty");
			return;
		}
		if (!formData.member_basic_amount) {
			showToast("Member Basic Amount field is empty");
			return;
		}
		if (!formData.gst_amount) {
			showToast("GST Amount field is empty");
			return;
		}
		if (!formData.total_installments) {
			showToast("Total Installemnts field is empty");
			return;
		}
		editCourse(token, formData, props.course_id)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				successToast("Course updated successfully.");
				navigate("/course-list");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="courseDetails">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Edit Course</div>
					<div className="topNavigation">
						<div className="item active">
							<p>Course details</p>
						</div>
						{/* <div className="item">
                            <p>Homework</p>
                        </div>
                        <div className="item">
                            <p>Materials</p>
                        </div> */}
					</div>
					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							<div className="formInput">
								<label htmlFor="course_name">
									Course Name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="course_name"
										placeholder="Enter course name"
										onChange={handleInputChange}
										value={formData.course_name}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="basic_amount">
									Primary Basic Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="basic_amount"
										placeholder="Enter basic amount"
										onChange={handleInputChange}
										value={formData.basic_amount}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="code">
									Code <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="code"
										placeholder="Enter code"
										onChange={handleInputChange}
										value={formData.code}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="member_basic_amount">
									Member Basic Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="member_basic_amount"
										placeholder="Enter member basic amount"
										onChange={handleInputChange}
										value={formData.member_basic_amount}
									/>
								</div>
							</div>
							{/* <div  className='formInput'>
                                <label htmlFor="duration_type">Duration Type <span>*</span></label>
                                <div className="inputUserName">
                                    <input
                                        type="text"
                                        id="duration_type"
                                        placeholder="Enter duration type"
                                        onChange={handleInputChange}
                                        value={formData.duration_type}
                                    />
                                </div>
                            </div> */}
							<div className="formInput">
								<label htmlFor="duration_type">
									Duration type <span>*</span>
								</label>
								<div className="inputUserName">
									<select
										id="duration_type"
										onChange={handleInputChange}
										value={formData.duration_type}
									>
										<option value="">Select Type</option>
										{durationType &&
											durationType.length > 0 &&
											durationType.map((duration, index) => (
												<option key={index} value={duration}>
													{duration}
												</option>
											))}
									</select>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="gst_amount">
									GST (%) <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="gst_amount"
										placeholder="Enter GST (%)"
										onChange={handleInputChange}
										value={formData.gst_amount}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="duration">
									Duration <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="duration"
										placeholder="Enter duration"
										onChange={handleInputChange}
										value={formData.duration}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="total_amount">
									Total Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="total_amount"
										placeholder="Enter total amount"
										onChange={handleInputChange}
										value={formData.total_amount}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="total_classes">
									Total Classes <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="total_classes"
										placeholder="Enter total no. of classes"
										onChange={handleInputChange}
										value={formData.total_classes}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="total_installments">Total Installments</label>
								<div className="inputUserName">
									<input
										type="number"
										onFocus={preventWheelScroll}
										onInput={handleNumericInput}
										id="total_installments"
										placeholder="Enter total no. of installments"
										onChange={handleInputChange}
										value={formData.total_installments}
									/>
								</div>
							</div>
						</div>
						<button className="nextBtn" onClick={handleSubmit}>
							Submit{" "}
							<img
								src={ImageList.ArrowForward}
								alt=""
								className="forwardArrow"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CourseDetails;
