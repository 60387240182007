import React from "react";
import "./Input.scss";

const Input = ({
  id,
  inputType,
  onChange,
  value,
  label,
  options,
  style,
  ...props
}) => {
  return (
    <div className="inputContainer">
      <label htmlFor={id}>{label}</label>
      <div className="input" style={{ ...style }}>
        {inputType === "select" && (
          <select id={id} onChange={onChange} value={value} {...props}>
            <option value="">{props?.placeholder}</option>
            {options?.map((option, index) => (
              <option key={index} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </select>
        )}
        {inputType === "text" && (
          <input
            {...props}
            type="text"
            onChange={onChange}
            id={id}
            value={value}
          />
        )}
        {inputType === "textarea" && (
          <textarea
            {...props}
            id={id}
            cols="30"
            rows="10"
            onChange={onChange}
            value={value}
          ></textarea>
        )}
      </div>
    </div>
  );
};

export default Input;
