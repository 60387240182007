import apiTasks from "../../apis/student/submitAbsentReason";

export function submitAbsentReason(token, body) {
	return new Promise(async (resolve, reject) => {
		apiTasks
			.post(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
