import React from "react";
import Navbar from "../Navbar/Navbar";
import HeroSection from "../HeroSection/HeroSection";
import BusinessesStatsPage from "../BusinessesStatsPage/BusinessesStatsPage";
import WorkshopPage from "../WorkshopPage/WorkshopPage";
import TestimonialPage from "../TestimonialPage/TestimonialPage";
import AlumniPage from "../AlumniPage/AlumniPage";
import AboutPage from "../AboutPage/AboutPage";
import ProfitsPage from "../ProfitsPage/ProfitsPage";
import FooterPage from "../FooterPage/FooterPage";
import FeaturedInPage from "../FeaturedInPage/FeaturedInPage";
import BusinessOwnerSolutions from "../BusinessOwnerSolutions/BusinessOwnerSolutions";
import HowToPage from "../HowToPage/HowToPage";
import WhatsAppPics from "../WhatsAppPics/WhatsAppPics";

const HomePage = () => {
	return (
		<div>
			{/* <Navbar /> */}
			<HeroSection />
			<BusinessesStatsPage />
			<BusinessOwnerSolutions />
			<HowToPage />
			<WorkshopPage />
			<TestimonialPage />
			<AlumniPage />
			<AboutPage />
			<FeaturedInPage />
			<ProfitsPage />
			<WhatsAppPics />
			<FooterPage />
		</div>
	);
};

export default HomePage;
