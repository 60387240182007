import { toast } from "react-toastify";
import { useEffect, useState } from 'react';


export const showToasterMessage = (message) => {
  toast.error(message, {
    toastId: message,
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    toastId: message,
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const handleNumericInput = (e) => {
  if (e.target && e.target.value !== undefined) {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  }
};

export const preventWheelScroll = (event) => {
  event.target.addEventListener(
    "wheel",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );
};


// export const useLocalStorage = (key, initialValue) => {
//   const [value, setValue] = useState(() => {
//     const storedValue = localStorage.getItem(key);
//     return storedValue !== null ? storedValue : initialValue;
//   });

//   const setStoredValue = (newValue) => {
//     setValue(newValue);
//     localStorage.setItem(key, newValue);
//   };

//   useEffect(() => {
//     const handleStorageChange = (event) => {
//       if (event.key === key) {
//         setValue(event.newValue);
//       }
//     };

//     window.addEventListener('storage', handleStorageChange);

//     return () => {
//       window.removeEventListener('storage', handleStorageChange);
//     };
//   }, [key]);

//   return [value, setStoredValue];
// };


