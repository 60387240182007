import React from "react";
import "./StudentTable.scss";
import ImageList from "../../utils/ImageList";
import NoDataFound from "../NoDataFound/NoDataFound";

const StudentTable = ({ headers, children, isEmpty }) => {
  return (
    <div className="TableContainer">
      <div className="Table" style={{ overflowX: "auto" }}>
        <table style={{ minHeight: isEmpty ? "200px" : "" }}>
          <thead>
            <tr>
              {headers?.map((header) => (
                <th>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isEmpty ? (
              <div className="NoDataFoundWrapper">
                <NoDataFound />
              </div>
            ) : (
              children
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentTable;
