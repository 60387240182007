import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./PaymentDetail.scss";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import { getStudentFees } from "../../../interface/implementation/admin/getStudentFeesAPIImp";
import { deletePayment } from "../../../interface/implementation/admin/deletePaymentAPIImp";
import { showToasterMessage } from "../../../utils/helper";
import { addPayment } from "../../../interface/implementation/admin/addPaymentAPIImp";
import { getSinglePayment } from "../../../interface/implementation/admin/getSinglePaymentAPIImp";
import { editSinglePayment } from "../../../interface/implementation/admin/editSinglePaymentAPIImp";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
function PaymentDetail() {
	const navigate = useNavigate();
	const location = useLocation();
	const batchId = location.state.batchId;

	const studentId = location.state.studentId;
	const phoneNumber = location.state.primaryPhoneNo;
	const [batchInfo, setBatchInfo] = useState();
	const [feesList, setFeesList] = useState([]);
	const [totalFees, setTotalFees] = useState();
	const [totalPayment, setTotalPayment] = useState();
	const [paymentDetails, setPaymentDetails] = useState([]);
	const [reload, setReload] = useState(false);
	const [type, setType] = useState("create");
	const [paymentConfirmation, setPaymentConfirmation] = useState(false);
	const [paymentId, setPaymentId] = useState("");
	const [formData, setFormData] = useState({
		batch_id: batchId,
		phone_number: phoneNumber,
	});
	const token = Cookies.get("token");

	useEffect(() => {
		let body = `?batch_id=${batchId}&student_id=${studentId}`;
		getStudentFees(token, body).then((res) => {
			if (res.status === "failure") {
				showToasterMessage(res.message);
			} else {
				setBatchInfo(res.payments[0]);
				setFeesList(res.payments[0].fees_details);
				setTotalFees(res.payments[0].total_fees_details[0]);
				setPaymentDetails(res.payments[0].payment_details);
				setTotalPayment(res.payments[0].total_payment_details[0]);
			}
		});
	}, [reload]);

	const handleInputChange = (e) => {
		const { id, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleSave = () => {
		if (!formData.amount || formData.amount.length === 0) {
			showToast("Amount field is empty");
			return;
		}
		if (!formData.mode_of_payment || formData.mode_of_payment.length === 0) {
			showToast("Payment type field is empty");
			return;
		}
		if (!formData.payment_month || formData.payment_month.length === 0) {
			showToast("Payment month field is empty");
			return;
		}
		if (!formData.payment_date || formData.payment_date.length === 0) {
			showToast("Received Date field is empty");
			return;
		}

		if (type === "create") {
			addPayment(token, formData)
				.then((res) => {
					handleReload();
					setFormData({
						batch_id: batchId,
						phone_number: phoneNumber,
						mode_of_payment: "",
						payment_date: "",
						payment_month: "",
						amount: "",
						invoice_no: "",
						tds_amount: "",
						remarks: "",
						payment_description: "",
					});
				})
				.catch();
		} else {
			editSinglePayment(token, formData)
				.then((res) => {
					handleReload();
					setFormData({
						batch_id: batchId,
						phone_number: phoneNumber,
						mode_of_payment: "",
						payment_date: "",
						payment_month: "",
						amount: "",
						invoice_no: "",
						tds_amount: "",
						remarks: "",
						payment_description: "",
					});
				})
				.catch();
		}
	};

	const handleReload = () => {
		setReload((prevState) => !prevState);
	};

	const handleDeletePayment = (id) => {
		setPaymentId(id);
		setPaymentConfirmation(true);
		// let body = {
		//     payment_id: id
		// }
		// deletePayment(token, body).then((res) => {
		//     handleReload()
		// }).catch()
	};

	const handleSinglePayment = (id) => {
		getSinglePayment(token, id)
			.then((res) => {
				setType("edit");
				setFormData({
					payment_id: id,
					batch_id: batchId,
					phone_number: phoneNumber,
					student_id: studentId,
					mode_of_payment: res.payments_details[0].mode_of_payment,
					payment_date: res.payments_details[0].payment_date
						?.split("-")
						.reverse()
						.join("-"),
					payment_month: res.payments_details[0].payment_month,
					amount: res.payments_details[0].paid_amount,
					invoice_no: res.payments_details[0].invoice_no,
					tds_amount: res.payments_details[0].tds_amount,
					remarks: res.payments_details[0].remarks,
					payment_description: res.payments_details[0].payment_description,
				});
			})
			.catch();
	};

	return (
		<div className="paymentDetail">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Payment Detail</div>
					<div className="paymentDetailsContainer topDetails">
						<p className="topTitle">{batchInfo?.batch_name}</p>
						<div className="paymentInfo" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Participant Name</th>
										<th>Type</th>
										<th>Status</th>
										<th>Contact Number</th>
										<th>Email ID</th>
										<th>Course Fee</th>
										<th>Discount (%)</th>
										<th>Pay Amount</th>
										<th>GST</th>
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									{feesList &&
										feesList.length > 0 &&
										feesList.map((fee, index) => (
											<tr>
												<td>{fee.participant_name}</td>
												<td>{fee.participant_type}</td>
												<td>{fee.participant_status}</td>
												<td>{fee.phone_no}</td>
												<td>{fee.email_id}</td>
												<td>{fee.course_fee}</td>
												<td>{fee.discount}%</td>
												<td>{fee.pay_amount}</td>
												<td>{fee.gst}</td>
												<td>{fee.total}</td>
											</tr>
										))}
									<tr className="totalDetails">
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>Total</td>
										<td>{totalFees?.total_course_fee}</td>
										<td></td>
										<td>{totalFees?.total_pay_amount}</td>
										<td>{totalFees?.total_gst}</td>
										<td>{totalFees?.total_amount}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="paymentDetailsContainer">
						<div className="courseDetailForm midForm">
							<div className="topFormFields">
								<div className="formInput">
									<label htmlFor="studentBatchId"> Batch Name </label>
									<div className="inputUserName">
										<input
											type="text"
											id="studentBatchId"
											// onChange={handleInputChange}
											value={batchInfo?.batch_name}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="batchName">Course Name </label>
									<div className="inputUserName">
										<input
											type="text"
											id="batchName"
											// onChange={handleInputChange}
											value={batchInfo?.course_name}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="paymentType">Due Date</label>
									<div className="inputUserName">
										<input
											type="date"
											id="paymentType"
											// onChange={handleInputChange}
											value={totalPayment?.payment_due_date}
											disabled
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="studentName">Total Due Amount</label>
									<div className="inputUserName">
										<input
											type="text"
											id="studentName"
											// onChange={handleInputChange}
											value={totalPayment?.total_due_amount}
											disabled
										/>
									</div>
								</div>
								{/* <div className='formInput'>
                                        <label htmlFor="individualPayment">Individual Payment</label>
                                        <div className="inputUserName">
                                            <input
                                                type="text"
                                                id="individualPayment"
                                                placeholder=''
                                                onChange={handleInputChange}
                                                value={formData.individualPayment}
                                            />
                                        </div>
                                    </div> */}
								<div className="formInput">
									<label htmlFor="amount">
										Amount <span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="amount"
											placeholder="Enter Amount"
											onChange={handleInputChange}
											value={formData.amount}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="invoiceNumber">Invoice Number</label>
									<div className="inputUserName">
										<input
											type="text"
											id="invoice_no"
											placeholder="Enter Invoice Number"
											onChange={handleInputChange}
											value={formData.invoice_no}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="tds">TDS</label>
									<div className="inputUserName">
										<input
											type="text"
											id="tds_amount"
											placeholder="Enter TDS"
											onChange={handleInputChange}
											value={formData.tds_amount}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="paymentTypeDropdown">
										Payment Type <span>*</span>
									</label>
									<div className="inputUserName">
										<select
											id="mode_of_payment"
											onChange={handleInputChange}
											value={formData.mode_of_payment}
										>
											<option value="">Select payment type</option>
											<option value="cashfreee">Cash free </option>
											<option value="Mojo">Mojo </option>
											<option value="razorpay">Razorpay </option>
											<option value="compbyrj">Compy by RJ </option>
											<option value="banktransfer">Bank Transfer </option>
											<option value="adjustment">Adjustment </option>
											<option value="upi">UPI </option>
											<option value="compbyaj">Compy by AJ </option>
											<option value="adjustment">Adjustment </option>
										</select>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="paymentMonthDropdown">
										Payment Month <span>*</span>
									</label>
									<div className="inputUserName">
										<select
											id="payment_month"
											onChange={handleInputChange}
											value={formData.payment_month}
										>
											<option value="">Select Month</option>
											{[...Array(10)].map((_, index) => (
												<option key={index + 1} value={`Month ${index + 1}`}>
													{index + 1}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="receivedDate">
										Received Date <span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="date"
											id="payment_date"
											placeholder="Enter payment date"
											onChange={handleInputChange}
											value={formData.payment_date}
										/>
									</div>
								</div>
							</div>
							<div className="textboxWrapper">
								<div className="formInput">
									<label htmlFor="payment_description">
										{" "}
										Payment Description{" "}
									</label>
									<div className="inputUserName">
										<input
											type="textbox"
											id="payment_description"
											placeholder="Enter Payment Description"
											onChange={handleInputChange}
											value={formData.payment_description}
										/>
									</div>
								</div>
								<div className="formInput">
									<label htmlFor="remarks">Remark </label>
									<div className="inputUserName">
										<input
											type="textbox"
											id="remarks"
											onChange={handleInputChange}
											placeholder={"Enter Remarks"}
											value={formData.remarks}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="footerNavBtns">
							<button className="nextBtn" onClick={handleSave}>
								Save
							</button>
						</div>
					</div>
					<div className="paymentDetailsContainer bottomInfo">
						{/* <div className='formInput'>
                            <div className="inputUserName">
                                <select
                                    id="paymentType"
                                    onChange={handleInputChange}
                                    value={formData.salesPerson}
                                >
                                    <option value="" selected>Primary</option>
                                    <option value="paymentType1">Type 1</option>
                                    <option value="paymentType2">Type 2</option>
                                </select>
                            </div>
                        </div> */}
						<div className="bottomInfoTable" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Month</th>
										<th>Mode of payment</th>
										<th>Paid Amount</th>
										<th>TDS Amount</th>
										<th>Discount Amount</th>
										<th>Received Date</th>
										<th>Payment Description</th>
										<th>Remark</th>
										<th>Updates</th>
									</tr>
								</thead>
								<tbody>
									{paymentDetails &&
										paymentDetails.length > 0 &&
										paymentDetails.map((payment, index) => (
											<tr>
												<td>{payment.month}</td>
												<td>{payment.mode_of_payment}</td>
												<td>{payment.paid_amount}</td>
												<td>{payment.tds_amount}</td>
												<td>{payment.discount_amount}</td>
												<td>
													{payment.received_data.split("-").reverse().join("/")}
												</td>
												<td>{payment.payment_description}</td>
												<td>{payment.remarks}</td>
												<td>
													<img
														src={ImageList.EditIcon}
														style={{ backgroundColor: "green" }}
														alt=""
														onClick={() => {
															handleSinglePayment(payment.payment_id);
														}}
													/>
													<img
														src={ImageList.DeleteIcon}
														alt=""
														onClick={() => {
															handleDeletePayment(payment.payment_id);
														}}
													/>
												</td>
											</tr>
										))}
									<tr className="totalDetails">
										<td></td>
										<td></td>
										<td>{totalPayment?.transaction_amount}</td>
										<td>{totalPayment?.tds}</td>
										<td>{totalPayment?.total_discount}</td>
										<td>total</td>
										<td>={totalPayment?.transaction_amount}</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
							{paymentConfirmation && (
								<ConfirmationModal
									onClick={() => setPaymentConfirmation(false)}
									paymentId={paymentId}
									handleReload={handleReload}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PaymentDetail;
