import { routeBaseUrl } from "../utils/route";
import { handleResponse, handleError } from "./response";

//removed version
const post = (resource, token, body) => {
	let data = {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(`${routeBaseUrl}/${resource}`, data)
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

const postEncoded = (resource, body) => {
	let data = {
		method: "POST",
		body: new URLSearchParams(body).toString(),
		headers: {
			"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
		},
	};

	return fetch(`${routeBaseUrl}/${resource}`, data)
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

const postFormData = async (resource, token, body) => {
	let data = {
		method: "POST",
		body: body,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return fetch(`${routeBaseUrl}/${resource}`, data)
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

const sort = async (resource, token, body) => {
	return fetch(`${routeBaseUrl}/${resource}${body}`, {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	})
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

const getSingle = (resource, token, id) => {
	return fetch(`${routeBaseUrl}/${resource}/${id}`, {
		headers: {
			"Cache-Control": "no-cache",
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

const getSingleWithQuery = (resource, token, body) => {
	return fetch(`${routeBaseUrl}/${resource}${body}`, {
		headers: {
			"Cache-Control": "no-cache",
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

const getAll = (resource, token, body) => {
	if (body) {
		return fetch(`${routeBaseUrl}/${resource}/${body}`, {
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then(handleResponse)
			.catch(handleError);
	} else {
		return fetch(`${routeBaseUrl}/${resource}`, {
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then(handleResponse)
			.catch(handleError);
	}
};

const download = (resource, token, body) => {
	if (body) {
		return fetch(`${routeBaseUrl}/${resource}${body}`, {
			headers: {
				Accept: "text/csv",
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.text())
			.then(handleResponse)
			.catch(handleError);
	} else {
		return fetch(`${routeBaseUrl}/${resource}`, {
			headers: {
				Accept: "text/csv",
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.text())
			.then(handleResponse)
			.catch(handleError);
	}
};

const put = (resource, token, body, id) => {
	if (id) {
		let data = {
			method: "PUT",
			body: JSON.stringify(body),
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		};

		return fetch(`${routeBaseUrl}/${resource}/${id}`, data)
			.then((response) => response.json())
			.then(handleResponse)
			.catch(handleError);
	} else {
		let data = {
			method: "PUT",
			body: JSON.stringify(body),
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		};

		return fetch(`${routeBaseUrl}/${resource}`, data)
			.then((response) => response.json())
			.then(handleResponse)
			.catch(handleError);
	}
};

const putWithQuery = (resource, token, body) => {
	let data = {
		method: "PUT",
		// body: JSON.stringify(body),
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${routeBaseUrl}/${resource}/${body}`, data)
		.then((response) => response.json())
		.then(handleResponse)
		.catch(handleError);
};

export const apiProvider = {
	post,
	getSingle,
	postEncoded,
	getAll,
	put,
	postFormData,
	sort,
	download,
	getSingleWithQuery,
	putWithQuery,
};
