import getBatchCRMAPI from '../../apis/admin/getBatchCRMAPI';


export function getBatchCRM(token){
    return new Promise(async(resolve, reject) => {
        getBatchCRMAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}