import downloadUsersAPI from "../../apis/admin/downloadUsersAPI";

export function downloadUsers(token, body) {
  return new Promise(async (resolve, reject) => {
    downloadUsersAPI
      .download(token, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}