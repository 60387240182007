import React, { useRef, useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CreateCalendar.scss";
import ImageList from "../../../utils/ImageList";
import Cookies from "js-cookie";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { useNavigate, useParams } from "react-router-dom";
import { addCalendar } from "../../../interface/implementation/admin/addCalendarAPIImp";
import { getCalendar } from "../../../interface/implementation/admin/getCalendarAPIImp";
import { editCalendar } from "../../../interface/implementation/admin/editCalendarAPIImp";
function CreateCalendar() {
	const navigate = useNavigate();
	const { type, calendarId } = useParams();
	const [formData, setFormData] = useState({
		calendar_title: "",
		calendar_file: [],
		batch_id: 0,
	});
	const token = Cookies.get("token");

	const [fileName, setFileName] = useState("");
	const [batchList, setBatchList] = useState([]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchList(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
		if (type === "edit") {
			getCalendar(token, calendarId)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setFormData(res[0]);
					setFileName("Calendar_1");
				})
				.catch((err) => showToast("Something went wrong"));
		}
	}, []);

	const fileInputRef = useRef(null);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setFileName(file.name);
		if (file) {
			// Display image preview
			const imageUrl = URL.createObjectURL(file);

			// imageRef.current.src = imageUrl;
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;
				setFormData((prevData) => ({
					...prevData,
					calendar_file: [
						{
							filename: file.name,
							content: base64String,
						},
					],
				}));
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveFile = () => {
		setFormData((prevData) => ({
			...prevData,
			calendar_file: [],
		}));
		setFileName("");
	};

	const handleSubmit = () => {
		if (
			!formData.calendar_title ||
			formData.calendar_title.trim().length === 0
		) {
			showToast("Calendar title is empty");
			return;
		}
		if (!formData.batch_id) {
			showToast("Select Batch field is empty");
			return;
		}

		// Handle form submission logic
		let body = formData;
		if (type === "edit") {
			editCalendar(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					successToast("Calendar updated successfully.");
					navigate("/calendar-list");
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			addCalendar(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					successToast("Calendar created successfully.");
					navigate("/calendar-list");
				})
				.catch((err) => showToast("Something went wrong"));
		}
	};

	return (
		<div className="createCalendar">
			<div className="addBatch">
				<ToastContainer limit={1} />

				<div className="dashboardBody">
					<div className="dashboardContent">
						<div className="title">
							{type === "edit" ? "Edit Calendar" : "Create BCI Calendar"}
						</div>
						<div className="createCalendarContainer">
							<div className="createCalendarForm">
								<div className="formInput">
									<label htmlFor="title">
										Add Title<span>*</span>
									</label>
									<div className="inputUserName">
										<input
											type="text"
											id="calendar_title"
											placeholder="Enter title"
											onChange={handleInputChange}
											value={formData.calendar_title}
										/>
									</div>
								</div>

								<div className="formInput">
									<label htmlFor="batch">
										Select Batch <span>*</span>
									</label>
									<select
										id="batch_id"
										onChange={handleInputChange}
										value={formData.batch_id}
									>
										<option value="">Select Batch</option>
										{batchList &&
											batchList.length > 0 &&
											batchList.map((batch, index) => (
												<option key={index} value={batch.batch_id}>
													{batch.batch_name}
												</option>
											))}
									</select>
								</div>

								<div className="uploadContainer">
									<p className="fileHeading">Add a new file</p>
									<div>
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
											accept="image/*"
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
									</div>
									<div className="fileNames">
										<div>
											{formData.calendar_file &&
											formData.calendar_file.length > 0 &&
											formData.calendar_file ? (
												<p className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile()}
													>
														Remove
													</span>
												</p>
											) : (
												<p className="uploadInfo">
													File format: jpeg, jpg, png
												</p>
											)}
										</div>
									</div>
								</div>
								<div>
									<button className="saveBtn" onClick={handleSubmit}>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreateCalendar;
