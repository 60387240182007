import React from "react";
import "./BarcodeSideModal.scss";
import ImageList from "../../utils/ImageList";

function BarcodeSideModal({ onClick, qrCode }) {
	const handleOkayClick = () => {
		onClick();
	};
	return (
		<div className="modal-overlay">
			<div className="qr-modal-content" style={{ width: "30%" }}>
				<div className="modalHeader">
					<div className="left">
						<img src={ImageList.BarcodeIcon} alt="" />
						<p className="title">Barcode view</p>
					</div>
					<p className="crossIcon" onClick={handleOkayClick}>
						x
					</p>
				</div>
				<div className="modalBody">
					<p className="heading"></p>
					{qrCode === "None" ? (
						<div className="notice">
							<img src={ImageList.WarningIcon} alt="" />
							<p>Barcode Class date expired or Class does not exist!</p>
						</div>
					) : (
						<div className="img">
							<img
								style={{ objectFit: "contain", width: "100%" }}
								src={qrCode && qrCode.length > 0 ? qrCode[0].content : ""}
								alt=""
							/>
						</div>
					)}
				</div>
				{/* <button className='goBackBtn' onClick={handleOkayClick}> <img src={ImageList.GoBackBlue} alt="" />Go Back</button> */}
			</div>
		</div>
	);
}

export default BarcodeSideModal;
