import editUserAPI from '../../apis/admin/editUserAPI';


export function editUser(token, body){
    return new Promise(async(resolve, reject) => {
        editUserAPI.put(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}