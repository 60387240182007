import accessRequestAPI from '../../apis/student/accessRequestAPI';


export function accessRequest(token,body){
    return new Promise(async(resolve, reject) => {
      accessRequestAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}