import getMyStudentsAPI from '../../apis/batchCRM/getMyStudentsAPI';

export function getMyStudents(token, id){
    return new Promise(async(resolve, reject) => {
        getMyStudentsAPI.getFiltered(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}