import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./BatchList.scss";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import Cookies from "js-cookie";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { searchJustBatches } from "../../../interface/implementation/admin/serachJustBatchesAPIImp";
import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function BatchList() {
	const navigate = useNavigate();
	const [batchName, setBatchName] = useState("");
	const [course, setCourse] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	// const [currentPage, setCurrentPage] = useState(1);
	// const itemsPerPage = 100;
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	const [batchList, setBatchList] = useState([]);
	// const [currentItems, setCurrentItems] = useState([]);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (batchName) {
			newBody += `?batch_name=${batchName}`;
		}

		if (course) {
			newBody += `${newBody ? "&" : "?"}course=${encodeURIComponent(course)}`;
		}

		if (startDate) {
			newBody += `${newBody ? "&" : "?"}start_date=${startDate}`;
		}

		if (endDate) {
			newBody += `${newBody ? "&" : "?"}end_date=${endDate}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		searchJustBatches(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatchList(res.batch);
				setCurrentPage(1);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// setCourseList()
				setBatchList(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems =
		batchList && batchList.length > 0
			? batchList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="batchList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Batch List</div>
					<div className="batchListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="batchName">Batch Name</label>
									<div className="inputCourseName">
										<input
											type="text"
											id="batchName"
											placeholder="Search by batch name"
											onChange={(e) => handleInputChange(e, setBatchName)}
											value={batchName}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="course">Course</label>
									<div className="inputCode">
										<input
											type="text"
											id="course"
											placeholder="Search by courses"
											onChange={(e) => handleInputChange(e, setCourse)}
											value={course}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="startDate">Start Date</label>
									<div className="inputCode">
										<input
											type="date"
											id="startDate"
											placeholder="Search by start date"
											onChange={(e) => handleInputChange(e, setStartDate)}
											value={startDate}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="endDate">End Date</label>
									<div className="inputCode">
										<input
											type="date"
											id="endDate"
											placeholder="Search by end date"
											onChange={(e) => handleInputChange(e, setEndDate)}
											value={endDate}
										/>
									</div>
								</div>

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Apply
									</button>
								</div>
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch name</th>
										<th>Code</th>
										<th>Course</th>
										<th>Start date</th>
										<th>End date</th>
										<th>Batch CRM</th>
										<th>Company billing</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((batch, index) => (
											<tr key={batch.batch_id}>
												<td>{startSerialNumber + index}</td>
												<td>{batch.batch_name}</td>
												<td>{batch.batch_code}</td>
												<td>{batch.course_name}</td>
												<td>
													{batch.start_date &&
														batch.start_date !== null &&
														batch.start_date.split("-").reverse().join("/")}
												</td>
												<td>
													{batch.end_date &&
														batch.end_date !== null &&
														batch.end_date.split("-").reverse().join("/")}
												</td>

												<td>{batch.batch_crm}</td>
												<td>{batch.company_billing}</td>
												<td className="actionBtn">
													<Link
														to={{
															pathname: `/batch/edit/${batch.batch_id}`,
															state: {
																type: "Edit",
																batchId: batch.batch_id,
															},
														}}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Edit
													</Link>
													<Link
														to={{
															pathname: `/update-class/${batch.batch_id}`,
															state: { batchId: batch.batch_id },
														}}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Add Class
													</Link>
													<Link
														to={{
															pathname: `/homework/${batch.batch_id}`,
															state: { batchId: batch.batch_id },
														}}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Add Homework
													</Link>
													<Link
														to={{
															pathname: `/technicalnotes/${batch.batch_id}`,
															state: { batchId: batch.batch_id },
														}}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Add Technical Notes
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(batchList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BatchList;
