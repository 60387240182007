/** @format */

import BCILogo from "../assets/BCILogo.svg";
import ArrowForward from "../assets/arrow_forward.svg";
import EmailIcon from "../assets/Email.svg";
import EyeClose from "../assets/eyeClose.svg";
import Password from "../assets/Password.svg";
import GoBack from "../assets/goBack.svg";
import SearchIcon from "../assets/searchIcon.svg";
import AvatarIcon from "../assets/avatarIcon.svg";
import ProfilePic from "../assets/profilePic.jpeg";
import HamburgerIcon from "../assets/hamburgerIcon.svg";
import UserImg from "../assets/userImg.png";
import UserListImg from "../assets/UserListImg.png";
import EyeIcon from "../assets/eyeIcon.svg";
import BarcodeIcon from "../assets/barCodeIcon.svg";
import WarningIcon from "../assets/warningIcon.svg";
import GoBackBlue from "../assets/goBackBlue.svg";
import MessageIcon from "../assets/messageIcon.svg";
import TicketIcon from "../assets/ticketIcon.svg";
import ChatAvt from "../assets/chatAvt.png";
import UserPlaceholder from "../assets/userPlaceholder.png";
import UploadIcon from "../assets/uploadIcon.svg";
import EditIcon from "../assets/editIcon.svg";
import DeleteIcon from "../assets/deleteIcon.svg";
import UserUpdated from "../assets/userUpdated.png";
import WhiteUploadIcon from "../assets/whiteUploadIcon.svg";
import ArrowForwardWhite from "../assets/arroe_forward_white.svg";
import WhiteTick from "../assets/whiteTick.svg";
import UploadDocIcon from "../assets/upload-doc-icon.svg";
import PlusIcon from "../assets/plusIcon.svg";
import PlusSquare from "../assets/plusSquare.svg";
import FilePlus from "../assets/filePlus.svg";
import Close from "../assets/close.svg";
import ClipboardCheck from "../assets/clipboardCheck.svg";
import ArrowRight from "../assets/arrow_right.svg";
import MaterialDelete from "../assets/materialDelete.svg";
import SendIcon from "../assets/sendIcon.svg";
import InstallmentIcon from "../assets/installmentIcon.svg";
import DownloadIcon from "../assets/downloadIcon.svg";
import DownloadIconBlue from "../assets/downloadIconBlue.svg";
import CloudDownload from "../assets/cloud_download.svg";
import PowerIcon from "../assets/powerIcon.svg";
import RightVector from "../assets/rightVector.svg";
import StudentIcon from "../assets/studentsIcon.svg";
import CommentIcon from "../assets/commentIcon.svg";
import RupeeIcon from "../assets/rupeeIcon.svg";
import HomeworkIcon from "../assets/homeworkIcon.svg";
import AttendanceIcon from "../assets/attendanceIcon.svg";
// import YoutubeIcon from "../assets/youtube-icon.svg";
import UserSiderbarImg from "../assets/userSiderImg.png";
import LogoutIcon from "../assets/logoutIcon.svg";

// Sidebar Icons 
import DashboardIcon from "../assets/dashboardIcon.svg";
import DropdownIcon from "../assets/sidebarDropdownList.svg";

import RoundedRightArrow from "../assets/mingcute_right-line.svg";
import BookIcon from "../assets/fa_book.svg";
import CreditCardIcon from "../assets/ion_card-outline.svg";
import BlackEditIcon from "../assets/bx_edit.svg";
import PhoneIcon from "../assets/ic_baseline-phone.svg";
import ProfileIcon from "../assets/iconamoon_profile-fill.svg";
import ClassifyIcon from "../assets/mingcute_classify-2-fill.svg";
import ButtonTicketIcon from "../assets/entypo_ticket.svg";
import AgreementIcon from "../assets/icon-park-outline_agreement.svg";
import BlueArrowLeft from "../assets/arrow_left_alt.svg";
import TableClock from "../assets/tabler_clock.svg";
import WarningBoard from "../assets/warning-board.svg";
// import LogoutIcon from "../assets/logoutIcon.svg";
import CalenderIcon from "../assets/radix-icons_calendar.svg";
import TimeIcon from "../assets/mingcute_time-line.svg";
import CalenderSmallIcon from "../assets/radix-icons_calendar-small.svg";
import YoutubeIcon from "../assets/youtube-icon.svg";
import BackgroundedBookIcon from "../assets/BackgroundedBookIcon.svg";
import NoDataFoundIcon from "../assets/warning-board.svg";
import EyeOpen from "../assets/eyeOpen.svg";
import EditIconRed from "../assets/edit_icon_red.svg";
import LoginImage from "../assets/loginBanner.png";
import EnlargeIcon from "../assets/enlargeIcon.png";
import FullScreenIcon from "../assets/fullscreenicon.svg";
import SaveIcon from "../assets/saveIcon.png";
import BatchListIcon from "../assets/batchListIcon.svg";
import PaymentInfoIcon from "../assets/paymentInfoIcon.svg";
import PaymentOverdueIcon from "../assets/paymentOverdue.svg";
import HelpTicketIcon from "../assets/helpTicketIcon.svg";
import CrmMsgIcon from "../assets/crmMsgIcon.svg"
import NewDashboardIcon from "../assets/dashboard.svg"
const ImageList = {
  EyeOpen,
  LoginImage,
  BCILogo,
  CreditCardIcon,
  ArrowForward,
  EmailIcon,
  EyeClose,
  Password,
  GoBack,
  SearchIcon,
  AvatarIcon,
  ProfilePic,
  HamburgerIcon,
  UserImg,
  UserListImg,
  EyeIcon,
  BarcodeIcon,
  WarningIcon,
  GoBackBlue,
  MessageIcon,
  TicketIcon,
  ChatAvt,
  UserPlaceholder,
  UploadIcon,
  EditIcon,
  BlackEditIcon,
  DeleteIcon,
  UserUpdated,
  WhiteUploadIcon,
  ArrowForwardWhite,
  WhiteTick,
  UploadDocIcon,
  PlusIcon,
  PlusSquare,
  FilePlus,
  Close,
  ClipboardCheck,
  ArrowRight,
  MaterialDelete,
  SendIcon,
  CloudDownload,
  InstallmentIcon,
  DownloadIcon,
  DownloadIconBlue,
  PowerIcon,
  RightVector,
  StudentIcon,
  CommentIcon,
  RupeeIcon,
  HomeworkIcon,
  AttendanceIcon,
  // YoutubeIcon,
  UserSiderbarImg,
  LogoutIcon,
  // Dashboard Icons
  DashboardIcon,
  DropdownIcon,
  RoundedRightArrow,
  BookIcon,
  PhoneIcon,
  ProfileIcon,
  ClassifyIcon,
  AgreementIcon,
  ButtonTicketIcon,
  BlueArrowLeft,
  TableClock,
  WarningBoard,
  LogoutIcon,
  TimeIcon,
  CalenderIcon,
  CalenderSmallIcon,
  YoutubeIcon,
  BackgroundedBookIcon,
  NoDataFoundIcon,
  EditIconRed,
  EnlargeIcon,
  FullScreenIcon,
  SaveIcon,
  BatchListIcon,
  PaymentInfoIcon,
  PaymentOverdueIcon,
  HelpTicketIcon,
  CrmMsgIcon,
  NewDashboardIcon
};

export default ImageList;
