import updateMsgStatusAPI from '../../apis/student/updateMsgStatusAPI';


export function updateMsgStatus(token){
    return new Promise(async(resolve, reject) => {
        updateMsgStatusAPI.post(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}