// VideoModal.jsx
import React, { useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./VideoModal.scss"; // Add styling for the modal if needed
import Cookies from "js-cookie";
import { updateWatchTime } from "../../interface/implementation/student/updateWatchTimeAPIImp";
const VideoModal = ({ isOpen, closeModal, videoId, batchId, classId }) => {
	const token = Cookies.get("token");
	useEffect(() => {
		let body = {
			vimeo_id: videoId,
			class_id: classId,
			batch_id: batchId,
			video_played: true,
		};
		updateWatchTime(token, body)
			.then((res) => {
				//  closeModal()
			})
			.catch();
	}, []);
	return (
		<div className={`video-modal ${isOpen ? "open" : ""}`}>
			<div className="modal-content">
				<Vimeo video={videoId} width={"100vw"} height={"100vh"} autoplay />
				<button onClick={closeModal}>Close</button>
			</div>
		</div>
	);
};

export default VideoModal;
