import React, { useState } from "react";
import "./WhatsAppPics.scss";
import { WpData } from "../../../assets/Data/Constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import rightArrow from "../../../assets/arrow-right.svg";
import leftArrow from "../../../assets/arrow-left.svg";

function ArrowRight(props) {
	const { className, onClick } = props;
	return <img src={rightArrow} className={className} onClick={onClick} />;
}

function ArrowLeft(props) {
	const { className, onClick } = props;
	return <img src={leftArrow} className={className} onClick={onClick} />;
}

const Modal = ({ isOpen, onClose, image }) => {
	if (!isOpen) return null;

	return (
		<div className="modal-overlay" onClick={onClose}>
			<div className="modal-content" onClick={(e) => e.stopPropagation()}>
				<img src={image} alt="Selected" />
				<button className="close-button" onClick={onClose}>
					X
				</button>
			</div>
		</div>
	);
};

const WhatsAppPics = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	var settings = {
		autoplay: false,
		arrows: true,
		swipeToSlide: true,
		dots: false,
		infinite: true,
		variableWidth: true,
		nextArrow: <ArrowRight />,
		prevArrow: <ArrowLeft />,
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setSelectedImage(null);
	};

	return (
		<div className="WhatsAppPicsContainer">
			<h1 className="maititle" data-aos="fade-up" data-aos-once={true}>
				{WpData && WpData.maintitle}
			</h1>
			<h2 className="subtitle" data-aos="fade-up" data-aos-once={true}>
				{WpData && WpData.subtitle}
			</h2>
			<p className="wpDesc" data-aos="fade-up" data-aos-once={true}>
				{WpData && WpData.desc}
			</p>

			<div className="slider-container">
				<Slider {...settings}>
					{WpData &&
						WpData.imgs.map((w, index) => (
							<div
								key={index}
								className="items"
								onClick={() => handleImageClick(w)}
							>
								<img src={w} alt={`WhatsApp Image ${index}`} />
							</div>
						))}
				</Slider>
			</div>

			<Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
		</div>
	);
};

export default WhatsAppPics;
