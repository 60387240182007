import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./CrmOverduePaymentInfo.scss";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import { getStudentFees } from "../../../interface/implementation/admin/getStudentFeesAPIImp";
import { deletePayment } from "../../../interface/implementation/admin/deletePaymentAPIImp";
import { showToasterMessage } from "../../../utils/helper";
import { addPayment } from "../../../interface/implementation/admin/addPaymentAPIImp";
import { getSinglePayment } from "../../../interface/implementation/admin/getSinglePaymentAPIImp";
import { editSinglePayment } from "../../../interface/implementation/admin/editSinglePaymentAPIImp";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

import { getAccessPaymentDetails } from "../../../interface/implementation/admin/getAccessPaymentDetailsAPIImp";

import { getCrmOverduePaymentInfo } from "../../../interface/implementation/batchCRM/getCrmOverduePaymentInfoAPIImp";

function CrmOverduePaymentInfo() {
	const navigate = useNavigate();
	const location = useLocation();
	const batchId = location.state.batchId;
	const studentId = location.state.studentId;
	const [paymentDetails, setPaymentDetails] = useState([]);
	const token = Cookies.get("token");

	useEffect(() => {
		let body = `?batch_id=${batchId}&student_id=${studentId}`;
		getCrmOverduePaymentInfo(token, body).then((res) => {
			if (res.detail === "Signature has expired.") {
				navigate("/");
				showToast("Token has expired. Please log in again.");
				return;
			}
			if (res.status === "failure") {
				showToast(res.message);
				return;
			}
			setPaymentDetails(res);
		});
	}, []);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	return (
		<div className="paymentDetail">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Payment Detail</div>
					<div className="paymentDetailsContainer topDetails">
						<div className="pageTopDetails">
							<div className="leftDetails">
								<p>
									<b>Name:</b> {paymentDetails.student_name}
								</p>
								<p>
									<b>Company address:</b> {paymentDetails.company_address}
								</p>
								<p>
									<b>Phone:</b> {paymentDetails.phone_number}
								</p>
								<p>
									<b>Email:</b> {paymentDetails.email_id}
								</p>
							</div>
							<div className="rightDetails">
								<p>
									<b>Date:</b>{" "}
									{new Date()
										.toLocaleDateString()
										.split("-")
										.reverse()
										.join("/")}
								</p>
								<p>
									<b>Payment Due:</b>{" "}
									{paymentDetails.payment_due_date &&
										paymentDetails.payment_due_date
											.split("-")
											.reverse()
											.join("/")}
								</p>
							</div>
						</div>
						<div className="paymentInfo" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Batch Name</th>
										<th>Batch Code</th>
										<th>Course Name</th>
										<th>Payment category</th>
										<th>Due Date</th>
										<th>GST</th>
										<th>Due Amount</th>
										{/* <th>Pay Amount</th>
                                        <th>GST</th>
                                        <th>Total</th> */}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{paymentDetails.batch_name}</td>
										<td>{paymentDetails.batch_code}</td>
										<td>{paymentDetails.course_name}</td>
										<td>{paymentDetails.payment_category}</td>
										<td>
											{paymentDetails.payment_due_date &&
												paymentDetails.payment_due_date
													.split("-")
													.reverse()
													.join("/")}
										</td>
										<td>{paymentDetails.gst}%</td>
										<td>{paymentDetails.due_amount}</td>
									</tr>

									<tr className="totalDetails">
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>Total</td>
										<td>{paymentDetails?.total_installment_amount}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="paymentInfo" style={{ overflowX: "auto" }}>
							<p
								className="topTitle"
								style={{ marginTop: "40px", paddingBottom: "30px" }}
							>
								Course Installment Amounts
							</p>
							<table>
								<thead>
									<tr>
										<th>Installment No</th>
										<th>Due Date</th>
										<th>Installment Amount</th>
									</tr>
								</thead>
								<tbody>
									{paymentDetails.installment_details &&
										paymentDetails.installment_details.length > 0 &&
										paymentDetails.installment_details.map(
											(installment, index) => (
												<tr key={index}>
													<td>{installment.installment_no}</td>
													<td>
														{installment.due_date &&
															installment.due_date
																.split("-")
																.reverse()
																.join("/")}
													</td>
													<td>{installment.basic_installment_amount}</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CrmOverduePaymentInfo;
