import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./WorkshopList.scss";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import Cookies from "js-cookie";
import ImageModal from "../../../components/ImageModal/ImageModal";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { searchJustBatches } from "../../../interface/implementation/admin/serachJustBatchesAPIImp";
import { useNavigate } from "react-router-dom";
import { getWorkshops } from "../../../interface/implementation/admin/getWorkshopsAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
function WorkshopList() {
	const navigate = useNavigate();
	const [batchName, setBatchName] = useState("");
	const [course, setCourse] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [selectedImage, setSelectedImage] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);
	// const [currentPage, setCurrentPage] = useState(1);
	// const itemsPerPage = 100;
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	const [batchList, setBatchList] = useState([]);
	// const [currentItems, setCurrentItems] = useState([]);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getWorkshops(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// setCourseList()
				setBatchList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const openModal = (imageUrl) => {
		setSelectedImage(imageUrl);
		setModalIsOpen(true);
	};
	const closeModal = () => {
		setSelectedImage("");
		setModalIsOpen(false);
	};
	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems =
		batchList && batchList.length > 0
			? batchList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="workshopList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Workshop List</div>
					<div className="batchListContainer">
						{/* <div className="topSearchBar">
                            <div className="searchInputs">
                                <div className='inputContainer'>
                                    <label htmlFor="batchName">Workshop Title</label>
                                    <div className="inputCourseName">
                                        <input
                                            type="text"
                                            id="batchName"
                                            placeholder="Search by batch name"
                                            onChange={(e) => handleInputChange(e, setBatchName)}
                                            value={batchName}
                                        />
                                    </div>
                                </div>
                                <div className='inputContainer'>
                                    <label htmlFor="course">Course</label>
                                    <div className="inputCode">
                                        <input
                                            type="text"
                                            id="course"
                                            placeholder="Search by courses"
                                            onChange={(e) => handleInputChange(e, setCourse)}
                                            value={course}
                                        />
                                    </div>
                                </div>
                                <div className='inputContainer'>
                                    <label htmlFor="startDate">Start Date</label>
                                    <div className="inputCode">
                                        <input
                                            type="date"
                                            id="startDate"
                                            placeholder="Search by start date"
                                            onChange={(e) => handleInputChange(e, setStartDate)}
                                            value={startDate}
                                        />
                                    </div>
                                </div>
                                <div className='inputContainer'>
                                    <label htmlFor="endDate">End Date</label>
                                    <div className="inputCode">
                                        <input
                                            type="date"
                                            id="endDate"
                                            placeholder="Search by end date"
                                            onChange={(e) => handleInputChange(e, setEndDate)}
                                            value={endDate}
                                        />
                                    </div>
                                </div>

                                <div className="searchBtnContainer">
                                    <button className="searchBtn" onClick={handleSearch}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div> */}
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Workshop Title</th>
										<th>Workshop Type</th>
										<th>Start date</th>
										<th>End date</th>
										<th>Work Flyer</th>
										<th>Registration link</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((batch, index) => (
											<tr key={batch.workshop_id}>
												<td>{startSerialNumber + index}</td>
												<td>{batch.workshop_title}</td>
												<td>{batch.workshop_type}</td>
												<td>
													{batch.workshop_start_datetime &&
														batch.workshop_start_datetime !== null && (
															<>
																{new Date(
																	batch.workshop_start_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																})}
																<br />
																{new Date(
																	batch.workshop_start_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleTimeString("en-GB", {
																	hour: "2-digit",
																	minute: "2-digit",
																})}
															</>
														)}
												</td>

												<td>
													{batch.workshop_end_datetime &&
														batch.workshop_end_datetime !== null && (
															<>
																{new Date(
																	batch.workshop_end_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleDateString("en-GB", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																})}
																<br />
																{new Date(
																	batch.workshop_end_datetime.replace(
																		/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
																		"$3-$2-$1T$4:$5:$6"
																	)
																).toLocaleTimeString("en-GB", {
																	hour: "2-digit",
																	minute: "2-digit",
																})}
															</>
														)}
												</td>

												<td
													onClick={() =>
														openModal(batch.workshop_flyer[0].content)
													}
												>
													<img
														style={{
															width: "300px",
															height: "300px",
															objectFit: "contain",
														}}
														// src={batch.workshop_flyer[0]?.content}
														src={
															Array.isArray(batch.workshop_flyer) &&
															batch.workshop_flyer.length > 0
																? batch.workshop_flyer[0]?.content
																: ""
														}
														alt=""
														className="calendarFile"
													/>
												</td>
												<td>
													<a href={batch.registration_link} target="_blank">
														Register
													</a>
												</td>
												<td>
													{/* <Link to={{ pathname: `/batch/edit/${batch.batch_id}`, state: { type: "Edit", batchId: batch.batch_id } }} className='editBtn'>
                                                    <img src={ImageList.EditIcon} alt="" />Edit
                                                </Link> */}
													<Link
														to={{
															pathname: `/update-workshop-class/${batch.workshop_id}`,
														}}
														state={{
															workshopId: batch.workshop_id,
															startDate: batch.workshop_start_datetime,
															endDate: batch.workshop_end_datetime,
														}}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Add Class
													</Link>
													<Link
														to={{
															pathname: `/add-workshop/edit/${batch.workshop_id}`,
														}}
														state={{ batch: batch }}
														className="editBtn"
														style={{ marginTop: "0.5rem" }}
													>
														<img src={ImageList.EditIcon} alt="" />
														Edit Workshop
													</Link>
													{/* <Link to={{ pathname: `/homework/${batch.batch_id}`, state: {  batchId: batch.batch_id } }} className='editBtn'>
                                                    <img src={ImageList.EditIcon} alt="" />Add Homework
                                                </Link>
                                                <Link to={{ pathname: `/technicalnotes/${batch.batch_id}`, state: {  batchId: batch.batch_id } }} className='editBtn'>
                                                    <img src={ImageList.EditIcon} alt="" />Add Technical Notes
                                                </Link> */}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<ImageModal
							isOpen={modalIsOpen}
							closeModal={closeModal}
							imageUrl={selectedImage}
						/>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(batchList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WorkshopList;
