import React from "react";
import "./BusinessesStatsPage.scss";
import { BusinessesStats } from "../../../assets/Data/Constants";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const BusinessesStatsPage = () => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	return (
		<div className="BusinessesStatsContainer" ref={ref}>
			<label htmlFor="titleTop" className="titleTop">
				TRANSFORMING
			</label>
			<label htmlFor="mainTitle" className="mainTitle">
				BUSINESSES SINCE 2004
			</label>
			<div className="subStatsContainer">
				{BusinessesStats &&
					BusinessesStats.map((b, index) => (
						<div className="subStats" key={index}>
							<label htmlFor="counts">
								{inView && (
									<CountUp start={10} end={b.count} duration={2} delay={0} />
								)}
								<span>{b.term}</span>
							</label>
							<p>{b.desc}</p>
						</div>
					))}
			</div>
		</div>
	);
};

export default BusinessesStatsPage;
