import React, { useEffect, useState } from "react";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./AccessApprovalRequest.scss";
import { Link } from "react-router-dom";
import { getStudentBatchList } from "../../../interface/implementation/admin/getStudentBatchListAPIImp";
import { getAproveRequestList } from "../../../interface/implementation/admin/getAproveRequestListAPIImp";
import { autoSearchAccess } from "../../../interface/implementation/admin/autoSearchAccessAPIImp";
import { searchAccess } from "../../../interface/implementation/admin/searchAccessAPIImp";
import GrantAccessModal from "../../../components/GrantAccessModal/GrantAccessModal";

import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import Cookies from "js-cookie";
import { searchJustBatches } from "../../../interface/implementation/admin/serachJustBatchesAPIImp";
import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function AccessApprovalRequest() {
	const navigate = useNavigate();
	const [batchname, setBatchname] = useState("");
	const [code, setCode] = useState("");
	const [course, setCourse] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [batchList, setBatchList] = useState([]);
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	const [requestList, setRequestList] = useState([]);

	const [grantAccessModal, setGrantAccessModal] = useState(false);
	const [selectedRequest, setSelectedRequest] = useState([]);

	// ---------------------------Auto Search Access ---------------------------------
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [search, setSearch] = useState("");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getAproveRequestList(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setRequestList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [grantAccessModal]);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (search) {
			// newBody += `?search=${search}`;
			newBody += `${search}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const searchFilter = (newBody) => {
		searchAccess(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setRequestList(res);
			})
			.catch();
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	//--------------------- AutoSearch Handler ----------------------------------

	const handleAutoSearch = async (e) => {
		if (e.target.value) {
			let newBody = e.target.value;

			autoSearchAccess(token, newBody)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setAutoValues(res);
					setAutoModal(true);
				})
				.catch();
		}
	};

	const handleGrantRequest = (request) => {
		setGrantAccessModal(true);
		setSelectedRequest(request);
	};

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		requestList && requestList.length > 0
			? requestList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="batchList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Payment Overdue Request</div>
					<div className="batchListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="search">
										Search <span>*</span>
									</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder="Company name/Student name/ Phone"
											onChange={(e) => {
												handleAutoSearch(e);
												handleInputChange(e, setSearch);
											}}
											value={search}
										/>
										{autoValues.length > 0 && autoModal && (
											<div className="searchDropdown">
												{autoValues.map((item, index) => (
													<div
														key={index}
														onClick={() => {
															setSearch(item);
															setAutoModal(false);
														}}
													>
														<p>{item}</p>
													</div>
												))}
											</div>
										)}
									</div>
								</div>

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>
								{/* <div className="searchBtnContainer">
                  <button className="resetBtn">Reset</button>
                </div> */}
							</div>
						</div>
						<div className="courses" style={{ overflowX: "auto" }}>
							<table className="studentBatchTable studentBatchTableNew">
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch Name</th>
										<th>Student Name</th>
										<th>Phone</th>
										<th>Company code</th>
										<th>Course</th>
										<th>Company name</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((request, index) => (
											<tr key={index}>
												<td>{startSerialNumber + index}</td>
												<td>{request.batch_name}</td>
												<td>{request.student_name}</td>
												<td>{request.phone_no}</td>
												<td>{request.company_code}</td>
												<td>{request.course_name}</td>
												<td>{request.company_name}</td>
												{/* <td>{batch.end_date && (
                          (batch.end_date).split('-').reverse().join('/')
                        )}</td> */}
												<td
													style={{
														// width: "30em",
														display: "flex",
														gap: "4px",
														justifyContent: "flex-start",
													}}
												>
													<Link
														className="batchTableBtn"
														onClick={() => handleGrantRequest(request)}
													>
														{/* <img src={ImageList.PlusSquare} alt="" /> */}
														Grant Request
													</Link>
													<Link
														to={{
															pathname: `/access-payment-detail/${request.student_id}`,
														}}
														state={{
															studentId: request.student_id,
															batchId: request.batch_id,
														}}
														className="batchTableBtn"
														style={{ backgroundColor: "#1251F3" }}
													>
														Payment Details
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						{grantAccessModal && (
							<GrantAccessModal
								onClick={() => setGrantAccessModal(false)}
								requestDetails={selectedRequest}
							/>
						)}
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(requestList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AccessApprovalRequest;
