import updateWatchTimeAPI from '../../apis/student/updateWatchTimeAPI';


export function updateWatchTime(token,body){
    return new Promise(async(resolve, reject) => {
        updateWatchTimeAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}