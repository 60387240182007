import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import { ToastContainer } from "react-toastify";
import "./RefundInvoice.scss";
import { getStudentFees } from "../../../interface/implementation/admin/getStudentFeesAPIImp";
import Cookies from "js-cookie";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { refundStudent } from "../../../interface/implementation/admin/refundStudentAPIImp";
function RefundInvoice() {
	const { type, userId } = useParams();
	const location = useLocation();
	const batchId = location.state.batchId;
	const studentId = location.state.studentId;
	const studentName = location.state.studentName;
	const companyName = location.state.companyName;
	const participantType = location.state.participantType;
	const navigate = useNavigate();
	const fileInputRef = useRef();
	const [batchInfo, setBatchInfo] = useState();
	const [totalFees, setTotalFees] = useState();
	const [totalPayment, setTotalPayment] = useState();
	const [viewAdjustmentType, setViewAdjustmentType] = useState(false);
	const [shiftAdjustmentType, setShiftAdjustmentType] = useState(false);
	const [refundedType, setRefundedType] = useState(false);
	const [fileNames, setFileNames] = useState([]);
	const [partnerStudents, setPartnerStudents] = useState([]);
	const token = Cookies.get("token");

	const [formData, setFormData] = useState({
		participant_type: participantType,
		student_id: studentId,
		replaced_student_id: null,
		approved_file: [],
	});

	useEffect(() => {
		let body = `?batch_id=${batchId}&student_id=${studentId}`;
		getStudentFees(token, body).then((res) => {
			if (res.status === "failure") {
				showToasterMessage(res.message);
			} else {
				setBatchInfo(res.payments[0]);

				setTotalFees(res.payments[0].total_fees_details[0]);

				setTotalPayment(res.payments[0].total_payment_details[0]);
				const filteredStudents = res.payments[0].fees_details.filter(
					(student) => student.member_type === "partner"
				);

				// Extract student_id and participant_name
				const extractedData = filteredStudents.map((student) => ({
					student_id: student.student_id,
					participant_name: student.participant_name,
				}));

				setPartnerStudents(extractedData);
			}
		});
	}, []);

	const handleInputChange = (e) => {
		const { id, value } = e.target;

		setFormData((prevData) => ({ ...prevData, [id]: value }));

		if (id === "refund_type") {
			if (value === "Adjustment") {
				setViewAdjustmentType(false);
				setShiftAdjustmentType(true);
				setRefundedType(false);
			} else if (value === "Shift" || value === "Next") {
				setShiftAdjustmentType(true);
				setViewAdjustmentType(false);
				setRefundedType(false);
			} else if (value === "Refunded") {
				setViewAdjustmentType(false);
				setShiftAdjustmentType(false);
				setRefundedType(false);
			} else {
				setViewAdjustmentType(false);
				setShiftAdjustmentType(false);
				setRefundedType(false);
			}
		}
	};

	const submitFormData = async () => {
		if (!formData.approved_file) {
			showToasterMessage("Approved file is mandatory");
			return;
		}
		if (!fileNames || fileNames.length === 0) {
			showToasterMessage("Approved file is mandatory");
			return;
		}
		refundStudent(token, formData)
			.then((res) => {
				if (res.status === "failure") {
					showToasterMessage(res.message);
					return;
				}
				showSuccessToast(`${formData.refund_type} Successfull`);
				navigate("/batch-student-list", {
					state: {
						batchId: batchId,
					},
				});
			})
			.catch();
	};

	const handleNext = async () => {
		await submitFormData();
	};

	// const handleBack = () => {
	//   navigate('/batch-student-list');
	// };

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (e) => {
		const files = e.target.files;

		// Create an array to store base64 representations of each file
		const newFiles = [];

		// Loop through each file
		for (let i = 0; i < files.length; i++) {
			const file = files[i];

			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;

				// Add the file object to the array
				newFiles.push({
					filename: file.name,
					content: base64String,
				});

				// All files have been processed, update state

				setFormData((prevData) => ({
					...prevData,
					approved_file: {
						filename: file.name,
						content: base64String,
					},
				}));

				// Update file names
				setFileNames((prevFileNames) => [
					// ...prevFileNames,
					...newFiles.map((file) => file.filename),
				]);
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveFile = (index) => {
		setFormData((prevData) => ({
			...prevData,
			approved_file: {},
		}));

		// Remove the file name at the specified index
		setFileNames((prevFileNames) => {
			const updatedFileNames = [...prevFileNames];
			updatedFileNames.splice(index, 1);
			return updatedFileNames;
		});
	};

	return (
		<div className="refundInvoice">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Complete Participant Process</div>
					<div className="courseDetailsContainer">
						<h6 className="titleDetails">
							<b>Course Amount :</b> {totalFees?.total_course_fee} |{" "}
							<b>Total Due amount :</b> {totalPayment?.total_due_amount}{" "}
							<b>{batchInfo?.batch_name}</b>
						</h6>
						<div className="refundInvoiceForm">
							<div className="formInput">
								<label htmlFor="offerCode">Participant Batch ID</label>
								<div className="inputUserName">
									<input
										type="text"
										id="offerCode"
										placeholder="Enter Participant batch ID"
										onChange={handleInputChange}
										value={batchId}
										disabled
									/>
								</div>
							</div>

							<div className="formInput">
								<label htmlFor="paymentType">Batch Name</label>
								<div className="inputUserName">
									<input
										id="paymentType"
										onChange={handleInputChange}
										value={batchInfo?.batch_name}
										disabled
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="paymentType">Payment Type</label>
								<div className="inputUserName">
									<input
										id="paymentType"
										onChange={handleInputChange}
										value={"Installment"}
										disabled
									/>
								</div>
							</div>

							<div className="formInput">
								<label htmlFor="studentName">Participant Name</label>
								<div className="inputUserName">
									<input
										type="text"
										id="studentName"
										placeholder="Enter Participant name"
										onChange={handleInputChange}
										value={studentName}
										disabled
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="companyName">Company Name</label>
								<div className="inputUserName">
									<input
										type="text"
										id="companyName"
										placeholder="Enter company name"
										onChange={handleInputChange}
										value={companyName}
										disabled
									/>
								</div>
							</div>
						</div>
						<div className="refundInvoiceForm formLower">
							{/* <div className="formInput">
                <label htmlFor="adjustmentAmount">Adjust/Refund / Shift Amount</label>
                <div className="inputUserName">
                  <input
                    type="text"
                    id="adjust_shift_amount"
                    placeholder="Enter adjustment amount"
                    onChange={handleInputChange}
                    value={formData.adjust_shift_amount}
                  />
                </div>
              </div> */}

							<div className="formInput">
								<label htmlFor="adjustmentDate">
									Adjust/ Refund / Shift Date
								</label>
								<div className="inputUserName">
									<input
										type="date"
										id="adjust_shift_date"
										placeholder="Enter adjustment date"
										onChange={handleInputChange}
										value={formData.adjust_shift_date}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="adjustmentType">Adjust/Shift/Refund</label>
								<div className="inputUserName">
									<select
										type="text"
										id="refund_type"
										placeholder="Select adjustment type"
										onChange={handleInputChange}
										value={formData.refund_type}
									>
										<option value="">Select Adjustment Type</option>
										<option value="Adjustment">Adjustment</option>
										<option value="Shift">Shift</option>
										<option value="Refunded">Refunded</option>
										<option value="Dropout">Dropout</option>
										<option value="Next">Next</option>
										<option value="Discontinued">Discontinued</option>
										{/* <option value="Registered">Registered & Next</option> */}
									</select>
								</div>
							</div>

							{refundedType && (
								<div className="formInput">
									<label htmlFor="fromMonth">From Month</label>
									<div className="inputUserName">
										<select
											type="text"
											id="from_month"
											onChange={handleInputChange}
											value={formData.from_month}
										>
											<option value="">Select Month</option>
											{[...Array(4)].map((_, index) => (
												<option key={index + 1} value={`Month ${index + 1}`}>
													{index + 1}
												</option>
											))}
										</select>
									</div>
								</div>
							)}

							{viewAdjustmentType && (
								<>
									<div className="formInput">
										<label htmlFor="fromMonth">From Month</label>
										<div className="inputUserName">
											<select
												type="text"
												id="from_month"
												onChange={handleInputChange}
												value={formData.from_month}
											>
												<option value="">Select Month</option>
												{[...Array(4)].map((_, index) => (
													<option key={index + 1} value={`Month ${index + 1}`}>
														{index + 1}
													</option>
												))}
											</select>
										</div>
									</div>
									{/* <div className="formInput">
                    <label htmlFor="adjustmentMonth">Adjustment Month</label>
                    <div className="inputUserName">
                      <input
                        type="text"
                        id="adjustment_month"
                        placeholder="Enter month  (Ex. Month 1)"
                        onChange={handleInputChange}
                        value={formData.adjustment_month}
                      />
                    </div>
                  </div> */}
									<div className="formInput">
										<label htmlFor="adjustmentMonth">Adjustment Month</label>
										<div className="inputUserName">
											<select
												id="adjustment_month"
												onChange={handleInputChange}
												value={formData.adjustment_month}
											>
												<option value="">Select Month</option>
												{[...Array(10)].map((_, index) => (
													<option key={index + 1} value={`Month ${index + 1}`}>
														{index + 1}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="tdsAmount">Tds Amount</label>
										<div className="inputUserName">
											<input
												type="text"
												id="tds_amount"
												placeholder="Enter TDS amount"
												onChange={handleInputChange}
												value={formData.tds_amount}
											/>
										</div>
									</div>
								</>
							)}

							{shiftAdjustmentType && participantType === "Primary" && (
								<div className="formInput">
									<label htmlFor="adjustmentType">Participants</label>
									<div className="inputUserName">
										<select
											type="text"
											id="replaced_student_id"
											placeholder="select"
											onChange={handleInputChange}
											value={formData.replaced_student_id}
										>
											<option value="">Select Participant</option>
											{partnerStudents &&
												partnerStudents.length > 0 &&
												partnerStudents.map((student, index) => {
													return (
														<option key={index} value={student.student_id}>
															{student.participant_name}
														</option>
													);
												})}

											{/* <option value="Registered">Registered & Next</option> */}
										</select>
									</div>
								</div>
							)}

							<div className="uploadArea">
								<p className="titleHomework">Approved File</p>
								<div className="uploadContainer">
									<input
										type="file"
										name=""
										id="fileInput"
										ref={fileInputRef}
										onChange={(e) => {
											handleFileChange(e);
										}}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={handleUploadButtonClick}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									{fileNames && fileNames.length > 0 ? (
										<div className="fileNames">
											{fileNames.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
									) : (
										<div>
											<p className="uploadInfo">
												Unlimited number of files can be uploaded to this field.
												Maximum size: 64 MB. File format: doc, docx, xls, xlsx,
												pdf, jpeg, jpg, png, zip, ppt, pptx.
											</p>
										</div>
									)}
								</div>
							</div>

							<div className="formInput">
								<label htmlFor="adjustmentRemark">
									Adjust/ Refund / Shift remark
								</label>
								<div className="inputUserName">
									<input
										type="textbox"
										id="remarks"
										placeholder="Enter adjustment remark"
										onChange={handleInputChange}
										value={formData.remarks}
									/>
								</div>
							</div>
						</div>
						<div className="footerNavBtns">
							{/* <button className="backBtn" onClick={handleBack}>
                <img
                  src={ImageList.GoBackBlue}
                  alt=""
                  className="forwardArrow"
                />{" "}
                Back to list
              </button> */}

							<button onClick={handleNext} className="nextBtn">
								Next{" "}
								<img
									src={ImageList.ArrowForwardWhite}
									alt=""
									className="forwardArrow"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RefundInvoice;
