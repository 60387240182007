import getSinglePaymentAPI from '../../apis/admin/getSinglePaymentAPI';


export function getSinglePayment(token,id){
    return new Promise(async(resolve, reject) => {
        getSinglePaymentAPI.getSingle(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}