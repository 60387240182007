import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./PaymentStatus.scss";
import Sucess from "../../../assets/success.svg";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";
import downloadIcon from "../../../assets/download.png";
import Cookies from "js-cookie";
import failed from "../../../assets/failed.svg";
import { showToasterMessage } from "../../../utils/helper";
import { getPaymentStatus } from "../../../interface/implementation/getPaymentStatusAPIImp";
import { apiCreateOrderAPI } from "../../../interface/implementation/createOrderAPIImp";
const PaymentStatus = () => {
	const navigate = useNavigate();
	const params = useParams();
	const token = Cookies.get("token");

	const orderId = params.id;
	// const orderId = "order_101218672jT5hnW4fwlHjvrxUgDXqy22lHn"
	const [loading, setLoading] = useState(true);
	const [investedAmount, setInvestedAmount] = useState("");
	const [status, setStatus] = useState("");
	const [paymentData, setPaymentData] = useState([
		{
			payment_method: "",
			order_amount: "",
			participant_name: "",
			payment_time: "",
			ref_number: "",
			order_currency: "",
			payment_method: "",
			reason: {
				error_code: "",
				error_description: "",
				error_reason: "",
				error_source: "",
			},
			recipient_id: "",
		},
	]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	useEffect(() => {
		setTimeout(() => {
			getPaymentStatus(token, orderId)
				.then((res) => {
					setStatus(res.payment_status);

					setPaymentData([
						{
							payment_method: res.payment_method,
							order_amount: res.order_amount,
							participant_name: res.participant_name,
							payment_time: res.payment_time,
							ref_number: res.unique_id,
							order_currency: res.order_currency,
							reason: {
								error_code: res.reason?.error_code ?? "",
								error_description: res.reason?.error_description ?? "",
								error_reason: res.reason?.error_reason ?? "",
								error_source: res.reason?.error_source ?? "",
							},
							recipient_id: res.recipient_id,
						},
					]);

					if (res.detail) {
						showToast(res.detail);
						return;
					}
					setLoading(false);
				})
				.catch();
		}, 10000);
	}, []);

	const CallCreateOrderAPI = (r) => {
		const body = {
			registrants_id: r,
		};
		apiCreateOrderAPI(token, body)
			.then((res) => {
				loginHandler(res.order_id, res.payment_session_id);
			})
			.catch((err) => {
				console.error(err);
				showToasterMessage("Registration failed. Please try again later.");
			});
	};

	const cashfree = window.Cashfree({
		mode: "production", // or sandbox
	});

	const loginHandler = (id, sessionId) => {
		let checkoutOptions = {
			paymentSessionId: sessionId,
			returnUrl: `https://programs.businesscoachingindia.com/payment-successful/${id}`,
			// notifyUrl: 'https://a4d2-2406-7400-51-f62-dc09-b12e-af1e-8b83.ngrok-free.app/investor/invest/payment/status',
			redirectTarget: "_self", // optional (_self or _blank)
		};

		cashfree.checkout(checkoutOptions);
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);

		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
		const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");

		return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
	};
	return (
		<div>
			{loading ? (
				<>
					<div className="loader"></div>
				</>
			) : (
				<div className="PaymentContainer">
					{paymentData &&
						paymentData.map((p, index) => {
							return (
								<div key={index} className="payment">
									{status && status === "SUCCESS" ? (
										<>
											<img className="successIcon" src={Sucess} alt="" />
											<label className="paymentStatus" htmlFor="paymentStatus">
												Payment Success!
											</label>
										</>
									) : (
										<>
											<img className="failedIcon" src={failed} alt="" />
											<label className="paymentStatus" htmlFor="paymentStatus">
												Payment Failed!
											</label>
											<label
												style={{ color: "red" }}
												className="paymentStatus"
												htmlFor="paymentStatus"
											>
												{p.reason.error_description}
											</label>
										</>
									)}
									<label className="amount" htmlFor="amount">
										{p.order_currency} {p.order_amount}
									</label>
									<div className="paymentDetails">
										<div className="basicDetails">
											<div>
												<p>Ref Number</p>
												<span>{p.ref_number}</span>
											</div>
											<div>
												<p>Payment Time</p>
												<span>{formatDate(p.payment_time)}</span>
											</div>
											<div>
												<p>Payment Method</p>
												<span>{p.payment_method}</span>
											</div>
											<div>
												<p>Sender Name</p>
												<span>{p.participant_name}</span>
											</div>
										</div>
										<hr
											style={{ width: "281px", borderBlockStyle: "dashed" }}
										/>
										<div className="AmountDetials">
											<div>
												<p>Amount</p>
												<span>
													{p.order_currency} {p.order_amount}
												</span>
											</div>
										</div>
									</div>

									{status && status === "SUCCESS" ? (
										<>
											{p.invoice_file &&
												p.invoice_file[0].map((i, index) => {
													return (
														<a
															key={index}
															data-cursor="-inverse"
															target="display"
															href={i.content}
															className="DownloadBtn"
														>
															<img src={downloadIcon} alt="" /> Get PDF Receipt
														</a>
													);
												})}

											<Link to={"/double-your-profits"}>
												<button className="homeBtn">
													Home <img src={ForwardArrow} alt="" />
												</button>
											</Link>
										</>
									) : (
										<>
											<button
												onClick={(e) => CallCreateOrderAPI(p.recipient_id)}
												className="retryBtn"
											>
												Retry
											</button>
											<Link to={"/double-your-profits"}>
												<button className="cancelBtn">Cancel</button>
											</Link>
										</>
									)}
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
};

export default PaymentStatus;
