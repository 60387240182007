import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function MCQCard({
	question,
	onAnswerChange,
	week_no,
	completion_status,
}) {
	const [value, setValue] = React.useState(question.answer || "");
	const handleChange = (e) => {
		const selectedAnswer = e.target.value;

		// Update the question's answer in the child component
		const updatedQuestion = { ...question, answer: selectedAnswer };

		setValue(selectedAnswer);

		// Pass the entire updated question and week_no back to the parent component
		onAnswerChange(updatedQuestion, week_no);
	};

	return (
		<div
			className="radioContainer"
			style={{ borderBottom: "1px solid rgb(223, 223, 223)" }}
		>
			<FormControl>
				<FormLabel
					style={{ color: "#000", marginBottom: "0.8rem", fontWeight: "500" }}
					id="demo-controlled-radio-buttons-group"
				>
					{`Week ${week_no} - ${question.question}`}
				</FormLabel>
				<RadioGroup
					aria-labelledby="demo-controlled-radio-buttons-group"
					name="controlled-radio-buttons-group"
					value={value}
					onChange={handleChange}
				>
					<div className="radioButtons">
						<FormControlLabel
							value="Yes"
							control={
								<Radio
									sx={{
										color: "#4367B0",
										"&.Mui-checked": { color: "#4367B0" },
									}}
									disabled={completion_status}
								/>
							}
							label={question.options[0]}
						/>
						<FormControlLabel
							value="No"
							control={
								<Radio
									sx={{
										color: "#4367B0",
										"&.Mui-checked": { color: "#4367B0" },
									}}
									disabled={completion_status}
								/>
							}
							label={question.options[1]}
						/>
						<FormControlLabel
							value="N/A"
							control={
								<Radio
									sx={{
										color: "#4367B0",
										"&.Mui-checked": { color: "#4367B0" },
									}}
									disabled={completion_status}
								/>
							}
							label={question.options[2]}
						/>
					</div>
				</RadioGroup>
			</FormControl>
		</div>
	);
}
