import React, { useState, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { useNavigate } from "react-router-dom";
import "./Materials.scss";

function Materials() {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		courseName: "",
		documentFiles: [],
		audioFiles: [],
		youtubeURLs: [""],
	});

	const documentFileInputRef = useRef(null);
	const audioFileInputRef = useRef(null);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handleUploadButtonClick = (fileInputRef, fileType) => {
		fileInputRef.current.click();
		setFormData((prevData) => ({
			...prevData,
			[`${fileType}UploadVisible`]: false,
		}));
	};

	const handleFileChange = (e, fileType) => {
		const file = e.target.files[0];

		setFormData((prevData) => ({
			...prevData,
			[`${fileType}Files`]: [...prevData[`${fileType}Files`], file.name],
			[`${fileType}UploadVisible`]: true,
		}));
	};

	const handleRemoveFile = (index, fileType) => {
		const updatedFiles = [...formData[`${fileType}Files`]];
		updatedFiles.splice(index, 1);

		setFormData((prevData) => ({
			...prevData,
			[`${fileType}Files`]: updatedFiles,
			[`${fileType}UploadVisible`]: updatedFiles.length === 0,
		}));
	};

	const handleAddAnotherURL = () => {
		setFormData((prevData) => ({
			...prevData,
			youtubeURLs: [...prevData.youtubeURLs, ""],
		}));
	};

	const handlePrevButtonClick = () => {
		navigate("/homework/:id");
	};

	const handleSaveButtonClick = () => {
		navigate("/materials/:id");
	};

	return (
		<div className="materials">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Update Course</div>
					<div className="topNavigation">
						<div className="item">
							<p>Course details</p>
						</div>
						<div className="item">
							<p>Homework</p>
						</div>
						<div className="item active">
							<p>Materials</p>
						</div>
					</div>
					<div className="materialContainer">
						<div className="courseDetailForm">
							<div className="formInput">
								<label htmlFor="courseName">
									Course Name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="courseName"
										placeholder="Enter course name"
										onChange={handleInputChange}
										value={formData.courseName}
									/>
								</div>
							</div>
						</div>

						<div className="uploadContainer">
							<div className="documentUpload container">
								<p className="title">Upload document files</p>
								<div className="uploadBox">
									{formData.documentFiles.length === 0 && (
										<div className="top">
											<img
												src={ImageList.UploadDocIcon}
												alt=""
												className="docIcon"
											/>
											<p className="uploadInfo">
												Unlimited number of files can be uploaded to this field.
												File format: doc, docx, xls, xlsx, pdf, jpeg, jpg, png,
												zip, ppt, pptx.
											</p>
										</div>
									)}
									<input
										type="file"
										name=""
										id="documentFileInput"
										ref={documentFileInputRef}
										onChange={(e) => handleFileChange(e, "document")}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={() =>
											handleUploadButtonClick(documentFileInputRef, "document")
										}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									<div className="fileNames">
										{formData.documentFiles.map((fileName, index) => (
											<p key={index} className="fileName">
												{fileName}
												<span
													className="removeBtn"
													onClick={() => handleRemoveFile(index, "document")}
												>
													x Remove
												</span>
											</p>
										))}
									</div>
									{formData.documentFiles.length === 0 && (
										<p className="maxSize">Maximum size: 50 MB</p>
									)}
								</div>
							</div>
							<div className="documentUpload container">
								<p className="title">Upload audio files</p>
								<div className="uploadBox">
									{formData.audioFiles.length === 0 && (
										<div className="top">
											<img
												src={ImageList.UploadDocIcon}
												alt=""
												className="docIcon"
											/>
											<p className="uploadInfo">
												Unlimited number of files can be uploaded to this field.
												File format: wav, mp3, mp4, wma, aac.
											</p>
										</div>
									)}

									<input
										type="file"
										name=""
										id="audioFileInput"
										ref={audioFileInputRef}
										onChange={(e) => handleFileChange(e, "audio")}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={() =>
											handleUploadButtonClick(audioFileInputRef, "audio")
										}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									<div className="fileNames">
										{formData.audioFiles.map((fileName, index) => (
											<p key={index} className="fileName">
												{fileName}
												<span
													className="removeBtn"
													onClick={() => handleRemoveFile(index, "audio")}
												>
													x Remove
												</span>
											</p>
										))}
									</div>
									{formData.audioFiles.length === 0 && (
										<p className="maxSize">Maximum size: 50 MB</p>
									)}
								</div>
							</div>
						</div>

						<div className="youtubeContainer">
							<p className="title">YouTube video URLs</p>
							{formData.youtubeURLs.map((url, index) => (
								<div key={index} className="youtubeInput">
									<input
										type="text"
										placeholder={`Enter YouTube URL #${index + 1}`}
										value={url}
										onChange={(e) => {
											const newURLs = [...formData.youtubeURLs];
											newURLs[index] = e.target.value;
											setFormData((prevData) => ({
												...prevData,
												youtubeURLs: newURLs,
											}));
										}}
									/>
								</div>
							))}
							<p onClick={handleAddAnotherURL}>+ Add another URL</p>
						</div>

						<div className="navigationBtns">
							<button className="prevBtn" onClick={handlePrevButtonClick}>
								<img src={ImageList.GoBackBlue} alt="" />
								Previous
							</button>
							<button className="saveBtn" onClick={handleSaveButtonClick}>
								Save
								<img src={ImageList.WhiteTick} alt="" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Materials;
