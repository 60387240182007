import React, { useState } from "react";
import "./ResetPassword.scss";
import ImageList from "../../utils/ImageList";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { resetPass } from "../../interface/implementation/resetPassAPIImp";
import Cookies from "js-cookie";
function ResetPassword(props) {
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState({
		password: "",
	});
	const [showPassword, setShowPassword] = useState(false);

	const token = Cookies.get("token");
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const email = params.get("email");
	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setCredentials((prevCredentials) => ({
			...prevCredentials,
			[id]: value,
		}));
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleTogglePassword = () => {
		setShowPassword((value) => !value);
	};

	const passwordResetHandler = () => {
		if (credentials.password && email) {
			let body = {
				email: email,
				new_password: credentials.password,
			};
			resetPass(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					showSuccessToast("Pssword reset successfully");
					navigate("/");
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			showToast("One or more fields are empty/wrong");
		}
	};

	return (
		<div className="resetPassword">
			<div className="loginContainer">
				<img src={ImageList.BCILogo} alt="BCI Logo" className="logo" />
				<p className="title">Enter your new password</p>
				<div className="loginForm">
					<div className="inputPass">
						<label htmlFor="userEmail">
							Create Password<span>*</span>
						</label>
						<div className="inputUserName">
							<img
								src={ImageList.Password}
								alt="eye-icon"
								className="passIcon"
							/>
							<input
								type={showPassword ? "text" : "password"}
								id="password"
								placeholder="Enter your password"
								onChange={handleInputChange}
								value={credentials.password}
							/>
							<img
								onClick={handleTogglePassword}
								src={ImageList.EyeClose}
								alt="eye-icon"
								className="eyeIcon"
							/>
						</div>
					</div>
				</div>
				{/* <p className='bottomText'>Password reset instructions will be sent to your registered email address.</p> */}
				<button className="loginBtn" onClick={passwordResetHandler}>
					Submit{" "}
					<img src={ImageList.ArrowForward} alt="" className="forwardArrow" />
				</button>
				{/* <Link to={'/'} className='goBackBtn'>
                    <img src={ImageList.GoBack} className='goBackIcon' alt="" /> Go Back
                </Link> */}
				<ToastContainer limit={1} />
			</div>
		</div>
	);
}

export default ResetPassword;
