import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentTerms.scss";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { getTermsStatus } from "../../../interface/implementation/getTermsStatusAPIImp";

function StudentTerms() {
	const token = Cookies.get("token");
	const [terms, setTerms] = useState("");
	useEffect(() => {
		const roles = JSON.parse(localStorage.getItem("userRoles"));
		if (roles.includes(1)) {
			getTermsStatus(token)
				.then((res) => {
					setTerms(res[0].terms_and_conditions);
				})
				.catch();
		}
	}, []);

	return (
		<div className="studentTerms">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Terms & Conditions</div>
					<div className="courseListContainer" style={{ marginTop: "10px" }}>
						<p
							style={{
								maxHeight: "530px",
								overflowX: "hidden",
								padding: "10px",
								overflowY: "auto",
							}}
							dangerouslySetInnerHTML={{ __html: terms }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentTerms;
