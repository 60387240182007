import React, { useEffect } from "react";
import "./TestimonialPage.scss";
import { Testimonial } from "../../../assets/Data/Constants";
import AOS from "aos";
import "aos/dist/aos.css";

const TestimonialPage = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);
	return (
		<div className="TestimonialContainer">
			<label htmlFor="subTitle" data-aos="fade-up" data-aos-once={true}>
				SUCCESS STORIES
			</label>
			<div className="videosContainer">
				{Testimonial.map((t, index) => (
					<div className="video" key={index}>
						<div>
							<iframe
								src={t.URL}
								title="Youtube video"
								allowFullScreen
							></iframe>
						</div>
						<p className="vTitle" htmlFor="vTitle">
							{t.title}
						</p>
						<p className="subTitle">{t.subtitle}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default TestimonialPage;
