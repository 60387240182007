import {ApiCore} from "../../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: false,
    getSingleWithQuery: true,
    postEncoded: false,
    post: false,
    put: false,
    delete: false,
    url: `api/workshop/students/get-workshop-students-workshop-id`,
    version: ''
});

export default apiTasks;


