import React, { useEffect } from "react";
import "./AlumniPage.scss";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";
import { Alumni } from "../../../assets/Data/Constants";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const AlumniPage = () => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);
	return (
		<div className="AlumniContainer">
			<label htmlFor="subTitle" data-aos="fade-up" data-aos-once={true}>
				OUR ALUMNI SAY
			</label>
			<h2 data-aos="fade-up" data-aos-once={true}>
				Insights from Our Alumni
			</h2>
			<div className="reviewsContainer">
				{Alumni.map((a, index) => (
					<div key={index} className="reviewCard">
						<div className="ReviewCardcontents">
							<img src={a.dp} alt="" />
							<p className="name" htmlFor="name">
								{a.name}
							</p>
							<p className="aluCompany">{a.company}</p>
							<p className="aluPosition">{a.position}</p>
						</div>
						<div className="leftAlumni">
							<ul className="review">
								{a.reviews.map((a, i) => (
									<li key={i}>{a}</li>
								))}
							</ul>
							<div className="aluLogoContainer">
								<img src={a.logo} alt="" />
							</div>
						</div>
					</div>
				))}
			</div>
			<Link
				to="/registrationForm"
				state={{ work_card_id: 1 }}
				data-aos="fade-up"
				data-aos-once={true}
			>
				<button className="btn">
					Get Started <img src={ForwardArrow} alt="" />
				</button>
			</Link>
		</div>
	);
};

export default AlumniPage;
