import React, { useEffect, useState, useCallback } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./StudentPaymentInfo.scss";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { getStudentBatches } from "../../../interface/implementation/student/getStudentBatchesAPIImp";
import { getStudentPaymentInfo } from "../../../interface/implementation/student/getStudentPaymentInfoAPIImp";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ImageList from "../../../utils/ImageList";

function StudentPaymentInfo() {
	const navigate = useNavigate();
	const handle = useFullScreenHandle();
	const [isFullScreenActive, setIsFullScreenActive] = useState(false);
	const location = useLocation();
	const [search, setSearch] = useState("");
	const [participant, setParticipant] = useState("");
	const [crm, setCrm] = useState("");
	const [participantOldStatus, setParticipantOldStatus] = useState("");
	const [status, setStatus] = useState("");
	const [activeStatus, setActiveStatus] = useState("");
	const [filterBatchId, setFilterBatchId] = useState();
	const [technicalNotes, setTechnicalNotes] = useState("");
	const token = Cookies.get("token");
	const [batchStudentList, setBatchStudentList] = useState([]);
	const [batchList, setBatchList] = useState([]);
	const [paymentList, setPaymentList] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	// const [currentItems, setCurrentItems] = useState([]);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState({
		crm: [],
	});
	const [showModal, setShowModal] = useState(false);
	const [replaceData, setReplaceData] = useState({});

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getStudentBatches(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				studentPaymentDetails(res[0].batch_id);
				setFilterBatchId(res[0].batch_name);
			});
		};
		fetchDropdowns();
		const studentPaymentDetails = (id) => {
			let body = `?batch_id=${id}`;
			getStudentPaymentInfo(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setPaymentList(res.payments_details);
				})
				.catch((err) => showToast("Something went wrong"));
		};
	}, [reloadUsers]);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;

	const currentItems =
		paymentList && paymentList.length > 0
			? paymentList.slice(indexOfFirstItem, indexOfLastItem)
			: [];

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleDownload = () => {
		if (filterBatchId) {
			let newBody = ``;

			if (search) {
				newBody += `&search=${search}`;
			}

			if (participant) {
				newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
			}
			if (filterBatchId) {
				newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
			}
		} else {
			showToasterMessage("Please select a batch");
		}
	};

	const onFullScreenChange = useCallback((state) => {
		if (!state) {
			setIsFullScreenActive(false);
		}
	}, []);

	return (
		<div className="studentPaymentInfo">
			<ToastContainer limit={1} />
			{/* <TopHeader /> */}
			<div className="dashboardBody">
				{/* <SidebarNav menuItems={SidebarMenuItems} /> */}
				<div className="dashboardContent">
					<div className="title">Payment Info</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs batchStudentSearch">
								<div className="inputContainer">
									<label htmlFor="batch">Batch Name</label>
									<div className="inputCode">
										<input
											id="batchId"
											disabled
											// onChange={(e) => handleInputChange(e, setFilterBatchId)}
											value={filterBatchId}
										></input>
									</div>
								</div>
							</div>
						</div>

						<div
							className="fullScreenConatiner"
							style={{ justifyContent: "flex-end" }}
						>
							<p
								className="fullScreenAction"
								onClick={() => {
									handle.enter();
									setIsFullScreenActive(true);
								}}
							>
								<img src={ImageList.FullScreenIcon} alt="" />
								Full Screen View
							</p>
						</div>

						<FullScreen handle={handle} onChange={onFullScreenChange}>
							<div
								className={`${isFullScreenActive ? "fullScreenActive" : ""}`}
							>
								{isFullScreenActive && (
									<div className="fullScreenConatiner">
										<p
											className="fullScreenAction"
											onClick={() => {
												handle.exit();
												setIsFullScreenActive(false);
											}}
										>
											<img src={ImageList.FullScreenIcon} alt="" />
											Exit Full Screen View
										</p>
									</div>
								)}
								<div className="courses" style={{ overflowX: "auto" }}>
									<table className="batchStudentTable">
										<thead>
											<tr>
												<th>Sl. No.</th>
												<th>Month</th>
												<th>Paid Amount</th>
												<th>TDS Amount</th>
												<th>Discount Amount</th>
												<th>Received Date</th>
												<th>Payment Description</th>
												<th>Remark</th>
											</tr>
										</thead>
										<tbody>
											{paymentList &&
												paymentList.length > 0 &&
												paymentList.map((payment, index) => (
													<tr key={index}>
														<td>{index + 1}</td>
														<td>{payment.payment_month}</td>
														<td>{payment.paid_amount}</td>
														<td>{payment.tds_amount}</td>
														<td>{payment.discount_amount}</td>
														<td>
															{payment.payment_date &&
																payment.payment_date !== null &&
																payment.payment_date.split("-").join("/")}
														</td>
														<td>{payment.payment_description}</td>
														<td>{payment.remarks}</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</FullScreen>
					</div>
					{/* <div className="pagination">
            <div></div>
            <div style={{ gap: "10px" }}>
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {Array.from(
                { length: Math.ceil(batchStudentList?.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                )
              )}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(batchStudentList?.length / itemsPerPage)
                }
              >
                {">"}
              </button>
            </div>
          </div> */}
				</div>
			</div>
		</div>
	);
}

export default StudentPaymentInfo;
