import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";

import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import ImageList from "../../../utils/ImageList";
import { preventWheelScroll, handleNumericInput } from "../../../utils/helper";
import "./AddBatch.scss";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { addBatch } from "../../../interface/implementation/admin/addBatchAPIImp";
import { adminCourseList } from "../../../interface/implementation/admin/getAdminCoursesAPIImp";
import { searchUsers } from "../../../interface/implementation/admin/searchUsersAPIImp";
import { getBanks } from "../../../interface/implementation/admin/getBanksAPIImp";
import { getBatchCRM } from "../../../interface/implementation/admin/getBatchCRMAPIImp";

function AddBatch() {
	const navigate = useNavigate();
	const [editorVisibility, setEditorVisibility] = useState({
		primaryStudentTC: false,
		tc50PercentParticipants: false,
		secondaryStudentTC: false,
	});

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const [courseList, setCourseList] = useState([]);

	const [editorStates, setEditorStates] = useState({
		primaryStudentTC: EditorState.createEmpty(),
		tc50PercentParticipants: EditorState.createEmpty(),
		secondaryStudentTC: EditorState.createEmpty(),
	});

	const weekdayList = [
		{ id: 0, day_value: "sunday" },
		{ id: 1, day_value: "monday" },
		{ id: 2, day_value: "tuesday" },
		{ id: 3, day_value: "wednesday" },
		{ id: 4, day_value: "thursday" },
		{ id: 5, day_value: "friday" },
		{ id: 6, day_value: "saturday" },
	];

	const [formData, setFormData] = useState({
		batch_name: "",
		batch_code: "",
		course_id: 0,
		start_date: "",
		end_date: "",
		total_amount: 0,
		member_amount: 0,
		running_week_day: "",
		batch_crm: "",
		company_billing: "",
		payment_option_show: "yes",
		// primary_student_terms_and_conditions:'',
		// terms_and_condition_50_per_participant: '',
		// secondary_student_terms_and_conditions: '',
		batch_installments: [],
		old_batch_installments: [],
	});

	const [userList, setUserList] = useState([]);
	const [bankDetail, setBankDetails] = useState([]);
	const [installments, setInstallments] = useState([
		{
			id: 1,
			installment_no: 1,
			primary_installment_amount: 0,
			secondary_installment_amount: 0,
			due_date: "",
		},
	]);

	const [installments9L, setInstallments9L] = useState([
		{
			id: 1,
			installment_no: 1,
			primary_installment_amount: 0,
			secondary_installment_amount: 0,
			due_date: "",
		},
	]);
	const [numberOfInstallments, setNumberOfInstallments] = useState(0);
	const [numberOfInstallments9L, setNumberOfInstallments9L] = useState(0);
	const [totalInstallments, setTotalInstallments] = useState(0);
	const [totalInstallments9L, setTotalInstallments9L] = useState(0);
	const token = Cookies.get("token");

	useEffect(() => {
		adminCourseList(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCourseList(res);
			})
			.catch((err) => showToast("Something went wrong"));

		getBanks(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBankDetails(res.bank_detail);
			})
			.catch((err) => showToast("Something went wrong"));

		getBatchCRM(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setUserList(res.users);
			})
			.catch((err) => showToast("Something went wrong"));

		// let newBody = ''
		// let roleId = 1
		// newBody += `?active=active&roles=${roleId}`;
		// searchUsers(token, newBody).then((res)=>{
		//     setUserList(res)
		//  }).catch()
	}, []);

	const handleInputChange = (e) => {
		const { id, value } = e.target;

		// Check if the input is 'course'
		if (id === "course") {
			const selectedCourse =
				courseList.filter(
					(course) => parseInt(course.course_id) === parseInt(value)
				)[0] || "";
			setNumberOfInstallments(selectedCourse.total_installments);
			setTotalInstallments(selectedCourse.total_installments);

			setNumberOfInstallments9L(selectedCourse.total_installments);
			setTotalInstallments9L(selectedCourse.total_installments);

			setInstallments([
				{
					id: 1,
					installment_no: 1,
					primary_installment_amount: 0,
					secondary_installment_amount: 0,
					due_date: "",
				},
			]);
			setInstallments9L([
				{
					id: 1,
					installment_no: 1,
					primary_installment_amount: 0,
					secondary_installment_amount: 0,
					due_date: "",
				},
			]);

			if (selectedCourse) {
				setFormData((prevData) => ({
					...prevData,
					//   [id]: value,
					course_id: selectedCourse.course_id,
					total_amount: selectedCourse.total_amount,
					member_amount: selectedCourse.member_total_amount,
				}));
			}
		} else {
			// If the input is not 'course', update the state without fetching from the API
			setFormData((prevData) => ({
				...prevData,
				[id]: value,
				total_course_fee: 10000000,
				old_batch_total_course_fee: 9000000,
			}));
		}
	};

	const addInstallment = () => {
		if (numberOfInstallments > 0) {
			const newInstallment = {
				id: installments.length + 1,
				installment_no: installments.length + 1,
				primary_installment_amount: "",
				secondary_installment_amount: "",
				due_date: "",
			};

			setInstallments([...installments, newInstallment]);
			setNumberOfInstallments(numberOfInstallments - 1);
		}
	};

	const addInstallment9L = () => {
		if (numberOfInstallments9L > 0) {
			const newInstallment = {
				id: installments9L.length + 1,
				installment_no: installments9L.length + 1,
				primary_installment_amount: "",
				secondary_installment_amount: "",
				due_date: "",
			};

			setInstallments9L([...installments9L, newInstallment]);
			setNumberOfInstallments9L(numberOfInstallments9L - 1);
		}
	};

	const handleEditorChange = (newEditorState, key, editorKey) => {
		setEditorStates((prevStates) => ({
			...prevStates,
			[editorKey]: newEditorState,
		}));
		// Extracting text from EditorState
		const contentState = newEditorState.getCurrentContent();
		const rawText = convertToRaw(contentState);
		const plainText = rawText.blocks.map((block) => block.text).join("\n");

		setFormData((prevData) => ({
			...prevData,
			[key]: plainText,
		}));
	};

	const handleInstallmentChange = (index, field, value) => {
		setInstallments((prevInstallments) => {
			const updatedInstallments = prevInstallments.map((installment, i) => {
				if (i === index) {
					return {
						...installment,
						[field]: value,
						installment_no: index + 1, // Assuming installment numbers start from 1
					};
				}
				return installment;
			});

			setFormData((prevFormData) => ({
				...prevFormData,
				batch_installments: updatedInstallments,
			}));

			return updatedInstallments; // Update state and return the new value
		});
	};

	const handleInstallmentChange9L = (index, field, value) => {
		setInstallments9L((prevInstallments) => {
			const updatedInstallments = prevInstallments.map((installment, i) => {
				if (i === index) {
					return {
						...installment,
						[field]: value,
						installment_no: index + 1, // Assuming installment numbers start from 1
					};
				}
				return installment;
			});

			setFormData((prevFormData) => ({
				...prevFormData,
				old_batch_installments: updatedInstallments,
			}));

			return updatedInstallments; // Update state and return the new value
		});
	};

	const handleToggleEditor = (editorKey) => {
		setEditorVisibility((prevVisibility) => ({
			...prevVisibility,
			[editorKey]: !prevVisibility[editorKey],
		}));
	};

	const handleSubmit = () => {
		if (!formData.batch_name || formData.batch_name.trim() === "") {
			showToast("Batch Name field is empty");
			return;
		}

		if (!formData.batch_code || formData.batch_code.trim() === "") {
			showToast("Batch Code field is empty");
			return;
		}

		if (!formData.course_id) {
			showToast("Course field is empty");
			return;
		}

		if (!formData.start_date || formData.start_date === "") {
			showToast("Start Date field is empty");
			return;
		}

		if (!formData.end_date || formData.end_date === "") {
			showToast("End Date field is empty");
			return;
		}
		if (formData.running_week_day === "") {
			showToast("Running week day field is empty");
			return;
		}
		if (!formData.batch_crm || formData.batch_crm === "") {
			showToast("Batch CRM field is empty");
			return;
		}
		if (!formData.company_billing || formData.company_billing === "") {
			showToast("Company billing field is empty");
			return;
		}
		if (!formData.payment_option_show || formData.payment_option_show === "") {
			showToast("Payment option show field is empty");
			return;
		}

		addBatch(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Batch created successfully.");
				navigate("/batch-list");
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="addBatch">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Add Batch</div>
					<div className="addBatchContainer">
						<div className="addBatchForm">
							<div className="formInput">
								<label htmlFor="batchName">
									Batch Name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="batch_name"
										placeholder="Enter batch name"
										onChange={handleInputChange}
										value={formData.batch_name}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="code">
									Code <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="batch_code"
										placeholder="Enter code"
										onChange={handleInputChange}
										value={formData.batch_code}
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="course">
									Course <span>*</span>
								</label>
								<select
									id="course"
									onChange={handleInputChange}
									value={formData.course}
								>
									<option value="">Select Course</option>
									{courseList &&
										courseList.length > 0 &&
										courseList.map((course, index) => (
											<option key={index} value={course.course_id}>
												{course.course_name}
											</option>
										))}
								</select>
							</div>
							<div className="formInput">
								<label htmlFor="startDate">
									Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="start_date"
									onChange={handleInputChange}
									value={formData.start_date}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Date <span>*</span>
								</label>
								<input
									type="date"
									id="end_date"
									onChange={handleInputChange}
									value={formData.end_date}
								/>
							</div>
							<div className="formInput">
								<label htmlFor="totalAmount">
									Total Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="totalAmount"
										placeholder="Enter total amount"
										// onChange={handleInputChange}
										value={formData.total_amount}
										disabled
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="memberAmount">
									Member Amount <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="memberAmount"
										placeholder="Enter member amount"
										// onChange={handleInputChange}
										value={formData.member_amount}
										disabled
									/>
								</div>
							</div>
							<div className="formInput">
								<label htmlFor="runningWeekday">
									Running Weekday <span>*</span>
								</label>
								<select
									id="running_week_day"
									onChange={handleInputChange}
									value={formData.running_week_day}
								>
									<option value="">Select Running Weekday</option>
									{weekdayList &&
										weekdayList.length > 0 &&
										weekdayList.map((day, index) => (
											<option key={index} value={index}>
												{day.day_value}
											</option>
										))}
								</select>
							</div>
							<div className="formInput">
								<label htmlFor="batchCRM">
									Batch CRM <span>*</span>
								</label>
								<select
									id="batch_crm"
									onChange={handleInputChange}
									value={formData.batch_crm}
								>
									<option value="">Select Batch CRM</option>
									{userList &&
										userList.length > 0 &&
										userList.map((user, index) => (
											<option key={index} value={user.user_id}>
												{user.full_name}
											</option>
										))}
								</select>
							</div>
							<div className="formInput">
								<label htmlFor="companyBilling">
									Company Billing <span>*</span>
								</label>
								<select
									id="company_billing"
									onChange={handleInputChange}
									value={formData.company_billing}
								>
									<option value="">Select Company Billing</option>
									{bankDetail &&
										bankDetail.length > 0 &&
										bankDetail.map((bank, index) => (
											<option key={index} value={bank.title}>
												{bank.title}
											</option>
										))}
								</select>
							</div>
							<div className="formInput">
								<label htmlFor="paymentOptionShow">
									Payment Option Show <span>*</span>
								</label>
								<select
									id="payment_option_show"
									onChange={handleInputChange}
									value={formData.payment_option_show}
								>
									<option value="">Select Payment Option Show</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
							</div>
							<button className="nextBtn" onClick={handleSubmit}>
								Next{" "}
								<img
									src={ImageList.ArrowForward}
									alt=""
									className="forwardArrow"
								/>
							</button>
						</div>
						<div className="addBatchRight">
							{totalInstallments > 0 && (
								<span>
									<small>Total installments: {totalInstallments}</small>
								</span>
							)}
							<div className="mainTitle">Installment details</div>
							<div
								className="installmentContainer"
								style={{ overflowX: "auto" }}
							>
								<table>
									<thead>
										<tr>
											<th>Installment no.</th>
											<th>
												Primary installment amount <br />
												<span>(Only basic amount)</span>
											</th>
											<th>
												Secondary installment amount <br />{" "}
												<span>(Only basic amount)</span>
											</th>
											<th>Due Date</th>
										</tr>
									</thead>
									<tbody>
										{installments.map((installment, index) => (
											<tr key={installment.id}>
												<td>
													<input
														type="number"
														onFocus={preventWheelScroll}
														onInput={handleNumericInput}
														value={index + 1}
														disabled
													/>
												</td>
												<td>
													<input
														type="number"
														onFocus={preventWheelScroll}
														onInput={handleNumericInput}
														min={0}
														placeholder="Enter amount"
														onChange={(e) =>
															handleInstallmentChange(
																index,
																"primary_installment_amount",
																e.target.value
															)
														}
													/>
												</td>
												<td>
													<input
														type="number"
														onFocus={preventWheelScroll}
														onInput={handleNumericInput}
														min={0}
														placeholder="Enter amount"
														onChange={(e) =>
															handleInstallmentChange(
																index,
																"secondary_installment_amount",
																e.target.value
															)
														}
													/>
												</td>
												<td>
													<input
														type="date"
														placeholder="Enter amount"
														onChange={(e) =>
															handleInstallmentChange(
																index,
																"due_date",
																e.target.value
															)
														}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							{numberOfInstallments > 1 && (
								<div className="installmentBtn">
									<button className="addInstallment" onClick={addInstallment}>
										+ Add installment details
									</button>
									<p>to installment details</p>
								</div>
							)}

							{/* <div className="mainTitle">Installment details - 9 Lakhs</div>
                            <div className="installmentContainer" style={{ overflowX: 'auto' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Installment no.</th>
                                            <th>Primary installment amount <br /><span>(Only basic amount)</span></th>
                                            <th>Secondary installment amount <br /> <span>(Only basic amount)</span></th>
                                            <th>Due Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {installments9L.map((installment, index) => (
                                            <tr key={installment.id}>
                                                <td><input  type="number" onFocus={preventWheelScroll} onInput={handleNumericInput} value={index + 1} disabled /></td>
                                                <td><input  type="number" onFocus={preventWheelScroll} onInput={handleNumericInput} min={0} placeholder='Enter amount' onChange={(e) => handleInstallmentChange9L(index, 'primary_installment_amount', e.target.value)} /></td>
                                                <td><input  type="number" onFocus={preventWheelScroll} onInput={handleNumericInput} min={0} placeholder='Enter amount' onChange={(e) => handleInstallmentChange9L(index, 'secondary_installment_amount', e.target.value)} /></td>
                                                <td><input type="date" placeholder='Enter amount' onChange={(e) => handleInstallmentChange9L(index, 'due_date', e.target.value)} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            {numberOfInstallments9L > 1 && (
                                <div className="installmentBtn">
                                    <button className="addInstallment" onClick={addInstallment9L}>+ Add installment details</button>
                                    <p>to installment details</p>
                                </div>
                            )} */}
							<div className="tcContainer">
								<div className="tc">
									<div
										className="accordionTitle"
										onClick={() => handleToggleEditor("primaryStudentTC")}
									>
										<p className="tcTitle">
											Primary Student T&C <span>*</span>
										</p>
										<p className="icon">
											{editorVisibility.primaryStudentTC ? "-" : "+"}
										</p>
									</div>
									{editorVisibility.primaryStudentTC && (
										<div className="editorContainer">
											<Editor
												editorState={editorStates.primaryStudentTC}
												editorClassName="editorName"
												onEditorStateChange={(e) =>
													handleEditorChange(
														e,
														"primary_student_terms_and_conditions",
														"primaryStudentTC"
													)
												}
											/>
										</div>
									)}
								</div>
								<div className="tc">
									<div
										className="accordionTitle"
										onClick={() =>
											handleToggleEditor("tc50PercentParticipants")
										}
									>
										<p className="tcTitle">
											T&C 50% Participants <span>*</span>
										</p>
										<p className="icon">
											{editorVisibility.tc50PercentParticipants ? "-" : "+"}
										</p>
									</div>
									{editorVisibility.tc50PercentParticipants && (
										<div className="editorContainer">
											<Editor
												editorState={editorStates.tc50PercentParticipants}
												editorClassName="editorName"
												onEditorStateChange={(e) =>
													handleEditorChange(
														e,
														"terms_and_condition_50_per_participant",
														"tc50PercentParticipants"
													)
												}
											/>
										</div>
									)}
								</div>
								<div className="tc">
									<div
										className="accordionTitle"
										onClick={() => handleToggleEditor("secondaryStudentTC")}
									>
										<p className="tcTitle">
											Secondary Student T&C <span>*</span>
										</p>
										<p className="icon">
											{editorVisibility.secondaryStudentTC ? "-" : "+"}
										</p>
									</div>
									{editorVisibility.secondaryStudentTC && (
										<div className="editorContainer">
											<Editor
												editorState={editorStates.secondaryStudentTC}
												editorClassName="editorName"
												onEditorStateChange={(e) =>
													handleEditorChange(
														e,
														"secondary_student_terms_and_conditions",
														"secondaryStudentTC"
													)
												}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddBatch;
