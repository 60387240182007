import React, { useState, useRef } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import "./PartialPaymentCSV.scss";
import Cookies from "js-cookie";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { downloadPayment } from "../../../interface/implementation/admin/downloadPaymentAPIImp";
import { uploadPayment } from "../../../interface/implementation/admin/uploadPaymentAPIImp";
import { ToastContainer } from "react-toastify";
import SamplePaymentFile from "../../../assets/files/Payment_template.csv";

import { useLocation, useNavigate } from "react-router-dom";

function PartialPaymentCSV() {
	const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);

	const navigate = useNavigate();
	const fileInputRef = useRef(null);
	const [failureModal, setFailureModal] = useState(false);
	const [failedUploadData, setFailedUploadData] = useState();
	const [formData, setFormData] = useState({
		documentFiles: [],
	});
	const token = Cookies.get("token");
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const successToast = (successText) => {
		showSuccessToast(successText);
	};
	const location = useLocation();
	const stateData = location.state;
	const batchName = stateData.batchName;
	const startDate = stateData.startDate.split("-").reverse().join("/");
	const endDate = stateData.endDate.split("-").reverse().join("/");

	const handleDownloadAttendance = async () => {
		await downloadPayment(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				const currentDate = new Date().toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
				const filename = `payment-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files).map((file) => file.name);
		setFormData((prevData) => ({
			...prevData,
			documentFiles: [...prevData.documentFiles, ...files],
		}));
	};

	const handleFileUpload = async () => {
		const fileInput = fileInputRef.current;
		if (fileInput.files.length > 0) {
			const formData = new FormData();

			formData.append("csv_file", fileInput.files[0]);

			setIsSpinnerLoading(true);
			try {
				const res = await uploadPayment(token, formData);
				if (
					(res.details || res.detail) &&
					(res.details !== "" || res.detail !== "")
				) {
					setIsSpinnerLoading(false);
					if (res.details) {
						setFailedUploadData(res.details);
					}
					if (res.detail) {
						setFailedUploadData(res.detail);
					}
					setFailureModal(true);
					return;
				}
				if (res.status !== "failure") {
					successToast("File uploaded.");
					// navigate("/student-batch-list");
					setIsSpinnerLoading(false);
				}
			} catch (error) {
				showToast("Error uploading file");
				console.error("Error uploading file:", error);
			} finally {
				setIsSpinnerLoading(false);
			}
		} else {
			console.warn("No file selected for upload.");
			showToast("No file selected for upload.");
		}
	};

	const handleRemoveFile = (index) => {
		setFormData((prevData) => {
			const updatedFiles = [...prevData.documentFiles];
			updatedFiles.splice(index, 1);
			return {
				...prevData,
				documentFiles: updatedFiles,
			};
		});
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	return (
		<div className="partialPaymentCSV">
			<ToastContainer limit={1} />
			{failureModal && (
				<div className="modal-overlay">
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						{failedUploadData && (
							<h1 className="reasonTitle">{failedUploadData.message}</h1>
						)}
						<div className="statusContainer">
							<ul className="orderedList">
								{failedUploadData &&
									failedUploadData.upload_status?.map((d, index) => (
										<li key={index}>{d}</li>
									))}
							</ul>
						</div>
						<button onClick={() => setFailureModal(false)} className="closeBtn">
							X
						</button>
					</div>
				</div>
			)}
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">
						{batchName} {`(${startDate} - ${endDate})`} :: CSV Import Batch
						Student
					</div>
					<div className="batchStudentListContainer">
						<div className="first row">
							<p className="bullet">1:</p>
							<p className="firstTitle">Download “Template CSV File”</p>
							<div className="downloadBtn">
								<a
									href="https://bcitemplates.s3.ap-south-1.amazonaws.com/Payment_template.csv" // Replace 'yourFileUrl' with the actual URL of your CSV file
									target="_blank" // Opens the link in a new tab/window
									download="payment_template.csv" // Specifies the name of the downloaded file
								>
									<img src={ImageList.DownloadIcon} alt="" />
									Download
								</a>
							</div>
						</div>
						<div className="second row">
							<p className="bullet">2:</p>
							<div className="content">
								<h6>Add or Edit info CSV data according to the below fields</h6>
								<p>
									<b>Required fields: </b> Phone Number (Only Primary Number),
									Date of Receipt, Amount, Deduct Amount, Discount Amount, Mode
									of Payment, Payment Description, Remark
								</p>
							</div>
						</div>
						<div className="third row">
							<p className="bullet">3:</p>
							<div className="studentDetails" style={{ overflowX: "auto" }}>
								<table className="studentTable">
									<thead>
										<tr>
											<th>Phone Number</th>
											<th>Installment Type</th>
											<th>Date of Receipt</th>
											<th>Amount</th>
											<th>Deduct Amount</th>
											<th>Discount Amount</th>
											<th>Mode of Payment</th>
											<th>Payment Description</th>
											<th>Remark</th>
										</tr>
									</thead>
								</table>
							</div>
						</div>
						<div className="first row">
							<p className="bullet">4:</p>
							<p className="firstTitle">Last uploaded file:</p>
							<div className="downloadBtn">
								<button className="white" onClick={handleDownloadAttendance}>
									<img src={ImageList.DownloadIconBlue} alt="" />
									Download
								</button>
							</div>
						</div>
						<div className="fourth row">
							<p className="bullet">5:</p>
							<div className="uploadContent">
								<p className="title" style={{ color: "red" }}>
									Note: Import Students Partial bifurcation payment with Primary
									account.
								</p>
								<div className="uploadArea">
									<p className="titleHomework">Upload CSV File *</p>
									<div className="uploadContainer">
										<input
											type="file"
											name=""
											id="fileInput"
											ref={fileInputRef}
											onChange={handleFileChange}
											style={{ display: "none" }}
										/>
										<button
											className="uploadHomeworkBtn"
											onClick={handleUploadButtonClick}
										>
											<img src={ImageList.WhiteUploadIcon} alt="" />
											Upload
										</button>
										<div className="fileNames">
											{formData.documentFiles.map((fileName, index) => (
												<p key={index} className="fileName">
													{fileName}
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(index)}
													>
														x Remove
													</span>
												</p>
											))}
										</div>
										{formData.documentFiles.length === 0 && (
											<div>
												<p className="uploadInfo">No file chosen</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<button onClick={handleFileUpload} className="proceedBtn">
								Proceed
							</button>
							{isSpinnerLoading && <LoadingSpinner isLoading={true} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PartialPaymentCSV;
