import React, { useEffect } from "react";
import "./HeroSection.scss";
import HeroImg from "../../../assets/HeroImg.svg";
import SubHeroImg from "../../../assets/subHeroImg.svg";
import ForwardArrow from "../../../assets/arroe_forward_white.svg";
import CursiveArrow from "../../../assets/cursiveArrow.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
	useEffect(() => {
		AOS.init({ duration: 1500 });
	}, []);
	return (
		<div className="HeroContainer">
			<div className="descContainer" data-aos="fade-up" data-aos-once={true}>
				<label htmlFor="subTitle">ONE DAY WORKSHOP</label>
				<h1 className="title1">Double Your</h1>
				<h1 className="title2">Profits</h1>
				<div className="desc">
					<p>
						Struggling with Sales & a Team which does not Perform? Join our
						program…{" "}
						<span>
							You will run your business operations working one day a week. Your
							profits will double.
						</span>
					</p>
				</div>
				<div className="heroSectionBtnsContainer">
					<Link
						className="getStartedBtn"
						to="/registrationForm"
						state={{ work_card_id: 1, city: "Delhi" }}
					>
						<div className="state"> Delhi</div>
						<div className="date">15th September</div>
					</Link>
					<Link
						className="getStartedBtn"
						to="/registrationForm"
						state={{ work_card_id: 1, city: "Mumbai" }}
					>
						<div className="state"> Mumbai</div>
						<div className="date">29th September</div>
					</Link>
				</div>
				<div className="timings">9:00 Am - 6:30 Pm</div>
				<img className="CursiveArrow" src={CursiveArrow} alt="" />
			</div>
			<div className="HeroImgContiner">
				<div className="backColordiv"></div>
				<img
					className="HeroImg"
					src={HeroImg}
					alt=""
					data-aos="fade-up"
					data-aos-once={true}
				/>
				<img
					className="subHeroImg"
					src={SubHeroImg}
					data-aos="fade-up"
					data-aos-once={true}
				/>
			</div>
		</div>
	);
};

export default HeroSection;
