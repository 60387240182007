import getUserAPI from '../../apis/admin/getUserAPI';


export function getUser(token,id){
    return new Promise(async(resolve, reject) => {
        getUserAPI.getSingle(token,id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}