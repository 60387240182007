import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import ImageList from "../../../utils/ImageList";
import { useNavigate, useParams } from "react-router-dom";
import "./Homework.scss";
import { getBatch } from "../../../interface/implementation/admin/getBatchAPIImp";
import Cookies from "js-cookie";
import { addAdminHomework } from "../../../interface/implementation/admin/addAdminHomeworkAPIImp";
import { getHomework } from "../../../interface/implementation/admin/getHomeworkAPIImp";
import { showSuccessToast } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { showToasterMessage } from "../../../utils/helper";
import { getCourse } from "../../../interface/implementation/admin/getCourseAPIImp";

function ClassCard({
	classNo,
	homeworkData,
	urlIds,
	homeworkIds,
	onHomeworkIdChange,
	onAddHomework,
	onClassDataChange,
	onClassUrlChange,
	onAddUrl,
	onHandleFile,
	onRemoveFile,
	fileNames,
}) {
	const fileInputRef = useRef(null);

	const handleHomeworkIdChange = (classNo, index, value) => {
		// Update vimeoIds array in the parent component

		onHomeworkIdChange(classNo, index, value);
	};

	const handleAddAnotherHomework = (classNo) => {
		onAddHomework(classNo);
	};

	const handleInputChange = (e, classNo) => {
		// Update classData array in the parent component
		onClassDataChange(e, classNo);
	};

	const handleUrlChange = (classNo, index, e) => {
		// Update classData array in the parent component
		onClassUrlChange(classNo, index, e);
	};

	const handleAddUrl = (classNo) => {
		onAddUrl(classNo);
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (classNo, e) => {
		onHandleFile(classNo, e);
	};

	const handleRemoveFile = (classNo, index) => {
		onRemoveFile(classNo, index);
	};

	return (
		<div className="homeworkContainer">
			<table>
				<thead>
					<tr>
						<th>Class number</th>
						<th>Title</th>
						<th>Homework question</th>
						<th>Attachment</th>
						<th>Youtube URL</th>
					</tr>
				</thead>
				<tbody>
					<tr valign="top">
						<td>{classNo}</td>
						<td>
							<input
								style={{ width: "200px" }}
								type="text"
								id="class_title"
								placeholder="Enter title here..."
								className="inputTitle"
								value={homeworkData.class_title}
								onChange={(e) => handleInputChange(e, classNo)}
							/>
						</td>
						<td>
							<div className="homeworkQuestion">
								<p className="titleHomework">Questions</p>
								{homeworkIds &&
									homeworkIds.length > 0 &&
									homeworkIds[classNo] &&
									homeworkIds[classNo].map((question, index) => (
										<div className="questionInputes" key={index}>
											<input
												type="text"
												placeholder={`Type your question #${index + 1} here...`}
												value={question.question}
												onChange={(e) => {
													handleHomeworkIdChange(
														classNo,
														index,
														e.target.value
													);
												}}
											/>
										</div>
									))}
								<p
									className="addItem"
									onClick={() => {
										handleAddAnotherHomework(classNo);
									}}
								>
									<span>+</span>Add another item
								</p>
							</div>
						</td>
						<td>
							<div className="uploadArea">
								<p className="titleHomework">Attach homework files here</p>
								<div className="uploadContainer">
									<input
										type="file"
										name=""
										id="fileInput"
										ref={fileInputRef}
										multiple
										onChange={(e) => {
											handleFileChange(classNo, e);
										}}
										style={{ display: "none" }}
									/>
									<button
										className="uploadHomeworkBtn"
										onClick={handleUploadButtonClick}
									>
										<img src={ImageList.WhiteUploadIcon} alt="" />
										Upload
									</button>
									<div className="fileNames">
										{homeworkData.attachments &&
											homeworkData.attachments.length > 0 &&
											homeworkData.attachments.map((file, index) => (
												<p key={index} className="fileName">
													<a
														href={file.content}
														target="_blank"
														rel="noopener noreferrer"
													>
														{" "}
														{file.filename}{" "}
													</a>
													<span
														className="removeBtn"
														onClick={() => handleRemoveFile(classNo, index)}
													>
														x Remove
													</span>
												</p>
											))}
									</div>
									{homeworkData.attachments &&
										homeworkData.attachments.length === 0 && (
											<div>
												<p className="uploadInfo">
													Unlimited number of files can be uploaded to this
													field. Maximum size: 64 MB. File format: doc, docx,
													xls, xlsx, pdf, jpeg, jpg, png, zip, ppt, pptx.
												</p>
											</div>
										)}
								</div>
							</div>
						</td>
						<td>
							<div className="youtubeSection">
								<p className="titleHomework">Youtube URL</p>

								{urlIds &&
									urlIds.length > 0 &&
									urlIds[classNo] &&
									urlIds[classNo].map((url, index) => (
										<div className="youtubeURLContainer" key={index}>
											<div className="drag&dropContainer"></div>
											<div className="urlInput">
												<div className="inputContainer">
													<input
														type="text"
														placeholder="Enter URL Here..."
														value={url}
														onChange={(e) =>
															handleUrlChange(classNo, index, e.target.value)
														}
													/>
												</div>
											</div>
										</div>
									))}
								<p className="addItem" onClick={() => handleAddUrl(classNo)}>
									<span>+</span>Add URL
								</p>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function Homework() {
	const { batchId } = useParams();
	const navigate = useNavigate();
	const [homeworkData, setHomeworkData] = useState([
		{
			class_no: 0,
			class_title: "",
			homework_questions: [],
			attachments: [],
			youtube_links: [],
		},
	]);
	const [uploadFile, setUploadFile] = useState("");
	const [noOfClasses, setNoOfClasses] = useState(0);
	const [homeworkIds, setHomeworkIds] = useState(
		Array.from({ length: noOfClasses }, () => [""])
	);
	// const [homeworkIds, setHomeworkIds] = useState(Array.from({ length: noOfClass }, () => [{ id: '', value: '' }]));

	const [urlIds, setUrlIds] = useState(
		Array.from({ length: noOfClasses }, () => [""])
	);
	const [classes, setClasses] = useState([0]);
	const [fileNames, setFileNames] = useState(
		Array.from({ length: noOfClasses }, () => [])
	);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	// const fileInputRef = useRef(null);
	const [batchData, setBatchData] = useState([]);
	const token = Cookies.get("token");
	useEffect(() => {
		let batch_id = parseInt(batchId);
		getBatch(token, batch_id)
			.then((res) => {
				setBatchData(res.batch[0]);

				// -------------------------Course Api----------
				getCourse(token, res.batch[0].course_id)
					.then((courseRes) => {
						if (courseRes.detail === "Signature has expired.") {
							navigate("/");
							showToast("Token has expired. Please log in again.");
							return;
						}
						if (courseRes.status === "failure") {
							showToast(courseRes.message);
							return;
						}
						setNoOfClasses(courseRes.total_classes);
					})
					.catch((err) => showToast("Something went wrong"));
			})
			.catch();

		getHomework(token, batchId)
			.then((res) => {
				if (res.homework_details[0].class_wise_homework.length > 0) {
					setHomeworkData(res.homework_details[0].class_wise_homework);
					setClasses(
						Array.from(
							{ length: res.homework_details[0].class_wise_homework.length },
							(_, index) => index
						)
					);
					const homeworkIdsArray =
						res.homework_details[0].class_wise_homework.map(
							(item) => item.homework_questions || []
						);

					setHomeworkIds(homeworkIdsArray);
					const urlIdsArray = res.homework_details[0].class_wise_homework.map(
						(item) => item.youtube_links || []
					);

					setUrlIds(urlIdsArray);

					const fileNamesArray =
						res.homework_details[0].class_wise_homework.map((item) => {
							const attachments = item.attachments || [];
							const numberOfAttachments = attachments.length;

							// Generate file names like "File_1", "File_2", ...
							const generatedFileNames = Array.from(
								{ length: numberOfAttachments },
								(_, index) => `File_${index + 1}`
							);

							return generatedFileNames;
						});

					setFileNames(fileNamesArray);
				}

				// setFormData(res.batch[0])
				// if(res.classes.length > 0){
				//   setType('edit')
				//   setClassData(res.classes)
				//   setWeeks(Array.from({ length: res.classes.length }, (_, index) => index));
				//   const vimeoIdsArray = res.classes.map((classItem) =>
				//   classItem.vimeo_id || []
				// );

				// setVimeoIds(vimeoIdsArray);
				// }
			})
			.catch();
	}, []);

	const handleAddHomework = () => {
		// Add class-wise homework logic
	};

	const handlePrevButtonClick = () => {
		navigate(`/update-class/${batchId}`);
	};

	const handleNextButtonClick = () => {
		let finalHomeworkData = {
			batch_id: batchId,
			homeworks: homeworkData,
		};
		addAdminHomework(token, finalHomeworkData)
			.then((res) => {
				successToast("Homework added successfully.");
			})
			.catch();
		navigate("/batch-list");
	};

	// const handleHomeworkIdChange = (classNo, index, value) => {
	//   setHomeworkIds((prevIds) => {
	//     const updatedIds = [...prevIds];

	//     // Ensure the inner array is initialized
	//     if (!updatedIds[classNo]) {
	//       updatedIds[classNo] = [];
	//     }

	//     // Update the Vimeo ID at the specified index
	//     updatedIds[classNo][index] = value;

	//     // Update classData with the new Vimeo IDs
	//     setHomeworkData((prevData) => {
	//       const updatedData = [...prevData];
	//       const classIndex = classNo;
	//       const updatedClassData = {
	//         ...updatedData[classIndex],
	//         homework_questions: updatedIds[classNo].map((value, index) => ({ id: index, question: value })),

	//         // vimeo_id: updatedIds[classNo],
	//       };
	//       updatedData[classIndex] = updatedClassData;
	//       return updatedData;
	//     });

	//     return updatedIds;
	//   });
	// }

	const handleHomeworkIdChange = (classNo, index, value) => {
		setHomeworkIds((prevIds) => {
			const updatedIds = [...prevIds];

			// Ensure the inner array is initialized
			if (!updatedIds[classNo]) {
				updatedIds[classNo] = [];
			}

			// Update the question at the specified index
			updatedIds[classNo][index] = { id: index, question: value };

			// Update classData with the new questions
			setHomeworkData((prevData) => {
				const updatedData = [...prevData];
				const classIndex = classNo;
				const updatedClassData = {
					...updatedData[classIndex],
					homework_questions: updatedIds[classNo],

					// vimeo_id: updatedIds[classNo],
				};
				updatedData[classIndex] = updatedClassData;
				return updatedData;
			});

			return updatedIds;
		});
	};

	const handleAddAnotherHomework = (classNo) => {
		setHomeworkIds((prevIds) => {
			const updatedIds = [...prevIds];
			updatedIds[classNo] = [...(updatedIds[classNo] || []), ""];
			return updatedIds;
		});
	};

	const handleClassDataChange = (e, classNo) => {
		const { id, value } = e.target;

		setHomeworkData((prevData) => {
			const updatedData = [...prevData];
			const classIndex = classNo; // Adjust to zero-based index
			const updatedClassData = {
				...updatedData[classIndex],
				[id]: value,
				class_no: classNo,
			};
			updatedData[classIndex] = updatedClassData;
			return updatedData;
		});
	};

	const handleUrlChange = (classNo, index, value) => {
		setUrlIds((prevIds) => {
			const updatedIds = [...prevIds];

			// Ensure the inner array is initialized
			if (!updatedIds[classNo]) {
				updatedIds[classNo] = [];
			}

			// Update the Vimeo ID at the specified index
			updatedIds[classNo][index] = value;

			// Update classData with the new Vimeo IDs
			setHomeworkData((prevData) => {
				const updatedData = [...prevData];
				const classIndex = classNo;
				const updatedClassData = {
					...updatedData[classIndex],
					youtube_links: updatedIds[classNo],

					// vimeo_id: updatedIds[classNo],
				};
				updatedData[classIndex] = updatedClassData;
				return updatedData;
			});

			return updatedIds;
		});
	};

	const handleAddUrl = (classNo) => {
		setUrlIds((prevIds) => {
			const updatedIds = [...prevIds];
			updatedIds[classNo] = [...(updatedIds[classNo] || []), ""];
			return updatedIds;
		});
	};

	const addHomework = () => {
		if (classes.length < noOfClasses) {
			setClasses((prevClasses) => [...prevClasses, prevClasses.length]);
			setHomeworkData((prevData) => [
				...prevData,
				{
					// Initialize the new class's data with default values
					class_no: 0,
					class_title: "",
					homework_questions: [],
					attachments: [],
					youtube_links: [],
				},
			]);

			setHomeworkIds((prevIds) => [...prevIds, [""]]);
			setUrlIds((prevIds) => [...prevIds, [""]]);
		} else {
			showToast("Can't add more then number of classes.");
		}
	};

	const handleFileChange = (classNo, e) => {
		const files = e.target.files;

		// Store file names for the correct classNo
		setFileNames((prevFileNames) => {
			const updatedFileNames = [...prevFileNames];
			updatedFileNames[classNo] = [
				...(prevFileNames[classNo] || []),
				...Array.from(files).map((file) => file.name),
			];
			return updatedFileNames;
		});

		if (files instanceof FileList || Array.isArray(files)) {
			const promises = Array.from(files).map((file) => {
				return new Promise((resolve) => {
					const reader = new FileReader();

					reader.onloadend = () => {
						const base64String = reader.result;
						resolve({ filename: file.name, content: base64String });
					};

					reader.readAsDataURL(file);
				});
			});

			Promise.all(promises)
				.then((fileObjects) => {
					setHomeworkData((prevData) => {
						const updatedData = [...prevData];
						const classIndex = classNo;

						const existingAttachments =
							updatedData[classIndex]?.attachments || [];
						const updatedClassData = {
							...updatedData[classIndex],
							attachments: [...existingAttachments, ...fileObjects],
						};

						updatedData[classIndex] = updatedClassData;
						return updatedData;
					});
				})
				.catch((error) => {
					console.error("Error converting files to base64:", error);
				});
		} else {
			console.error("Files is not a valid FileList or array:", files);
		}
	};

	const handleRemoveFile = (classNo, index) => {
		setFileNames((prevFileNames) => {
			const updatedFileNames = [...prevFileNames];
			updatedFileNames[classNo].splice(index, 1);
			return updatedFileNames;
		});

		setHomeworkData((prevData) => {
			const updatedData = [...prevData];
			const classIndex = classNo;

			const updatedClassData = {
				...updatedData[classIndex],
				attachments: updatedData[classIndex].attachments.filter(
					(_, i) => i !== index
				),
			};

			updatedData[classIndex] = updatedClassData;
			return updatedData;
		});
	};

	function formatDateForInput(apiDate) {
		if (apiDate) {
			const date = new Date(apiDate);
			const formattedDate = date.toISOString().split("T")[0];
			return formattedDate;
		}
	}

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	return (
		<div className="homeWork">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Update Batch</div>
					<div className="topNavigation">
						<div className="item active">
							<p>Edit Batch</p>
						</div>
						<div className="item active">
							<p>Class/Webinar</p>
						</div>
						<div className="item active">
							<p>Homework</p>
						</div>
					</div>
					<div className="courseDetailsContainer">
						<div className="courseDetailForm">
							<div className="formInput">
								<label htmlFor="batchName">
									Batch Name <span>*</span>
								</label>
								<div className="inputUserName">
									<input
										type="text"
										id="batchName"
										placeholder="Enter Batch name"
										disabled
										// onChange={handleInputChange}
										value={batchData.batch_name}
									/>
								</div>
							</div>

							<div className="formInput">
								<label htmlFor="course">
									Course<span>*</span>
								</label>
								<input
									type="text"
									id="course_id"
									// placeholder="Enter batch name"
									disabled
									value={batchData.course_name}
								/>
							</div>

							<div className="formInput">
								<label htmlFor="startDate">
									Start Date <span>*</span>
								</label>
								<input
									type="date"
									id="start_date"
									value={formatDateForInput(batchData.start_date)}
									disabled
								/>
							</div>
							<div className="formInput">
								<label htmlFor="endDate">
									End Date <span>*</span>
								</label>
								<input
									type="date"
									id="end_date"
									value={formatDateForInput(batchData.end_date)}
									disabled
								/>
							</div>

							{/* <div className='formInput'>
                <label htmlFor="totalClasses">Total no of classes <span>*</span></label>
                <div className="inputUserName">
                  <input
                    type="text"
                    id="totalClasses"
                    placeholder="Enter total classes"
                    // onChange={handleInputChange}
                    disabled
                    value={batchData.totalClasses}
                  />
                </div>
              </div> */}
						</div>
						{classes &&
							classes.length > 0 &&
							classes.map((item, index) => (
								<ClassCard
									key={item}
									classNo={index}
									// isCollapsed={collapseAll}
									// onClassDataChange={handleClassDataChange}
									homeworkData={homeworkData[item]}
									urlIds={urlIds}
									homeworkIds={homeworkIds}
									onHomeworkIdChange={handleHomeworkIdChange}
									onAddHomework={handleAddAnotherHomework}
									onClassDataChange={handleClassDataChange}
									onClassUrlChange={handleUrlChange}
									onAddUrl={handleAddUrl}
									onHandleFile={handleFileChange}
									onRemoveFile={handleRemoveFile}
									fileNames={fileNames}
								/>
							))}

						<div className="addHomework">
							<button onClick={addHomework}>+ Add class-wise homework</button>
							<p>to add class-wise questions</p>
						</div>
						<div className="navigationBtns">
							<button className="prevBtn" onClick={handlePrevButtonClick}>
								<img src={ImageList.GoBackBlue} alt="" />
								Previous
							</button>
							<button className="nextButton" onClick={handleNextButtonClick}>
								Next
								<img src={ImageList.ArrowForwardWhite} alt="" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Homework;
