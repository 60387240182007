import registerClassAPI from '../apis/registerClassAPI';


export function registerClass(token,body){
    return new Promise(async(resolve, reject) => {
        registerClassAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}