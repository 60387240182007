/** @format */

import React from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Login from "./pages/LoginPage/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import CourseDetails from "./pages/SuperAdmin/CourseDetails/CourseDetails";
import CourseList from "./pages/SuperAdmin/CourseList/CourseList";
import AddBatch from "./pages/SuperAdmin/AddBatch/AddBatch";
import AddUser from "./pages/SuperAdmin/AddUser/AddUser";
import UserList from "./pages/SuperAdmin/UserList/UserList";
import BarcodeList from "./pages/SuperAdmin/BarcodeList/BarcodeList";
import CRMHelpTicket from "./pages/CRMadmin/CRMHelpTicket/CRMHelpTicket";
import BatchHelpTicket from "./pages/BatchCRM/BatchHelpTicket/BatchHelpTicket";
import AdminHelpTicket from "./pages/SuperAdmin/AdminHelpTicket/AdminHelpTicket";
import StudentHelpTicket from "./pages/student/StudentHelpTicket/StudentHelpTicket";
import Homework from "./pages/SuperAdmin/Homework/Homework";
import Materials from "./pages/SuperAdmin/Materials/Materials";
import AddCourse from "./pages/SuperAdmin/AddCourse/AddCourse";
import BatchList from "./pages/SuperAdmin/BatchList/BatchList";
import EditBatch from "./pages/SuperAdmin/EditBatch/EditBatch";
import StudentBatchList from "./pages/SuperAdmin/StudentBatchList/StudentBatchList";
import StudentAttendanceList from "./pages/SuperAdmin/StudentAttendance/StudentAttendanceList";
import UpdateClass from "./pages/SuperAdmin/BatchWebinar/UpdateClass";
import CompanyDetails from "./pages/SuperAdmin/CompanyDetails/CompanyDetails";
import BankDetailsList from "./pages/SuperAdmin/BankDetailsList/BankDetailsList";
import StudentVideoRequestList from "./pages/SuperAdmin/StudentVideoRequest/StudentVideoRequestList";
import CalendarList from "./pages/SuperAdmin/CalendarList/CalendarList";
import CreateCalendar from "./pages/SuperAdmin/CreateCalendar/CreateCalendar";
import CRMMessages from "./pages/CRMadmin/CRMMessages/CRMMessages";
import StudentMessages from "./pages/student/StudentMessages/StudentMessages";
import BatchCRMMessages from "./pages/BatchCRM/BatchCRMMessages/BatchCRMMessages";
import AddStudent from "./pages/SuperAdmin/AddStudent/AddStudent";
import AddPartner from "./pages/SuperAdmin/AddPartner/AddPartner";
import MemberList from "./pages/SuperAdmin/MemberList/MemberList";
import StudentProcess from "./pages/SuperAdmin/StudentProcess/StudentProcess";
import BatchStudentList from "./pages/SuperAdmin/BatchStudentList/BatchStudentList";
import PaymentDetail from "./pages/SuperAdmin/PaymentDetail/PaymentDetail";
import AssignCRM from "./pages/SuperAdmin/AssignCRM/AssignCRM";
import NewStudentCSV from "./pages/SuperAdmin/NewStudentCSV/NewStudentCSV";
import UpdateStudentCSV from "./pages/SuperAdmin/UpdateStudentCSV/UpdateStudentCSV";
import PartialPaymentCSV from "./pages/SuperAdmin/PartialPaymentCSV/PartialPaymentCSV";
import PaymentUpdate from "./pages/SuperAdmin/PaymentUpdate/PaymentUpdate";
import StudentBatch from "./pages/student/StudentBatch/StudentBatch";
import CSVImport from "./pages/SuperAdmin/AttendanceCSVImport/CSVImport";
import StudentTicket from "./pages/student/StudentTicket/StudentTicket";
import StudentClassList from "./pages/student/StudentClassList/StudentClassList";
import StudentDashboard from "./pages/student/StudentDashboard/StudentDashboard";
import HomeWorkList from "./pages/student/HomeWorkList/HomeWorkList";
import StudentCRMHelpTicket from "./pages/student/StudentCRMHelpTicket/StudentCRMHelpTicket";
import StudentCRMMessages from "./pages/student/StudentCRMMessages/StudentCRMMessages";
import CrmBatchList from "./pages/BatchCRM/CrmBatchList/CrmBatchList";
import CrmAdminList from "./pages/CRMadmin/CrmBatchList/CrmBatchList";
import CrmBatchHomework from "./pages/BatchCRM/CrmBatchHomework/CrmBatchHomework";
import MyCRMStudents from "./pages/CRMadmin/MyCRMStudents/MyCRMStudents";
import AllStudents from "./pages/BatchCRM/AllStudents/AllStudents";
import MyStudents from "./pages/BatchCRM/MyStudents/MyStudents";
import CrmAdminHomework from "./pages/CRMadmin/CrmAdminHomework/CrmAdminHomework";
import CrmAdminVideoRequest from "./pages/CRMadmin/CrmAdminVideoRequest/CrmAdminVideoRequest";
import CrmVideoRequestPage from "./pages/BatchCRM/CrmVideoRequestPage/CrmVideoRequestPage";
import HomeWorkAction from "./pages/student/HomeWorkAction/HomeWorkAction";
import StudentCalendarList from "./pages/student/StudentCalendarList/StudentCalendarList";
import StudentTerms from "./pages/student/StudentTerms/StudentTerms";
import HomeWorkAccess from "./pages/CRMadmin/CrmHomeworkAccess/CrmHomeworkAccess";
import NatureBusiness from "./pages/SuperAdmin/NatureBusiness/NatureBusiness";
import Profile from "./pages/Profile/Profile";
import AdminPaymentInfo from "./pages/SuperAdmin/AdminPaymentInfo/AdminPaymentInfo";
import StudentPaymentInfo from "./pages/student/StudentPaymentInfo/StudentPaymentInfo";
import RefundInvoice from "./pages/SuperAdmin/RefundInvoice/RefundInvoice";
import ProtectedLayout from "./ProtectedLayout";
import BatchCRMPaymentInfo from "./pages/BatchCRM/BatchCRMPaymentInfo/BatchCRMPaymentInfo";
import CRMPaymentInfo from "./pages/CRMadmin/CRMPaymentInfo/CRMPaymentInfo";
import CrmAdminAttendance from "./pages/CRMadmin/CrmAdminAttendance/CrmAdminAttendance";
import AccessApprovalRequest from "./pages/SuperAdmin/AccessApprovalRequest/AccessApprovalRequest";
import AccessPaymentDetail from "./pages/SuperAdmin/AccessPaymentDetail/AccessPaymentDetail";

import Registration from "./pages/Registration/Registration";
import ThankYou from "./pages/ThankYou/ThankYou";
import TechnicalNotes from "./pages/SuperAdmin/TechnicalNotes/TechnicalNotes";
import StudentAttendancePercentage from "./pages/SuperAdmin/StudentAttendancePercentage/StudentAttendancePercentage";
import AddWorkshop from "./pages/SuperAdmin/AddWorkshop/AddWorkshop";
import WorkshopList from "./pages/SuperAdmin/WorkshopList/WorkshopList";

import PaymentOverdueList from "./pages/SuperAdmin/PaymentOverdueList/PaymentOverdueList";
import OverduePaymentInfo from "./pages/SuperAdmin/OverduePaymentInfo/OverduePaymentInfo";

import CrmPaymentOverdueList from "./pages/BatchCRM/CrmPaymentOverdueList/CrmPaymentOverdueList";
import CrmOverduePaymentInfo from "./pages/BatchCRM/CrmOverduePaymentInfo/CrmOverduePaymentInfo";
import WorkshopAttendanceList from "./pages/SuperAdmin/WorkshopAttendance/WorkshopAttendanceList";
import UpdateWorkshopClass from "./pages/SuperAdmin/UpdateWorkshopClass/UpdateWorkshopClass";
import WorkshopBarcodeList from "./pages/SuperAdmin/WorkshopBarcodeList/WorkshopBarcodeList";
import ParticipantWorkshopList from "./pages/SuperAdmin/ParticipantWorkshopList/ParticipantWorkshopList";
import WorkshopParticipantList from "./pages/SuperAdmin/WorkshopParticipantList/WorkshopParticipantList";
import NewWorkshopStudentCSV from "./pages/SuperAdmin/NewWorkshopStudentCSV/NewWorkshopStudentCSV";
import UpdateWorkshopStudentCSV from "./pages/SuperAdmin/UpdateWorkshopStudentCSV/UpdateWorkshopStudentCSV";
import WorkshopRegistration from "./pages/WorkshopRegistration/WorkshopRegistration";
import Payment from "./pages/Payment/Payment";
import HomePage from "./pages/OneDayWorkshop/HomePage/HomePage";
import RegistrationForm from "./pages/OneDayWorkshop/RegistrationForm/RegistrationForm";
import PaymentStatus from "./pages/OneDayWorkshop/PaymentStatus/PaymentStatus";

// const ResetPasswordFunction = () => {
//   const params = useParams();
//   return <ResetPassword userId={params.userId} />;
// }

const CourseDetailsFunction = () => {
	const params = useParams();
	return <CourseDetails course_id={params.id} />;
};

function Router() {
	// const location = window.location.pathname;
	// const location = useParams();

	// const routesWithoutSidebar = [
	//   "/",
	//   "/login",
	//   "/forgot-password",
	//   "/reset-password",
	// ];

	// const isSidebarVisible = !routesWithoutSidebar.includes(location);
	return (
		<div>
			<BrowserRouter>
				<Routes>
					{/* for everyone */}
					<Route path="/" element={<Login />} />
					<Route path="/thank-you" element={<ThankYou />} />
					<Route path="/payment" element={<Payment />} />

					{/* *****************oneDayWoekshop routes*************** */}
					<Route path="/double-your-profits" element={<HomePage />} />
					<Route path="/registrationForm" element={<RegistrationForm />} />
					<Route path="/payment-status/:id" element={<PaymentStatus />} />

					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/registration/:id" element={<Registration />} />
					<Route
						path="/workshop-registration/:id"
						element={<WorkshopRegistration />}
					/>
					<Route element={<ProtectedLayout />}>
						<Route index path="/dashboard" element={<Dashboard />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/dashboard" element={<Dashboard />} />
						{/* for super admin */}
						<Route path="/course-list" element={<CourseList />} />
						<Route
							path="/course-details/:id"
							element={<CourseDetailsFunction />}
						/>
						<Route path="/user/:type" element={<AddUser />} />
						<Route path="/refund-invoice" element={<RefundInvoice />} />
						<Route path="/user/:type/:userId" element={<AddUser />} />
						<Route path="/user-list" element={<UserList />} />
						<Route path="/nature-business" element={<NatureBusiness />} />
						<Route path="/barcode-list" element={<BarcodeList />} />
						<Route path="/homework/:batchId" element={<Homework />} />
						<Route
							path="/technicalnotes/:batchId"
							element={<TechnicalNotes />}
						/>
						<Route path="/materials/:id" element={<Materials />} />
						<Route path="/add-course" element={<AddCourse />} />
						<Route path="/add-batch" element={<AddBatch />} />
						<Route path="/batch-list" element={<BatchList />} />
						<Route path="/workshop-list" element={<WorkshopList />} />
						<Route path="/batch/edit/:batchId" element={<EditBatch />} />
						<Route path="/update-class/:batchId" element={<UpdateClass />} />
						<Route path="/add-workshop" element={<AddWorkshop />} />
						<Route
							path="/add-workshop/:type/:workshopId"
							element={<AddWorkshop />}
						/>

						<Route path="/company-details" element={<CompanyDetails />} />
						<Route
							path="/company-details/:type/:id"
							element={<CompanyDetails />}
						/>
						<Route path="/bank-details" element={<BankDetailsList />} />
						<Route path="/calendar-list" element={<CalendarList />} />
						<Route path="/create-calendar" element={<CreateCalendar />} />
						<Route
							path="/calendar/:type/:calendarId"
							element={<CreateCalendar />}
						/>
						<Route path="/csv-import" element={<CreateCalendar />} />
						{/* for super admin & sub admin */}
						<Route path="/student-batch-list" element={<StudentBatchList />} />
						<Route
							path="/student-attendance-list"
							element={<StudentAttendanceList />}
						/>
						<Route
							path="/student-attendance-percentage"
							element={<StudentAttendancePercentage />}
						/>
						<Route
							path="/student-video-requests"
							element={<StudentVideoRequestList />}
						/>
						<Route
							path="/admin/crm-help-ticket"
							element={<AdminHelpTicket />}
						/>
						{/* <Route path="/student/:type" element={<AddStudent />} /> */}
						<Route path="/student/:type/:userId" element={<AddStudent />} />
						<Route path="/add-partner" element={<AddPartner />} />
						<Route path="/member-list" element={<MemberList />} />
						<Route path="/student-process" element={<StudentProcess />} />
						<Route path="/batch-student-list" element={<BatchStudentList />} />
						<Route
							path="/student-payment-info"
							element={<AdminPaymentInfo />}
						/>
						<Route
							path="/payment-overdue-list"
							element={<PaymentOverdueList />}
						/>
						<Route
							path="/overdue-payment-info/:studentId"
							element={<OverduePaymentInfo />}
						/>
						<Route path="/payment-detail" element={<PaymentDetail />} />
						<Route
							path="/access-payment-detail/:studentId"
							element={<AccessPaymentDetail />}
						/>
						<Route path="/assign-crm" element={<AssignCRM />} />
						<Route path="/new-student-csv" element={<NewStudentCSV />} />
						<Route path="/update-student-csv" element={<UpdateStudentCSV />} />
						<Route
							path="/partial-payment-csv"
							element={<PartialPaymentCSV />}
						/>
						<Route path="/payment-update" element={<PaymentUpdate />} />
						<Route
							path="/attendance-csv-import/:batchId"
							element={<CSVImport />}
						/>
						<Route
							path="/update-workshop-class/:workshopId"
							element={<UpdateWorkshopClass />}
						/>
						<Route
							path="/workshop-barcode-list"
							element={<WorkshopBarcodeList />}
						/>
						<Route
							path="/participant-workshop-list"
							element={<ParticipantWorkshopList />}
						/>
						<Route
							path="/workshop-participant-list"
							element={<WorkshopParticipantList />}
						/>
						<Route
							path="/workshop-attendance-list"
							element={<WorkshopAttendanceList />}
						/>
						<Route
							path="/new-workshop-student-csv"
							element={<NewWorkshopStudentCSV />}
						/>
						<Route
							path="/update-workshop-student-csv"
							element={<UpdateWorkshopStudentCSV />}
						/>
						{/* for batch crm */}
						<Route
							path="/crm-batch/crm-help-ticket"
							element={<BatchHelpTicket />}
						/>
						<Route
							path="/crm-batch/student-message"
							element={<BatchCRMMessages />}
						/>
						<Route path="/crm-batch/batch-list" element={<CrmBatchList />} />
						<Route
							path="/crm-batch/payment-info"
							element={<BatchCRMPaymentInfo />}
						/>
						<Route
							path="/crm-batch/payment-overdue-list"
							element={<CrmPaymentOverdueList />}
						/>
						<Route
							path="/crm-batch/batch-list/:id/my-students"
							element={<MyStudents />}
						/>
						<Route
							path="/crm-batch/batch-list/:id/all-students"
							element={<AllStudents />}
						/>
						<Route
							path="/crm-batch/batch-list/:id/homework"
							element={<CrmBatchHomework />}
						/>
						<Route
							path="/crm-batch/batch-list/:id/video-request"
							element={<CrmVideoRequestPage />}
						/>
						<Route
							path="/access-approval-request"
							element={<AccessApprovalRequest />}
						/>
						{/* for crm admin */}
						<Route
							path="/crm-admin/crm-help-ticket"
							element={<CRMHelpTicket />}
						/>
						<Route
							path="/crm-admin/student-message"
							element={<CRMMessages />}
						/>
						<Route
							path="/crm-admin/batch-list/:id/my-students"
							element={<MyCRMStudents />}
						/>
						<Route path="/crm-admin/batch-list" element={<CrmAdminList />} />
						<Route
							path="/crm-admin/payment-info"
							element={<CRMPaymentInfo />}
						/>
						<Route
							path="/crm-admin/payment-overdue-list"
							element={<CrmPaymentOverdueList />}
						/>
						<Route
							path="/crm-overdue-payment-info/:studentId"
							element={<CrmOverduePaymentInfo />}
						/>
						<Route
							path="/crm-admin/batch-list/:id/video-request"
							element={<CrmAdminVideoRequest />}
						/>
						<Route
							path="/crm-admin/batch-list/:id/view-attendance"
							element={<CrmAdminAttendance />}
						/>
						<Route
							path="/crm-admin/batch-list/:id/homework"
							element={<CrmAdminHomework />}
						/>
						<Route
							path="/crm-admin/student/homework/:id"
							element={<HomeWorkAccess />}
						/>
						{/* for student */}
						{/* <Route path="/dashboard" element={<StudentDashboard />} /> */}
						<Route
							path="/student/crm-help-ticket"
							element={<StudentHelpTicket />}
						/>
						<Route path="/student/crm-message" element={<StudentMessages />} />
						<Route path="/student/student-batch" element={<StudentBatch />} />
						<Route path="/student/course/ticket" element={<StudentTicket />} />
						<Route
							path="/student/course/class-list"
							element={<StudentClassList />}
						/>
						<Route
							path="/student/class-List/homework"
							element={<HomeWorkList />}
						/>
						<Route
							path="/student/homework-action"
							element={<HomeWorkAction />}
						/>
						<Route
							path="/student/bci-calendar"
							element={<StudentCalendarList />}
						/>
						<Route path="/student/terms" element={<StudentTerms />} />
						<Route
							path="/student/payment-history"
							element={<StudentPaymentInfo />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default Router;
