import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./MemberList.scss";
import { Link, useLocation } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import { getMembers } from "../../../interface/implementation/admin/getMembersAPIImp";
import Cookies from "js-cookie";

import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";

import { useNavigate } from "react-router-dom";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";

function MemberList() {
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;
	const token = Cookies.get("token");
	const [currentItems, setCurrentItems] = useState([]);
	const [memberList, setMemberList] = useState([]);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const location = useLocation();
	const stateData = location.state;
	const primaryParticipantPhoneNumber = stateData.primaryParticipantPhoneNumber;

	useEffect(() => {
		getMembers(token, primaryParticipantPhoneNumber).then((res) => {
			if (res.detail === "Signature has expired.") {
				navigate("/");
				showToast("Token has expired. Please log in again.");
				return;
			}
			if (res.status === "failure") {
				showToast(res.message);
				return;
			}

			setMemberList(res.member_details);

			setCurrentItems(
				res.member_details.slice(indexOfFirstItem, indexOfLastItem)
			);
		});
	}, []);
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const indexOfLastItem = currentPage * itemsPerPage;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="memberList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">View Members List</div>
					<div className="batchListContainer">
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Batch Name</th>
										<th>Company Code</th>
										<th>Member Type</th>
										<th>Student Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th>Profile / Paument</th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map((member, index) => (
										<tr key={member.student_id}>
											<td>{index + 1}</td>
											<td>{member.company_name}</td>
											<td>{member.company_id}</td>
											<td>{member.member_type}</td>
											<td>{member.student_name}</td>
											<td>{member.email_id}</td>
											<td>{member.phone_no}</td>
											<td className="actionBtn">
												<Link
													state={{ type: "Edit", memberId: member.student_id }}
													to={{
														pathname: `/student/edit/${member.student_id}`,
													}}
													className="editBtn"
												>
													<img src={ImageList.EditIcon} alt="" />
													Edit
												</Link>
												<Link
													to={{
														pathname: `/student-process`,
														state: { type: "Edit", memberId: member.id },
													}}
													className="editBtn"
												>
													<img src={ImageList.InstallmentIcon} alt="" />
													Installment
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(memberList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MemberList;
