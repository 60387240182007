import React, { useEffect, useState } from "react";
import "./CRMTicketModal.scss";
import ImageList from "../../utils/ImageList";
import Cookies from "js-cookie";
import { ticketReply } from "../../interface/implementation/crmAdmin/ticketReplyAPIImp";
import { reopenTicket } from "../../interface/implementation/student/reopenTicketAPIImp";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CRMTicketModal({ onClick, ticket, role, setReloadState }) {
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [isChecked, setIsChecked] = useState(false);
	const [message, setMessage] = useState("");

	const loggedInUser = localStorage.getItem("userName");

	const handleCloseClick = () => {
		onClick();
	};

	const handleInputChange = (e) => {
		setIsChecked(e.target.checked);
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const handleSendClick = () => {
		if (!message) {
			showToast("Please type your reason");
		} else {
			let formData = {
				helpdesk_id: ticket.ticket_id,
				is_ticket_closed: isChecked,
				comments: [
					{
						comment: message,
					},
				],
			};
			if (role === "crm") {
				ticketReply(token, formData).then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					// navigate("/course-list")
					successToast("Reply sent successfully.");

					setReloadState(true);
					onClick();
				});
			} else if (role === "student") {
				reopenTicket(token, formData)
					.then((res) => {
						if (res.detail === "Signature has expired.") {
							navigate("/");
							showToast("Token has expired. Please log in again.");
							return;
						}
						if (res.status === "failure") {
							showToast(res.message);
							return;
						}
						// navigate("/course-list")
						successToast("Ticket reopened successfully.");

						setReloadState(true);
						onClick();
					})
					.catch((err) => showToast("Something went wrong"));
			}
		}
	};

	return (
		<div className="modal-overlay CRMTicketModal">
			<ToastContainer limit={1} />
			<div className="modal-content">
				<div className="modalHeader">
					<div className="left">
						<img src={ImageList.TicketIcon} alt="" />
						<p className="title">Ticket</p>
					</div>
					<p className="crossIcon" onClick={handleCloseClick}>
						x
					</p>
				</div>

				<div className="modalBody">
					{ticket?.comments?.map((comment, index) => (
						<div key={index} className="chatBubble">
							<div
								className={`bubble ${
									comment.comment_username === loggedInUser
										? "userBubble"
										: "supportBubble"
								}`}
							>
								<div className="top">
									<p
										className={`name ${
											comment.comment_username === loggedInUser
												? "boldBlack"
												: ""
										}`}
									>
										{comment.comment_by}
									</p>
									<p className="timeStamp">
										{new Date(comment.comment_on)
											.toLocaleString("en-GB", {
												day: "numeric",
												month: "numeric",
												year: "numeric",
												hour: "numeric",
												minute: "numeric",
												hour12: false,
											})
											.replace(",", " -")}
									</p>
								</div>
								<p className="desc">{comment.comment}</p>
							</div>
						</div>
					))}
				</div>

				{role === "crm" && ticket.ticket_status !== "closed" && (
					<div className="modalFooter checkboxFooter">
						<input
							type="text"
							className="msgBox"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						<div className="actions">
							<label className="ticketCheckbox">
								<input
									type="checkbox"
									checked={isChecked}
									onChange={handleInputChange}
								/>
								Close ticket
							</label>

							<button className="sendBtn" onClick={handleSendClick}>
								Send
								<img src={ImageList.SendIcon} alt="" />
							</button>
						</div>
					</div>
				)}
				{role === "student" && ticket.ticket_status === "closed" && (
					<div className="modalFooter">
						<input
							type="text"
							className="msgBox"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						<div className="actions">
							<button className="sendBtn" onClick={handleSendClick}>
								Reopen
								<img src={ImageList.SendIcon} alt="" />
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default CRMTicketModal;
