import { apiProvider } from "./apiProvider";

export class ApiCore {
	constructor(options) {
		if (options.post) {
			this.post = (token, body) => {
				return apiProvider.post(options.url, token, body); //removed version
			};
		}

		if (options.postEncoded) {
			this.postEncoded = (token, body) => {
				return apiProvider.postEncoded(options.url, token, body); //removed version
			};
		}

		if (options.postFormData) {
			this.postFormData = (token, body) => {
				return apiProvider.postFormData(options.url, token, body); //removed version
			};
		}

		if (options.getSingle) {
			this.getSingle = (token, id) => {
				return apiProvider.getSingle(options.url, token, id);
			};
		}

		if (options.getSingleWithQuery) {
			this.getSingleWithQuery = (token, id, body) => {
				return apiProvider.getSingleWithQuery(options.url, token, id, body);
			};
		}

		if (options.getAll) {
			this.getAll = (token, body, id) => {
				return apiProvider.getAll(options.url, token, body, id);
			};
		}

		if (options.download) {
			this.download = (token, body) => {
				return apiProvider.download(options.url, token, body);
			};
		}

		if (options.getFiltered) {
			this.getFiltered = (token, id) => {
				return apiProvider.getAll(options.url, token, id);
			};
		}

		if (options.sort) {
			this.sort = (token, body) => {
				return apiProvider.sort(options.url, token, body);
			};
		}

		if (options.put) {
			this.put = (token, body, id) => {
				return apiProvider.put(options.url, token, body, id); //removed version
			};
		}

		if (options.putWithQuery) {
			this.putWithQuery = (token, body) => {
				return apiProvider.putWithQuery(options.url, token, body); //removed version
			};
		}
	}
}
