import apiTasks from "../../apis/student/getStudentAbsent";

export function getStudentAbsent(token) {
	return new Promise(async (resolve, reject) => {
		apiTasks
			.getAll(token)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
