import React, { useState } from "react";
import "./VideoApprovModal.scss";
import { enableVideoRequest } from "../../interface/implementation/batchCRM/enableVideoRequestAPIImp";
import Cookies from "js-cookie";
import { showSuccessToast, showToasterMessage } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function VideoApprovModal({
	onClick,
	initialStatus,
	studentId,
	batchId,
	classId,
}) {
	const navigate = useNavigate();
	const token = Cookies.get("token");

	const [status, setStatus] = useState(initialStatus);
	const handleInputChange = (e) => {
		setStatus(e.target.value);
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleCloseClick = () => {
		onClick();
	};
	const handleSaveClick = () => {
		let formData = {
			class_id: classId,
			student_id: studentId,
			batch_id: batchId,
			enable_video_request: status === "Yes" ? true : false,
		};
		enableVideoRequest(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				showSuccessToast("Enabled successful");
				// navigate("/course-list")
				onClick();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="video-modal-overlay VideoApprovModal">
			<div className="video-modal-content">
				<div className="video-modal-header">
					<p className="video-title">Enable participant video request</p>
					<p className="closeBtn" onClick={handleCloseClick}>
						x
					</p>
				</div>
				<div className="body">
					<div className="inputContainer">
						<label htmlFor="selectedParticipantType">
							{" "}
							Enable video request
						</label>
						<div className="inputCode">
							<select
								id="selectedParticipantType"
								onChange={(e) => handleInputChange(e)}
								value={status}
							>
								<option value="">Enable video request</option>
								<option value="Yes">yes</option>
								<option value="No">no</option>
							</select>
						</div>
					</div>
				</div>
				<div className="saveButtonWrapper">
					<button className="approveBtn" onClick={handleSaveClick}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default VideoApprovModal;
