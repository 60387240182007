import getStudentBatchesAPI from '../../apis/student/getStudentBatchesAPI';

export function getStudentBatches(token){
    return new Promise(async(resolve, reject) => {
        getStudentBatchesAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}