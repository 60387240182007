import getWorkshopAttendanceAPI from "../../apis/admin/getWorkshopAttendanceAPI";

export const getWorkshopAttendance = (token) => {
  return new Promise(async (resolve, reject) => {
    getWorkshopAttendanceAPI
      .getAll(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
