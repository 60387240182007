import getHomeworkAPI from '../../apis/admin/getHomeworkAPI';


export function getHomework(token, id){
    return new Promise(async(resolve, reject) => {
        getHomeworkAPI.getAll(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}