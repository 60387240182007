import React, { useState, useEffect } from "react";
import "./SideBar.scss";
import ImageList from "../../utils/ImageList";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { logout } from "../../interface/implementation/logoutAPIImp";
import Cookies from "js-cookie";
import { userDetails } from "../../interface/implementation/userDetailsAPIImp";
import {
	superAdminMenuItems,
	subAdminListItems,
	adminCRMListItems,
	batchCRMListItems,
	studentCRMListItems,
} from "../../utils/SidebarMenuItems";
import { showToasterMessage } from "../../utils/helper";
import { checkRolePermission } from "../../interface/implementation/checkRolePermissionAPIImp";

function SidebarNav() {
	const allRoleList = [
		{ value: "1", name: "Student" },
		{ value: "2", name: "Super Admin" },
		{ value: "3", name: "Sub Admin" },
		{ value: "4", name: "Batch CRM" },
		{ value: "5", name: "Admin CRM" },
	];
	const [userRoles, setUserRoles] = useState([]);
	const [activeList, setActiveList] = useState([]);
	const [dropdownList, setDropdownList] = useState([]);
	const [selectedRole, setSelectedRole] = useState("");
	const [user, setUser] = useState({});
	const navigate = useNavigate();
	const location = useLocation();
	const token = Cookies.get("token");

	useEffect(() => {
		const roles = JSON.parse(localStorage.getItem("userRoles")) || [];
		const userName = localStorage.getItem("userName");
		setUserRoles(roles);

		const storedSelectedRole = localStorage.getItem("selectedRole");
		checkRolePermission(token, storedSelectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch();
		setSelectedRole(storedSelectedRole || roles[0] || "");
		userDetails(token, userName)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToasterMessage("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToasterMessage(res.message);
					return;
				}
				setUser(res.user);
				localStorage.setItem("fullname", res.user.full_name);
			})
			.catch((err) => showToasterMessage("Something went wrong"));
		localStorage.setItem(
			"selectedRole",
			storedSelectedRole ? storedSelectedRole : roles[0] || ""
		);
	}, [token]);

	useEffect(() => {
		const sidebarMapping = {
			1: studentCRMListItems,
			2: superAdminMenuItems,
			3: subAdminListItems,
			4: batchCRMListItems,
			5: adminCRMListItems,
		};

		const filteredRoles = allRoleList.filter((role) =>
			userRoles.includes(parseInt(role.value))
		);

		setDropdownList(filteredRoles);
		// setSelectedRole(filteredRoles[0]?.value || "");
		const storedSelectedRole = localStorage.getItem("selectedRole");
		setSelectedRole(storedSelectedRole || filteredRoles[0]?.value || "");

		const filteredSidebarMapping = Object.fromEntries(
			Object.entries(sidebarMapping).filter(([key]) =>
				userRoles.includes(parseInt(key))
			)
		);

		setActiveList(Object.values(filteredSidebarMapping));
	}, [userRoles]);

	useEffect(() => {
		if (selectedRole === "1") {
			setActiveList(studentCRMListItems);
		} else if (selectedRole === "2") {
			setActiveList(superAdminMenuItems);
		} else if (selectedRole === "3") {
			setActiveList(subAdminListItems);
		} else if (selectedRole === "4") {
			setActiveList(batchCRMListItems);
		} else if (selectedRole === "5") {
			setActiveList(adminCRMListItems);
		}
	}, [selectedRole]);

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	const handleRoleChange = (event) => {
		const selectedValue = event.target.value;
		setSelectedRole(selectedValue);
		localStorage.setItem("selectedRole", selectedValue);
		navigate("/dashboard");
		window.location.reload();
	};

	// const handleMenuClick = (index, link) => {
	//   const hasSubmenus = activeList[index]?.subMenus?.length > 0;
	//   const isActive =
	//     location.pathname === link || location.pathname.includes(link);

	//   if (hasSubmenus) {
	//     setActiveList((prev) =>
	//     prev.map((item, i) =>
	//       i === index
	//         ? { ...item, active: !item.active }
	//         : { ...item, active: false }
	//     )
	//   );
	//   } else if (!isActive) {
	//     navigate(link);
	//   }
	// };

	const handleMenuClick = (index, link) => {
		const hasSubmenus = activeList[index]?.subMenus?.length > 0;
		const isActive = location.pathname === link;

		setActiveList((prev) =>
			prev.map((item, i) =>
				i === index
					? { ...item, active: !item.active }
					: { ...item, active: false }
			)
		);

		if (!isActive && !hasSubmenus) {
			navigate(link);
		}
	};

	const handleSubMenuClick = (link) => {
		navigate(link);
	};

	return (
		<div className="sideBarContainer">
			<div className="headerDropdown">
				<select id="userRole" onChange={handleRoleChange} value={selectedRole}>
					{dropdownList.map((role) => (
						<option key={role.value} value={role.value}>
							{role.name === "Admin CRM" ? "CRM" : role.name}
						</option>
					))}
				</select>
			</div>
			<div className="menuItemList">
				{activeList.map(({ link, iconSrc, title, subMenus }, index) => (
					<div className="menuItem" key={index}>
						<div
							className={`menuBox ${
								location.pathname === link ? "active" : ""
							}`}
							onClick={() => handleMenuClick(index, link)}
						>
							<div className="iconText">
								<img src={iconSrc} alt="" className="menuIcon" />
								<p className="menuTitle">{title}</p>
							</div>
							{subMenus && subMenus.length > 0 && (
								<img
									src={ImageList.DropdownIcon}
									alt=""
									className="dropdownIndicator"
								/>
							)}
						</div>
						{subMenus && subMenus.length > 0 && (
							<div
								className="subMenus"
								style={{
									display: activeList[index]?.active ? "block" : "none",
								}}
							>
								{subMenus.map(({ link, title }, subIndex) => (
									<p
										className={`menuBox ${
											location.pathname === link ||
											location.pathname.includes(link)
												? "active"
												: ""
										}`}
										onClick={() => handleSubMenuClick(link)}
										key={subIndex}
									>
										{title}
									</p>
								))}
							</div>
						)}
					</div>
				))}
			</div>

			<div className="sidebarFooter">
				<div className="avatarContainer">
					{/* <div className="avatar">
            <img src={user.profile_picture ? user.profile_picture :  ImageList.UserSiderbarImg} alt="" />
            <span className="greenDot"></span>
          </div> */}
					<div className="details">
						<p className="name">{user.full_name ? user.full_name : ""}</p>
						<p
							style={{
								whiteSpace: "wrap",
								width: "80%",
							}}
						>
							{user.email_id ? user.email_id : ""}
						</p>
					</div>
				</div>
				<div className="logoutBox" onClick={signOut}>
					<img src={ImageList.LogoutIcon} alt="" />
					<p className="logout">Logout</p>
				</div>
			</div>
		</div>
	);
}

export default SidebarNav;
