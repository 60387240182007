import studentReplyAPI from '../apis/studentReplyAPI';


export function studentReply(token,body){
    return new Promise(async(resolve, reject) => {
        studentReplyAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}