// import { showToasterMessage } from "../utils/helper";

// export function handleResponse(response) {
//     if (response.results) {
//       return response.results;
//     }

//     if (response.data) {
//       return response.data;
//     }

//     // if(response.detail){
//     //   let res= {
//     //       status: "failure",
//     //       message: response.detail
//     //   }
//     //   return res
//     // }

//     return response;
//   }

//   export function handleError(error) {
//     if (error.data) {
//       // showToasterMessage("something went wrong")
//       return error.data;

//     }
//     // showToasterMessage(error)
//     // showToasterMessage("something went wrong")
//     return error
//   }

import { toast } from "react-toastify";
import Cookies from "js-cookie";

export function handleResponse(response) {
	if (response.results) {
		if (
			response.results.detail === "Signature has expired." ||
			response.results.detail === "Not enough segments"
		) {
			HandleTokenExpiration();
		}
		return response.results;
	}

	if (response.data) {
		if (
			response.data.detail === "Signature has expired." ||
			response.data.detail === "Not enough segments"
		) {
			HandleTokenExpiration();
		}
		return response.data;
	}

	// if(response.detail){
	//   let res= {
	//       status: "failure",
	//       message: response.detail
	//   }
	//   return res
	// }
	if (
		response.detail === "Signature has expired." ||
		response.detail === "Not enough segments"
	) {
		HandleTokenExpiration();
	}
	return response;
}

export function handleError(error) {
	if (error.data) {
		// showToasterMessage("something went wrong")
		return error.data;
	}
	// showToasterMessage(error)
	// showToasterMessage("something went wrong")
	return error;
}

function HandleTokenExpiration() {
	Cookies.set("expiry", "true");
	Cookies.remove("token");

	window.location.replace("/");
}
