import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import TopHeader from "./components/TopHeader/TopHeader";
import SidebarNav from "./components/SideBar/SideBar";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "./ProtectedLayout.scss";

function ProtectedLayout() {
	const navigate = useNavigate();
	const accessToken = Cookies.get("token");
	useEffect(() => {
		if (!accessToken) {
			navigate("/");
		}
	}, []);

	return (
		<div className="mainOutlet">
			<TopHeader />
			<div className="bodyOutlet">
				<SidebarNav />
				<div className="mainRoutes">
					<Outlet />
				</div>
			</div>
		</div>
	);
}

export default ProtectedLayout;
