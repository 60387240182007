import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CrmVideoRequestPage.scss";
import { Link, useLocation } from "react-router-dom";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import VideoApprovModal from "../../../components/VideoApprovModal/VideoApprovModal";
import { getCRMVideoRequest } from "../../../interface/implementation/batchCRM/getCRMVideoRequestAPIImp";
import { useNavigate } from "react-router-dom";

function CrmVideoRequestPage() {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;
	const location = useLocation();
	const token = Cookies.get("token");

	const batchId = location.state.batchId;
	const studentId = location.state.studentId;
	const [crmVideoRequestList, setCrmVideoRequestPage] = useState([]);
	const [status, setStatus] = useState(false);
	const [classId, setClassId] = useState(0);

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	useEffect(() => {
		let newBody = `?student_id=${studentId}&batch_id=${batchId}`;

		getCRMVideoRequest(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCrmVideoRequestPage(res.video_request);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = crmVideoRequestList.slice(indexOfFirstItem, indexOfLastItem);

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="crmVideoRequestPage">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Test : 8553423983 </div>
					<div className="batchListContainer">
						<div className="courses" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Week No.</th>
										<th>Week Date/Time</th>
										<th>Status</th>
										<th>Video Count</th>
										<th>Homework/Video Request Option</th>
									</tr>
								</thead>
								<tbody>
									{crmVideoRequestList &&
										crmVideoRequestList.length > 0 &&
										crmVideoRequestList.map((request, index) => (
											<tr key={index}>
												<td>{request.week_no}</td>
												<td>
													{request.class_start_date} -{" "}
													{request.class_start_time}
												</td>
												<td>{request.video_request_status}</td>
												<td className="questionNo">
													<span>{request.watched_video_count}</span>
												</td>
												{request.watched_video_count > 0 ? (
													<td>
														{request.enable_video_request === "Request" ? (
															<button
																onClick={() => {
																	setClassId(request.class_id);
																	setStatus("No");
																	setShowModal(true);
																}}
																className="editBtn"
																style={{ backgroundColor: "#FF742E" }}
															>
																<img src={ImageList.YoutubeIcon} alt="" />
																Request
															</button>
														) : (
															<td style={{ color: "green" }}>
																{request.enable_video_request}
															</td>
														)}
													</td>
												) : (
													<td>No information found</td>
												)}
											</tr>
										))}
								</tbody>
							</table>
							{showModal && (
								<VideoApprovModal
									onClick={() => setShowModal(false)}
									status={status}
									batchId={batchId}
									studentId={studentId}
									classId={classId}
								/>
							)}
						</div>
					</div>
					{/* <div className="pagination">
                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                            {"<"}
                        </button>
                        {Array.from({ length: Math.ceil(crmVideoRequestPage.length / itemsPerPage) }, (_, index) => (
                            <button key={index} onClick={() => paginate(index + 1)} className={currentPage === index + 1 ? "active" : ""}>
                                {index + 1}
                            </button>
                        ))}
                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(crmVideoRequestPage.length / itemsPerPage)}>
                            {">"}
                        </button>
                    </div> */}
				</div>
			</div>
		</div>
	);
}

export default CrmVideoRequestPage;
