import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./BankDetailsList.scss";

import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import ImageList from "../../../utils/ImageList";
import Cookies from "js-cookie";
import { getBanks } from "../../../interface/implementation/admin/getBanksAPIImp";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

function BankDetailsList() {
	const navigate = useNavigate();
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};
	const [bankDetails, setBankDetails] = useState([]);
	const token = Cookies.get("token");
	const [reloadUsers, setReloadUsers] = useState(false);
	useEffect(() => {
		getBanks(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBankDetails(res.bank_detail);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);
	return (
		<div className="bankDetails">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Bank Detail List</div>

					<div className="bankDetailsContainer">
						<div className="bankListTable">
							<table>
								<thead>
									<tr>
										<th>Title</th>
										<th>Bank Name</th>
										<th>A/C No</th>
										<th>IFSC Code</th>
										<th>Batch Name</th>
										<th>Company Address</th>
										<th>GST No</th>
										<th>PAN No</th>
										<th>Actions</th>
										{/* <th>Update</th> */}
									</tr>
								</thead>
								<tbody>
									{bankDetails &&
										bankDetails.length > 0 &&
										bankDetails.map((bank, i) => (
											<tr key={i}>
												<td>{bank.title}</td>
												<td>{bank.bank_name}</td>
												<td>{bank.acc_no}</td>
												<td>{bank.ifsc_code}</td>
												<td>{bank.batch_name}</td>
												<td>{bank.company_address}</td>
												<td>{bank.gst_no}</td>
												<td>{bank.pan_no}</td>
												<td>
													<Link
														to={`/company-details/edit/${bank.bank_id}`}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Edit
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BankDetailsList;
