import getStudentWeekHomeworkAPI from '../../apis/student/getStudentWeekHomeworkAPI';


export function getStudentWeekHomework(token,body){
    return new Promise(async(resolve, reject) => {
        getStudentWeekHomeworkAPI.getAll(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}