import getCrmOverdueDetailsAPI from '../../apis/batchCRM/getCrmOverdueDetailsAPI';


export function getCrmOverdueDetails(token){
    return new Promise(async(resolve, reject) => {
      getCrmOverdueDetailsAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}