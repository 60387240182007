import React from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./CalendarList.scss";
import ImageList from "../../../utils/ImageList";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import { adminCalendarList } from "../../../interface/implementation/admin/getCalendarListAPI";
import Cookies from "js-cookie";
import ImageModal from "../../../components/ImageModal/ImageModal";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

function CalendarList() {
	const navigate = useNavigate();
	// const calendarList = [
	//   {
	//     id: 1,
	//     title: "Advance Sales Program",
	//     batch: "Advance Sales Program",
	//     imageUrl:
	//       "https://img.freepik.com/free-vector/schedule-calendar-flat-style_78370-1550.jpg?size=338&ext=jpg&ga=GA1.1.632798143.1705708800&semt=ais",
	//   },
	//   {
	//     id: 2,
	//     title: "Advance Sales Program",
	//     batch: "Advance Sales Program",
	//     imageUrl:
	//       "https://img.freepik.com/free-vector/schedule-calendar-flat-style_78370-1550.jpg?size=338&ext=jpg&ga=GA1.1.632798143.1705708800&semt=ais",
	//   },
	//   {
	//     id: 3,
	//     title: "Advance Sales Program",
	//     batch: "Advance Sales Program",
	//     imageUrl:
	//       "https://img.freepik.com/free-vector/schedule-calendar-flat-style_78370-1550.jpg?size=338&ext=jpg&ga=GA1.1.632798143.1705708800&semt=ais",
	//   },
	// ];
	const [calendarList, setCalendarList] = useState([]);
	const [selectedImage, setSelectedImage] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const openModal = (imageUrl) => {
		setSelectedImage(imageUrl);
		setModalIsOpen(true);
	};
	const closeModal = () => {
		setSelectedImage("");
		setModalIsOpen(false);
	};

	useEffect(() => {
		adminCalendarList(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCalendarList(res);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);
	return (
		<div className="calendarList">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">BCI Calendar List</div>

					<div className="calendarListContainer">
						<div className="calendarListTable">
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>
										<th>Calendar</th>
										<th>Calendar Title</th>
										<th>Select Batch</th>
										<th>Update</th>
									</tr>
								</thead>
								<tbody>
									{calendarList &&
										calendarList.length > 0 &&
										calendarList.map((calendar, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												<td
													onClick={() =>
														openModal(calendar.calendar_file[0]?.content)
													}
												>
													<img
														src={
															calendar.calendar_file &&
															calendar.calendar_file.length > 0
																? calendar.calendar_file[0]?.content
																: ""
														}
														alt=""
													/>
												</td>
												<td>{calendar.calendar_title}</td>
												<td>{calendar.batch_name}</td>
												<td className="">
													{/* <button className="editBtn">
                            <img src={ImageList.EditIcon} alt="" />
                            Edit
                          </button> */}
													<Link
														to={{
															pathname: `/calendar/edit/${calendar.calendar_id}`,
														}}
														className="editBtn"
													>
														<img src={ImageList.EditIcon} alt="" />
														Edit
													</Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<ImageModal
				isOpen={modalIsOpen}
				closeModal={closeModal}
				imageUrl={selectedImage}
			/>
		</div>
	);
}

export default CalendarList;
