import React from "react";
import SidebarNav from "../SideBar/SideBar";
import TopHeader from "../TopHeader/TopHeader";
import "./Layout.scss";

const Layout = ({ children, headerChildren, title }) => {
  return (
    <div className="Layout">
      {/* <TopHeader /> */}
      <div className="dashboardBody">
        {/* <SidebarNav /> */}
        <div className="dashboardContent">
          <div className="titleWrapper">
            <div className="title">{title}</div>
            <div className="buttonTitleWrapper">{headerChildren}</div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
