import getMembersAPI from "../../apis/admin/getMembersAPI";

export function getMembers(token, id) {
  return new Promise(async (resolve, reject) => {
    getMembersAPI
      .getSingle(token, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
