import studentVideoRequestAPI from '../../apis/student/studentVideoRequestAPI';


export function studentVideoRequest(token,body){
    return new Promise(async(resolve, reject) => {
        studentVideoRequestAPI.post(token,body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}