import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./StudentHelpTicket.scss";
import ImageList from "../../../utils/ImageList";
import CRMTicketModal from "../../../components/CRMTicketModal/CRMTicketModal";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { getTickets } from "../../../interface/implementation/getTicketsAPIImp";
import { getCRMTickets } from "../../../interface/implementation/crmAdmin/getCRMTicketsAPIImp";
import { getStudentTickets } from "../../../interface/implementation/student/getStudentTicketsAPIImp";
import { useNavigate } from "react-router-dom";

function StudentHelpTicket(props) {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [selectedBatch, setSelectedBatch] = useState("");
	const [selectedCRM, setSelectedCRM] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [userRole, setUserRole] = useState("");
	const [ticketList, setTicketList] = useState([]);
	const [ticket, setTicket] = useState("");
	const [reloadState, setReloadState] = useState(false);
	const token = Cookies.get("token");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleReloadStateToggle = () => {
		setReloadState((prevState) => !prevState); // Toggle the state
	};

	useEffect(() => {
		getStudentTickets(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setTicketList(res.details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadState]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = () => {};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems =
		ticketList &&
		ticketList.length > 0 &&
		ticketList.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="studentHelpTicket">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">CRM Help Ticket</div>
					<div className="courseListContainer">
						{/* <div className="topSearchBar">
                            <div className="searchInputs">
                                <div className='inputContainer'>
                                    <label htmlFor="batchName">Select batch</label>
                                    <div className="inputCourseName">
                                        <select
                                            id="batchName"
                                            onChange={(e) => handleInputChange(e, setSelectedBatch)}
                                            value={selectedBatch}
                                        >
                                            <option value="">Select</option>
                                            <option value="batch1">Batch 1</option>
                                            <option value="batch2">Batch 2</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='inputContainer'>
                                    <label htmlFor="crm">Select CRM</label>
                                    <div className="inputCode">
                                        <select
                                            id="crm"
                                            onChange={(e) => handleInputChange(e, setSelectedCRM)}
                                            value={selectedCRM}
                                        >
                                            <option value="">Select</option>
                                            <option value="crm1">CRM 1</option>
                                            <option value="crm2">CRM 2</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='inputContainer'>
                                    <label htmlFor="status">Ticket status</label>
                                    <div className="inputCode">
                                        <select
                                            id="status"
                                            onChange={(e) => handleInputChange(e, setSelectedStatus)}
                                            value={selectedStatus}
                                        >
                                            <option value="">Select</option>
                                            <option value="open">Open</option>
                                            <option value="closed">Closed</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="searchBtnContainer">
                                    <button className="searchBtn" onClick={handleSearch}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div> */}
						<div className="barcodes" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Sl. No.</th>

										<th>Subject</th>
										<th>Ticket text</th>
										<th>Created by</th>
										<th>Created date</th>
										<th>Status</th>
										{/* <th>CRM</th> */}
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.length > 0 &&
										currentItems.map((ticket, index) => (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>{ticket.ticket_subject}</td>
												<td>{ticket.ticket_description}</td>
												<td>{ticket.created_by}</td>
												<td>
													{ticket.created_on &&
														new Date(ticket.created_on)
															.toISOString()
															.split("T")[0]
															.split("-")
															.reverse()
															.join("/")}
												</td>
												<td>{ticket.ticket_status}</td>
												{/* <td>{ticket.crm}</td> */}
												<td className="actionBtn">
													<button
														onClick={() => {
															setTicket(ticket);
															setShowModal(true);
														}}
														className="viewBtn"
													>
														<img src={ImageList.MessageIcon} alt="" />
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>

							{showModal && (
								<CRMTicketModal
									onClick={() => setShowModal(false)}
									ticket={ticket}
									role={"student"}
									setReloadState={handleReloadStateToggle}
								/>
							)}
						</div>
					</div>
					{currentItems && currentItems.length > 0 && (
						<div className="pagination">
							<button
								onClick={() => paginate(currentPage - 1)}
								disabled={currentPage === 1}
							>
								{"<"}
							</button>
							{Array.from(
								{ length: Math.ceil(currentItems.length / itemsPerPage) },
								(_, index) => (
									<button
										key={index}
										onClick={() => paginate(index + 1)}
										className={currentPage === index + 1 ? "active" : ""}
									>
										{index + 1}
									</button>
								)
							)}
							<button
								onClick={() => paginate(currentPage + 1)}
								disabled={
									currentPage === Math.ceil(currentItems.length / itemsPerPage)
								}
							>
								{">"}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default StudentHelpTicket;
