import getStudentTicketsAPI from '../../apis/student/getStudentTicketsAPI';

export function getStudentTickets(token){
    return new Promise(async(resolve, reject) => {
        getStudentTicketsAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}