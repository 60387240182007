import getAttendanceAPI from "../../apis/admin/getAttendanceAPI";

export const getStudentAttendance = (token) => {
  return new Promise(async (resolve, reject) => {
    getAttendanceAPI
      .getAll(token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
