import React, { useState, useEffect } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import "./BarcodeList.scss";
import ImageList from "../../../utils/ImageList";
import BarcodeSideModal from "../../../components/BarcodeSideModal/BarcodeSideModal";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { showToasterMessage } from "../../../utils/helper";
import { getQRCodes } from "../../../interface/implementation/admin/getQRCodesAPIImp";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { searchQRCode } from "../../../interface/implementation/admin/searchQRCodeAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
function BarcodeList() {
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
	const [selectedQR, setSelectedQR] = useState("");
	const token = Cookies.get("token");
	// const [batchName, setBatchName] = useState('');
	const [weekNumber, setWeekNumber] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [batches, setBatches] = useState([]);
	const [batchId, setBatchId] = useState(0);
	const [batchname, setBatchname] = useState("");
	const [reloadUsers, setReloadUsers] = useState(false);

	const [barcodeList, setBarcodeList] = useState([]);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	useEffect(() => {
		getBatches(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBatches(res.batch);
			})
			.catch((err) => showToast("Something went wrong"));
	}, []);

	useEffect(() => {
		getQRCodes(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setBarcodeList(res.details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";
		if (!batchname && batchname !== "") {
			showToast("Batch Name is missing");
			return;
		}

		if (batchname) {
			newBody += `?batch_id=${batchname}`;
		}

		if (weekNumber) {
			if (!batchname && batchname === "") {
				showToast("Batch Name is missing");
				return;
			} else {
				newBody += `${newBody ? "&" : "?"}week_no=${weekNumber}`;
			}
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};

	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		searchQRCode(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);

					//   setAttendanceList();
					setBarcodeList([]);

					return;
				}

				setCurrentPage(1);
				setBarcodeList(res.details);
				// setShowUpdateButton(true);
			})
			.catch();
		// setShowUpdateButton(true);
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = barcodeList
		? barcodeList?.slice(indexOfFirstItem, indexOfLastItem)
		: [];
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="barcodeList">
			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Barcode List</div>
					<div className="courseListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								<div className="inputContainer">
									<label htmlFor="batchname">
										Batch Name <span style={{ color: "red" }}>*</span>
									</label>
									<div className="inputCode">
										<select
											id="batchname"
											onChange={(e) => {
												setBatchId(e.target.value);
												handleInputChange(e, setBatchname);
											}}
											value={batchname}
										>
											<option value="">Select Batch Name</option>
											{batches &&
												batches.length > 0 &&
												batches.map((batch, i) => (
													<option key={batch.batch_id} value={batch.batch_id}>
														{batch.batch_name}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="weekNumber">Week number</label>
									<div className="inputCode">
										<input
											type="number"
											id="weekNumber"
											placeholder="Search by week number"
											onChange={(e) => handleInputChange(e, setWeekNumber)}
											value={weekNumber}
										/>
									</div>
								</div>
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>
							</div>
						</div>
						<div className="barcodes" style={{ overflowX: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Batch name</th>
										<th>Week title</th>
										<th>Week number</th>
										<th>Barcode view</th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map((barcode, index) => (
										<tr key={index}>
											<td>{barcode.batch_name}</td>
											<td>{barcode.class_name}</td>
											<td>{barcode.week_no}</td>
											<td className="actionBtn">
												<button
													onClick={() => {
														setSelectedQR(barcode.qr_code);
														setShowModal(true);
													}}
													className="viewBtn"
												>
													<img src={ImageList.EyeIcon} alt="" />
													View
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							{showModal && (
								<BarcodeSideModal
									onClick={() => setShowModal(false)}
									qrCode={selectedQR}
								/>
							)}
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(barcodeList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BarcodeList;
