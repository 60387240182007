import React, { useEffect, useState } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showSuccessToast, showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
// import ImageList from '../../../utils/ImageList';
import "./AssignCRM.scss";
import { Link, useLocation } from "react-router-dom";
import { getStudentCRM } from "../../../interface/implementation/admin/getStudentCRMAPIImp";
import Cookies from "js-cookie";
import { updateCRM } from "../../../interface/implementation/admin/updateCRMAPIImp";
import { getCRM } from "../../../interface/implementation/admin/getCRMAPIImp";

import { useNavigate } from "react-router-dom";
import { getStudentBatchList } from "../../../interface/implementation/admin/getStudentBatchListAPIImp";
import { filterStudentCRM } from "../../../interface/implementation/admin/filterStudentCRMAPIImp";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
function AssignCRM() {
	const navigate = useNavigate();
	const [selectedBatch, setSelectedBatch] = useState("");
	const [selectedParticipantType, setSelectedParticipantType] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [phone, setPhone] = useState("");
	const [selectedCRM, setSelectedCRM] = useState(null);
	const [numberOfCompanies, setNumberOfCompanies] = useState("");
	const [selectAll, setSelectAll] = useState(false);
	const token = Cookies.get("token");
	const [crmStudentList, setCrmStudentList] = useState([]);
	// const [currentItems, setCurrentItems] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	const [assignedCrm, setAssignedCrm] = useState("");
	const [crmValues, setCrmValues] = useState([]);

	// const [currentPage, setCurrentPage] = useState(1);
	// const itemsPerPage = 10;

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	const assignCrmList = [
		{
			id: 1,
			name: "Ruben Savio",
			phone: "2637282929",
			companyName: "atii",
			participantType: "Primary",
			companyGst: "GST",
			assignedCrm: "Unassigned",
			registeredDate: "22 Jan, 2024",
		},
		{
			id: 2,
			name: "Ruben Savio",
			phone: "2637282929",
			companyName: "atii",
			participantType: "Primary",
			companyGst: "GST",
			assignedCrm: "Unassigned",
			registeredDate: "22 Jan, 2024",
		},
		{
			id: 3,
			name: "Ruben Savio",
			phone: "2637282929",
			companyName: "atii",
			participantType: "Primary",
			companyGst: "GST",
			assignedCrm: "Unassigned",
			registeredDate: "22 Jan, 2024",
		},
		{
			id: 4,
			name: "Ruben Savio",
			phone: "2637282929",
			companyName: "atii",
			participantType: "Primary",
			companyGst: "GST",
			assignedCrm: "Unassigned",
			registeredDate: "22 Jan, 2024",
		},
		{
			id: 5,
			name: "Ruben Savio",
			phone: "2637282929",
			companyName: "atii",
			participantType: "Primary",
			companyGst: "GST",
			assignedCrm: "Unassigned",
			registeredDate: "22 Jan, 2024",
		},
	];

	useEffect(() => {
		const fetchCRMDropdown = async () => {
			await getCRM(token)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					setCrmValues(res.users);
				})
				.catch();
		};
		fetchCRMDropdown();
	}, []);

	const state = useLocation();
	const batchId = state.state.batchId;

	useEffect(() => {
		// const searchParameter = `?batch_id=${batchId}`;
		getStudentBatchList(token, batchId).then((res) => {
			if (res.detail === "Signature has expired.") {
				navigate("/");
				showToast("Token has expired. Please log in again.");
				return;
			}
			if (res.status === "failure") {
				showToast(res.message);
				return;
			}
			setCrmStudentList(res.student_details);
			// const indexOfLastItem = currentPage * itemsPerPage;
			// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
			// res.student_details && res.student_details.length > 0 &&
			//   setCurrentItems(
			//     res.student_details?.slice(indexOfFirstItem, indexOfLastItem)
			//   );
		});
	}, [reloadUsers]);

	const handleUpdateCRM = async () => {
		let body = {
			batch_id: batchId,
			crm_id: assignedCrm,
			student_id: selectedRows,
		};

		if (!batchId || batchId === "") {
			showToast("Batch Id is missing");
			return;
		}
		if (!assignedCrm || assignedCrm === "") {
			showToast("Assigned Crm is empty");
			return;
		}
		if (!selectedRows || selectedRows.length === 0) {
			showToast("Select at least one Participant.");
			return;
		}
		// const body = `${batchId}/?crm_id=${assignedCrm}&student_ids=${selectedRows}`;
		updateCRM(token, body)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("CRM Updated.");
				setSelectedRows([]);
				setReloadUsers((prevReloadUsers) => !prevReloadUsers);
			})
			.catch((err) => showToast("Something went wrong"));
		setSelectedRows([]);
	};

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = `?batch_id=${batchId}`;

		if (selectedParticipantType) {
			newBody += `${
				newBody ? "&" : "?"
			}participant_type=${selectedParticipantType}`;
		}

		if (companyName) {
			newBody += `${newBody ? "&" : "?"}company_name=${companyName}`;
		}

		if (phone) {
			newBody += `${newBody ? "&" : "?"}phone_no=${phone}`;
		}

		if (selectedCRM) {
			newBody += `${newBody ? "&" : "?"}crm=${selectedCRM}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			search(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	const search = (newBody) => {
		filterStudentCRM(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setCrmStudentList(res);
				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch((err) => showToast("Something went wrong"));
	};

	const handleCheckboxChange = (id) => {
		setSelectedRows((prevSelectedRows) => {
			if (prevSelectedRows.includes(id)) {
				return prevSelectedRows.filter((rowId) => rowId !== id);
			} else {
				return [...prevSelectedRows, id];
			}
		});
	};

	const handleSelectAll = () => {
		setSelectAll(!selectAll);
		setSelectedRows(selectAll ? [] : assignCrmList.map((data) => data.id));
	};

	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = assignCrmList.slice(indexOfFirstItem, indexOfLastItem);
	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		crmStudentList && crmStudentList.length > 0
			? crmStudentList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	// useEffect(() => {
	// setCurrentItems(crmStudentList && crmStudentList.length > 0 ? crmStudentList.slice(indexOfFirstItem, indexOfLastItem) : []);

	// }, [crmStudentList])
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleReset = () => {
		setSelectedParticipantType("");
		setCompanyName("");
		setPhone("");
		setSelectedCRM("");
	};

	return (
		<div className="assignCRM">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="title">Assign/Re-Assign CRM</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs">
								{/* <div className="inputContainer">
                  <label htmlFor="selectedBatch">Select Batch</label>
                  <div className="inputCourseName">
                    <input
                      type="text"
                      id="selectedBatch"
                      placeholder="Search"
                      onChange={(e) => handleInputChange(e, setSelectedBatch)}
                      value={selectedBatch}
                    />
                  </div>
                </div> */}
								<div className="inputContainer">
									<label htmlFor="selectedParticipantType">
										Select Participant Type
									</label>
									<div className="inputCode">
										<select
											id="selectedParticipantType"
											onChange={(e) =>
												handleInputChange(e, setSelectedParticipantType)
											}
											value={selectedParticipantType}
										>
											<option value="">Select Participant Type</option>
											<option value="primary">Primary</option>
											<option value="secondary">Secondary</option>
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="companyName">Company Name</label>
									<div className="inputCourseName">
										<input
											type="text"
											id="companyName"
											placeholder="Enter Company Name"
											onChange={(e) => handleInputChange(e, setCompanyName)}
											value={companyName}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="phone">Phone</label>
									<div className="inputCourseName">
										<input
											type="number"
											id="phone"
											placeholder="Enter Phone Number"
											onChange={(e) => handleInputChange(e, setPhone)}
											value={phone}
										/>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="selectedCRM">Select CRM</label>
									<div className="inputCode">
										<select
											id="selectedCRM"
											onChange={(e) => handleInputChange(e, setSelectedCRM)}
											value={selectedCRM}
										>
											<option value="">Select CRM</option>
											{crmValues &&
												crmValues.length > 0 &&
												crmValues.map((option, i) => (
													<option key={i} value={option.user_id}>
														{option.full_name}
													</option>
												))}
										</select>
									</div>
								</div>

								{/* <div className="inputContainer">
                  <label htmlFor="numberOfCompanies">No of Company</label>
                  <div className="inputCourseName">
                    <input
                      type="text"
                      id="numberOfCompanies"
                      placeholder="Enter Number of Companies"
                      onChange={(e) =>
                        handleInputChange(e, setNumberOfCompanies)
                      }
                      value={numberOfCompanies}
                    />
                  </div>
                </div> */}

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Filter
									</button>
									<button
										style={{ marginLeft: "20px" }}
										className="searchBtn "
										onClick={handleReset}
									>
										Reset
									</button>
								</div>
							</div>
						</div>

						<div className="crmListContainer">
							<div className="topInput">
								<div className="inputContainer">
									<label htmlFor="selectedParticipant">
										Assign/Re-Assign CRM
									</label>
									<div className="inputCode">
										<select
											id="selectedCRMstudents"
											onChange={(e) => handleInputChange(e, setAssignedCrm)}
											value={assignedCrm}
										>
											<option value="">Select CRM</option>
											{crmValues &&
												crmValues.length > 0 &&
												crmValues.map((option, i) => (
													<option key={i} value={option.user_id}>
														{option.full_name}
													</option>
												))}
										</select>
									</div>
								</div>
								{/* <div className="inputContainer">
                  <label htmlFor="numberOfCompanies">No of Company</label>
                  <div className="inputCourseName">
                    <input
                      type="text"
                      id="numberOfCompanies"
                      placeholder="Enter Number of Companies"
                      onChange={(e) =>
                        handleInputChange(e, setNumberOfCompanies)
                      }
                      value={numberOfCompanies}
                    />
                  </div>
                </div> */}
								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleUpdateCRM}>
										Assign
									</button>
								</div>
							</div>
							<div className="courses" style={{ overflowX: "auto" }}>
								<table className="batchTable">
									<thead>
										<tr>
											<th>
												{/* <input
                          type="checkbox"
                          name=""
                          id="selectAllCheckbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        /> */}
											</th>
											<th>Name</th>
											<th>Phone</th>
											<th>Company Name</th>

											<th>Participant Type</th>
											<th>Company GST</th>
											<th>Assigned CRM</th>
											<th>Registered Date</th>
										</tr>
									</thead>
									<tbody>
										{currentItems &&
											currentItems.length > 0 &&
											currentItems.map((batchData, index) => (
												<tr
													key={index}
													className={
														selectedRows.includes(batchData.student_id)
															? "selected"
															: ""
													}
												>
													<td>
														<input
															type="checkbox"
															name=""
															id={`checkbox-${batchData.student_id}`}
															checked={selectedRows.includes(
																batchData.student_id
															)}
															onChange={() =>
																handleCheckboxChange(batchData.student_id)
															}
														/>
													</td>
													<td>{batchData.student_name}</td>
													<td>{batchData.phone_no}</td>
													<td>{batchData.company_name}</td>
													<td>{batchData.participant_type}</td>
													<td>{batchData.gst_no}</td>
													<td>{batchData.crm_name}</td>
													<td>
														{" "}
														{batchData.register_date &&
															batchData.register_date !== null &&
															batchData.register_date
																.split("-")
																.reverse()
																.join("/")}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="paginationWrapper">
						<Pagination
							key={currentPage}
							totalPages={Math.ceil(crmStudentList?.length / itemsPerPage)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AssignCRM;
