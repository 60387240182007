import enableVideoRequestAPI from '../../apis/batchCRM/enableVideoRequestAPI';


export function enableVideoRequest(token, body){
    return new Promise(async(resolve, reject) => {
        enableVideoRequestAPI.post(token, body).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}