import React, { useState } from "react";
import "./VideoRequestStudentModal.scss";
import Cookies from "js-cookie";
import { showToasterMessage } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { studentVideoRequest } from "../../interface/implementation/student/studentVideoRequestAPIImp";

function VideoRequestStudentModal({
	closeScreen,
	initialStatus,
	studentId,
	batchId,
	classId,
}) {
	const navigate = useNavigate();
	const token = Cookies.get("token");

	const [status, setStatus] = useState(initialStatus);
	const [comments, setComments] = useState("");

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const handleCloseClick = () => {
		closeScreen();
	};
	const handleSaveClick = () => {
		let formData = {
			class_id: classId,
			batch_id: batchId,
			comments: comments,
		};
		studentVideoRequest(token, formData)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				// navigate("/course-list")
				showToast("Request submitted");
				closeScreen();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="video-modal-overlay VideoRequestStudentModal">
			<div className="video-modal-content">
				<div className="video-modal-header">
					<p className="video-title">Video request</p>
					<p className="closeBtn" onClick={handleCloseClick}>
						x
					</p>
				</div>
				<div className="body">
					<div className="inputContainer">
						<label htmlFor="selectedParticipantType">
							{" "}
							Enable video request
						</label>
						<div className="inputCode">
							<input
								type="text"
								id="comments"
								placeholder="Enter your reason for access"
								onChange={(e) => setComments(e.target.value)}
								value={comments}
							/>
						</div>
					</div>
				</div>
				<div className="saveButtonWrapper">
					<button className="approveBtn" onClick={handleSaveClick}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default VideoRequestStudentModal;
