import React, { useState } from 'react';
import './UnreadMsgModal.scss';
import Cookies from "js-cookie";
import {  useNavigate } from 'react-router-dom';


function UnreadMsgModal({ onClick, count, handleNavigate }) {
  const [isChecked, setIsChecked] = useState(false);
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const handleCloseClick = () => {
    navigate('/student/crm-message')
    // onClick();
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="unread-modal-overlay UnreadModal">
      <div className="unread-modal-content">
        <div className="tandc-header">
          <p className='tandc-title'>Notification</p>
        </div>
        <div className="body">
        <p style={{fontSize: 20}}>Hey! You have {count} new messages from CRM</p>
         </div>
        <div className="saveButtonWrapper">
         

          <button
            className='approveBtn'
            onClick={handleCloseClick}
            // disabled={!isChecked} // Disable the button if the checkbox is not checked
          >
           Check it out
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnreadMsgModal;
