/** @format */

import React from "react";
import Button from "../../../components/Button/Button";
import DashboardBanner from "../../../components/DashboardBanner/DashboardBanner";
import DashboardCard from "../../../components/DashboardCard/DashboardCard";
import Layout from "../../../components/Layout/Layout";
import ImageList from "../../../utils/ImageList";
import "./StudentDashboard.scss";
import { Calendar } from "rsuite";
import "rsuite/Calendar/styles/index.css";
import CourseCard from "../../../components/CourseCard/CourseCard";
import Input from "../../../components/Input/Input";
import MCQCard from "../../../components/HWRadioButton/RadioButton";
import StudentTable from "../../../components/StudentTable/StudentTable";

const BANNERS = [
  {
    bannerTitle: "Course Detail",
    bannerIcon: ImageList.BookIcon,
    bannerDescription: "Classes, Homework, Materials",
    onClick: () => {},
  },
  {
    bannerTitle: "Payment History",
    bannerIcon: ImageList.CreditCardIcon,
    bannerDescription: "View Payment",
    onClick: () => {},
  },
  {
    bannerTitle: "Edit",
    bannerIcon: ImageList.BlackEditIcon,
    bannerDescription: "Edit Profile",
    onClick: () => {},
  },
];

const homeworkListData = [
  {
    quest: "Week 0 - Have you hired an Executive Assistant?",
  },
  {
    quest: "Week 0 - Have you started reaching office by 8 AM?",
  },
  {
    quest: "Week 0 - Have you started the PARCHI SYSTEM?",
  },
  {
    quest:
      "Week 0 - Have you listed all your To-Do, Weekly To-Do & Monthly To-Do Items on the Formats in the BMP Worksheet attached?",
  },
  {
    quest:
      "Week 0 - Have you fixed a 4 Hour slot every week to complete the assignment? (Always every Tuesday -First Half)",
  },
];

const Headers = ["Sl. No.", "Title", "Technical Attachment", "Technical View URL"];

function Dashboard() {
  const CourseData = [
    {
      title: "Double Your Profits - Mumbai",
      date_time: "18 November, 2023",
      venue: "Mumbai",
    },
    {
      title: "Double Your Profits - Mumbai",
      date_time: "18 November, 2023",
      venue: "Mumbai",
    },
  ];

  const tableData = [
    {
      title: "CLOUD BASIC NOTES",
      technical_attachment: "Delegation sheet",
      technical_view_url: [
        "Delegation sheet",
        "Delegation setup and woking video",
      ],
    },
  ];
  return (
    <Layout
      title={"Welcome to Dashboard"}
      headerChildren={
        <>
          <Button
            buttonName={"Logout"}
            buttonIcon={ImageList.LogoutIcon}
            color={"#fff"}
            background={"#D80032"}
            onClick={() => {}}
            style={{ padding: "0.5rem 1rem" }}
          />
        </>
      }
    >
      <div className="dashboardListContainer">
        {BANNERS.map((banner, index) => (
          <DashboardBanner
            bannerDescription={banner.bannerDescription}
            bannerIcon={banner.bannerIcon}
            bannerTitle={banner.bannerTitle}
            onClick={() => {}}
            key={index}
          />
        ))}
      </div>
      {/* <div className="dshboardContent">
        <DashboardCard
          icon={ImageList.ProfilePic}
          title={"987654321"}
          subTitle={"Nisha rawat"}
          headerChildren={"Hello"}
        >
          <>
            <div className="headerChildrenOne">
              <span>
                <b>Email:</b>
              </span>
              <span>nisha.na1305@gmail.com</span>
            </div>
            <div className="headerChildrenOne">
              <span>
                <b>Phone:</b>
              </span>
              <span>987654321</span>
            </div>
            <div className="headerChildrenOne noBorder">
              <span>
                <b>Company:</b>
              </span>
              <span>BCI</span>
            </div>
          </>
        </DashboardCard>
        <DashboardCard icon={ImageList.TableClock} title={"Upcoming Classes"}>
          <div className="headerChildrenTwo">
            <img src={ImageList.WarningBoard} />
            <span className="noDataText">No data found!</span>
          </div>
        </DashboardCard>
      </div> */}
      {/* <div className="dshboardContent">
        <DashboardCard
          icon={ImageList.CalenderIcon}
          title={"BCI Calendar"}
          children={<Calendar />}
        />

        <DashboardCard
          icon={ImageList.TableClock}
          title={"Upcoming BCI Courses"}
          children={
            <div className="courseCardWrapper">
              {CourseData?.map((data) => (
                <CourseCard data={data} onClick={() => {}} />
              ))}
            </div>
          }
        />
      </div> */}
      <div className="dshboardContent">
        <DashboardCard
          icon={ImageList.BookIcon}
          title={"Homework Action Point"}
          subTitle={
            <div className="dashboardHomeWorkSubtitles">
              <span>Completed Scoring: 30%</span>
              <span>InComplete Scoring: 70%</span>
            </div>
          }
          headerChildren={
            <Button
              buttonName={"View Page"}
              color={"#4367B0"}
              style={{
                border: "1px solid #4367B0",
              }}
            />
          }
          children={
            <div className="dashboardHomeWorkContainer">
              <div className="dashboardHomeWorkInput">
                <Input
                  inputType={"select"}
                  label={"Select Batch"}
                  onChange={() => {}}
                  value={""}
                />
              </div>
              <div className="dashboardHomeWorkQuestionContainer">
                <div className="dashboardHomeWorkTitle">Homework</div>
                {homeworkListData?.map((question) => (
                  <MCQCard label={question.quest} />
                ))}
              </div>
            </div>
          }
        />

        {/* <DashboardCard
          icon={ImageList.BookIcon}
          title={"Technical Notes"}
          children={
            <div className="dashboardHomeWorkContainer">
              <StudentTable
                headers={Headers}
                children={
                  <>
                    {tableData?.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.title}</td>
                        <td>
                          {data.technical_attachment !== "" ? (
                            <div className="dashboardNotesLink">🔗Link</div>
                          ) : null}
                        </td>
                        <td>
                          <div className="homeWorkUrlWrapper">
                            {data.technical_view_url.map((url) => (
                              <div className="dashboardNotesLink">{url}</div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            </div>
          }
        /> */}
      </div>
    </Layout>
  );
}

export default Dashboard;
