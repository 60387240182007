import userDetailsAPI from '../apis/userDetailsAPI';

export function userDetails(token, id){
    return new Promise(async(resolve, reject) => {
        userDetailsAPI.getSingle(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}