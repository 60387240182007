import {ApiCore} from "../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: false,
    post: false,
    put: true,
    delete: false,
    url: 'api/users/update-user',
    version: ''
});

export default apiTasks;