import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import TopHeader from "../../components/TopHeader/TopHeader";
import SidebarNav from "../../components/SideBar/SideBar";
import SidebarMenuItems from "../../utils/SidebarMenuItems";
import ImageList from "../../utils/ImageList";
import "./Profile.scss";
import { ToastContainer } from "react-toastify";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { userDetails } from "../../interface/implementation/userDetailsAPIImp";
import { getRoles } from "../../interface/implementation/admin/getRolesAPIImp";
import Cookies from "js-cookie";
import { editProfile } from "../../interface/implementation/editProfileAPIImp";

function Profile() {
	const { type, userId } = useParams();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const token = Cookies.get("token");
	const [user, setUser] = useState("");
	const initialState = {
		email_id: "",
		full_name: "",
		username: "",
		phone_number: "",
		password: "",
		company_name: "",
		confirmPassword: "",
		company_gst: "",
		profile_picture: [],
		is_active: true,
		roles: [],
	};

	const [formData, setFormData] = useState(initialState);
	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const successToast = (successText) => {
		showSuccessToast(successText);
	};

	useEffect(() => {
		const userName = localStorage.getItem("userName");

		userDetails(token, userName).then((res) => {
			// Assuming res.user is your object

			// Extract role_ids from the roles array
			const roleIds = res.user.roles.map((role) => role.role_id);

			// Modify the res.user object to keep the roles property with array of role_ids
			const modifiedUser = {
				...res.user,
				roles: roleIds,
			};

			// Pass modifiedUser to setFormData
			setFormData(modifiedUser);
		});
	}, []);

	const [userRole, setUserRole] = useState([]);
	const [profile_picture, setProfile] = useState(ImageList.ProfilePic);

	// useEffect(() => {
	//     getRoles(token).then((res) => {
	//         if (res.detail === "Signature has expired.") {
	//             navigate("/");
	//             showToast('Token has expired. Please log in again.');
	//             return;
	//         }
	//         if (res.status === "failure") {
	//             showToast(res.message);
	//             return;
	//         }
	//
	//         const rolesFromApi = res.roles.Roles;

	//         // Create a new array with isChecked property added for each role
	//         const rolesWithChecked = rolesFromApi.map((role) => ({
	//             ...role,
	//             isChecked: false, // Set the initial value for isChecked as needed
	//         }));
	//         setUserRole(rolesWithChecked)
	//     }).catch((err) => showToast("Something went wrong"))
	// }, [])

	const fileInputRef = useRef(null);

	const handleInputChange = (e) => {
		const { id, value, type, checked } = e.target;

		if (type === "checkbox") {
			setFormData((prevData) => {
				const isRoleSelected = prevData.roles.includes(id);
				const updatedRoles = isRoleSelected
					? prevData.roles.filter((roleId) => roleId !== id)
					: [...prevData.roles, id];

				return {
					...prevData,
					roles: updatedRoles,
				};
			});

			setUserRole((prevRoles) =>
				prevRoles.map((role) =>
					role.role_id === id ? { ...role, isChecked: !role.isChecked } : role
				)
			);

			// Update the checked property of the input element
			e.target.checked = !e.target.checked;
		} else if (type === "radio") {
			setFormData((prevData) => ({
				...prevData,
				is_active: value === "true" ? true : false,
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[id]: value,
			}));
		}
	};

	const handleTogglePassword = (field) => {
		if (field === "password") {
			setShowPassword((value) => !value);
		} else if (field === "confirmPassword") {
			setShowConfirmPassword((value) => !value);
		}
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current.click();
	};
	const handleRemoveImage = () => {
		setProfile("");
		setFormData((prevData) => ({
			...prevData,
			profile_picture: {},
		}));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			// Display image preview
			const imageUrl = URL.createObjectURL(file);
			setProfile(imageUrl);
			// imageRef.current.src = imageUrl;
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result contains the base64 representation of the image
				const base64String = reader.result;
				setFormData((prevData) => ({
					...prevData,
					profile_picture: [
						{
							filename: file.name,
							content: base64String,
						},
					],
				}));
			};

			// Read the file as a data URL (base64)
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = () => {
		if (
			!formData.email_id ||
			!formData.full_name ||
			!formData.username ||
			!formData.phone_number
		) {
			showToast("One or more fields are empty/wrong");
			return;
		}

		if (formData.password !== formData.confirmPassword) {
			showToast("Passwords mismatch. Please check and try again.");
			return;
		}

		// Handle form submission logic
		let body = formData;

		editProfile(token, body)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				successToast("Profile updated successfully.");
				navigate("/dashboard");
				window.location.reload();
			})
			.catch((err) => showToast("Something went wrong"));
	};

	return (
		<div className="profile">
			<ToastContainer limit={1} />

			<div className="dashboardBody">
				<div className="dashboardContent">
					<div className="blueHeader">
						<div className="title">
							{type === "edit" ? "Edit User" : "Add New User"}
						</div>
					</div>
					<div className="contentBody">
						<div className="profileUser">
							<img
								style={{ backgroundColor: "#eee" }}
								ty
								src={
									formData.profile_picture &&
									formData.profile_picture.length > 0
										? formData.profile_picture[0].content
										: ImageList.AvatarIcon
								}
								alt=""
								className="userImgUpdated"
							/>
							<input
								type="file"
								name=""
								id="fileInput"
								ref={fileInputRef}
								onChange={handleFileChange}
								style={{ display: "none" }}
								accept=".jpg, .jpeg, .png"
							/>
							{formData.profile_picture ? (
								<div style={{ display: "flex", gap: "20px" }}>
									<button
										className="removeBtn"
										onClick={handleUploadButtonClick}
									>
										<img
											src={ImageList.EditIconRed}
											alt=""
											className="uploadIcon"
										/>
										Edit
									</button>
									{/* <button className="removeBtn" onClick={handleRemoveImage}>
                                    <img src={ImageList.DeleteIcon} alt="" className='uploadIcon' />Remove
                                </button> */}
								</div>
							) : (
								<button className="uploadBtn" onClick={handleUploadButtonClick}>
									<img
										src={ImageList.UploadIcon}
										alt=""
										className="uploadIcon"
									/>
									Upload Image
								</button>
							)}

							<hr />
							<div className="details">
								<p className="name">{formData.username}</p>
								<p className="email">{formData.email_id}</p>
								<p className="status">
									<span></span> {formData.status}
								</p>
							</div>
						</div>

						<div className="userForm">
							<div className="credentialForm">
								<p className="title">
									{/* {type === "edit" ? `${formData.username ? formData.username : "Credential details"}` : "Credential details"} */}
									Credential details
								</p>
								<div className="formFields">
									<div className="formInput">
										<label htmlFor="emailAddress">
											Email Address<span>*</span>
										</label>
										<div className="inputUserName">
											<input
												type="text"
												id="email_id"
												placeholder="Enter email address"
												onChange={handleInputChange}
												value={formData.email_id}
												disabled
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="username">
											Username <span>*</span>
										</label>
										<div className="inputUserName">
											<input
												type="text"
												id="username"
												placeholder="Enter username"
												onChange={handleInputChange}
												value={formData.username}
												disabled
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="password">
											Password <span>*</span>
										</label>
										<div className="inputPass">
											<input
												type={showPassword ? "text" : "password"}
												id="password"
												placeholder="Enter password"
												onChange={handleInputChange}
												value={formData.password}
											/>
											<img
												onClick={() => handleTogglePassword("password")}
												src={
													showPassword ? ImageList.EyeOpen : ImageList.EyeClose
												}
												alt="eye-icon"
												className="eyeIcon"
											/>
										</div>
									</div>

									<div className="formInput">
										<label htmlFor="confirmPassword">
											Confirm Password <span>*</span>
										</label>
										<div className="inputPass">
											<input
												type={showConfirmPassword ? "text" : "password"}
												id="confirmPassword"
												placeholder="Confirm password"
												onChange={handleInputChange}
												value={formData.confirmPassword}
											/>
											<img
												onClick={() => handleTogglePassword("confirmPassword")}
												src={
													showConfirmPassword
														? ImageList.EyeOpen
														: ImageList.EyeClose
												}
												alt="eye-icon"
												className="eyeIcon"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="personalFrom">
								<p className="title">Personal details</p>
								<div className="formFields">
									<div className="formInput">
										<label htmlFor="full_name">
											Name <span>*</span>
										</label>
										<div className="inputUserName">
											<input
												type="text"
												id="full_name"
												placeholder="Enter name"
												onChange={handleInputChange}
												value={formData.full_name}
												// disabled
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="phoneNumber">
											Phone Number <span>*</span>
										</label>
										<input
											type="tel"
											id="phone_number"
											placeholder="Enter phone number"
											onChange={handleInputChange}
											value={formData.phone_number}
											minLength="10"
											maxLength="15"
											pattern="[0-9]{10,15}"
											title="This is an disabled field. You can't chnage this."
											// disabled
										/>
									</div>

									<div className="formInput">
										<label htmlFor="companyName">Company Name </label>
										<div className="inputUserName">
											<input
												type="text"
												id="company_name"
												placeholder="Enter company name"
												onChange={handleInputChange}
												value={formData.company_name}
												// disabled
											/>
										</div>
									</div>
									<div className="formInput">
										<label htmlFor="companyGST">Company GST </label>
										<input
											type="text"
											id="company_gst"
											placeholder="Enter company GST"
											onChange={handleInputChange}
											value={formData.company_gst}
											// disabled
										/>
									</div>
								</div>
							</div>
							{/* <div className="status">
                                <p>Status</p>
                                <div className='radioBtns'>
                                    <label>
                                        <input
                                            type="radio"
                                            id="is_active_true"
                                            value={true}
                                            checked={formData.is_active}
                                            onChange={handleInputChange}
                                        />
                                        Active
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            id="is_active_false"
                                            value={false}
                                            checked={!formData.is_active}
                                            onChange={handleInputChange}
                                        />
                                        Blocked
                                    </label>

                                </div>
                            </div>
                            <div className="roles">
                                <p>Roles</p>
                                <div className='checkboxBtn'>
                                    {userRole.map((role) => (
                                        <label key={role.role_id}>
                                            <input
                                                type="checkbox"
                                                id={role.role_id}
                                                data-roleid={role.role_id}
                                                checked={formData.roles.includes(String(role.role_id))}
                                                onChange={handleInputChange}
                                            />
                                            {role.role_name}
                                        </label>
                                    ))}


                                </div>
                            </div> */}
							<button className="nextBtn" onClick={handleSubmit}>
								Edit profile
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Profile;
