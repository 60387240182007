import {ApiCore} from "../../apiCore";

const apiTasks = new ApiCore({
    getAll: false,
    getSingle: true,
    postEncoded: false,
    post: false,
    put: false,
    delete: false,
    url: 'api/bank/get-bank-list',
    version: '',
});

export default apiTasks;