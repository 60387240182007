import React, { useEffect, useState, useCallback } from "react";
import TopHeader from "../../../components/TopHeader/TopHeader";
import SidebarNav from "../../../components/SideBar/SideBar";
import SidebarMenuItems from "../../../utils/SidebarMenuItems";
import { showToasterMessage } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import "./AdminPaymentInfo.scss";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { getBatches } from "../../../interface/implementation/admin/getBatchesAPIImp";
import { getAdminPaymentInfo } from "../../../interface/implementation/admin/getAdminPaymentInfoAPIImp";
import { searchAdminPaymentInfo } from "../../../interface/implementation/admin/searchAdminPaymentInfoAPIImp";
import { downloadPaymentInfo } from "../../../interface/implementation/admin/downloadPaymentInfoAPIImp";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ImageList from "../../../utils/ImageList";
import Pagination from "../../CRMadmin/CrmBatchList/Pagination";
import { checkRolePermission } from "../../../interface/implementation/checkRolePermissionAPIImp";
import { logout } from "../../../interface/implementation/logoutAPIImp";

function AdminPaymentInfo() {
	const selectedRole = Number(localStorage.getItem("selectedRole"));
	const handle = useFullScreenHandle();
	const [isFullScreenActive, setIsFullScreenActive] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [search, setSearch] = useState("");
	const [participant, setParticipant] = useState("");
	const [crm, setCrm] = useState("");
	const [participantOldStatus, setParticipantOldStatus] = useState("");
	const [status, setStatus] = useState("");
	const [activeStatus, setActiveStatus] = useState("");
	const [filterBatchId, setFilterBatchId] = useState();
	const [technicalNotes, setTechnicalNotes] = useState("");
	const token = Cookies.get("token");
	const [batchStudentList, setBatchStudentList] = useState([]);
	const [batchList, setBatchList] = useState([]);
	const [paymentList, setPaymentList] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	// const [currentItems, setCurrentItems] = useState([]);
	const [autoValues, setAutoValues] = useState([]);
	const [autoModal, setAutoModal] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState({
		crm: [],
	});
	const [paymentStatus, setPaymentStatus] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [replaceData, setReplaceData] = useState({});

	// const [currentPage, setCurrentPage] = useState(1);
	// const itemsPerPage = 10;

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const signOut = () => {
		localStorage.removeItem("selectedRole");
		logout(token)
			.then((res) => {
				Cookies.remove("token");
				navigate("/");
			})
			.catch();
	};

	useEffect(() => {
		checkRolePermission(token, selectedRole)
			.then((res) => {
				if (res.detail) return;
				if (!res.role_permission) {
					showToasterMessage("Unauthorized access. Logged out...");
					signOut(); // Log out if role_permission is false
				}
			})
			.catch((err) => {
				console.error("Error checking role permission:", err);
			});
	}, [selectedRole]);

	useEffect(() => {
		getAdminPaymentInfo(token)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setPaymentList(res.payments_details);
			})
			.catch((err) => showToast("Something went wrong"));
	}, [reloadUsers]);

	useEffect(() => {
		const fetchDropdowns = async () => {
			await getBatches(token).then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}

				setBatchList(res.batch);
			});
		};
		fetchDropdowns();
	}, []);

	const handleInputChange = (e, setter) => {
		setter(e.target.value);
	};

	const handleSearch = async () => {
		let newBody = "";

		if (search) {
			newBody += `${newBody ? "&" : "?"}search_data=${search}`;
		}

		if (participant) {
			newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
		}

		if (filterBatchId) {
			newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
		}

		if (paymentStatus) {
			newBody += `${newBody ? "&" : "?"}payment)status=${paymentStatus}`;
		}

		// Call another asynchronous function here if needed
		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			searchFilter(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const handleDownload = async () => {
		let newBody = "";

		if (filterBatchId) {
			if (search) {
				newBody += `${newBody ? "&" : "?"}search_data=${search}`;
			}

			if (participant) {
				newBody += `${newBody ? "&" : "?"}participant_type=${participant}`;
			}

			if (filterBatchId) {
				newBody += `${newBody ? "&" : "?"}batch_id=${filterBatchId}`;
			}
		} else {
			showToasterMessage("Please select a batch");
			return;
		}

		await anotherAsyncFunction(newBody);

		if (newBody) {
			// Now call your search function
			downloadHandle(newBody);
		} else {
			setReloadUsers((prevReloadUsers) => !prevReloadUsers);
		}
	};
	const anotherAsyncFunction = async (newBody) => {
		// Do something asynchronously with newBody or perform other asynchronous actions
	};

	// const handleAutoSearch = async (e) => {
	//   if (e.target.value) {
	//     let newBody = `${batchId}/${e.target.value}`;

	//     autoSearchBatches(token, newBody)
	//       .then((res) => {
	//         if (res.detail === "Signature has expired.") {
	//           navigate("/");
	//           showToast("Token has expired. Please log in again.");
	//           return;
	//         }
	//         if (res.status === "failure") {
	//           showToast(res.message);
	//           return;
	//         }
	//         setAutoValues(res)
	//         setAutoModal(true)

	//       })
	//       .catch();

	//   }
	// };

	const searchFilter = (newBody) => {
		searchAdminPaymentInfo(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				setCurrentPage(1);
				setPaymentList(res.payments_details);

				// const indexOfLastItem = currentPage * itemsPerPage;
				// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
				// setCurrentItems(res.batch.slice(indexOfFirstItem, indexOfLastItem));
			})
			.catch();
	};
	const downloadHandle = (newBody) => {
		downloadPaymentInfo(token, newBody)
			.then((res) => {
				if (res.detail === "Signature has expired.") {
					navigate("/");
					showToast("Token has expired. Please log in again.");
					return;
				}
				if (res.status === "failure") {
					showToast(res.message);
					return;
				}
				const currentDate = new Date().toISOString().slice(0, 10);
				const filename = `payment-info-${currentDate}.csv`;
				const blob = new Blob([res], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => showToast("Something went wrong"));
	};

	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;

	// const currentItems = paymentList && paymentList.length > 0 ? paymentList.slice(
	//   indexOfFirstItem,
	//   indexOfLastItem
	// ) : [];

	// const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// -------------------Pagination-------------------------------------------
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const startSerialNumber = (currentPage - 1) * itemsPerPage + 1;
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const currentItems =
		paymentList && paymentList.length > 0
			? paymentList.slice(indexOfFirstItem, indexOfLastItem)
			: [];
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const onFullScreenChange = useCallback((state) => {
		if (!state) {
			setIsFullScreenActive(false);
		}
	}, []);

	return (
		<div className="adminPaymentInfo">
			<ToastContainer limit={1} />
			{/* <TopHeader /> */}
			<div className="dashboardBody">
				{/* <SidebarNav menuItems={SidebarMenuItems} /> */}
				<div className="dashboardContent">
					<div className="title">Payment Info</div>
					<div className="batchStudentListContainer">
						<div className="topSearchBar">
							<div className="searchInputs batchStudentSearch">
								<div className="inputContainer">
									<label htmlFor="batch">Select batch</label>
									<div className="inputCode">
										<select
											id="batchId"
											onChange={(e) => handleInputChange(e, setFilterBatchId)}
											value={filterBatchId}
										>
											<option value="">Select Batch</option>
											{batchList &&
												batchList.length > 0 &&
												batchList.map((batch, index) => {
													return (
														<option key={index} value={batch.batch_id}>
															{batch.batch_name}
														</option>
													);
												})}
										</select>
									</div>
								</div>
								<div className="inputContainer">
									<label htmlFor="search">Company name / Phone number</label>
									<div className="inputCourseName searchField">
										<input
											type="text"
											id="search"
											placeholder=""
											onChange={(e) => {
												// handleAutoSearch(e)
												handleInputChange(e, setSearch);
											}}
											value={search}
										/>
									</div>
								</div>
								{/* <div className="inputContainer">
                  <label htmlFor="participant">Participant</label>
                  <div className="inputCode">
                    <select
                      id="participant"
                      onChange={(e) => handleInputChange(e, setParticipant)}
                      value={participant}
                    >
                      <option value="">Select Participant</option>
                      <option value="primary">
                        Primary
                      </option>
                      <option value="secondary">
                        Secondary
                      </option>
                    </select>
                  </div>
                </div> */}

								{/* refund status filter */}
								{/* <div className="inputContainer">
                  <label htmlFor="status">
                    Registered/Adjust/Shift/Refund Status
                  </label>
                  <div className="inputCode">
                    <select
                      id="status"
                      onChange={(e) => handleInputChange(e, setStatus)}
                      value={status}
                    >
                      <option value="">Select Status</option>
                      <option value="registered">Registered</option>
                      <option value="adjust">Adjust</option>
                      <option value="shift">Shift</option>
                      <option value="refund">Refund</option>
                    </select>
                  </div>
                </div> */}
								{/* status filter */}
								{/* <div className="inputContainer">
                  <label htmlFor="activeStatus">Payment Status</label>
                  <div className="inputCode">
                    <select
                      id="activeStatus"
                      onChange={(e) => handleInputChange(e, setPaymentStatus)}
                      value={activeStatus}
                    >
                      <option value="">Select Payment Status</option>
                      <option value="pending">Pending </option>
                      <option value="completed">Completed </option>
                    </select>
                  </div>
                </div> */}

								<div className="searchBtnContainer">
									<button className="searchBtn" onClick={handleSearch}>
										Search
									</button>
								</div>
								{selectedRole !== 3 && (
									<div className="searchBtnContainer">
										<button className="searchBtn" onClick={handleDownload}>
											Download
										</button>
									</div>
								)}
							</div>
						</div>
						<div
							className="fullScreenConatiner"
							style={{ justifyContent: "flex-end" }}
						>
							<p
								className="fullScreenAction"
								onClick={() => {
									handle.enter();
									setIsFullScreenActive(true);
								}}
							>
								<img src={ImageList.FullScreenIcon} alt="" />
								Full Screen View
							</p>
						</div>
						<FullScreen handle={handle} onChange={onFullScreenChange}>
							<div
								className={`${isFullScreenActive ? "fullScreenActive" : ""}`}
							>
								{isFullScreenActive && (
									<div className="fullScreenConatiner">
										<p
											className="fullScreenAction"
											onClick={() => {
												handle.exit();
												setIsFullScreenActive(false);
											}}
										>
											<img src={ImageList.FullScreenIcon} alt="" />
											Exit Full Screen View
										</p>
									</div>
								)}
								<div className="courses" style={{ overflowX: "auto" }}>
									<table className="batchStudentTable">
										<thead>
											<tr>
												<th>Sl. No.</th>
												<th>Company Code</th>
												<th>Participant Name</th>
												<th>Company Name</th>
												<th>Phone number</th>
												<th>Participant Mail</th>
												<th>Participant Type</th>
												<th>Participant Status</th>
												<th>Payment Month</th>
												<th>Installment Amount</th>
												<th>Payable Amount</th>
												{/* <th>Adjust/Shift/Refund</th> */}
												{/* <th>Paid Amount</th> */}
												<th>Received Amount</th>
												<th>TDS Amount</th>
												<th>Discount Amount</th>
												<th>Due Amount</th>
												<th>Received date</th>
												<th>Mode of Payment</th>

												<th>Payment Description</th>
												<th>Remarks</th>
											</tr>
										</thead>
										<tbody>
											{currentItems &&
												currentItems.length > 0 &&
												currentItems.map((payment, index) => (
													<tr key={index}>
														<td>{startSerialNumber + index}</td>
														<td>{payment.company_id}</td>
														<td style={{ whiteSpace: "wrap" }}>
															{payment.student_name}
														</td>
														<td style={{ whiteSpace: "wrap" }}>
															{payment.company_name}
														</td>
														<td>{payment.phone_number}</td>
														<td>{payment.student_mail}</td>
														<td>{payment.participant_type}</td>
														<td style={{ whiteSpace: "wrap" }}>
															{payment.participant_status}
														</td>
														<td>{payment.payment_month}</td>
														<td>{payment.installment_amount}</td>
														<td>{payment.payable_amount}</td>
														{/* <td>{payment.shift}</td> */}

														{/* <td>{payment.paid_amount}</td> */}
														<td>{payment.paid_amount}</td>
														<td>{payment.tds_amount}</td>
														<td>{payment.discount_amount}</td>
														<td>{payment.due_amount}</td>
														<td>{payment.payment_date}</td>
														<td>{payment.mode_of_payment}</td>
														<td>{payment.payment_description}</td>
														<td>{payment.remarks}</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</FullScreen>

						<div className="paginationWrapper">
							<Pagination
								key={currentPage}
								totalPages={Math.ceil(paymentList?.length / itemsPerPage)}
								currentPage={currentPage}
								onPageChange={handlePageChange}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminPaymentInfo;
