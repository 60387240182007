import autoSearchBatchesAPI from "../../apis/admin/autoSearchBatchesAPI";

export function autoSearchBatches(token, id) {
	return new Promise(async (resolve, reject) => {
		autoSearchBatchesAPI
			.getSingle(token, id)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
