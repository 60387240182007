import grantAccessAPIImp from "../../apis/admin/grantAccessAPI";

export function grantAccessAPI(token, body) {
	return new Promise(async (resolve, reject) => {
		grantAccessAPIImp
			.post(token, body)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
