import editCourseAPI from '../../apis/admin/editCourseAPI';


export function editCourse(token, body, id){
    return new Promise(async(resolve, reject) => {
      editCourseAPI.put(token, body, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}