import getCalendarListAPI from '../../apis/admin/getCalendarListAPI';

export function adminCalendarList(token){
    return new Promise(async(resolve, reject) => {
        getCalendarListAPI.getAll(token).then((res)=>{
        resolve(res)
      }).catch((err)=> { 
        reject(err)
      })
    })
}