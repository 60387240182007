import editCalendarAPI from '../../apis/admin/editCalendarAPI';


export function editCalendar(token, body, id){
    return new Promise(async(resolve, reject) => {
        editCalendarAPI.put(token, body, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}