import getClassAPI from '../../apis/admin/getClassAPI';


export function getClass(token,id){
    return new Promise(async(resolve, reject) => {
        getClassAPI.getSingle(token, id).then((res)=>{
        resolve(res)
      }).catch((err)=> {
        reject(err)
      })
    })
}