import React, { useState } from "react";
import "./ForgotPassword.scss";
import ImageList from "../../utils/ImageList";
import { Link } from "react-router-dom";
import { forgotPass } from "../../interface/implementation/forgotPassAPIImp";
import { showToasterMessage, showSuccessToast } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
function ForgotPassword() {
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState({
		userEmail: "",
	});
	const token = Cookies.get("token");

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setCredentials((prevCredentials) => ({
			...prevCredentials,
			[id]: value,
		}));
	};

	const showToast = (errorText) => {
		showToasterMessage(errorText);
	};

	const passwordForgotHandler = () => {
		if (credentials.userEmail) {
			let body = {
				email_or_username: credentials.userEmail,
			};
			forgotPass(token, body)
				.then((res) => {
					if (res.detail === "Signature has expired.") {
						navigate("/");
						showToast("Token has expired. Please log in again.");
						return;
					}
					if (res.status === "failure") {
						showToast(res.message);
						return;
					}
					if (res.detail === "User not found") {
						showToast(res.detail);
						return;
					}
					showSuccessToast("Reset password link has been emailed");
				})
				.catch((err) => showToast("Something went wrong"));
		} else {
			showToast("One or more fields are empty/wrong");
		}
	};

	return (
		<div className="resetPassword">
			<ToastContainer limit={1} />
			<div className="loginContainer">
				<img src={ImageList.BCILogo} alt="BCI Logo" className="logo" />
				<p className="title">
					Enter your registered email address to reset your password
				</p>
				<div className="loginForm">
					<div className="inputPass">
						<label htmlFor="userEmail">
							Email address <span>*</span>
						</label>
						<div className="inputUserName">
							<img
								src={ImageList.EmailIcon}
								alt="email-icon"
								className="emailIcon"
							/>
							<input
								type="text"
								id="userEmail"
								placeholder="Enter your email address"
								onChange={handleInputChange}
								value={credentials.userEmail}
							/>
						</div>
					</div>
				</div>
				<p className="bottomText">
					Password reset instructions will be sent to your registered email
					address.
				</p>
				<button className="loginBtn" onClick={passwordForgotHandler}>
					Submit{" "}
					<img src={ImageList.ArrowForward} alt="" className="forwardArrow" />
				</button>
				<Link to={"/"} className="goBackBtn">
					<img src={ImageList.GoBack} className="goBackIcon" alt="" /> Go Back
				</Link>
			</div>
		</div>
	);
}

export default ForgotPassword;
